import { toast } from "react-toastify";
function HandleFormValidation(type: String, form: any) {
  console.log("handleFormValidation type", type);
  if (type === "fx_eq") {
    console.log("handleFormValidation", form);
    console.log("handleFormValidation price ", Number(form.price));
    console.log("handleFormValidation stop ", Number(form.stop_price));
    if (
      (form.order_type.toLowerCase() === "market" ||
        form.order_type.toLowerCase() === "limit" ||
        form.order_type.toLowerCase() === "stop" ||
        form.order_type.toLowerCase() === "stop_limit") &&
      Number(String(form.quantity).replace(/[ ,]+/g, "")) === 0
    ) {
      toast.error("Invalid quantity", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (form.order_type.toLowerCase() === "limit" && Number(form.price) === 0) {
      toast.error("Please enter valid price", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (form.order_type.toLowerCase() === "stop" && Number(form.stop_price) === 0) {
      toast.error("Please enter valid stop price", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (form.order_type.toLowerCase() === "stop_limit") {
      if (Number(form.stop_price) === 0) {
        toast.error("Please enter valid stop price", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
      if (Number(form.price) === 0) {
        toast.error("Please enter valid limit price", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
    }

    // check client allocation is missing any basic details
    if (form.allocate_portfolios.length > 1 && Number(form.is_calculate) === 1) {
      console.log("handleFormValidation allocate_portfolios ", form.allocate_portfolios.length);
      let nullClientIdCount = 0;
      let nullQtyCount = 0;
      form.allocate_portfolios.forEach((obj: any) => {
        if (obj.client_id === null || Number(obj.client_id) === 0) {
          nullClientIdCount++;
        }
        if (obj.qty === null || obj.qty === undefined || obj.qty === "" || String(obj.qty) === "0" || Number(obj.qty) === 0) {
          nullQtyCount++;
        }
      });
      if (nullClientIdCount !== 0) {
        toast.error("Invalid client allocation", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
      if (nullQtyCount !== 0) {
        toast.error("Invalid client QTY. allocation", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
    }
    return true;
  }
  if (type === "bond") {
    console.log("handleFormValidation", form);
    console.log("handleFormValidation price ", Number(form.limit_price));
    if (
      (form.type.toLowerCase() === "market" ||
        form.type.toLowerCase() === "limit" ||
        form.type.toLowerCase() === "stop" ||
        form.type.toLowerCase() === "stop_limit") &&
      Number(String(form.quantity).replace(/[ ,]+/g, "")) === 0
    ) {
      toast.error("Invalid quantity", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      if (Number(String(form.quantity).replace(/[ ,]+/g, "")) < 1000) {
        toast.error("Required Min. QTY is 1000", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
    }
    if (form.type.toLowerCase() === "limit" && Number(form.limit_price) === 0) {
      toast.error("Please enter valid limit price", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    // check client allocation is missing any basic details
    if (form.allocate_portfolios.length > 1 && Number(form.is_calculate) === 1) {
      console.log("handleFormValidation allocate_portfolios ", form.allocate_portfolios.length);
      let nullClientIdCount = 0;
      let nullQtyCount = 0;
      form.allocate_portfolios.forEach((obj: any) => {
        if (obj.client_id === null || Number(obj.client_id) === 0) {
          nullClientIdCount++;
        }
        if (obj.qty === null || obj.qty === undefined || obj.qty === "" || String(obj.qty) === "0" || Number(obj.qty) === 0) {
          nullQtyCount++;
        }
      });
      if (nullClientIdCount !== 0) {
        toast.error("Invalid client allocation", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
      if (nullQtyCount !== 0) {
        toast.error("Invalid client QTY. allocation", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
    }
    return true;
  }
  if (type === "fund") {
    console.log("handleFormValidation", form);
    if (
      (form.order_type.toLowerCase() === "market" ||
        form.order_type.toLowerCase() === "limit" ||
        form.order_type.toLowerCase() === "stop" ||
        form.order_type.toLowerCase() === "stop_limit") &&
      form.fund_order_type.toLowerCase() === "share" &&
      Number(String(form.quantity).replace(/[ ,]+/g, "")) === 0
    ) {
      toast.error("Invalid quantity", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (
      (form.order_type.toLowerCase() === "market" ||
        form.order_type.toLowerCase() === "limit" ||
        form.order_type.toLowerCase() === "stop" ||
        form.order_type.toLowerCase() === "stop_limit") &&
      form.fund_order_type.toLowerCase() === "amount" &&
      Number(String(form.fund_amount).replace(/[ ,]+/g, "")) === 0
    ) {
      toast.error("Invalid amount", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    return true;
  }
  if (type === "isin") {
    let inputIsin = form.isin;
    if (!inputIsin) {
      return false;
    }

    // Check if the ISIN has any space
    if (inputIsin.includes(" ")) {
      return false;
    }

    // Remove any non-alphanumeric characters and convert to uppercase
    let cleanedIsin = inputIsin.toUpperCase().replace(/[^A-Z0-9]/g, "");

    // Check if the ISIN has the correct length
    if (cleanedIsin.length !== 12) {
      return false;
    }

    // Regular expression to check the ISIN format
    const pattern = /^[A-Z]{2}[A-Z0-9]{9}[0-9]$/;

    // Check if the ISIN matches the pattern
    if (pattern.test(cleanedIsin)) {
      // Here you could add any additional logic to compare the calculated check digit with the actual check digit
      return true;
    }
  }
}
export default HandleFormValidation;
