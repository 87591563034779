import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { useAppSelector } from "../../Redux/app/hooks";
import { buySellAmount, formatNumber } from "../../helpers/helper";
function BondSOrderConfirmationDetail({ confirm, bid_value, principal_amount }: any) {
  const form = useAppSelector((state) => state.orderSection.rfq_form_data);
  return (
    <>
      <div className="order-confirmation-table">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Transaction</th>
              <th>Type</th>
              <th>Duration</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Est. Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`${form.direction.toLowerCase() === "buy" ? "buy" : "sell"}-row`}>
              <td>
                <span>{confirm.security_name}</span>
                <div className="tooltiptext">
                  <p>{confirm.security_name}</p>
                </div>
              </td>
              <td>{form.direction.toUpperCase()}</td>
              <td>{form.type.toUpperCase()}</td>
              <td>
                <span>{confirm.time.replace(/_/g, " ")}</span>
              </td>
              <td>
                <>
                  {bid_value === 0 && form.type.toLowerCase() === "market" && form.direction.toLowerCase() === "sell" ? (
                    <b>N/A</b>
                  ) : (
                    <>{form.direction.toLowerCase() === "sell" && <b>{Number(confirm.price).toFixed(3)}</b>}</>
                  )}
                </>
              </td>
              <td>
                <b>{formatNumber(confirm.quantity)}</b>
              </td>
              <td>
                {bid_value === 0 && form.type.toLowerCase() === "market" && form.direction.toLowerCase() === "sell" ? (
                  <b>N/A</b>
                ) : (
                  <>
                    {form.direction.toLowerCase() === "sell" && (
                      <b>
                        ({formatNumber(principal_amount)}) {confirm.currency}
                      </b>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <>
          {bid_value === 0 && form.type.toLowerCase() === "market" && form.direction.toLowerCase() === "sell" ? (
            <></>
          ) : (
            <>
              {form.direction.toLowerCase() === "sell" && (
                <p>
                  At a price of {confirm.price !== 0 ? Number(confirm.price).toFixed(2) : ""} per share, the value of this trade is estimated at{" "}
                  {formatNumber(principal_amount)} {confirm.currency}
                </p>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
}

export default BondSOrderConfirmationDetail;
