import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable, useSortBy, useFilters } from "react-table";
import Filterpanel from "../../../components/OrderFilterPanel/Filterpanel";
import Loading from "../../../components/Loading/Loading";
import PopModal from "../../../components/PopModal/PopModal";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TransactionDetails from "../../../components/TransactionDetails/TransactionDetails";
import { useIsMount } from "../../../CustomHooks/useIsMount ";
import { useOnClickOutside } from "../../../CustomHooks/useOnClickOutside";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { RootState } from "../../../Redux/app/store";
import { fetchkillcount, update_iscall_portfolio_order } from "../../../Redux/features/Dashboard/DashBoardSlice";
import {
  clearOrderFilter,
  clearOrders,
  fetchOrders,
  is_update_transaction_data,
  posttransactionDetailsData,
  sortColumn,
} from "../../../Redux/features/Orders/OrderListingSlice";
import { OrdersColumns } from "../../../utils/column";
import { recordListingTabUpdate } from "../../../Redux/features/UIChange/UIController";
import { updatesetSelectedID, updatesetSideDrawer } from "../../../Redux/features/MainDashboard/MainDashBoardSlice";
import TableV8 from "../../../components/TableV8/TableV8";
import { fetchColumnData, fetchOrderedColumnData, setOrderColumnData, updateColumnData } from "../../../Redux/features/Orders/OrderColumnSlice";
import Svg from "../../../components/Svg/Svg";
type OrderListingProps = {
  // orders: any;
  isMaximize: String;
  FilterPanel: boolean;
  setFilterPanel: Function;
  dashBoard: any;
  height: any;
  width: any;
};

function OrdersListingNew({
  FilterPanel,
  // orders,
  setFilterPanel,
  isMaximize,
  dashBoard,
  height,
  width,
}: OrderListingProps) {
  const dispatch = useAppDispatch();
  const [sideDrawer, setSideDrawer] = useState(false);
  const [showTooltip, setShowTooltip] = useState("");
  const [selectedID, set_selectedID] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const orders = useAppSelector((state) => state.orders);
  // console.log(orders);
  // console.log(orders.Loader);
  const TransactionDetail = useAppSelector((state) => state.orders.transactionData);
  const TransactionData = useAppSelector((state: RootState) => state.orders.orders);
  const FilteredPortFolio = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const filter_portfolio_ids = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const iscall_portfolio_order = useAppSelector((state) => state.dashBoard.iscall_portfolio_order);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    setSideDrawer(false);
  });
  const data: any = useMemo(() => TransactionData, [TransactionData]);
  const Columns: any = useMemo(() => OrdersColumns, []);
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);

  // console.log(data);

  useEffect(() => {
    if (orders.current_page === 1 || orders.current_page === 0) {
      window.scrollTo(0, -10);
    }
  }, [orders.current_page]);

  const isUpdateTransactionData: any = useAppSelector((state) => state.orders.isUpdateTransactionData);
  const reference = useAppSelector((state) => state.orders.reference);
  const trade_date = useAppSelector((state) => state.orders.trade_date_val);
  const settlement_date_val = useAppSelector((state) => state.orders.settlement_date_val);
  const security_name_val = useAppSelector((state) => state.orders.security_name_val);
  const isin_val = useAppSelector((state) => state.orders.isin_val);
  const buy_direction_val = useAppSelector((state) => state.orders.buy_direction_val);
  const order_type = useAppSelector((state) => state.orders.order_type);
  const market_value = useAppSelector((state) => state.orders.market_value);
  const amount_val = useAppSelector((state) => state.orders.amount_val);
  const price_val = useAppSelector((state) => state.orders.price_val);
  const accured_val = useAppSelector((state) => state.orders.accured_val);
  const portfolio_val = useAppSelector((state) => state.orders.portfolio_val);
  const counterparty_val = useAppSelector((state) => state.orders.counterparty_val);
  const status_val = useAppSelector((state) => state.orders.status_val);

  const identifier_val = useAppSelector((state) => state.orders.identifier_val);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      // dispatch(clearOrders());
      // dispatch(clearOrderFilter());
    };
  }, []);

  useEffect(() => {
    if (isUpdateTransactionData === true) {
      // console.log("renderr");
      dispatch(
        fetchOrders({
          payload: {
            filter_portfolio_ids: filter_portfolio_ids,
            draw: 1,
            start: 0,
            length: 40,
            reference: reference,
            settlement_date_val:
              moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
            trade_date_oprator: "equal",
            trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
            security_name_oprator: "equal",
            security_name_val: security_name_val,
            identifier_oprator: "equal",
            identifier_val: identifier_val,
            amount_oprator: "equal",
            amount_val: amount_val,
            price_oprator: "equal",
            price_val: price_val,
            portfolio_oprator: "equal",
            portfolio_val: portfolio_val,
            counterparty_oprator: "equal",
            counterparty_val: counterparty_val,
            status_oprator: "equal",
            status_val: status_val ? (status_val?.value ? [status_val?.value] : null) : status_val,
            is_status_ordered: 1,
            isin_val: isin_val,
            accured_val: accured_val,
            buy_direction_val: buy_direction_val,
            order_type: order_type,
            market_value: market_value,
            sort_dir: sort_dir,
            sort_type: sort_type,
          },
          loader: false,
          isTransaction: false,
          showLoader: false,
        })
      );
      dispatch(is_update_transaction_data(false));
    }
    dispatch(fetchkillcount());
  }, [isUpdateTransactionData]);

  const is_mount = useIsMount();
  // console.log(isUpdateTransactionData, iscall_portfolio_order, is_mount);
  useEffect(() => {
    if (iscall_portfolio_order === true) {
      dispatch(update_iscall_portfolio_order(false));
      // console.log("is_mount", is_mount);
      if (filter_portfolio_ids.length !== 0) {
        dispatch(
          fetchOrders({
            payload: {
              filter_portfolio_ids: dashBoard?.portfoliosIds,
              draw: 1,
              start: 0,
              length: 40,
              reference: null,
              trade_date: null,
              security_name: null,
              status: null,
              settlement_date_oprator: "equal",
              settlement_date_val: null,
              trade_date_oprator: "equal",
              trade_date_val: null,
              security_name_oprator: "equal",
              security_name_val: null,
              order_type: null,
              identifier_oprator: "equal",
              identifier_val: null,
              amount_oprator: "equal",
              amount_val: null,
              market_value: null,
              price_oprator: "equal",
              price_val: null,
              portfolio_oprator: "equal",
              portfolio_val: null,
              counterparty_oprator: "equal",
              counterparty_val: null,
              status_oprator: "equal",
              status_val: null,
              sort_type: null,
              sort_dir: null,
              is_status_ordered: 1,
            },
            loader: false,
            isTransaction: false,
            showLoader: true,
          })
        ).then((res: any) => {
          if (res.payload.data.is_order_tab_active || true) {
            dispatch(recordListingTabUpdate("Orders"));
          } else {
            dispatch(recordListingTabUpdate("Transactions"));
          }
        });
      }
    }
  }, [iscall_portfolio_order]);

  useEffect(() => {
    if (!is_mount) {
      // console.log("enter AT ORDER else");
      if (filter_portfolio_ids.length !== 0 && sort_dir && sort_type) {
        dispatch(
          fetchOrders({
            payload: {
              filter_portfolio_ids: filter_portfolio_ids,
              draw: 1,
              start: 0,
              length: 40,
              reference: reference,
              settlement_date_val:
                moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
              trade_date_oprator: "equal",
              trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
              security_name_oprator: "equal",
              security_name_val: security_name_val,
              identifier_oprator: "equal",
              identifier_val: identifier_val,
              amount_oprator: "equal",
              amount_val: amount_val,
              price_oprator: "equal",
              price_val: price_val,
              portfolio_oprator: "equal",
              portfolio_val: portfolio_val,
              counterparty_oprator: "equal",
              counterparty_val: counterparty_val,
              status_oprator: "equal",
              status_val: status_val ? (status_val?.value ? [status_val?.value] : null) : status_val,
              is_status_ordered: 1,
              isin_val: isin_val,
              accured_val: accured_val,
              buy_direction_val: buy_direction_val,
              order_type: order_type,
              market_value: market_value,
              sort_dir: sort_dir,
              sort_type: sort_type,
            },
            loader: false,
            isTransaction: false,
            showLoader: true,
          })
        );
      }
    }
  }, [sort_dir, sort_type]);

  useEffect(() => {
    if (orders.next_page_index === 0) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [orders.next_page_index]);
  const [cols, setCols] = useState<any>({});
  const selectedColumns = useAppSelector((state) => state.orderColumnData.selectedColumns);

  useEffect(() => {
    setCols(selectedColumns);
  }, [selectedColumns]);

  // console.log(selectedColumns);

  //call to get column data to toggle

  useEffect(() => {
    dispatch(fetchColumnData())
      .then((result: any) => {
        console.log(result);
      })
      .catch((err: any) => {
        console.log(err);
      });
    dispatch(fetchOrderedColumnData())
      .then((result: any) => {
        console.log(result);
        dispatch(setOrderColumnData(result.payload));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const filteredColumns = useAppSelector((state) => state.orderColumnData.filteredColumns);
  const filteredColumsStringArray = useAppSelector((state) => state.orderColumnData.orderedColumns);

  //checkbox loader state
  const checkLoader = useAppSelector((state) => state.orderColumnData.loading);

  // console.log(isLoading, " infinite scroll loader");
  // console.log(orders.Loader, "filter loader");
  // console.log(checkLoader, "checkbox loader");

  return (
    <>
      <TableV8
        height={height}
        DragAndDropIcon={<Svg type={"draggIcon"} />}
        isColumnDraggble={true}
        isLoading={isLoading || orders.Loader || checkLoader}
        // isLoading={true}
        onRowClick={(rowData: any) => {
          dispatch(
            posttransactionDetailsData({
              record_type: rowData.record_type,
              id: rowData.id,
              is_status_ordered: 1,
            })
          );
          dispatch(updatesetSideDrawer(true));
          dispatch(updatesetSelectedID(rowData.id));
        }}
        columns={filteredColumns}
        filteredColumsStringArray={filteredColumsStringArray}
        // pageSize={orders.startPerPage}
        tableData={checkLoader || orders.Loader === true ? [] : data}
        columnFilter={true}
        selectStatusArray={["Placed", "Working", "Filled", "Outstanding", "Partially Filled", "Killed", "Rejected"]}
        nextFunction={() => {
          setIsLoading(true);
          dispatch(
            fetchOrders({
              payload: {
                filter_portfolio_ids: FilteredPortFolio,
                draw: orders.next_page_index,
                start: orders.orders.length,
                length: 40,
                reference: orders.reference,
                trade_date: orders.trade_date,
                security_name: orders.security_name,
                status: orders.status,
                settlement_date_val:
                  moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
                trade_date_oprator: "equal",
                trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
                security_name_oprator: orders.security_name_oprator,
                security_name_val: orders.security_name_val,
                identifier_oprator: orders.identifier_oprator,
                identifier_val: orders.identifier_val,
                amount_oprator: orders.amount_oprator,
                amount_val: orders.amount_val,
                price_oprator: orders.price_oprator,
                price_val: orders.price_val,
                portfolio_oprator: orders.portfolio_oprator,
                portfolio_val: orders.portfolio_val,
                counterparty_oprator: orders.counterparty_oprator,
                counterparty_val: orders.counterparty_val,
                status_oprator: orders.status_oprator,
                status_val: status_val ? (status_val?.value ? [status_val?.value] : null) : status_val,
                sort_dir: sort_dir,
                sort_type: sort_type,
                is_status_ordered: 1,
                isin_val: orders.isin_val,
                accured_val: orders.accured_val,
                buy_direction_val: orders.buy_direction_val,
                order_type: orders.order_type,
                market_value: orders.market_value,
              },
              loader: true,
              isTransaction: false,
              showLoader: false,
            })
          )
            .then((response: any) => {
              // Assuming the response includes the fetched orders and possibly a flag indicating if more data is available
              // Update the logic below based on your actual API response structure
              console.log(response);
              setIsLoading(false);
            })
            .catch((error: any) => {
              console.error("Error fetching orders:", error);
              setIsLoading(false);
            });
        }}
      />

      <PopModal show={FilterPanel} setShow={setFilterPanel}>
        <Filterpanel dashBoard={dashBoard} transaction={orders} setShow={setFilterPanel} />
      </PopModal>
    </>
  );
}

export default OrdersListingNew;
