function InputFilter({ field, onlyNumber, className, label }: any) {
  return (
    <div className="input-container">
      <input
        className={
          "w-full border border-borderColor rounded-[10px] focus:ring-0 animate-none focus:border-borderColor " +
          className
        }
        type={onlyNumber ? "number" : "text"}
        onKeyDown={
          onlyNumber
            ? (evt) => evt.key === "e" && evt.preventDefault()
            : () => {}
        }
        {...field}
        value={field.value ? field.value : ""}
        pattern={onlyNumber ? "^-?[0-9]d*.?d*$" : undefined}
      />
      <label
        className={` left-2 ${field.value ? "filled" : ""} `}
        htmlFor={"userName"}
      >
        {label}
      </label>
    </div>
  );
}

export default InputFilter;
