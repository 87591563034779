import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { holdingStateTypes } from "../../../types/components/types";
import { RootState } from "../../app/store";

export const postHoldingsData: any = createAsyncThunk("holdings/holdings", async (data: any) => {
  const response: any = await postData("holding", data);
  // console.log(response);
  return response.data;
});

export const postHoldingsChartData: any = createAsyncThunk("holdings/holdingsChart", async (data: any) => {
  const response: any = await postData("holding-chart-data", data);
  // console.log(response);
  return response.data;
});

const initialState: holdingStateTypes = {
  market_value: 0,
  holdingStatus: "loading",
  holdingChartStatus: "loading",
  holdings: [],
  holding_data: {},
  asset_chart_data: {},
  currency_chart_data: null,
  timeline: "one_year",
  holding_type: "all",
  is_history: true,
  chart_data: [],
};

const HoldingSlice = createSlice({
  name: "holdings",
  initialState,
  reducers: {
    update_holding_timeline: (state: holdingStateTypes, action: PayloadAction<string>) => {
      return {
        ...state,
        timeline: action.payload,
      };
    },
    update_holding_type: (state: holdingStateTypes, action: PayloadAction<string>) => {
      return {
        ...state,
        holding_type: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postHoldingsData.pending, (state) => {
        return {
          ...state,
          holdingStatus: "loading",
        };
      })
      .addCase(postHoldingsData.fulfilled, (state, { payload }) => {
        // console.log(payload);
        return {
          ...state,
          holdings: payload.data,
          holding_data: payload.holding_data,
          asset_chart_data: payload.asset_chart_data,
          currency_chart_data: payload.currency_chart_data,
          holdingStatus: "success",
        };
      })
      .addCase(postHoldingsData.rejected, (state) => {
        return {
          ...state,
          holdingStatus: "error",
        };
      })
      .addCase(postHoldingsChartData.pending, (state) => {
        return {
          ...state,
          holdingChartStatus: "loading",
        };
      })
      .addCase(postHoldingsChartData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          chart_data: payload.data.chart_data,
          holdingChartStatus: "success",
          market_value: payload.data.market_value,
        };
      })
      .addCase(postHoldingsChartData.rejected, (state) => {
        return {
          ...state,
          holdingChartStatus: "error",
        };
      });
  },
});

export const { update_holding_timeline, update_holding_type } = HoldingSlice.actions;

export const holdingsSelector = (state: RootState) => state.holdings.holdings;
export const holdingDataSelector = (state: RootState) => state.holdings.holding_data;
export const assetChartDataSelector = (state: RootState) => state.holdings.asset_chart_data;
export const currencyChartDataselector = (state: RootState) => state.holdings.currency_chart_data;
export const chartDataSelector = (state: RootState) => state.holdings.chart_data;

export const timelineSelector = (state: RootState) => state.holdings.timeline;
export const holdingTypeSelector = (state: RootState) => state.holdings.holding_type;

export default HoldingSlice.reducer;
