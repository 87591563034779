import React from 'react'
import themesConfig from "../utils/themesConfig";
import { Link } from 'react-router-dom';
const baseURL: any = themesConfig.get_react_app_login_url();
type Props = {}

const NotFound = (props: Props) => {
  return (
    <section className="page-404">
      <div className="content-404">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="number"> <b> 404 </b></div>
                    <div>
                        <h3>Page not found.</h3>
                        <p>Unfortunately, the page you have requested could not be found. You might have used an outdated bookmark or the page may have been renamed, deleted or updated. Please also check the URL you entered for spelling errors.
                            <br/><br/>
                            <Link to="/" className="btn rturn-home">Return Home</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default NotFound