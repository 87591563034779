// import { queryHelpers } from "@testing-library/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useIsMount } from "../../CustomHooks/useIsMount ";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import TextBox from "../../components/TextBox/TextBox";
import Select from "react-select";
import { DropDownStyles2 } from "../../helpers/helper";
import Button from "../../components/Button/Button";
import modalCloseIcon from "../../assets/close-icon.svg";
import { toast } from "react-toastify";
import { sendNewSecurityRequest, updatesendNewSecurityRequestStatus } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import { update_security_message, update_send_new_sec_req } from "../../Redux/features/ChartData/ChartDataSlice";
import HandleFormValidation from "../Validation/Validation";
type Props = {
  setShow: any;
  isincode: any;
};
function SecurityAddRequest({ setShow, isincode }: Props) {
  const dispatch = useAppDispatch();

  const [isin, setISIN] = useState(isincode);
  const [symbol, setSymbol] = useState("");
  const [btnlbl, setBtnlbl] = useState("Request");
  const [btn_disabled, setBtndisable] = useState(false);
  const [mktID, setMktID] = useState(0);

  const options = [
    { value: "6", label: "Forex" },
    { value: "1", label: "Equity" },
    { value: "8", label: "Fund" },
    { value: "5", label: "Bond" },
  ];

  const handleChangeISIN = (e: any) => {
    console.log(`ISIN === ${e.target.value}`);
    setISIN(e.target.value);
  };
  const updateISINEvent = (e: any) => {
    console.log(`ISIN === updateISINEvent ${e.target.value}`);
  };
  const handleChangeSymbol = (e: any) => {
    console.log(`Symbol === ${e.target.value}`);
    setSymbol(e.target.value);
  };

  const updateSymbolEvent = (e: any) => {
    console.log(`symbol === updateSymbolEvent ${e.target.value}`);
  };
  const handlemarketIDchangeEvent = (e: any) => {
    console.log("select value", e.value);
    setMktID(e.value);
  };
  const rendorNullSelect = () => {
    setMktID(0);
  };
  const confirmClose = () => {
    setMktID(0);
    setSymbol("");
    setBtnlbl("Request");
    setBtndisable(false);
    localStorage.removeItem("new_sec_isin");
    dispatch(update_security_message(false));
    dispatch(update_send_new_sec_req(false));
    updatesendNewSecurityRequestStatus(false);
  };
  const submitRequestFrom = (e: any) => {
    let isSubmit = true;
    if (mktID === 0) {
      isSubmit = false;
      toast.error("Please select market type", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (isin === "" || isin === undefined) {
      isSubmit = false;
      toast.error("Please enter ISIN", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (HandleFormValidation("isin", { isin: isin })) {
      isSubmit = true;
    } else {
      isSubmit = false;
      toast.error("Invalid ISIN code", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (symbol !== "" && symbol !== undefined) {
      if (symbol.length > 7) {
        isSubmit = false;
        toast.error("Invalid symbol", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      }
    }
    if (isSubmit === true) {
      setBtnlbl("Requesting...");
      setBtndisable(true);
      console.log(`Submit event `);
      dispatch(sendNewSecurityRequest({ isin: isin, mktID: mktID, symbol: symbol }));
    }
  };
  return (
    <>
      <div className="w-[80vw] sm:w-[400px] min-h-auto overflow-auto tbScroll max-h-[90vh] bg-layoutBg custome-modal">
        <div className="modal-header">
          <h2>New Security Request</h2>
          <span className="cursor-pointer" onClick={(e: any) => confirmClose()}>
            <img className="cursor-pointer" src={modalCloseIcon} alt="" />
          </span>
        </div>
        <div className="add-new-security-wrap modal-body">
          <div className="order-form-select-input custome-select mb-4">
            <label className="select-lable">Market Type*</label>
            <Select
              isClearable={true}
              styles={DropDownStyles2}
              placeholder="Select Market Type"
              options={options}
              // onChange={(e: any) => handlemarketIDchangeEvent(e)}
              onChange={(e: any) => {
                if (e) {
                  handlemarketIDchangeEvent(e);
                } else {
                  rendorNullSelect();
                }
              }}
              //  value={{
              //   value: form.day,
              //   label: form.day === "good_till_cancel" ? "GTC" : form.day.toUpperCase(),
              // }}
            />
          </div>
          <div className={`order-form-select-input p-relative mb-4`}>
            <div className={`input-container-left input-container`}>
              <TextBox
                label="ISIN*"
                value={isin}
                onChange={(e: any) => handleChangeISIN(e)}
                onKeyUp={(e: any) => updateISINEvent(e)}
                maxLength={12}
              />
              {/* <label className={`left-2`} htmlFor={"userName"}>
                ISIN
              </label> */}
            </div>
          </div>

          <div className={`order-form-select-input p-relative mb-4`}>
            <div className={`input-container-left input-container`}>
              {/* <input type="text" /> */}
              <TextBox
                label="Symbol/Ticker"
                value={symbol}
                onChange={(e: any) => handleChangeSymbol(e)}
                onKeyUp={(e: any) => updateSymbolEvent(e)}
                maxLength={7}
              />
            </div>
          </div>
          {/* <div className={`order-form-select-input p-relative mb-4`}>
            <div className={`input-container-left input-container`}>
              <textarea name="description" />
              <label className={`left-2`} htmlFor={"Title"}>
                Description
              </label>
            </div>
          </div> */}

          <Button
            disabled={btn_disabled}
            className={"custom-btn w-full req-btn"}
            type="button"
            varient={""}
            CTA={btnlbl}
            handleClick={(e: any) => submitRequestFrom(e)}
          />
        </div>
      </div>
    </>
  );
}

export default SecurityAddRequest;
