import { useEffect, useState } from "react";
import TextBox from "../TextBox/TextBox";
import { editWatchlistTitle, updateWatchlistTitle } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
type Props = {
  id: any;
  title: any;
  className: any;
  gridType: any;
};

const Title = ({ id, title, className, gridType }: Props) => {
  const dispatch = useAppDispatch();
  const watchlistTitle = useAppSelector((state) => state.mainDashBoard.watchlistTitle);

  const [isEdit, setIsEdit] = useState<any>(false);
  const [isEditStart, setIsEditStart] = useState<number>(0);
  const [gridTitle, setGridTitle] = useState<any>("");
  useEffect(() => {
    setGridTitle(title);
  }, [title]);
  useEffect(() => {
    if (isEditStart === 1) {
      setIsEdit(false);
      setIsEditStart(0);
    }
  }, [isEditStart]);
  useEffect(() => {
    if (watchlistTitle) {
      setIsEditStart(1);
    }
  }, [watchlistTitle]);
  const onclickChangTitle = () => {
    if (gridType === "wishList") {
      setIsEdit(true);
    }
  };
  const handleChangeWatchListTitle = (e: any) => {
    setGridTitle(e.target.value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    dispatch(updateWatchlistTitle(gridTitle));
    dispatch(editWatchlistTitle({ id: id, title: gridTitle }));
  };
  return (
    <div id="title-dropdown" className={className} title={gridTitle}>
      {!isEdit && <span onClick={() => onclickChangTitle()}>{gridTitle}</span>}
      {isEdit && (
        <>
          <form className="watchlist-panel-body w-full " onSubmit={(e: any) => handleSubmit(e)}>
            <div className="">
              <TextBox
                className="watchlist-panel-input"
                name="title"
                label="Title"
                value={gridTitle}
                onChange={(e: any) => handleChangeWatchListTitle(e)}
                isClickEvent={true}
                // onKeyUp={(e: any) => updateChangeWatchListTitle(e)}
              />
            </div>
            {/* <div className="watchlist-panel-btn text-navItemsText rounded-[10px]">
              <button type="submit" className="w-full  mx-auto py-3 rounded-[10px] scan-btn">
                Update
              </button>
            </div> */}
          </form>
        </>
      )}
    </div>
  );
};
export default Title;
