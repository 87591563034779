import { createSlice } from "@reduxjs/toolkit";
import { UIControllerTypes } from "../../../types/components/types";

const initialState: UIControllerTypes = {
  isMaximized: "minimize",
  tradeWindowTabStatus: "TickerDetails",
  recordListingTabStatus: "Orders",
  chartTabStatus: "",
  holdingTabStatus: "",
  informationwindow: false,
  holdingOrderTab: "Historical",
  holdingChartTab: "Breakdown",
};

const UIController = createSlice({
  name: "UIController",
  initialState,
  reducers: {
    updateHoldingOrderTab: (state, { payload }) => {
      return {
        ...state,
        holdingOrderTab: payload,
      };
    },
    updateHoldingChartTab: (state, { payload }) => {
      return {
        ...state,
        holdingChartTab: payload,
      };
    },
    isMaximizedDispatch: (state, { payload }) => {
      return {
        ...state,
        isMaximized: payload,
      };
    },

    showInformationWindow: (state) => {
      return {
        ...state,
        informationwindow: !state.informationwindow,
      };
    },
    recordListingTabUpdate: (state, { payload }) => {
      if (payload === "Transactions" || payload === "Cash Flow" || payload === "Performance") {
        return {
          ...state,
          chartTabStatus: payload,
          recordListingTabStatus: payload,
        };
      } else {
        return {
          ...state,
          recordListingTabStatus: payload,
        };
      }
    },
    chartTabStatusUpdate: (state, { payload }) => {
      if (payload === "Transactions" || payload === "Cash Flow" || payload === "Performance") {
        return {
          ...state,
          chartTabStatus: payload,
          recordListingTabStatus: payload,
        };
      } else {
        return {
          ...state,
          chartTabStatus: payload,
        };
      }
    },
    holdingTabStatusUpdate: (state, { payload }) => {
      return {
        ...state,
        holdingTabStatus: payload,
      };
    },
    tradeWindowTabStatusUpdate: (state, { payload }) => {
      return {
        ...state,
        holdingTabStatus: payload,
      };
    },
  },
});

export const {
  isMaximizedDispatch,
  recordListingTabUpdate,
  holdingTabStatusUpdate,
  chartTabStatusUpdate,
  tradeWindowTabStatusUpdate,
  showInformationWindow,
  updateHoldingChartTab,
  updateHoldingOrderTab,
} = UIController.actions;

export default UIController.reducer;
