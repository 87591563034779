import moment from "moment";
import { useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../Redux/app/hooks";
import { fetchOrders, filterPrice } from "../../Redux/features/Orders/OrderListingSlice";

type Props = {};

export const PriceFilterNew = (props: any) => {
  // console.log(props.isOrderTab, "filter");
  const column = props;
  const { id } = column;
  const dispatch = useAppDispatch();
  const reference = useAppSelector((state) => state.orders.reference);
  const trade_date = useAppSelector((state) => state.orders.trade_date_val);
  const settlement_date_val = useAppSelector((state) => state.orders.settlement_date_val);
  const security_name_val = useAppSelector((state) => state.orders.security_name_val);
  const order_type = useAppSelector((state) => state.orders.order_type);
  const isin_val = useAppSelector((state) => state.orders.isin_val);
  const buy_direction_val = useAppSelector((state) => state.orders.buy_direction_val);
  const amount_val = useAppSelector((state) => state.orders.amount_val);
  const market_value = useAppSelector((state) => state.orders.market_value);
  const price_val = useAppSelector((state) => state.orders.price_val);
  const accured_val = useAppSelector((state) => state.orders.accured_val);
  const portfolio_val = useAppSelector((state) => state.orders.portfolio_val);
  const counterparty_val = useAppSelector((state) => state.orders.counterparty_val);
  const status_val = useAppSelector((state) => state.orders.status_val);
  const filter_portfolio_ids = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const identifier_val = useAppSelector((state) => state.orders.identifier_val);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const scanner_on = useAppSelector((state) => state.orders.scanner_on);
  const updatedPayload = useMemo(() => {
    return {
      filter_portfolio_ids: filter_portfolio_ids,
      draw: 1,
      start: 0,
      length: 40,
      reference: reference,
      settlement_date_val:
        moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
      trade_date_oprator: "equal",
      trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
      security_name_oprator: "equal",
      security_name_val: security_name_val,
      order_type: order_type,
      identifier_oprator: "equal",
      identifier_val: identifier_val,
      amount_oprator: "equal",
      amount_val: amount_val,
      market_value: market_value,
      price_oprator: "equal",
      price_val: price_val,
      portfolio_oprator: "equal",
      portfolio_val: portfolio_val,
      counterparty_oprator: "equal",
      counterparty_val: counterparty_val,
      status_oprator: "equal",
      status_val: status_val ? (status_val?.value ? [status_val?.value] : null) : status_val,
      is_status_ordered: props.isOrderTab ? 1 : 0,
      isin_val: isin_val,
      accured_val: accured_val,
      buy_direction_val: buy_direction_val,
      sort_dir: sort_dir,
      sort_type: sort_type,
    };
  }, [
    filter_portfolio_ids,
    reference,
    security_name_val,
    order_type,
    counterparty_val,
    portfolio_val,
    identifier_val,
    settlement_date_val,
    status_val,
    trade_date,
    amount_val,
    market_value,
    sort_dir,
    sort_type,
    isin_val,
    accured_val,
    buy_direction_val,
    props.isOrderTab,
    price_val,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (price_val !== null && price_val !== "" && scanner_on !== true) {
        // console.log("run at Price");
        dispatch(
          fetchOrders({
            payload: updatedPayload,
            loader: false,
            isTransaction: props.isOrderTab ? false : true,
            showLoader: true,
          })
        );
      } else if (price_val === "" && scanner_on !== true) {
        // console.log("run at price");
        dispatch(
          fetchOrders({
            payload: { ...updatedPayload, price_val: null },
            loader: false,
            isTransaction: props.isOrderTab ? false : true,
            showLoader: true,
          })
        );

        dispatch(filterPrice({ price_val: null }));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [price_val]);

  return (
    <div className={`${column.isRightAlign ? "input-container-right" : "input-container-left"} input-container`}>
      <input
        className={`w-full border text-right border-[#e5e5e5] focus:ring-0 animate-none focus:border-secondaryBg input-item `}
        type="text"
        value={price_val ? price_val : ""}
        onChange={(e) => dispatch(filterPrice({ price_val: e.target.value, scanner_on: false }))}
      />
      <label className={`${column.isRightAlign ? "right-2" : "left-2"} ${price_val && "filled"} label-filter`} htmlFor={"userName"}>
        {id}
      </label>
    </div>
  );
};
