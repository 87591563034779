import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { FilterPanelOptions, FilterPanelSelects } from "../../utils/constants";
import moment from "moment";
import FilterIcon from "../../assets/filter.png";
import CrossIcon from "../../assets/close-icon-white.svg";
import modalCloseIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { clearOrderFilter, fetchOrders, updatePayload } from "../../Redux/features/Orders/OrderListingSlice";
import { date } from "yup";
import { updatesetFilterPanel } from "../../Redux/features/MainDashboard/MainDashBoardSlice";

type Props = {
  dashBoard: any;
  transaction: any;
  setShow: any;
};

export default function Filterpanel({ dashBoard, transaction, setShow }: Props) {
  const settlementDateOprator = useAppSelector((state) => state.orders.settlement_date_oprator);
  const tradeDateOprator = useAppSelector((state) => state.orders.trade_date_oprator);
  const SecurityNameOprator = useAppSelector((state) => state.orders.security_name_oprator);
  const IdentifierOprator = useAppSelector((state) => state.orders.identifier_oprator);
  const AmountOprator = useAppSelector((state) => state.orders.amount_oprator);
  const PriceOprator = useAppSelector((state) => state.orders.price_oprator);
  const AccountOprator = useAppSelector((state) => state.orders.portfolio_oprator);
  const CounterPartyOprator = useAppSelector((state) => state.orders.counterparty_oprator);
  const StatuesOprator = useAppSelector((state) => state.orders.status_oprator);

  const settlement_date_val = useAppSelector((state) => state.orders.settlement_date_val);
  const trade_date = useAppSelector((state) => state.orders.trade_date_val);
  const SecurityNameVal = useAppSelector((state) => state.orders.security_name_val);
  const IdentifierNameVal = useAppSelector((state) => state.orders.identifier_val);
  const AmountVal = useAppSelector((state) => state.orders.amount_val);
  const PriceVal = useAppSelector((state) => state.orders.price_val);
  const AccountVal = useAppSelector((state) => state.orders.portfolio_val);
  const currentTab = useAppSelector((state) => state.uicontroller.recordListingTabStatus);
  const CounterPartyVal = useAppSelector((state) => state.orders.counterparty_val);
  const status_val = useAppSelector((state) => state.orders.status_val);

  const defaultValues: any = {
    settlement_date_oprator:
      settlementDateOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    trade_date_oprator:
      tradeDateOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    security_name_oprator:
      SecurityNameOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    identifier_oprator:
      IdentifierOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    amount_oprator:
      AmountOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    price_oprator:
      PriceOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    portfolio_oprator:
      AccountOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    counterparty_oprator:
      CounterPartyOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    status_oprator:
      StatuesOprator === "equal"
        ? {
            value: "equal",
            label: "Equal",
          }
        : {
            value: "not_equal",
            label: " Not Equal",
          },
    settlement_date_val:
      moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
    security_name_val: SecurityNameVal,
    trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
    identifier_val: IdentifierNameVal,
    amount_val: AmountVal,
    portfolio_val: AccountVal,
    status_val: status_val,
    counterparty_val: CounterPartyVal,
    price_val: PriceVal,
  };
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });

  const dispatch = useAppDispatch();

  const resetValues = () => {
    reset(
      {
        settlement_date_oprator: {
          value: "equal",
          label: "Equal",
        },
        trade_date_oprator: {
          value: "equal",
          label: "Equal",
        },
        security_name_oprator: {
          value: "equal",
          label: "Equal",
        },
        identifier_oprator: {
          value: "equal",
          label: "Equal",
        },
        amount_oprator: {
          value: "equal",
          label: "Equal",
        },
        price_oprator: {
          value: "equal",
          label: "Equal",
        },
        portfolio_oprator: {
          value: "equal",
          label: "Equal",
        },
        counterparty_oprator: {
          value: "equal",
          label: "Equal",
        },
        status_oprator: {
          value: "equal",
          label: "Equal",
        },
        settlement_date_val: null,
        security_name_val: null,
        trade_date_val: null,
        identifier_val: "",
        amount_val: null,
        portfolio_val: null,
        status_val: null,
        counterparty_val: null,
        price_val: null,
      },
      { keepValues: false }
    );
  };
  console.log("status_val", status_val);
  const onSubmit = (data: any) => {
    console.log("call in handle submit");
    dispatch(clearOrderFilter());
    dispatch(
      updatePayload({
        settlement_date_oprator: data.settlement_date_oprator.value,
        settlement_date_val: data.settlement_date_val ? data.settlement_date_val : "",
        trade_date_oprator: data.trade_date_oprator.value,
        trade_date_val: data.trade_date_val ? data.trade_date_val : "",
        security_name_oprator: data.security_name_oprator.value,
        security_name_val: data.security_name_val,
        identifier_oprator: data.identifier_oprator.value,
        identifier_val: data.identifier_val,
        counterparty_oprator: data.counterparty_oprator.value,
        counterparty_val: data.counterparty_val,
        amount_oprator: data.amount_oprator.value,
        amount_val: data.amount_val,
        price_oprator: data.price_oprator.value,
        price_val: data.price_val,
        portfolio_oprator: data.portfolio_oprator.value,
        portfolio_val: data.portfolio_val,
        status_oprator: data.status_oprator.value,
        status_val: data.status_val,
        scanner_on: true,
      })
    );
    dispatch(
      fetchOrders({
        payload: {
          filter_portfolio_ids: dashBoard?.portfoliosIds,
          draw: 1,
          start: 0,
          length: 40,
          reference: null,
          trade_date: null,
          security_name: null,
          settlement_date_oprator: data.settlement_date_oprator.value,
          settlement_date_val: data.settlement_date_val ? moment(data.settlement_date_val).format("YYYY-MM-DD") : null,
          trade_date_oprator: data.trade_date_oprator.value,
          trade_date_val: data.trade_date_val ? moment(data.trade_date_val).format("YYYY-MM-DD") : null,
          security_name_oprator: data.security_name_oprator.value,
          security_name_val: data.security_name_val,
          identifier_oprator: data.identifier_oprator.value,
          identifier_val: data.identifier_val,
          amount_oprator: data.amount_oprator.value,
          amount_val: data.amount_val,
          price_oprator: data.price_oprator.value,
          price_val: data.price_val,
          portfolio_oprator: data.portfolio_oprator.value,
          portfolio_val: data.portfolio_val,
          counterparty_oprator: data.counterparty_oprator.value,
          counterparty_val: data.counterparty_val,
          status_oprator: data.status_oprator.value,
          status_val: Array.isArray(data.status_val) ? data.status_val.map((x: any) => x.value) : data.status_val,
          is_status_ordered: currentTab !== "Transactions" ? 1 : 0,
        },
        loader: false,
        isTransaction: currentTab !== "Transactions" ? false : true,
        showLoader: true,
      })
    );
    // setShow(false);
    dispatch(updatesetFilterPanel(false));
  };

  return (
    <div>
      <div className="flex px-6 justify-between items-center">
        <p className="text-lg font-medium">{`Scanner (${transaction.total_records})`}</p>
        <div className="flex gap-4">
          <button className="bg-[#d3d4d5] text-[#787879] text-xs my-2 py-1  flex items-center justify-between rounded-md">
            <img src={FilterIcon} className="px-1" alt="" />
            <span className="px-1" onClick={() => resetValues()}>
              Clear Values
            </span>
          </button>
          <img className="cursor-pointer" onClick={() => dispatch(updatesetFilterPanel(false))} src={modalCloseIcon} alt="" />
        </div>
      </div>
      <form className="w-full px-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full">
          <div className="w-full px-3">
            {FilterPanelSelects.map((i) => (
              <div className="py-2">
                <Controller
                  key={i.id}
                  name={i.id}
                  control={control}
                  render={({ field }) => <i.Render field={field} onlyNumber={i.onlyNumber} label={i.name} />}
                />
              </div>
            ))}
          </div>
          <div className="w-full px-3">
            {FilterPanelOptions.map((i) => (
              <div className="py-2 ">
                <Controller
                  key={i.id}
                  name={i.id}
                  control={control}
                  render={({ field }) => <i.Render field={field} onlyNumber={i.onlyNumber} label={i.name} className={"min-h-[2.75vw]"} />}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="px-3 text-navItemsText rounded-[10px] mb-4">
          <button type="submit" className="w-full  mx-auto py-3 rounded-[10px] scan-btn">
            Scan
          </button>
        </div>
      </form>
    </div>
  );
}
