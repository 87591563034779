import { useEffect, useRef, useState } from "react";
import Gridcontent from "./Gridcontent";
import Title from "./Title";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  remove_main_user_grid,
  removeLayoutFromUserLayouts,
  removeUserGridData,
  updateIsShowChangeTitle,
  updateIsShowSearchModal,
  updateIsShowmanageColumn,
  updateSelectedWatchListID,
  updatesetFilterPanel,
  updateWatchlistTitle,
  updateWatchlistTitleID,
} from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import Svg from "../Svg/Svg";
import FilterIcon from "../../assets/filter.png";
import { clearTransactionFilter, removeTransactionScannerFilter } from "../../Redux/features/Transaction/TransactionListingSlice";
import { clearOrderFilter, fetchOrders, removeOrderScannerFilter } from "../../Redux/features/Orders/OrderListingSlice";
import { confirmAlert } from "react-confirm-alert";
import { fetchKill } from "../../Redux/features/Dashboard/DashBoardSlice";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import themesConfig from "../../utils/themesConfig";
import { fetchColumnData, setZindex, updateColumnData } from "../../Redux/features/Orders/OrderColumnSlice";
import TableV8CheckboxTree from "../TableV8/TableV8CheckboxTree";
const GridItem = ({ title, gridType, id, children, handleZindex }: any) => {
  // const GridItem = (props: any) => {
  const dispatch = useAppDispatch();
  // const { title, gridType, id, style, children } = props;
  // const [transactionFilterShow, setTransactionFilterShow] = useState(false);
  // const [orderFilterShow, setOrderFilterShow] = useState(false);
  const orders = useAppSelector((state) => state.orders);
  const dashBoard = useAppSelector((state) => state.dashBoard);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  const theme_name = themesConfig.getThemeName();
  // console.log("theme_name ----", theme_name);
  // console.log("Gridcontent", gridType);
  // console.log("Gridcontent style", props);
  const handleRemoveBlock = (layout: any) => {
    //console.log("handleRemoveBlock :: ", id);
    dispatch(remove_main_user_grid(layout));
    // fire API
    dispatch(removeUserGridData(id));
  };
  // console.log("Gridcontent orders", orders);
  const clearFilters = () => {
    console.log("enter AT ORDER else 3 sss", dashBoard);
    if (dashBoard?.portfoliosIds.length > 0) {
      dispatch(removeOrderScannerFilter());
      dispatch(clearOrderFilter());
      // console.log("here");
      console.log("enter AT ORDER else 3");
      dispatch(
        fetchOrders({
          payload: {
            filter_portfolio_ids: dashBoard?.portfoliosIds,
            draw: 1,
            start: 0,
            length: 40,
            reference: null,
            trade_date: null,
            security_name: null,
            status: null,
            settlement_date_oprator: "equal",
            settlement_date_val: null,
            trade_date_oprator: "equal",
            trade_date_val: null,
            security_name_oprator: "equal",
            security_name_val: null,
            identifier_oprator: "equal",
            identifier_val: null,
            amount_oprator: "equal",
            amount_val: null,
            price_oprator: "equal",
            price_val: null,
            portfolio_oprator: "equal",
            portfolio_val: null,
            counterparty_oprator: "equal",
            counterparty_val: null,
            status_oprator: "equal",
            status_val: null,
            sort_type: null,
            sort_dir: null,
            is_status_ordered: 1,
          },
          loader: false,
          isTransaction: false,
          showLoader: true,
        })
      );
    }
  };
  const kill_counter = useAppSelector((state) => state.dashBoard.kill_counter);
  const [killPopUp, setKillPopUp] = useState(false);
  const [columnPopUp, setColumnPopUp] = useState<boolean>(false);
  const tradeKill = () => {
    dispatch(fetchKill({ record_type: "All", id: 0 }));
    setKillPopUp(false);
  };
  const updateFilters = () => {
    dispatch(updatesetFilterPanel(true));
  };
  const [dataGrid, setDataGrid] = useState(false);
  const setIsDataGrid = () => {
    let dg = dataGrid ? false : true;
    setDataGrid(dg);
    console.log("setDataGrid :: call");
  };
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    // setData(false);
    setDataGrid(false);
    // dispatch(updateIsShowChangeTitle(false));
    // setIsOpened(0);
  });
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);
  // const [updatedUserGrids, setUpdatedUserGrids] = useState<any>([]);
  // useEffect(() => {
  //   //set grid title
  //   if (userGrids) {
  //     setUpdatedUserGrids(userGrids);
  //   }
  //   console.log("setDataGrid :: call--ddd---ok", userGrids);
  // }, [userGrids]);
  const handleChangeTitle = (id: any) => {
    //get title from old data
    const filteredItems = userGrids.filter((grid: any) => grid.id === id);
    // console.log("setDataGrid :: call--ddd---ok", userGrids);
    console.log("setDataGrid :: call-----ok", filteredItems);
    dispatch(updateIsShowChangeTitle(true));
    dispatch(updateWatchlistTitleID(id));
    dispatch(updateWatchlistTitle(filteredItems[0].title));
  };
  const dummyclick = (e: any) => {};
  const [listItem, setListItem] = useState<any>();
  const [gridTitle, setGridTitle] = useState("");
  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);
  // console.log("default_security_set :: call-----ok", default_security_set);
  useEffect(() => {
    //set grid title
    if (gridType === "chartGrid") {
      setGridTitle(default_security_set.tabName);
    } else if (gridType === "orderWindow") {
      setGridTitle("Execution");
    } else if (gridType === "orderList") {
      setGridTitle("Transactions");
    } else {
      setGridTitle(title);
    }
  }, [default_security_set]);
  const watchlistTitle = useAppSelector((state) => state.mainDashBoard.watchlistTitle);
  const watchlistTitleID = useAppSelector((state) => state.mainDashBoard.watchlistTitleID);
  useEffect(() => {
    //set list item for action
    if (gridType === "wishList") {
      setListItem([
        { id: id, label: "Change Title", onclickItem: handleChangeTitle },
        { id: id, label: "Remove Grid", onclickItem: handleRemoveBlock },
      ]);
    } else {
      setListItem([{ id: id, label: "Remove Grid", onclickItem: handleRemoveBlock }]);
    }
    //set grid title
    if (gridType === "chartGrid") {
      setGridTitle(default_security_set.tabName);
    } else if (gridType === "orderWindow") {
      setGridTitle("Execution");
    } else if (gridType === "orderList") {
      setGridTitle("Transactions");
    } else {
      setGridTitle(title);
    }
  }, []);
  useEffect(() => {
    //set grid title
    if (watchlistTitleID === id) {
      setGridTitle(String(watchlistTitle));
    }
  }, [watchlistTitle, watchlistTitleID]);

  const settRef: any = useRef(null);
  const openModalManageColumn = () => {
    console.log("watchlist id------ ::", id);
    //dispatch(updateIsShowSearchModal(true));
    dispatch(updateIsShowmanageColumn(true));
  };

  const plusRef: any = useRef(null);
  const openModalSearchSecurity = () => {
    console.log("watchlist id------ ::", id);
    dispatch(updateIsShowSearchModal(true));
    dispatch(updateSelectedWatchListID(id));
  };

  const GridRef = useRef<HTMLDivElement | null>(null);

  const columnsToMakeTree = useAppSelector((state) => state.orderColumnData.selectedColumns);
  const predisabledColumn = useAppSelector((state) => state.orderColumnData.getColumnData);
  const loadingStateOfCheckBoxTree = useAppSelector((state) => state.orderColumnData.loading);
  const filteredColumnStringArrays = useAppSelector((state) => state.orderColumnData.orderedColumns);

  const handleApply = (data: string[]) => {
    dispatch(updateColumnData(data))
      .then((result: any) => {
        console.log(result);
        dispatch(fetchColumnData());
        setColumnPopUp(false);
        handleZindex();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  // console.log(columnsToMakeTree);

  const filteredColumns = useAppSelector((state) => state.orderColumnData.filteredColumns);
  // console.log(filteredColumns);

  const checkBoxRef: any = useRef(null);
  useOnClickOutside(checkBoxRef, () => {
    setColumnPopUp(false);
  });

  // console.log("areachart gridItem", GridRef.current?.offsetWidth, GridRef.current?.offsetHeight);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  // console.log(dimensions);
  useEffect(() => {
    if (GridRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          if (width > 0 && height > 0) {
            let h = height > 260 ? height - 20 : height;
            setDimensions({ width, height: h });
            // console.log(`Width: ${width}, Height: ${height}`);
          }
        }
      });

      observer.observe(GridRef.current);

      return () => observer.disconnect();
    }
  }, [GridRef]);
  const is_screen_lock = useAppSelector((state) => state.mainDashBoard.is_screen_lock);
  return (
    <div className={`grid-item  h-full`}>
      <div className="grid-item__header">
        <Title id={id} title={gridTitle} className={"grid-item-header-title"} gridType={gridType} />
        <div className="grid-item__title"></div>
        <div className="grid-item__action">
          {gridType === "orderList" && (orders.is_filter || orders.is_scanner) ? (
            <button
              onClick={() => clearFilters()}
              className="bg-[#d3d4d5] text-[#787879] text-xs my-2 py-1 px-1 mx-2 flex items-center justify-between rounded-md flex-shrink-0"
            >
              <img src={FilterIcon} className="px-1" alt="" />
              <span className="px-1">Clear Filter</span>
            </button>
          ) : null}
          {/* {gridType === "orderList" ? (
            <button
              className="small-custom-btn filter-btn "
              onClick={() => updateFilters()}
              // onClick={gridType === "orderList" ? () => setOrderFilterShow(true) : () => setTransactionFilterShow(true)}
            >
              Filters
            </button>
          ) : null} */}
          {gridType === "orderList" && kill_counter !== 0 && (
            <button
              onClick={() =>
                confirmAlert({
                  title: "Kill Orders",
                  message: "Are you sure ?",
                  buttons: [
                    {
                      label: "OK",
                      className: "custom-btn ok-btn",
                      onClick: () => tradeKill(),
                    },
                    {
                      label: "Cancel",
                      className: "custom-btn cancel-btn",
                      onClick: () => dummyclick(true),
                    },
                  ],
                })
              }
              disabled={kill_counter === 0 ? true : false}
              className="mx-2 my-2 small-custom-btn kill-btn "
            >
              Kill All
            </button>
          )}
          {gridType === "orderList" && (
            <div className="relative" ref={checkBoxRef}>
              {!is_screen_lock && (
                <button
                  onClick={() => {
                    setColumnPopUp((prev) => !prev);
                    handleZindex();
                    // dispatch(setZindex());
                  }}
                  className="mx-2 my-2 bg-secondaryBg w-[26px] h-[26px] flex justify-center items-center rounded-md"
                  type="button"
                  style={{ padding: "4px" }}
                >
                  <Svg type="edit" />
                  {/* <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3.75 2h5.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 9.25 22h-5.5A1.75 1.75 0 0 1 2 20.25V3.75C2 2.784 2.784 2 3.75 2Zm11 0h5.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 20.25 22h-5.5A1.75 1.75 0 0 1 13 20.25V3.75c0-.966.784-1.75 1.75-1.75ZM3.5 3.75v16.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25Zm11 0v16.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25Z"></path>
                </svg> */}
                </button>
              )}

              {columnPopUp && (
                <div className="absolute bg-white p-4 rounded-md shadow-lg top-[100%] right-[50%] z-[99] translate-x-1/4 text-black">
                  <TableV8CheckboxTree
                    predisabledColumn={predisabledColumn}
                    columns={columnsToMakeTree}
                    selectedColumns={filteredColumns}
                    handleApply={handleApply}
                    orderedColumns={filteredColumnStringArrays}
                    loading={loadingStateOfCheckBoxTree}
                  />
                </div>
              )}
            </div>
          )}
          {/* <span onClick={(e: any) => handleRemoveBlock(id)}>
            <Svg type="close" />
          </span> */}
          {gridType === "wishList" && (
            <span ref={plusRef} className={`${!is_screen_lock ? "round-plus mx-2" : "round-plus mr-2"}`}>
              <Svg type="plus" onClickMethod={openModalSearchSecurity} width={15} height={15} viewboxString={"0 0 27 27"} />
              {theme_name === "fintech" && (
                <div className="tooltiptext tool-right">
                  <p>Add Securities</p>
                </div>
              )}
            </span>
          )}
          {loggedType === "internal" && theme_name !== "fintech" && !is_screen_lock && (
            <span className="grid-remove-btn" ref={ref}>
              <Svg type="close" onClickMethod={(e: any) => handleRemoveBlock(id)} />
              {theme_name === "fintech" && (
                <div className="tooltiptext tool-right">
                  <p>Remove</p>
                </div>
              )}
            </span>
          )}
          {gridType === "wishList" && loggedType === "external" && theme_name !== "fintech" && !is_screen_lock && (
            <span className="grid-remove-btn" ref={ref}>
              <Svg type="close" onClickMethod={(e: any) => handleRemoveBlock(id)} />
              {theme_name === "fintech" && (
                <div className="tooltiptext tool-right">
                  <p>Remove</p>
                </div>
              )}
            </span>
          )}
          {theme_name === "fintech" && !is_screen_lock && (
            <span className="grid-remove-btn" ref={ref}>
              <Svg type="close" onClickMethod={(e: any) => handleRemoveBlock(id)} />
              {theme_name === "fintech" && (
                <div className="tooltiptext tool-right">
                  <p>Remove</p>
                </div>
              )}
            </span>
          )}
        </div>
      </div>

      <div ref={GridRef} className="grid-item__graph h-[100%] overflow-hidden">
        <Gridcontent
          gridType={gridType}
          height={dimensions?.height !== 0 && dimensions?.height}
          width={dimensions?.width !== 0 && dimensions?.width}
          gridID={id}
        />
      </div>
      {children}
    </div>
  );
};

export default GridItem;
