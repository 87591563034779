import React, { ReactNode, useRef, useState } from "react";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";

type PopModalType = {
  show: boolean;
  setShow: Function;
  children: ReactNode;
  classString?: string;
};

function PopModal({
  show,
  setShow,
  children,
  classString = "w-[33vw] min-h-[33vh] max-h-[95vh] overflow-auto  bg-layoutBg  rounded-[10px]",
}: PopModalType) {
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShow(false));
  //w-[33vw] min-h-[33vh] max-h-[95vh] overflow-auto  bg-layoutBg  rounded-[10px]
  return (
    <>
      {show ? (
        <div>
          <div className="fixed z-10 top-0 left-0 bottom-0 right-0 h-screen">
            <div className="z-30 flex items-center justify-center  bg-[rgba(0,0,0,0.6)] h-screen  ">
              <div ref={ref} className={`${classString}`}>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PopModal;
