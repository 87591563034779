import { ChangeEvent, ChangeEventHandler } from "react";

type searchBoxTypes = {
  label: string;
  placeholder: string;
  onChangeFun: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: Function;
};

function SearchBox({
  label,
  placeholder,
  onChangeFun,
  onBlur,
}: searchBoxTypes) {
  return (
    <div className="flex flex-col">
      <input
        className="order-form-search"
        placeholder={placeholder}
        type="search"
        name="1"
        id=""
        onChange={(e) => onChangeFun(e)}
        // onBlur={() => onBlur(null)}
      />
    </div>
  );
}

export default SearchBox;
