// import { useAppDispatch } from "../../Redux/app/hooks";
// import { updateCashFlowChartStatus } from "../../Redux/features/CashFlow/CashFlowSlice";
// import { update_performance_Chart_status } from "../../Redux/features/Performance/PerformanceSlice";
// import { update_transactionChartStatus } from "../../Redux/features/Transaction/TransactionSlice";
import { TabNavItemTypes } from "../../types/components/types";

const TabNavItem = ({
  id,
  title,
  activeTab,
  setActiveTab,
}: TabNavItemTypes) => {
  // const dispatch = useAppDispatch();
  const handleClick = () => {
    // if (id !== "Statement") {
    //   dispatch(update_transactionChartStatus("loading"));
    //   dispatch(updateCashFlowChartStatus("loading"));
    //   dispatch(update_performance_Chart_status("loading"));
    // }
    console.log("id---", id);
    // setActiveTab(id);
  };

  return (
    <li
      // onClick={handleClick}
      className={
        activeTab === id
          ? "text-base rounded-t-2xl px-4 py-2 text-center text-navItemsText font-bold"
          : "text-base rounded-t-2xl px-4 py-2 text-center text-navItemsText font-bold"
      }
    >
      <p className="block whitespace-nowrap overflow-hidden text-ellipsis m-auto max-w-[8rem]">
        {title}
      </p>
    </li>
  );
};
export default TabNavItem;
