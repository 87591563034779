import CrossIcon from "../../assets/close-icon-white.svg";
import RightIcon from "../../assets/right-arrow.svg";
import { buttonTypes } from "../../types/components/types";
import WhiteLoader from "../../assets/loader-white.gif";
import EmailIcon from "../../assets/envelope-open-text-thin.svg";
function Button({
  id,
  type,
  varient,
  CTA,
  handleClick,
  disabled = false,
  className = "",
  inputRef = null,
  isCrossIcon = false,
  isRightIcon = false,
  isLoaderIcon = false,
  title = "",
  isEmailIcon = false,
}: buttonTypes) {
  return (
    <button
      id={id}
      type={type}
      className={
        varient === "green"
          ? "green-btn custom-btn " + className
          : varient === "darkblue"
          ? "bg-secondaryBg custom-btn " + className
          : "red-btn custom-btn " + className
      }
      onClick={(e) => handleClick(e)}
      disabled={disabled}
      ref={inputRef}
    >
      {isCrossIcon && <img className="order-search-loader" src={CrossIcon} alt="" />}
      {isRightIcon && <img className="order-search-loader" src={RightIcon} alt="" />}
      {isLoaderIcon && <img className="order-search-loader" src={WhiteLoader} alt="" />}
      {isEmailIcon && <img className="order-search-loader" src={EmailIcon} alt="" />}

      {CTA}
      {title && (
        <div className="tooltiptext tool-right">
          <p>{title}</p>
        </div>
      )}
    </button>
  );
}

export default Button;
