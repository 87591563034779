import React from 'react'

type Props = {}

const Breadcrumb = (props: Props) => {
  return (
    <ul className="breadcrumb">
        <li><a href="/">Home</a></li>
        <li className="active">Execution</li>
    </ul>
  )
}

export default Breadcrumb