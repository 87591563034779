import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    state : 'loading'
}

const FundsSlice = createSlice({
  name: 'Funds',
  initialState,
  reducers: {
    
  }
});

export const {} = FundsSlice.actions

export default FundsSlice.reducer