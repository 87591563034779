import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import DashBoardSlice from "../features/Dashboard/DashBoardSlice";
import MainDashBoardSlice from "../features/MainDashboard/MainDashBoardSlice";
import OrderSectionSlice from "../features/OrderSection/OrderSectionSlice";
import EquitySlice from "../features/Equity/EquitySlice";
import FundsSlice from "../features/Funds/FundsSlice";
import HoldingSlice from "../features/Holdings/HoldingSlice";
import PerformanceSlice from "../features/Performance/PerformanceSlice";
import CashFlowSlice from "../features/CashFlow/CashFlowSlice";
import StatementsSlice from "../features/Statements/StatementsSlice";
import UIController from "../features/UIChange/UIController";
import ChartDataSlice from "../features/ChartData/ChartDataSlice";
import DataandResearchSlice from "../features/DataandResearchSlice/DataandResearchSlice";
import NotificationSlice from "../features/NotificationSlice/NotificationSlice";
import SettingSlice from "../features/Settings/SettingSlice";
import ShowHideColumnSlice from "../features/Settings/ShowHideColumnSlice";
import OrderListingSlice from "../features/Orders/OrderListingSlice";
import TransactionListingSlice from "../features/Transaction/TransactionListingSlice";
import OrderColumnSlice from "../features/Orders/OrderColumnSlice";

export const store = configureStore({
  reducer: {
    equity: EquitySlice,
    funds: FundsSlice,
    dashBoard: DashBoardSlice,
    mainDashBoard: MainDashBoardSlice,
    orderSection: OrderSectionSlice,
    holdings: HoldingSlice,
    transaction: TransactionListingSlice,
    orders: OrderListingSlice,
    performance: PerformanceSlice,
    cashFlows: CashFlowSlice,
    statements: StatementsSlice,
    uicontroller: UIController,
    chartdata: ChartDataSlice,
    dataAndResearch: DataandResearchSlice,
    notification: NotificationSlice,
    settings: SettingSlice,
    ShowHideColumn: ShowHideColumnSlice,
    orderColumnData: OrderColumnSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
