import React from "react";
import loadingImage from "../../assets/loadingImg.gif";
// import loadingImage from "../../assets/loadingImg.png";

function Loading({ isMaximized = "minimize", customHieghtLoader }: any) {
  return (
    <div
      style={{
        width: "100%",
        display: "table",
        tableLayout: "fixed",
      }}
      className={
        `${isMaximized === "minimize" ? ` h-[38vh] relative` : "h-[83vh] relative"} ${customHieghtLoader}`
      }
    >
      <div className="loading full-page">
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default Loading;
