import moment from "moment";
import { useEffect, useMemo } from "react";
import ReactSelect from "react-select";
import { DropDownStyles3, NewDropDownStyles3, NewDropDownStyles4 } from "../../helpers/helper";
import { useAppSelector, useAppDispatch } from "../../Redux/app/hooks";
import { fetchOrders, filterStatus, update_scanner } from "../../Redux/features/Orders/OrderListingSlice";

export function StatusSelectFilterNew(props: any) {
  const orderTabOptions = [
    { value: null, label: "All" },
    { value: "outstanding", label: "Outstanding" },
    { value: "order_pending", label: "Pending" },
    { value: "order_placing", label: "Placed" },
    { value: "order_working", label: "Working" },
    { value: "partially_filled", label: "Partially Filled" },
    { value: "order_filled", label: "Filled" },
    { value: "order_killed", label: "Killed" },
    { value: "order_rejected", label: "Rejected" },
    // { value: "locked", label: "Locked" },
  ];
  const transactionTabOptions = [
    { value: null, label: "All" },
    { value: "pending_matching_trade", label: "Pending" },
    { value: "pre-matched", label: "Submitted" },
    { value: "matched", label: "Matched" },
    { value: "unmatched", label: "Unmatched" },
    { value: "settled", label: "Settled" },
    { value: "cancelled", label: "Cancelled" },
  ];
  // console.log(props.isOrderTab, "filter");
  const column = props;
  const { id } = column;
  const dispatch = useAppDispatch();
  const reference = useAppSelector((state) => state.orders.reference);
  const trade_date = useAppSelector((state) => state.orders.trade_date_val);
  const settlement_date_val = useAppSelector((state) => state.orders.settlement_date_val);
  const security_name_val = useAppSelector((state) => state.orders.security_name_val);
  const order_type = useAppSelector((state) => state.orders.order_type);
  const isin_val = useAppSelector((state) => state.orders.isin_val);
  const buy_direction_val = useAppSelector((state) => state.orders.buy_direction_val);
  const amount_val = useAppSelector((state) => state.orders.amount_val);
  const market_value = useAppSelector((state) => state.orders.market_value);
  const price_val = useAppSelector((state) => state.orders.price_val);
  const accured_val = useAppSelector((state) => state.orders.accured_val);
  const portfolio_val = useAppSelector((state) => state.orders.portfolio_val);
  const counterparty_val = useAppSelector((state) => state.orders.counterparty_val);
  const status_val = useAppSelector((state) => state.orders.status_val);
  const filter_portfolio_ids = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const identifier_val = useAppSelector((state) => state.orders.identifier_val);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const scanner_on = useAppSelector((state) => state.orders.scanner_on);
  const updatedPayload = useMemo(() => {
    return {
      filter_portfolio_ids: filter_portfolio_ids,
      draw: 1,
      start: 0,
      length: 40,
      reference: reference,
      settlement_date_oprator: "equal",
      settlement_date_val:
        moment(settlement_date_val).format("yyyy-MM-DD") === "Invalid date" ? null : moment(settlement_date_val).format("yyyy-MM-DD"),
      trade_date_oprator: "equal",
      trade_date_val: moment(trade_date).format("yyyy-MM-DD") === "Invalid date" ? null : moment(trade_date).format("yyyy-MM-DD"),
      security_name_oprator: "equal",
      security_name_val: security_name_val,
      order_type: order_type,
      identifier_oprator: "equal",
      identifier_val: identifier_val,
      amount_oprator: "equal",
      amount_val: amount_val,
      market_value: market_value,
      price_oprator: "equal",
      price_val: price_val,
      portfolio_oprator: "equal",
      portfolio_val: portfolio_val,
      counterparty_oprator: "equal",
      counterparty_val: counterparty_val,
      status_oprator: "equal",
      status_val:
        status_val === null ? null : Array.isArray(status_val) ? status_val.map((x: any) => x.value) : status_val ? [status_val?.value] : status_val,
      is_status_ordered: props.isOrderTab ? 1 : 0,
      isin_val: isin_val,
      accured_val: accured_val,
      buy_direction_val: buy_direction_val,
      sort_dir: sort_dir,
      sort_type: sort_type,
    };
  }, [
    filter_portfolio_ids,
    reference,
    security_name_val,
    order_type,
    counterparty_val,
    portfolio_val,
    identifier_val,
    settlement_date_val,
    status_val,
    trade_date,
    amount_val,
    market_value,
    sort_dir,
    sort_type,
    isin_val,
    accured_val,
    buy_direction_val,
    props.isOrderTab,
    price_val,
  ]);
  // console.log(
  //   Array.isArray(status_val)
  //     ? status_val.map((x: any) => x.value)
  //     : status_val
  //     ? [status_val?.value]
  //     : status_val,
  //   "status_val"
  // );
  useEffect(() => {
    if (status_val !== null && status_val?.value !== null && scanner_on !== true) {
      // console.log("run at status");
      dispatch(
        fetchOrders({
          payload: updatedPayload,
          loader: false,
          isTransaction: props.isOrderTab ? false : true,
          showLoader: true,
        })
      );
      dispatch(update_scanner({ scanner_on: true }));
    } else if (status_val?.value === null && scanner_on !== true) {
      // console.log("run at status null");
      dispatch(
        fetchOrders({
          payload: {
            ...updatedPayload,
            status_val: null,
          },
          loader: false,
          isTransaction: props.isOrderTab ? false : true,
          showLoader: true,
        })
      );
    }
  }, [status_val]);

  return (
    <div className="order-form-select-input custome-select">
      <ReactSelect
        placeholder="Status"
        styles={NewDropDownStyles4}
        className="status-filter order-tab-filter custom-select"
        value={status_val ? status_val : { label: "All", value: null }}
        onChange={(e: any) => dispatch(filterStatus({ status_val: e, scanner_on: false }))}
        isSearchable={false}
        options={props.isOrderTab ? orderTabOptions : transactionTabOptions}
      />
    </div>
  );
}
