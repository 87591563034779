import moment from "moment";
// import ReactSelect from "react-select";
import { DatePickerComponent } from "../components/DatePickerComponent/DatePickerComponent";
import InputFilter from "../components/InputFilter/InputFilter";
import MultiSelectFIlter from "../components/MultiSelect/MultiSelect";
import SelectFIlter from "../components/SelectFIlter/SelectFIlter";

export const DateRangeOptions = [
  {
    htmlFor: "1 Month",
    id: "1 Month",
    value: moment().subtract(1, "months").format("YYYY-MM-DD"),
    name: "1 Month",
    label: "1 Month",
  },
  {
    htmlFor: "3 Months",
    id: "3 Months",
    value: moment().subtract(3, "months").format("YYYY-MM-DD"),
    name: "3 Months",
    label: "3 Months",
  },
  {
    htmlFor: "6 Months",
    id: "6 Months",
    value: moment().subtract(6, "months").format("YYYY-MM-DD"),
    name: "6 Months",
    label: "6 Months",
  },
  {
    htmlFor: "1 Year",
    id: "1 Year",
    value: moment().subtract(1, "years").format("YYYY-MM-DD"),
    name: "1 Year",
    label: "1 Year",
  },
  {
    htmlFor: "5 Years",
    id: "5 Years",
    value: moment().subtract(5, "years").format("YYYY-MM-DD"),
    name: "5 Years",
    label: "5 Years",
  },
  {
    htmlFor: "YTD",
    id: "YTD",
    value: moment().startOf("years").format("YYYY-MM-DD"),
    name: "YTD",
    label: "YTD",
  },
];

export const NewDateRangeOptions = [
  {
    htmlFor: "5D",
    id: "5D",
    value: [moment().subtract(6, "day").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "5D",
    label: "5D",
  },
  {
    htmlFor: "1M",
    id: "1M",
    value: [moment().subtract(1, "months").subtract(1, "day").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "1M",
    label: "1M",
  },
  {
    htmlFor: "6M",
    id: "6M",
    value: [moment().subtract(6, "month").subtract(1, "day").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "6M",
    label: "6M",
  },
  {
    htmlFor: "YTD",
    id: "YTD",
    value: [moment().startOf("years").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "YTD",
    label: "YTD",
  },
  {
    htmlFor: "1Y",
    id: "1Y",
    value: [moment().subtract(12, "month").subtract(1, "day").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "1Y",
    label: "1Y",
  },
  {
    htmlFor: "5Y",
    id: "5Y",
    value: [moment().subtract(5, "years").subtract(1, "day").format("YYYY-MM-DD"), moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "5Y",
    label: "5Y",
  },
  {
    htmlFor: "MAX",
    id: "MAX",
    value: ["2018-01-01", moment().subtract(1, "day").format("YYYY-MM-DD")],
    name: "MAX",
    label: "MAX",
  },
];


export const FilterPanelSelects: any[] = [
  {
    name: "Settlement Operator",
    id: "settlement_date_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Trade Date Operator",
    id: "trade_date_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Security Name Operator",
    id: "security_name_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Identifier Operator",
    id: "identifier_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Amount Operator",
    id: "amount_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Price Operator",
    id: "price_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Account Operator",
    id: "portfolio_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Counterparty Operator",
    id: "counterparty_oprator",
    Render: SelectFIlter,
  },
  {
    name: "Status Operator",
    id: "status_oprator",
    Render: SelectFIlter,
  },
];

export const TourOptions:any[] = [
  {
    element: "#first-element",
    popover: {
      className: "one-step-popover-class ",
      title: "Settings",
      description: "You can change currency and update your profile",
      prefferedSide: "left",
      prevBtnText: "Previous",
      nextBtnText: 'Next',
      closeBtnText: 'Skip',
      disableButtons: ["previous"],
    },
  },
  {
    element: "#second-element",
    popover: {
      className: "two-step-popover-class ",
      title: "Portfolio",
      description: "Portfolio selection aims to assess a combination of all portfolio.",
      prefferedSide: "left",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#theard-element",
    popover: {
      className: "three-step-popover-class ",
      title: "Notification",
      description: "It will show order trade status with different types of status action",
      prefferedSide: "left",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#fourth-element",
    popover: {
      className: "four-step-popover-class ",
      title: "Upload/Download",
      description: "Trade Entry bulk upload ",
      prefferedSide: "left",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#fifth-element",
    popover: {
      className: "five-step-popover-class ",
      title: "Search bar",
      description: '<div class="setp-item"><b>Search Link Trade</b><br/>Display a data where you are able to see link trade of searchable Reference, ISIN, Ticker or Counterparty.</div><div class="setp-item"><b>Upgrade Link Trade</b><br/>Shows a second level with result of link trade data Contractual date, Actual date and ISIN.</div><div class="setp-item"><b>Back to Previous Step</b><br/>It will again move to previous result of upgrade link trade.</div>',
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#sixth-element",
    popover: {
      className: "six-step-popover-class ",
      title: "Order Entry",
      description: "You make an order of four type of assets class equity, bond, funds and currency.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#seventh-element",
    popover: {
      className: "seven-step-popover-class ",
      title: "Chart",
      description: "Chart will be show based on search ISIN or security.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#eighth-element",
    popover: {
      className: "eight-step-popover-class ",
      title: "Orders",
      description: "Show all the orders with status and also you are able to check updated status as well.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip',
      doneBtnText: 'Done'
    },
  
  },
  // Add more steps as needed
]
export const WMTourOptions:any[] = [
  {
    element: "#first-element",
    popover: {
      className: "one-step-popover-class ",
      title: "Settings",
      description: "You can change currency and update your profile",
      prefferedSide: "left",
      prevBtnText: "Previous",
      nextBtnText: 'Next',
      closeBtnText: 'Skip',
      disableButtons: ["previous"],
    },
  },
  {
    element: "#second-element",
    popover: {
      className: "two-step-popover-class ",
      title: "Portfolio",
      description: "Portfolio selection aims to assess a combination of all portfolio.",
      prefferedSide: "left",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#theard-element",
    popover: {
      className: "three-step-popover-class ",
      title: "Notification",
      description: "It will show order trade status with different types of status action",
      prefferedSide: "left",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },  
  {
    element: "#fifth-element",
    popover: {
      className: "five-step-popover-class ",
      title: "Search bar",
      description: '<div class="setp-item"><b>Search Link Trade</b><br/>Display a data where you are able to see link trade of searchable Reference, ISIN, Ticker or Counterparty.</div><div class="setp-item"><b>Upgrade Link Trade</b><br/>Shows a second level with result of link trade data Contractual date, Actual date and ISIN.</div><div class="setp-item"><b>Back to Previous Step</b><br/>It will again move to previous result of upgrade link trade.</div>',
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#sixth-element",
    popover: {
      className: "six-step-popover-class ",
      title: "Order Entry",
      description: "You make an order of four type of assets class equity, bond, funds and currency.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#seventh-element",
    popover: {
      className: "seven-step-popover-class ",
      title: "Chart",
      description: "Chart will be show based on search ISIN or security.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip'
    },
  },
  {
    element: "#eighth-element",
    popover: {
      className: "eight-step-popover-class ",
      title: "Orders",
      description: "Show all the orders with status and also you are able to check updated status as well.",
      nextBtnText: 'Next',
      prevBtnText: 'Previous',
      closeBtnText: 'Skip',
      doneBtnText: 'Done'
    },
   
  },
  // Add more steps as needed
]


export const FilterPanelOptions: any[] = [
  {
    name: "Settlement",
    id: "settlement_date_val",
    Render: DatePickerComponent,
  },
  {
    name: "Trade Date",
    id: "trade_date_val",
    Render: DatePickerComponent,
  },
  {
    name: "Security Name",
    id: "security_name_val",
    Render: InputFilter,
  },
  {
    name: "Identifier",
    id: "identifier_val",
    Render: InputFilter,
  },
  {
    name: "Amount",
    id: "amount_val",
    Render: InputFilter,
    onlyNumber: true,
  },
  {
    name: "Price",
    id: "price_val",
    Render: InputFilter,
    onlyNumber: true,
  },
  {
    name: "Account",
    id: "portfolio_val",
    Render: InputFilter,
  },
  {
    name: "Counterparty",
    id: "counterparty_val",
    Render: InputFilter,
  },
  {
    name: "Status",
    id: "status_val",
    Render: MultiSelectFIlter,
  },
];
