import React, { useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import { Controller } from "react-hook-form";
import {
  fetchProfileCurrency,
  profile_selected_currency,
  portfolios,
  update_country_ids,
  update_regions_ids,
  update_is_report_country,
  update_report_send_time,
  fetchProfile,
  fetchProfileCounterparties,
  fetchTableHiddenFields,
} from "../../Redux/features/Dashboard/DashBoardSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DropDownStyles2,
  ProfileDropDownStyle,
  currencyApiParams,
  currencyApiParamsValue,
  getCountryPreferencesSelected,
  getMailingPreferencesSelected,
  getSelectedCountriesFromStrings,
  getSelectedRegionsFromStrings,
} from "../../helpers/helper";
import { Link } from "react-router-dom";
import themesConfig from "../../utils/themesConfig";
import { useForm } from "react-hook-form";
import { postChangePwd, postSettings } from "../../Redux/features/Settings/SettingSlice";
import Select, { components, ValueContainerProps } from "react-select";
import { toast } from "react-toastify";
import loaderGIF from "../../assets/loader.gif";
import * as yup from "yup";
import Dashboard from "../../pages/Dashboard";
const baseURL: any = themesConfig.get_react_app_login_url();
type Props = {};

const SettingsBox = (props: Props) => {
  const [data, setData] = useState(false);
  const [shwoProfile, setProfile] = useState(false);
  // console.log(shwoProfile);
  const [dataCurrency, setCurrency] = useState(false);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    setData(false);
    setProfile(false);
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("The name field is required.").min(2, "The name must be at least 2 characters."),
    email: yup.string(),
    old_password: yup.string().required("The old password field is required").min(4, "The old password field must be at least 4 characters"),
    report_send_time: yup.string(),
    new_password: yup.string(),
    new_password_confirmation: yup.string().when("new_password", {
      is: "",
      then: yup.string().notRequired(),
      otherwise: yup.string().required("Email is required"),
    }),
  });
  const report_send_time = useAppSelector((state: any) => state.dashBoard.profile.report_send_time);
  const selectedMailPref: any = useMemo(() => getMailingPreferencesSelected(report_send_time), [report_send_time]);
  const userEmail = useAppSelector((state: any) => state.dashBoard.profile.email);
  const userName = useAppSelector((state: any) => state.dashBoard.profile.name);
  const OptionsArray: Array<string> = ["USD", "EUR", "GBP", "BASE"];
  const selected_currency = currencyApiParamsValue(useAppSelector(profile_selected_currency));
  // console.log(selectedMailPref);
  const onCurrencyChange = (item: string) => {
    let currencyApiparam = currencyApiParams(item);

    dispatch(
      fetchProfileCurrency({
        currency: currencyApiparam,
      })
    );
    setData(false);
    setCurrency(false);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: userEmail,
      report_send_time: selectedMailPref.value,
      name: userName,
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  });

  useEffect(() => {
    setValue("report_send_time", selectedMailPref.value);
  }, [selectedMailPref]);

  const [activeTab, setActiveTab] = useState("Preferences");
  const dispatch = useAppDispatch();
  const [newpassword, setnewpassword] = useState();
  const [oldpassword, setoldpassword] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiMsg, setApiMsg] = useState("");
  // mail pref vars start
  const mail_preferences_data: any = [
    { value: 1, label: "Realtime" },
    { value: 2, label: "Daily" },
    { value: 3, label: "Weekly" },
    { value: 0, label: "No Reports" },
  ];

  const onMailPrefChange = (e: any) => {
    // console.log(e);
    setValue("report_send_time", e.value);
    dispatch(update_report_send_time(e.value));
  };
  // mail pref vars over

  // country pref vars start
  const country_preferences_data: any = [
    { value: 1, label: "All" },
    { value: 2, label: "Selected" },
  ];

  const is_report_country = useAppSelector((state: any) => state.dashBoard.profile.is_report_country);

  const selectedCountryPref: any = getCountryPreferencesSelected(is_report_country);

  const onCountryPrefChange = (e: any) => {
    // console.log("onCountryPrefChange", e);
    dispatch(update_is_report_country(e.value));
  };
  // country pref vars over

  const settings = useAppSelector((state) => state.settings);
  //   console.log(settings);

  useEffect(() => {
    // console.log("updateSettingMSg===", settings.updateSettingMSg);
    if (settings.updateSettingMSg !== "") {
      // alert(settings.updateSettingMSg);
      setApiMsg(settings.updateSettingMSg);
      setAlertOpen(true);
    }
    // return () => {
    //   console.log("updateSettingMSg=== cleanup");
    //   dispatch(update_SettingMSg());
    //   reset();
    // };
  }, [settings.updateSettingMSg]);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) =>
        toast.error(error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        })
      );
    }
  }, [errors]);
  // regions var start
  const regions_ids = useAppSelector((state: any) => state.dashBoard.profile.regions_ids);

  const regions = useAppSelector((state) => state.settings.regions_list);
  const PwdStatus = useAppSelector((state) => state.settings.post_Status);
  const selectedRegion = getSelectedRegionsFromStrings(regions_ids, regions);

  const onRegionsChange = (e: any) => {
    // console.log("onRegionsChange", e);

    let select_region_ids = e.map((i: any) => Number(i.value));

    dispatch(update_regions_ids(select_region_ids.toString()));

    //updating countries from selected regions
    let newArray = [];
    for (var j in countries) {
      if (select_region_ids.includes(countries[j].region_id)) {
        newArray.push(countries[j].id);
      }
    }

    dispatch(update_country_ids(newArray.toString()));
  };
  // regions var end

  //countries var start
  const country_ids = useAppSelector((state: any) => state.dashBoard.profile.country_ids);

  const countries = useAppSelector((state) => state.settings.counties_with_region);

  const selectedCountry = getSelectedCountriesFromStrings(country_ids, countries);

  // console.log("selectedCountry", selectedCountry);

  const onCountryChange = (e: any) => {
    // setSelectedCountry(e);
    // console.log("onCountryChange", e);
    // selectedCountry = e;
    dispatch(update_country_ids(e.map((i: any) => i.value).toString()));
  };
  //countries var end
  const onSubmit = (data: any) => {
    // console.log(data, "HEEE");
    if (data.new_password === "") {
      dispatch(
        postChangePwd({
          name: data.name,
          report_send_time: data.report_send_time,
          old_password: data.old_password,
        })
      ).then((res: any) => {
        if (res.payload.status) {
          toast.success("Your settings has been updated successfully.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          dispatch(fetchProfile());
          // dispatch(fetchProfileCounterparties());
          dispatch(fetchTableHiddenFields());
          setProfile(false);
          setData(false);
          setValue("new_password", "");
          setValue("old_password", "");
          setValue("new_password_confirmation", "");
        } else {
          toast.error(res.payload.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    } else {
      dispatch(
        postChangePwd({
          report_send_time: data.report_send_time,
          email: data.email,
          name: data.name,
          new_password: data.new_password,
          old_password: data.old_password,
          new_password_confirmation: data.new_password_confirmation,
        })
      ).then((res: any) => {
        if (res.payload.status) {
          toast.success("Your settings has been updated successfully.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          dispatch(fetchProfile());
          // dispatch(fetchProfileCounterparties());
          dispatch(fetchTableHiddenFields());
          setProfile(false);
          setData(false);
          setValue("new_password", "");
          setValue("old_password", "");
          setValue("new_password_confirmation", "");
        } else {
          toast.error(res.payload.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    }
  };

  const updateSettings = () => {
    // if (activeTab === "Preferences") {
    let payload = {
      is_report_country: selectedCountryPref.value,
      report_send_time: selectedMailPref.value,
      country_ids: selectedCountry.map((item: any) => item.value),
      region_ids: selectedRegion.map((item: any) => item.value),
    };

    // console.log(payload);

    dispatch(postSettings(payload));
    // } else if (activeTab === "Profile") {
    // dispatch(postChangePwd(profileData));
    // }
  };

  //   const PortFolio = useAppSelector(portfolios);
  const Name = useAppSelector((state: any) => state.dashBoard.profile.name);

  const handleLogout = () => {
    localStorage.clear();
    setData(false);
    window.location.href = themesConfig.get_react_app_login_url() + "logout";
  };

  const CalanderShow = useAppSelector((state) => state.dashBoard.show_calander);
  const HandbookShow = useAppSelector((state) => state.dashBoard.show_handbook);
  const loading = useAppSelector((state) => state.dashBoard.bulkUploadStatus);

  return (
    <div id="headerSetting" className={`${data === true ? "open" : ""} p-relative ml-10 upload-btn current-design-btn mt-0 btn-match`} ref={ref}>
      <button
        id="first-element"
        className="dropdown-toggle new-btn-2"
        type="button"
        data-toggle="dropdown"
        onClick={() => {
          setData((show) => !show);
          setProfile(false);
        }}
      >
        <span className="new-btn-2-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.202" height="20.2" viewBox="0 0 20.202 20.2">
            <path
              id="logout_red"
              className="innerColorStro innerColor"
              d="M3.511,17.591A9.792,9.792,0,0,1,.086,11.3,9.043,9.043,0,0,1,0,9.971a10.064,10.064,0,0,1,3.625-7.73.644.644,0,0,1,.951.021.639.639,0,0,1-.081.975A8.874,8.874,0,0,0,2.211,6.146a8.3,8.3,0,0,0-.857,3.37,8.534,8.534,0,0,0,4.1,7.852A8.057,8.057,0,0,0,8.987,18.6a8.658,8.658,0,0,0,9.107-5.514,8.48,8.48,0,0,0,.558-2.708,8.5,8.5,0,0,0-2.094-6.069,7.955,7.955,0,0,0-1.09-1.042.637.637,0,0,1-.236-.684.642.642,0,0,1,.5-.494.62.62,0,0,1,.562.144,9.645,9.645,0,0,1,3.523,5.907,9.753,9.753,0,0,1-2.694,8.85,9.5,9.5,0,0,1-5.822,2.917A11.212,11.212,0,0,1,9.918,20,9.635,9.635,0,0,1,3.511,17.591Zm5.883-7.373a.727.727,0,0,1-.039-.276q0-4.621,0-9.242a.647.647,0,1,1,1.289.009q0,2.311,0,4.621t0,4.641a.655.655,0,0,1-.52.663.7.7,0,0,1-.121.011A.65.65,0,0,1,9.394,10.219Z"
              transform="translate(0.1 0.101)"
              strokeMiterlimit="10"
              strokeWidth="0.2"
            ></path>
          </svg>
        </span>
        <div className="tooltiptext tool-right">
          <p>Logout</p>
        </div>
      </button>
      {data && (
        <ul className="dropdown-menu pull-right sm-wh-100 setting-dropdown-one">
          {/* <div className={`${dataCurrency === true ? "open" : ""} p-relative current-design-btn main-download mb-10`}>
            <button
              className="dropdown-toggle download-btn download-link"
              id="newHeaderUSDDrop"
              type="button"
              data-toggle="dropdown"
              onClick={() => setCurrency((dataCurrency) => !dataCurrency)}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10.5" viewBox="0 0 18 10.5">
                  <g id="currancy_blue" data-name="currancy blue" transform="translate(-1747 -33)">
                    <path
                      id="innerColor"
                      d="M20.25,5H3.75A.709.709,0,0,0,3,5.75v9a.709.709,0,0,0,.75.75h16.5a.709.709,0,0,0,.75-.75v-9A.709.709,0,0,0,20.25,5ZM4.875,11.75a.562.562,0,0,0-.375.075V8.675a.562.562,0,0,0,.375.075A1.857,1.857,0,0,0,6.75,6.875.562.562,0,0,0,6.675,6.5H17.25v.375A1.857,1.857,0,0,0,19.125,8.75a.562.562,0,0,0,.375-.075V11.75a3.248,3.248,0,0,0-.375-.075A1.857,1.857,0,0,0,17.25,13.55a.562.562,0,0,0,.075.375H6.675c.075,0,.075-.15.075-.3A1.857,1.857,0,0,0,4.875,11.75Zm4.875-1.5A2.25,2.25,0,1,1,12,12.5,2.21,2.21,0,0,1,9.75,10.25Z"
                      transform="translate(1744 28)"
                    />
                  </g>
                </svg>{" "}
                {selected_currency}{" "}
                <svg className="arrow-down" xmlns="http://www.w3.org/2000/svg" width="13.119" height="7.81" viewBox="0 0 13.119 7.81">
                  <path
                    className="innerColor"
                    id="_9042707_nav_arrow_down_icon"
                    data-name="9042707_nav_arrow_down_icon"
                    d="M6,9l5.5,6L17,9"
                    transform="translate(-4.94 -7.94)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    opacity="0.8"
                  />
                </svg>
              </span>
            </button>
            {dataCurrency && (
              <ul className="dropdown-menu setting-dropdown-menu">
                {OptionsArray.map((item: any, index: number) => (
                  <li
                    key={index}
                    className={`${index % 2 === 0 ? "" : ""} ${selected_currency === item ? "hidden" : ""}`}
                    onClick={() => onCurrencyChange(item)}
                  >
                    <Link to="" title={item}>
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div> */}

          <div className="form-body" onClick={() => setProfile(true)}>
            <span id="myProfileBtn" className={`${dataCurrency === true ? "open" : ""} download-link mb-10 user-main`}>
              <svg width="11.464" height="13.375" viewBox="0 0 11.464 13.375">
                <g transform="translate(-629.268 -3913.313)">
                  <path
                    id="innerColor"
                    d="M12.344,8.688A3.344,3.344,0,1,0,9,5.344,3.344,3.344,0,0,0,12.344,8.688Zm0-5.732A2.388,2.388,0,1,1,9.955,5.344,2.388,2.388,0,0,1,12.344,2.955Z"
                    transform="translate(622.656 3911.313)"
                  />
                  <path
                    id="innerColor"
                    d="M10.21,18H9.254A5.254,5.254,0,0,0,4,23.254a.478.478,0,0,0,.478.478H14.987a.478.478,0,0,0,.478-.478A5.254,5.254,0,0,0,10.21,18ZM4.984,22.777a4.3,4.3,0,0,1,4.27-3.821h.955a4.3,4.3,0,0,1,4.27,3.821Z"
                    transform="translate(625.268 3902.955)"
                  />
                </g>
              </svg>{" "}
              <span className="setting-user-name">{Name?.length <= 20 ? Name : Name?.slice(0, 20) + "..."}</span>
              <svg className="arrow-down" width="13.119" height="7.81" viewBox="0 0 13.119 7.81">
                <path
                  className="innerColor"
                  id="innerColor"
                  data-name="9042707_nav_arrow_down_icon"
                  d="M6,9l5.5,6L17,9"
                  transform="translate(-4.94 -7.94)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  opacity="0.8"
                ></path>
              </svg>
            </span>
          </div>
          {HandbookShow ? (
            <>
              <div className="form-body">
                <a href={`${process.env.REACT_APP_PUBLIC_WEB}/handbook`} id="myProfileBtn" className="download-link mb-10">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="book">
                    <path d="M52 4H15a6 6 0 0 0-6 6v45a5.2 5.2 0 0 0 5.23 5H33a1 1 0 0 0 0-2H14.23A3.18 3.18 0 0 1 11 55v-.27a2.73 2.73 0 0 1 .11-.6 3 3 0 0 1 .12-.3c0-.08.07-.16.1-.23a3.73 3.73 0 0 1 .52-.71A3 3 0 0 1 14 52h38a2.6 2.6 0 0 0 .56-.06h.18l.26-.07V53a1 1 0 0 1-1 1H14a1 1 0 0 0 0 2h38a3 3 0 0 0 1-.18V57a1 1 0 0 1-1 1H42a1 1 0 0 0 0 2h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3ZM13.1 50.09l-.28.07a4.43 4.43 0 0 0-.59.18c-.1 0-.2.08-.3.12a5.21 5.21 0 0 0-.52.28l-.27.17L11 51V10a4 4 0 0 1 4-4v44h-1a4.43 4.43 0 0 0-.9.09ZM17 50V6h35a1 1 0 0 1 1 1v42a1 1 0 0 1-1 1Z"></path>
                    <path d="M46 14H24a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7H25v-5h20zm-1 17H26a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2zm0 5H26a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2zm-6 15h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z"></path>
                  </svg>
                  Handbook
                </a>
              </div>
            </>
          ) : null}
          {CalanderShow ? (
            <>
              <div className="form-body">
                <a href={`${baseURL}calendar`} id="myProfileBtn" className="download-link mb-10">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="calendar">
                    <path d="M5 4.5a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 1 0v2a.5.5 0 0 1-.5.5zM11 4.5a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 1 0v2a.5.5 0 0 1-.5.5z"></path>
                    <path d="M13 14.5H3c-.827 0-1.5-.673-1.5-1.5V4c0-.827.673-1.5 1.5-1.5h10c.827 0 1.5.673 1.5 1.5v9c0 .827-.673 1.5-1.5 1.5zM3 3.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5H3z"></path>
                    <path d="M14 6.5H2a.5.5 0 0 1 0-1h12a.5.5 0 0 1 0 1zM5.5 7.5h1v1h-1zM7.5 7.5h1v1h-1zM9.5 7.5h1v1h-1zM11.5 7.5h1v1h-1zM3.5 9.5h1v1h-1zM5.5 9.5h1v1h-1zM7.5 9.5h1v1h-1zM9.5 9.5h1v1h-1zM11.5 9.5h1v1h-1zM3.5 11.5h1v1h-1zM5.5 11.5h1v1h-1zM7.5 11.5h1v1h-1z"></path>
                  </svg>
                  Calendar
                </a>
              </div>
            </>
          ) : null}
          <div className="form-body">
            <a onClick={() => handleLogout()} className="logout-btn mb-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.202" height="20.2" viewBox="0 0 20.202 20.2">
                <path
                  id="logout_red"
                  className="innerColorStro innerColor"
                  d="M3.511,17.591A9.792,9.792,0,0,1,.086,11.3,9.043,9.043,0,0,1,0,9.971a10.064,10.064,0,0,1,3.625-7.73.644.644,0,0,1,.951.021.639.639,0,0,1-.081.975A8.874,8.874,0,0,0,2.211,6.146a8.3,8.3,0,0,0-.857,3.37,8.534,8.534,0,0,0,4.1,7.852A8.057,8.057,0,0,0,8.987,18.6a8.658,8.658,0,0,0,9.107-5.514,8.48,8.48,0,0,0,.558-2.708,8.5,8.5,0,0,0-2.094-6.069,7.955,7.955,0,0,0-1.09-1.042.637.637,0,0,1-.236-.684.642.642,0,0,1,.5-.494.62.62,0,0,1,.562.144,9.645,9.645,0,0,1,3.523,5.907,9.753,9.753,0,0,1-2.694,8.85,9.5,9.5,0,0,1-5.822,2.917A11.212,11.212,0,0,1,9.918,20,9.635,9.635,0,0,1,3.511,17.591Zm5.883-7.373a.727.727,0,0,1-.039-.276q0-4.621,0-9.242a.647.647,0,1,1,1.289.009q0,2.311,0,4.621t0,4.641a.655.655,0,0,1-.52.663.7.7,0,0,1-.121.011A.65.65,0,0,1,9.394,10.219Z"
                  transform="translate(0.1 0.101)"
                  strokeMiterlimit="10"
                  strokeWidth="0.2"
                ></path>
              </svg>{" "}
              Logout
            </a>
          </div>
        </ul>
      )}
      {shwoProfile && (
        <ul className="dropdown-menu pull-right sm-wh-100 profil-setting-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-main flex-wrap">
              <div className="form-group custom-input">
                <div className="input-container">
                  <input
                    defaultValue={userName}
                    className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                  />
                  <label className={` left-2 ${watch("name") !== "" ? "filled" : ""}`} htmlFor={"userName"}>
                    Name <span className="required">*</span>
                  </label>
                </div>
              </div>
              <div className="form-group custom-input">
                <div className="input-container">
                  <input
                    defaultValue={userEmail}
                    disabled
                    className="w-full border cursor-not-allowed border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                    type="text"
                    {...register("email", { required: true })}
                  />
                  <label className={` left-2 ${watch("email") !== "" && "filled"}`} htmlFor={"email"}>
                    Email <span className="required">*</span>
                  </label>
                </div>
              </div>
              <div className="form-group custome-select mb-2">
                <Controller
                  name={"report_send_time"}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <label className="select-lable">
                        Mailing Preferences <span className="required">*</span>
                      </label>
                      <Select
                        defaultValue={selectedMailPref}
                        onChange={(e: any) => {
                          onMailPrefChange(e);
                        }}
                        options={mail_preferences_data.filter((item: any) => item.value !== selectedMailPref.value)}
                        styles={ProfileDropDownStyle}
                      />
                    </>
                  )}
                />
              </div>
              <div className="form-group custom-input">
                <div className="input-container">
                  <input
                    defaultValue={oldpassword}
                    className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                    type="password"
                    {...register("old_password", {
                      required: true,
                    })}
                  />
                  <label className={` left-2 ${watch("old_password") !== "" && "filled"}`} htmlFor={"old_password"}>
                    Old Password <span className="required">*</span>
                  </label>
                </div>
              </div>
              <div className="form-group custom-input">
                <div className="input-container">
                  <input
                    defaultValue={newpassword}
                    className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                    type="password"
                    {...register("new_password")}
                  />
                  <label className={` left-2 ${watch("new_password") !== "" && "filled"}`} htmlFor={"new_password"}>
                    New Password <span className="required">*</span>
                  </label>
                </div>
              </div>
              <div className="form-group custom-input">
                <div className="input-container">
                  <input
                    className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                    type="password"
                    {...register("new_password_confirmation")}
                  />
                  <label className={` left-2 ${watch("new_password_confirmation") !== "" && "filled"}`} htmlFor={"new_password_confirmation"}>
                    Confirm Password <span className="required">*</span>
                  </label>
                </div>
              </div>

              <div className="w-full profile-setting-group-btn">
                <button onClick={() => setProfile(false)} className={"mx-0 h-[100%]  custom-btn "} type="button">
                  Cancel
                </button>
                <button className={"mx-0 h-[100%] custom-btn "} type="submit" disabled={PwdStatus === "loading"}>
                  {PwdStatus === "loading" ? (
                    <>
                      <img className="uploadLoader h-3 w-3 mx-auto" src={loaderGIF} alt="loading" />
                    </>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </form>
        </ul>
      )}
    </div>
  );
};

export default SettingsBox;
