import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchData, postData } from "../../../services/apiFunctions";
import { settingsStateTypes } from "../../../types/components/types";

export const fetchSettingsData: any = createAsyncThunk("settings/fetchSettingsData", async () => {
  const response: any = await fetchData(`get-settings`);
  return response.data;
});

export const postSettings: any = createAsyncThunk("settings/postSettings", async (data) => {
  const response: any = await postData("profile/settings", data);
  return response.data;
});

export const postChangePwd: any = createAsyncThunk("settings/postChangePwd", async (data) => {
  const response: any = await postData("profile/change-password", data);
  return response.data;
});

const initialState: settingsStateTypes = {
  Settings_status: "loading",
  post_Status: "success",
  counties_with_region: [],
  regions_list: [],
  countries_with_security: [],
  currency_with_security: [],
  ticker_with_security: [],
  second_countries_with_security: [],
  second_currency_with_security: [],
  exchange_with_security: [],
  type_with_security: [],
  group_with_security: [],
  sector_with_security: [],
  area_with_security: [],
  gcategory_with_security: [],
  rating_with_security: [],
  company_with_security: [],
  category_with_security: [],
  peer_with_security: [],
  trades_status: [],
  booking_forms: [],
  updateSettingMSg: "",
};

const SettingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    update_SettingMSg: (state) => {
      return {
        ...state,
        updateSettingMSg: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsData.pending, (state) => {
        return {
          ...state,
          Settings_status: "loading",
        };
      })
      .addCase(fetchSettingsData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          counties_with_region: payload.data.counties_with_region,
          regions_list: payload.data.regions_list,
          countries_with_security: payload.data.countries_with_security,
          currency_with_security: payload.data.currency_with_security,
          ticker_with_security: payload.data.ticker_with_security,
          second_countries_with_security: payload.data.second_countries_with_security,
          second_currency_with_security: payload.data.second_currency_with_security,
          exchange_with_security: payload.data.exchange_with_security,
          type_with_security: payload.data.type_with_security,
          group_with_security: payload.data.group_with_security,
          sector_with_security: payload.data.sector_with_security,
          area_with_security: payload.data.area_with_security,
          gcategory_with_security: payload.data.gcategory_with_security,
          rating_with_security: payload.data.rating_with_security,
          company_with_security: payload.data.company_with_security,
          category_with_security: payload.data.category_with_security,
          peer_with_security: payload.data.peer_with_security,
          trades_status: payload.data.trades_status,
          booking_forms: payload.data.booking_forms,
          Settings_status: "success",
        };
      })
      .addCase(fetchSettingsData.rejected, (state) => {
        return {
          ...state,
          Settings_status: "error",
        };
      })
      .addCase(postSettings.pending, (state) => {
        return {
          ...state,
          post_Status: "loading",
        };
      })
      .addCase(postSettings.fulfilled, (state, { payload }) => {
        if (payload.status === 1) {
          return {
            ...state,
            post_Status: "success",
            updateSettingMSg: payload.message,
          };
        } else {
          return {
            ...state,
            post_Status: "error",
            updateSettingMSg: payload.message,
          };
        }
      })
      .addCase(postSettings.rejected, (state, { payload }) => {
        return {
          ...state,
          post_Status: "error",
        };
      })

      .addCase(postChangePwd.pending, (state) => {
        return {
          ...state,
          post_Status: "loading",
        };
      })
      .addCase(postChangePwd.fulfilled, (state, { payload }) => {
        if (payload.status === 1) {
          return {
            ...state,
            post_Status: "success",
            updateSettingMSg: payload.message,
          };
        } else {
          return {
            ...state,
            post_Status: "error",
            updateSettingMSg: payload.message,
          };
        }
      })
      .addCase(postChangePwd.rejected, (state, { payload }) => {
        return {
          ...state,
          post_Status: "error",
        };
      });
    // postChangePwd
  },
});

export const { update_SettingMSg } = SettingSlice.actions;

export default SettingSlice.reducer;
