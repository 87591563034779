import { TabContentTypes } from "../../types/components/types";

const TabContent = ({ id, activeTab, children, isMaximize, className = "" }: TabContentTypes) => {
  return activeTab === id ? (
    // <div
    //   className={
    //     isMaximize === "minimize"
    //       ? `TabContent h-[calc(100%-2.2rem)] rounded-b-2xl overflow-auto tbScroll ` +
    //         className
    //       : "TabContent h-[calc(100vh-(140px+4rem))] rounded-b-2xl overflow-auto tbScroll " +
    //         className
    //   }
    // >
    <div className="TabContent rounded-b-2xl overflow-auto tbScroll">{children}</div>
  ) : null;
};

export default TabContent;
