import React, { useState, useEffect } from "react";
import modalCloseIcon from "../../assets/close-icon.svg";
import ReactDom from "react-dom";
import { useAppDispatch } from "../../Redux/app/hooks";
import { updateresponsePlaceOrder } from "../../Redux/features/OrderSection/OrderSectionSlice";
function OrderConfirmationModal({ show = true, confirmClose, header, children, Componentref }: any) {
  function createMarkup(header: any) {
    return { __html: header };
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(updateresponsePlaceOrder());
    };
    // eslint-disable-next-line
  }, []);
  const modalRoot: any = document.getElementById("modal-root");
  return ReactDom.createPortal(
    <>
      {show ? (
        <div className="fixed z-40 top-0 left-0 bottom-0 right-0 h-screen  ">
          <div className="flex items-center justify-center  bg-[rgba(0,0,0,0.68)] z-10 h-screen  ">
            <div ref={Componentref} className="w-[80vw] sm:w-[990px] min-h-auto overflow-auto tbScroll max-h-[90vh] bg-layoutBg custome-modal ">
              <div className="modal-header">
                <h2 dangerouslySetInnerHTML={createMarkup(header)}></h2>
                <span className="cursor-pointer" onClick={(e: any) => confirmClose()}>
                  <img src={modalCloseIcon} alt="" />
                </span>
              </div>
              <div className="modal-body">{children}</div>
              {/* <div>Footer</div> */}
            </div>
          </div>
        </div>
      ) : null}
    </>,
    modalRoot
  );
}

export default OrderConfirmationModal;
