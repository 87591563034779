import moment from "moment";
import { StatusCellForV8table } from "../../../utils/TableCells";
import { StatusSelectFilterNew } from "../../../components/OrdersFilter/OrderStatusFilterNew";
import { ReferenceFilterNew } from "../../../components/OrdersFilter/OrderreferenceFilterNew";
import { TradeDateFilterNew } from "../../../components/OrdersFilter/OrderTradeDateFilterNew";
import { SettlementDateFilterNew } from "../../../components/OrdersFilter/OrderSettlementDateFilterNew";
import { SecurityNameFilterNew } from "../../../components/OrdersFilter/OrderSecurityNameFilterNew";
import { OrderTypeFilterNew } from "../../../components/OrdersFilter/OrderTypeFilterNew";
import { ISINFilterNew } from "../../../components/OrdersFilter/OrderISINFilterNew";
import { BuyDirectionFilterNew } from "../../../components/OrdersFilter/OrderBuyDirectionFilterNew";
import { AmountFilterNew } from "../../../components/OrdersFilter/AmountFilterNew";
import { PriceFilterNew } from "../../../components/OrdersFilter/OrderPriceFilterNew";
import { AccuredFilterNew } from "../../../components/OrdersFilter/OrderAccuredFilterNew";
import { MarketValueFilterNew } from "../../../components/OrdersFilter/MarketValueFilterNew";
import { OrderAccountFilterNew } from "../../../components/OrdersFilter/OrderAccountFilternew";
import { CounterPartyFilterNew } from "../../../components/OrdersFilter/OrderCounterPartyFilterNew";

export const columns = [
  {
    id: "Reference",
    accessorFn: (row: { code: any }) => row.code,
    sort_id: "code",
    cell: (info: { getValue: () => any }) => info.getValue(),
    header: () => <span>Reference</span>,
    Filter: (props: any) => <ReferenceFilterNew {...props} />,
    footer: (props: { column: { id: any } }) => props.column.id,
    minSize: "15%",
    enableColumnFilter: true,
    isRightAlign: false,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Trade Date",
    header: () => <span>Trade Date</span>,
    accessorKey: "trade_create",
    sort_id: "trade_create",
    minSize: "11%",
    cell: (props: any) => <span>{moment(props.row.original.trade_create).format("MM/DD/YYYY")}</span>,
    enableColumnFilter: true,
    isRightAlign: false,
    Filter: (props: any) => <TradeDateFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Settlement",
    header: () => <span>Settlement</span>,
    sort_id: "settlement_date",
    accessorKey: "settlement_date",
    minSize: "11%",
    isRightAlign: false,
    Filter: (props: any) => <SettlementDateFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Security",
    header: () => <span>Security</span>,
    sort_id: "security_name",
    accessorKey: "security_name",
    minSize: "14%",
    isRightAlign: false,
    Filter: (props: any) => <SecurityNameFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Order Type",
    accessorFn: (row: { order_type: any }) => row.order_type,
    cell: (props: any) => <>{props?.row?.original?.order_type?.toUpperCase()}</>,
    header: () => <span>Order Type</span>,
    sort_id: "order_type",
    accessorKey: "order_type",
    minSize: "11%",
    isRightAlign: false,
    Filter: (props: any) => <OrderTypeFilterNew {...props} />,

    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "ISIN",
    header: () => <span>ISIN</span>,
    sort_id: "isin_code",
    accessorKey: "isin_code",
    minSize: "20%",
    isRightAlign: false,
    Filter: (props: any) => <ISINFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Direction",
    header: () => <span>Direction</span>,
    sort_id: "direction",
    accessorKey: "direction",
    minSize: "9%",
    isRightAlign: false,
    Filter: (props: any) => <BuyDirectionFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Amount",
    header: () => <span>Amount</span>,
    sort_id: "original_quantity",
    accessorKey: "original_quantity",
    sortType: "basic",
    minSize: "14%",
    isRightAlign: false,
    Filter: (props: any) => <AmountFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
    textAlign: "right",
  },
  {
    id: "Price",
    header: () => <span>Price</span>,
    sort_id: "price",
    accessorKey: "price",
    minSize: "10%",
    isRightAlign: false,
    Filter: (props: any) => <PriceFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
    textAlign: "right",
  },
  {
    id: "Accrued",
    header: () => <span>Accrued</span>,
    sort_id: "accrued",
    accessorKey: "accrued",
    minSize: "10%",
    isRightAlign: false,
    Filter: (props: any) => <AccuredFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
    textAlign: "right",
  },
  {
    id: "Market Value",
    sort_id: "market_value",
    header: () => <span>Market Value</span>,
    accessorKey: "market_value",
    minSize: "12%",
    isRightAlign: false,
    Filter: (props: any) => <MarketValueFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
    textAlign: "right",
  },
  {
    id: "Portfolio",
    header: () => <span>Portfolio</span>,
    accessorKey: "portfolio",
    sort_id: "portfolio",
    minSize: "10%",
    isRightAlign: false,
    Filter: (props: any) => <OrderAccountFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Counterparty",
    header: () => <span>Counterparty</span>,
    accessorKey: "legal_name_the_entity",
    sort_id: "legal_name_the_entity",
    minSize: "12%",
    isRightAlign: false,
    Filter: (props: any) => <CounterPartyFilterNew {...props} />,
    columnVisible: true,
    isOrderTab: true,
  },
  {
    id: "Status",
    header: () => <span>Status</span>,
    accessorKey: "status",
    sort_id: "status",
    accessorFn: (row: { status: any }) => row.status,
    minSize: "16%",
    isRightAlign: false,
    Filter: (props: any) => <StatusSelectFilterNew {...props} />,
    columnVisible: true,
    cell: (props: any) => <StatusCellForV8table {...props} />,
    isOrderTab: true,
  },
];
