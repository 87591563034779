import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  live_update_candle_chart_data,
  update_candle_chart_data,
  update_chart_data,
  update_is_live_update,
} from "../../../../Redux/features/ChartData/ChartDataSlice";
import moment from "moment";
import socket from "../../../../../src/socket";
function CandleStickChart(props: any) {
  const dispatch = useAppDispatch();
  const chartRef = useRef<any>(null);
  const candleChartData = useAppSelector((state) => state.chartdata.candle_chart_data);
  const live_candleChartData = useAppSelector((state) => state.chartdata.live_candle_chart_data);
  const isliveUpdate = useAppSelector((state) => state.chartdata.is_live_update);
  const [candleData, setCandleData] = useState<any>([]);
  const [ischangeSecurity, setIschangeSecurity] = useState(true);
  const { chart_data, isMaximized, security_name, globalTimeline } = props;
  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);
  useEffect(() => {
    console.log("CHART:: candle globalTimeline", globalTimeline);
    // chartRef.current?.chart?.series[0].remove(true);
    // if (ischangeSecurity) {
    //   setIschangeSecurity(true);
    // }
  }, [default_security_set]);
  useEffect(() => {
    (async () => {
      if (live_candleChartData.length > 0 && isliveUpdate) {
        let dataLen = live_candleChartData.length;
        if (dataLen === 1) {
          const series: any = chartRef.current?.chart?.series[0];

          for (var i = 0; i < dataLen; i++) {
            let d = live_candleChartData[i];
            if (candleData.length > 0) {
              let lastDataPoint = candleData[candleData.length - 1];
              // console.log("CHART:: POINT lastDataPoint", lastDataPoint);
              // console.log(`CHART:: POINT condition", ${lastDataPoint[0]} === ${new Date(d[0]).valueOf()}`);
              let liveDate = new Date(d[0]).valueOf();
              if (lastDataPoint[0] === liveDate) {
                if (series.data[series.data.length - 1] === undefined) return true;
                series.data[series.data.length - 1].update(
                  [
                    new Date(d[0]).valueOf(),
                    Number(parseFloat(d[1]).toFixed(2)),
                    Number(parseFloat(d[2]).toFixed(2)),
                    Number(parseFloat(d[3]).toFixed(2)),
                    Number(parseFloat(d[4]).toFixed(2)),
                    Number(parseFloat(d[5]).toFixed(2)),
                  ],
                  true,
                  true
                );
              } else {
                // console.log("CHART:: POINT  Add", d);
                series.addPoint(
                  [
                    new Date(d[0]).valueOf(),
                    Number(parseFloat(d[1]).toFixed(2)),
                    Number(parseFloat(d[2]).toFixed(2)),
                    Number(parseFloat(d[3]).toFixed(2)),
                    Number(parseFloat(d[4]).toFixed(2)),
                    Number(parseFloat(d[5]).toFixed(2)),
                  ],
                  true,
                  true
                );
              }
            }
          }
        }
      }
    })();
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [live_candleChartData]);
  useEffect(() => {
    // console.log("CHART::  equity_chart_bidask_data_channel_++++ In candle", candleChartData);
    console.log("CHART:: Dependency  isliveUpdate", isliveUpdate);
    console.log("CHART:: Dependency  candleChartData", candleChartData);
    (async () => {
      console.log("CHART:: Dependency INNNN  isliveUpdate", isliveUpdate);
      console.log("CHART:: Dependency  INNNN candleChartData", candleChartData);
      if (candleChartData.length > 1 && !isliveUpdate) {
        let dataLen = candleChartData.length;
        if (dataLen > 1) {
          for (var i = 0; i < dataLen; i++) {
            let d = candleChartData[i];

            setCandleData((candleData: any) => [
              ...candleData,
              [
                new Date(d[0]).valueOf(),
                Number(parseFloat(d[1]).toFixed(2)),
                Number(parseFloat(d[2]).toFixed(2)),
                Number(parseFloat(d[3]).toFixed(2)),
                Number(parseFloat(d[4]).toFixed(2)),
                Number(parseFloat(d[5]).toFixed(2)),
              ],
            ]);
          }
        }
      } else {
        if (candleChartData.length === 1 && isliveUpdate) {
          let dataLen = candleChartData.length;
          if (dataLen === 1) {
            const series: any = chartRef.current?.chart?.series[0];
            if (candleData.length > 0) {
              for (var i = 0; i < dataLen; i++) {
                let d = candleChartData[i];

                let lastDataPoint = candleData[candleData.length - 1];
                // console.log("CHART:: POINT lastDataPoint", lastDataPoint);
                // console.log(`CHART:: POINT condition", ${lastDataPoint[0]} === ${new Date(d[0]).valueOf()}`);
                let liveDate = new Date(d[0]).valueOf();
                if (lastDataPoint[0] === liveDate) {
                  if (series.data[series.data.length - 1] === undefined) return true;
                  series.data[series.data.length - 1].update(
                    [
                      new Date(d[0]).valueOf(),
                      Number(parseFloat(d[1]).toFixed(2)),
                      Number(parseFloat(d[2]).toFixed(2)),
                      Number(parseFloat(d[3]).toFixed(2)),
                      Number(parseFloat(d[4]).toFixed(2)),
                      Number(parseFloat(d[5]).toFixed(2)),
                    ],
                    true,
                    true
                  );
                } else {
                  // console.log("CHART:: POINT  Add", d);
                  series.addPoint(
                    [
                      new Date(d[0]).valueOf(),
                      Number(parseFloat(d[1]).toFixed(2)),
                      Number(parseFloat(d[2]).toFixed(2)),
                      Number(parseFloat(d[3]).toFixed(2)),
                      Number(parseFloat(d[4]).toFixed(2)),
                      Number(parseFloat(d[5]).toFixed(2)),
                    ],
                    true,
                    true
                  );
                }
              }
            }
          }
        }
      }
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, [candleChartData]);

  useEffect(() => {
    if (candleData.length > 0) {
      let max = candleData[candleData.length - 1][0];
      let min = candleData[candleData.length > 40 ? candleData.length - 40 : 0][0];
      console.log(`CHART::  equity_chart_bidask_data_channel_ MIN MAX" ${min} = ${max} `);
      console.log("CHART::  equity_chart_bidask_data_channel_ candleData", candleData);
      setCandleChartOptions({
        ...candleChartOptions,
        xAxis: {
          min: min,
          max: max,
          labels: {
            formatter: function () {
              var point: any = this;
              if (String(globalTimeline) === "5Y" || String(globalTimeline) === "1Y") {
                return moment(point.value).format("MMM HH:mm");
              } else {
                return moment(point.value).format("HH:mm");
              }
            },
          },
        },
        title: {
          text: "",
        },
        series: [
          {
            type: "candlestick",
            name: security_name,
            data: candleData,
            pointWidth: 15,
          },
        ],
      });
    } else {
      setCandleChartOptions({
        ...candleChartOptions,
        title: {
          // text: "Nothing To Display Here",
          text: "",
        },
        series: [
          {
            type: "",
            name: security_name,
            data: [],
          },
        ],
      });
    }
  }, [candleData]);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // chart.setSize(props.containerWidth, props.containerHeight);
    if (Number(props.containerHeight) && Number(props.containerWidth)) {
      setTimeout(() => {
        const reflow = () => chart.reflow();
        window.addEventListener("resize", reflow);
        if (Object.keys(chart).length !== 0) {
          chart.setSize(props.containerWidth - 20, props.containerHeight - 36);
        }
        return () => window.removeEventListener("resize", reflow);
      }, 800);
    }
  }, [props.containerHeight, props.containerWidth]);
  // candle Chart Options

  const [candleChartOptions, setCandleChartOptions] = useState<any>({
    chart: {
      type: "candlestick",
      zoomType: "x",
    },
    accessibility: {
      enabled: false,
    },

    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          var point: any = this;
          //console.log("xAxis-point", point.value);
          return moment(point.value).format("HH:mm");
        },
      },
    },
    yAxis: {
      opposite: false,
      labels: {
        format: "{value:.2f}",
      },
      title: {
        text: "",
      },
    },
    showInNavigator: false,

    // navigator: {
    //   series: {
    //     type: "area",
    //     color: "#ccc",
    //     fillOpacity: 0.5,
    //     lineColor: "#c5c5c5",
    //     fillColor: {
    //       linearGradient: {
    //         x1: 0,
    //         y1: 0,
    //         x2: 0,
    //         y2: 1,
    //       },
    //       stops: [
    //         [0, "#ccc"],
    //         [1, "#fff"],
    //       ],
    //     },
    //   },
    // },
    legend: {
      enabled: false,
    },
    lang: {
      noData: '<div class="no-datagroup"><img src="./no-data-found.svg" alt=""><p>Nothing To Display Here</p></div>',
    },
    noData: {
      useHTML: true,
      style: {
        fontWeight: "500",
        fontSize: "15px",
        color: "#333333",
        stroke: "transparent", // basic
        strokeWidth: 0, // hyphenated
        borderBottom: "none",
      },
      position: {
        align: "center",
        verticalAlign: "middle",
      },
    },
    plotOptions: {
      candlestick: {
        color: "var(--colorone)", //"#E55235",
        upColor: "var(--upColor)", //"#3CBD31",
        lineColor: "var(--lineColor)", //"#E55235",
        upLineColor: "var(--upLineColor)", //"#3CBD31",
        //     // borderColor: "#fff",
        //     // dataGrouping: {
        //     //   enabled: false,
        //     // },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        var point: any = this;
        // console.log("tooltip-point", point.points[0].point);
        return (
          "<b>" +
          point.points[0].series.name +
          "</b> " +
          moment(point.x).format("DD MMM yyyy HH:mm:ss") +
          "<br/><b> Open:</b>" +
          point.points[0].point.open +
          " <b>High:</b>" +
          point.points[0].point.high +
          " <b>Low:</b>" +
          point.points[0].point.low +
          " <b>Close:</b>" +
          point.points[0].point.close
        );
      },
    },
    series: [
      {
        type: "",
        name: security_name,
        data: [],
        // dataGrouping: {
        //   enabled: false,
        // },
        units: [
          [
            "minute", // unit name
            [1], // allowed multiples
          ],
        ],
      },
    ],
  });

  return (
    <div>
      <HighchartsReact
        ref={chartRef}
        containerProps={{
          style: {
            backgroundColor: "white",
            height: Number(props.containerHeight) ? props.containerHeight - 36 : 190,
            width: Number(props.containerWidth) && Number(props.containerWidth) >= 800 ? props.containerWidth - 20 : 945,
          },
        }}
        highcharts={Highcharts}
        options={candleChartOptions}
        constructorType={"stockChart"}
      />
    </div>
  );
}

export default CandleStickChart;
