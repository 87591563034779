import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import modalCloseIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  editWatchlistTitle,
  updateIsShowAddtoWatchList,
  updateIsShowChangeTitle,
  updateWatchlistTitle,
  addSecurityToWatchlist,
} from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import TextBox from "../TextBox/TextBox";
import Radio from "../Radio/Radio";
import { useState } from "react";

type Props = {
  setShow: any;
};

export default function AddToWatchList({ setShow }: Props) {
  const dispatch = useAppDispatch();
  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);
  const watchlistTitle = useAppSelector((state) => state.mainDashBoard.watchlistTitle);
  const watchlistTitleID = useAppSelector((state) => state.mainDashBoard.watchlistTitleID);
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);

  const defaultValues: any = {
    title: watchlistTitle,
  };
  const [selectedWatchlistID, setSelectedWatchlistID] = useState(null);

  const handleCheckItem = (e: any) => {
    console.log("handleCheckItem ::", e.target.value);
    setSelectedWatchlistID(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("handleCheckItem :: security_id", default_security_set.security_id);
    console.log("handleCheckItem :: selectedWatchlistID", selectedWatchlistID);
    dispatch(addSecurityToWatchlist({ id: selectedWatchlistID, security_id: default_security_set.security_id }));
  };
  return (
    <div className="watchlist-panel">
      <div className="watchlist-panel-header flex justify-between items-center">
        <p className="text-lg font-medium">Your's watchlist</p>
        <div className="flex gap-4">
          <img className="cursor-pointer" onClick={() => dispatch(updateIsShowAddtoWatchList(false))} src={modalCloseIcon} alt="" />
        </div>
      </div>
      <form className="watchlist-panel-body w-full " onSubmit={(e: any) => handleSubmit(e)}>
        <div className="watchlist-panel-body-list">
          <ul className="body-list radio-list">
            {userGrids.map(
              (item: any, index: number) =>
                item.grid_component_slug === "wishList" && (
                  <Radio
                    className="watchlist-panel-check-input"
                    name="title"
                    label={item.title}
                    value={item.id}
                    onClick={(e: any) => handleCheckItem(e)}
                    id={`chk-${item.id}`}
                    key={`key-${item.id}`}
                  />
                )
            )}
          </ul>
        </div>
        <div className="watchlist-panel-btn text-navItemsText rounded-[10px]">
          <button type="submit" className="w-full  mx-auto py-3 rounded-[10px] scan-btn">
            Add
          </button>
        </div>
      </form>
    </div>
  );
}
