import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import modalCloseIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  editWatchlistTitle,
  updateIsShowChangeTitle,
  updateUserGridTitle,
  updateWatchlistTitle,
  updateWatchlistTitleID,
} from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import TextBox from "../TextBox/TextBox";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";

type Props = {
  setShow: any;
};

export default function Wishlist({ setShow }: Props) {
  const dispatch = useAppDispatch();
  const watchlistTitle = useAppSelector((state) => state.mainDashBoard.watchlistTitle);
  const [wTitle, setWTitle] = useState<any>("");
  const watchlistTitleID = useAppSelector((state) => state.mainDashBoard.watchlistTitleID);
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);
  //  const [updatedUserGrids, setUpdatedUserGrids] = useState([]);
  useEffect(() => {
    console.log("Change Title:: 1", watchlistTitle);
    setWTitle(watchlistTitle);
  }, [watchlistTitle]);
  useEffect(() => {
    //set grid title
    if (userGrids) {
      for (const key in userGrids) {
        if (userGrids[key].id === watchlistTitleID) {
          dispatch(updateWatchlistTitle(userGrids[key].title));
        }
      }
    }
    // setWTitle(watchlistTitle);
    console.log("setDataGrid :: call--ddd---watchlistTitleID ok", watchlistTitleID);
    console.log("setDataGrid :: call--ddd---+++ok", userGrids);
  }, [userGrids]);
  const handleChangeWatchListTitle = (e: any) => {
    console.log("handleChangeWatchListTitle Title", e.target.value);
    setWTitle(e.target.value);
    // dispatch(updateWatchlistTitle(e.target.value));
  };

  const updateChangeWatchListTitle = (e: any) => {
    console.log("handleChangeWatchListTitle update event", e.target.value);
    setWTitle(e.target.value);
    // updateWatchlistTitleID,
    // updateWatchlistTitle,
    // dispatch(updateWatchlistTitle(e.target.value));
    // dispatch(updateUserGridTitle({ id: watchlistTitleID, title: e.target.value }));
  };
  const defaultValues: any = {
    title: watchlistTitle,
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("handleSubmit habdle submt", watchlistTitle);
    console.log("handleSubmit habdle id", watchlistTitleID);
    dispatch(updateWatchlistTitle(wTitle));
    setWTitle(wTitle);
    dispatch(editWatchlistTitle({ id: watchlistTitleID, title: wTitle }));
    // dispatch(updateWatchlistTitleID(0));
    // dispatch(updateWatchlistTitle(""));
  };
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    dispatch(updateIsShowChangeTitle(false));
  });
  const handleCloseChangeTitle = (e: any) => {
    console.log("handleCloseWatchListTitle Title", watchlistTitle);
    dispatch(updateWatchlistTitle(watchlistTitle));
    dispatch(updateIsShowChangeTitle(false));
    setWTitle(wTitle);
  };

  return (
    <div ref={ref} className="watchlist-panel">
      <div className="watchlist-panel-header flex justify-between items-center">
        <p className="text-lg font-medium">Name your list</p>
        <div className="flex gap-4">
          <img className="cursor-pointer" onClick={(e: any) => handleCloseChangeTitle(e)} src={modalCloseIcon} alt="" />
        </div>
      </div>
      <form className="watchlist-panel-body w-full " onSubmit={(e: any) => handleSubmit(e)}>
        <div className="">
          <TextBox
            className="watchlist-panel-input"
            name="title"
            label="Your watchlist name"
            value={wTitle}
            onChange={(e: any) => handleChangeWatchListTitle(e)}
            onKeyUp={(e: any) => updateChangeWatchListTitle(e)}
          />
        </div>
        <div className="watchlist-panel-btn text-navItemsText rounded-[10px]">
          <button type="submit" className="w-full  mx-auto py-3 rounded-[10px] scan-btn">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
