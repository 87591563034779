import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchData } from "../../../services/apiFunctions";
import { dataAndResearchStateTypes } from "../../../types/components/types";

export const fetchSecurityData: any = createAsyncThunk(
  "DataAndResearch/fetchSecurityData",
  async (data: any) => {
    const response: any = await fetchData(`get-securities/${data}`);
    return response.data;
  }
);

export const fetchSecurityResearch: any = createAsyncThunk(
  "DataAndResearch/fetchSecurityResearch",
  async (data: any) => {
    const response: any = await fetchData(`get-securities/${data}`);
    return response.data;
  }
);

const initialState: dataAndResearchStateTypes = {
  dataStatus: "loading",
  researchStatus: "loading",
  title: "",
  logo: null,
  market_id: 6,
  basic_details: [],
  company_details: [],
  ibbond_details: [],
  fund_legal_documents: [],
  cash_flow_details: null,
  insider_roster: null,
  insider_summary: null,
  security_data: [],
  security_research: [],
  total_records: 1193,
  current_page: 1,
  next_page_index: 2,
};

const DataandResearchSlice = createSlice({
  name: "dataAndResearch",
  initialState,
  reducers: {
    removeSecurityData: (state: any) => {
      return {
        ...state,
        security_data: [],
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSecurityData.pending, (state) => {
        return {
          ...state,
          dataStatus: "loading",
        };
      })
      .addCase(fetchSecurityData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          security_research: payload.data,
          total_records: payload.total_records,
          current_page: payload.current_page,
          next_page_index: payload.next_page_index,
          dataStatus: "success",
        };
      })
      .addCase(fetchSecurityData.rejected, (state) => {
        return {
          ...state,
          dataStatus: "error",
        };
      })
      .addCase(fetchSecurityResearch.pending, (state) => {
        return {
          ...state,
          researchStatus: "loading",
        };
      })
      .addCase(fetchSecurityResearch.fulfilled, (state, { payload }) => {
        return {
          ...state,
          title: payload.data.title,
          logo: payload.data.logo,
          market_id: payload.market_id,
          basic_details: payload.basic_details,
          company_details: payload.data.company_details,
          ibbond_details: payload.ibbond_details,
          fund_legal_documents: payload.data.fund_legal_documents,
          cash_flow_details: payload.data.cash_flow_details,
          insider_roster: payload.data.insider_roster,
          insider_summary: payload.data.insider_summary,
          researchStatus: "success",
        };
      })
      .addCase(fetchSecurityResearch.rejected, (state) => {
        return {
          ...state,
          researchStatus: "error",
        };
      });
  },
});

export const { removeSecurityData } = DataandResearchSlice.actions;

export default DataandResearchSlice.reducer;
