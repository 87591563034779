import ReactSelect from "react-select";
import { MultiSelectStyle } from "../../helpers/helper";
import { useAppSelector } from "../../Redux/app/hooks";

function MultiSelectFIlter({ field, label }: any) {
  const currentTab = useAppSelector((state) => state.uicontroller.recordListingTabStatus);

  const optionArray1 = [
    { value: "pending_matching_trade", label: "Pending" },
    { value: "pre-matched", label: "Submitted" },
    { value: "matched", label: "Matched" },
    { value: "settled", label: "Settled" },
    { value: "cancelled", label: "Cancelled" },
    // { value: "locked", label: "Locked" },
  ];
  const optionArray2 = [
    { value: "order_pending", label: "Pending" },
    { value: "order_placing", label: "Placed" },
    { value: "order_working", label: "Working" },
    { value: "order_filled", label: "Filled" },
    { value: "order_killed", label: "Killed" },
    { value: "order_rejected", label: "Rejected" },
  ];

  return (
    <div className="order-form-select-input custome-select">
      <label className="select-lable text-2xl">{label}</label>{" "}
      <ReactSelect
        styles={MultiSelectStyle}
        menuPlacement="auto"
        maxMenuHeight={180}
        isMulti={true}
        isClearable={false}
        {...field}
        options={currentTab === "Transactions" ? optionArray1 : optionArray2}
      />
    </div>
  );
}

export default MultiSelectFIlter;
