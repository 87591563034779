import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { statementsStateTypes } from "../../../types/components/types";

export const postStatementData: any = createAsyncThunk(
  "statements/statements",
  async (data: any) => {
    const response: any = await postData("statements", data);
    return response.data;
  }
);

const initialState: statementsStateTypes = {
  statementStatus: "loading",
  total_records: 0,
  current_page: 0,
  next_page_index: 1,
  startPerPage: 0,
  last_page: 0,
  statementData: [],
};

const StatementsSlice = createSlice({
  name: "statement",
  initialState,
  reducers: {
    cleanstatementData: (state: any) => {
      return {
        ...state,
        statementData: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postStatementData.pending, (state) => {
        return {
          ...state,
          statementStatus: "loading",
        };
      })
      .addCase(postStatementData.fulfilled, (state: any, { payload }: any) => {
        return {
          ...state,
          total_records: payload.data.total_records,
          current_page: payload.data.current_page,
          next_page_index: payload.data.next_page_index,
          startPerPage: payload.data.startPerPage,
          last_page: payload.data.last_page,
          statementData: [...state.statementData, ...payload.data.data],
          statementStatus: "success",
        };
      })
      .addCase(postStatementData.rejected, (state) => {
        return {
          ...state,
          statementStatus: "error",
        };
      });
  },
});

export const { cleanstatementData } = StatementsSlice.actions;

export default StatementsSlice.reducer;
