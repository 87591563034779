import React, { useState, useEffect, ReactNode } from "react";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";
import { fetchOrderedColumnData, setOrderColumnData, updateOrderedColumnData } from "../../Redux/features/Orders/OrderColumnSlice";
import { useAppDispatch } from "../../Redux/app/hooks";

interface TableV8CheckboxTreeProps {
  columns: any[]; // all columns which are available
  selectedColumns: any; // filtered columns which not includes the disabled column object
  handleApply: (uncheckedColumns: string[]) => void;
  predisabledColumn: string[]; // disabled from very start
  loader?: ReactNode;
  loading?: boolean;
  orderedColumns?: string[];
}

interface columnTree {
  id: string;
  flag: boolean;
}

const TableV8CheckboxTree: React.FC<TableV8CheckboxTreeProps> = ({
  columns,
  selectedColumns,
  handleApply,
  predisabledColumn,
  orderedColumns,
  loader,
  loading,
}) => {
  const [uncheckedColumns, setUncheckedColumns] = useState<string[]>([...predisabledColumn]);
  const [columnVal, setColumnVal] = useState<columnTree[]>([]);
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const checkerForColumn: string[] = [...predisabledColumn];
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (columnVal.some((column) => column.flag)) {
      setShowError(false);
    }
  }, [columnVal]);

  useEffect(() => {
    const commonColumns: columnTree[] = columns.map((column: any) => ({
      id: column.id,
      flag: selectedColumns.filter((fColumn: any) => fColumn.id === column.id).length > 0 ? true : false,
    }));

    setColumnVal(commonColumns);
    const commonColumnorder: string[] = columns
      .filter((column: any) => selectedColumns.some((fColumn: any) => fColumn.id === column.id))
      .map((column: any) => column.id);
    setColumnOrder(commonColumnorder);
  }, [columns, selectedColumns]);

  const handleColumnChange = (id: string) => {
    const updatedColumnVal = [...columnVal];

    const columnIndex = updatedColumnVal.findIndex((column) => column.id === id);

    if (columnIndex !== -1) {
      updatedColumnVal[columnIndex].flag = !updatedColumnVal[columnIndex].flag;

      setColumnVal(updatedColumnVal);

      setUncheckedColumns((prevUncheckedColumns) => {
        if (!updatedColumnVal[columnIndex].flag) {
          return [...prevUncheckedColumns, id];
        } else {
          return prevUncheckedColumns.filter((uncheckedId) => uncheckedId !== id);
        }
      });
    }
    // console.log(updatedColumnVal);
  };
  const dispatch = useAppDispatch();
  const handleApplyClick = () => {
    if (!columnVal.some((column) => column.flag)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
      const columnValMap = columnVal.reduce((acc: any, val: any) => {
        acc[val.id] = val.flag;
        return acc;
      }, {});

      // Filter orderedColumns based on the map, maintaining the specified order
      const filteredOrderedColumns: any = orderedColumns && orderedColumns.filter((id) => columnValMap[id]);

      // Find ids from columnVal that are not in orderedColumns but have a flag of true
      const additionalColumns: any = columnVal.filter((val) => val.flag && orderedColumns && !orderedColumns.includes(val.id)).map((val) => val.id);

      // Combine the ordered filtered columns with the additional columns
      const resultedArray = [...filteredOrderedColumns, ...additionalColumns];

      if (checkerForColumn.length === uncheckedColumns.length && checkerForColumn.every((x) => uncheckedColumns.includes(x))) {
        console.log("no changed in column array");
        dispatch(updateOrderedColumnData(resultedArray))
          .then((result: any) => {
            console.log(result);
            dispatch(setOrderColumnData(result.payload));
            dispatch(fetchOrderedColumnData());
          })
          .catch((err: any) => {
            console.log(err);
          });
        handleApply(uncheckedColumns);
      } else {
        dispatch(updateOrderedColumnData(resultedArray))
          .then((result: any) => {
            console.log(result);
            dispatch(setOrderColumnData(result.payload));
            dispatch(fetchOrderedColumnData());
          })
          .catch((err: any) => {
            console.log(err);
          });
        handleApply(uncheckedColumns);
      }
    }
  };
  const selectAllColumns = () => {
    const updatedColumnVal = columnVal.map((column) => ({
      ...column,
      flag: true, // Set flag to true for all
    }));

    setColumnVal(updatedColumnVal);
    setUncheckedColumns([]); // Clear all unchecked columns
  };

  const deselectAllColumns = () => {
    const updatedColumnVal = columnVal.map((column) => ({
      ...column,
      flag: false, // Set flag to false for all
    }));

    setColumnVal(updatedColumnVal);
    setUncheckedColumns(columns.map((column) => column.id)); // Add all column ids to uncheckedColumns
  };

  // console.log(loading);
  // console.log(uncheckedColumns);

  return (
    <>
      <div className="flex justify-start items-center gap-[8px] py-[0.275rem]">
        <input
          type="checkbox"
          checked={columnVal.every((column) => column.flag)} // True if all are checked
          onChange={(e) => (e.target.checked ? selectAllColumns() : deselectAllColumns())}
          id="selectAll"
          className="mt-[2px 5px 0 0] border-checkBoxColor rounded-sm form-checkbox checked:bg-checkBoxColor checked:focus:bg-checkBoxColor ring-offset-0 focus:ring-offset-0 checked:hover:bg-checkBoxColor focus:ring-0  styckbox"
        />
        <label className="text-black text-[12px] cursor-pointer" htmlFor={"selectAll"}>
          {!columnVal.every((column) => column.flag) ? "Select All" : "Unselect All"}
        </label>
      </div>
      <hr />
      {columnVal.map((column: columnTree, index: number) => (
        <div key={index} className="flex justify-start items-center gap-[8px] py-[0.275rem]">
          <input
            type="checkbox"
            checked={column?.flag}
            onChange={() => handleColumnChange(column.id)}
            id={column.id}
            className="mt-[2px 5px 0 0] border-checkBoxColor rounded-sm form-checkbox checked:bg-checkBoxColor checked:focus:bg-checkBoxColor ring-offset-0 focus:ring-offset-0 checked:hover:bg-checkBoxColor focus:ring-0  styckbox"
          />
          <label className="text-black text-[12px] cursor-pointer" htmlFor={column.id}>
            {column.id}
          </label>
        </div>
      ))}
      {showError && (
        <div className="flex justify-start items-center gap-[8px] p-[0.175rem] border-red-400 border mt-[5px]">
          <label className="text-[10px] cursor-pointer text-red-400 rounded-md text-center">Select At least one column</label>
        </div>
      )}
      <div className="flex justify-center items-center pt-2">
        <button
          className="bg-secondaryBg text-white text-sm py-2 px-10 rounded-[10px] hover:text-primaryText hover:bg-layoutBg hover:border-primaryText border"
          onClick={handleApplyClick}
          style={{ fontSize: "12px", padding: "4px 16px", minHeight: "auto", width: "auto" }}
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default TableV8CheckboxTree;
