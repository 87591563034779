import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// import logo from "../../assets/logo.svg";
import logo from "../../assets/logo.svg";
import CurrencyDropdown from "../../components/CurrencyDropdown/CurrencyDropdown";
import PortfolioDropdown from "../../components/PortfolioDropDown/PortfolioDropdown";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
// import { isDark } from "../../utils/column";
import Notification from "../Notification/Notification";
import "../../index.scss";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import TransactionDetails from "../../components/TransactionDetails/TransactionDetails";
import Loading from "../../components/Loading/Loading";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import themesConfig from "../../utils/themesConfig";
import { Link } from "react-router-dom";
import GlobalSearch from "../../components/GlobalSearch/GlobalSearch";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import UploadButton from "../../components/UploadButton/UploadButton";
import SettingsBox from "../../components/SettingsBox/SettingsBox";
import { Boarding } from "boarding.js";
import "boarding.js/styles/main.css";
import { postTourGuide } from "../../Redux/features/ChartData/ChartDataSlice";
import { TourOptions, WMTourOptions } from "../../utils/constants";

function NavBar(props: any) {
  // const { socket } = props;
  // const Profile = useAppSelector(
  //   (state: any) => state?.dashBoard?.portfoliosIds
  // );
  const startProductTour = () => {
    const boarding = new Boarding({
      className: "scoped-class", // className to wrap driver.js popover
      animate: true, // Animate while changing highlighted element
      opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
      padding: 10, // Distance of element from around the edges
      allowClose: false, // Whether clicking on overlay should close or not
      //overlayClickNext: false, // Should it move to next step on overlay click
      doneBtnText: "Done", // Text on the final button
      closeBtnText: "Skip", // Text on the close button for this step
      nextBtnText: "Next", // Next button text for this step
      prevBtnText: "Previous", // Previous button text for this step
      //showButtons: false, // Do not show control buttons in footer
      keyboardControl: true, // Allow controlling through keyboard (escape to close, arrow keys to move)
      scrollIntoViewOptions: {}, // We use `scrollIntoView()` when possible, pass here the options for it if you want any
      onHighlighted: (Element) => {}, // Called when element is fully highlighted
      onDeselected: (Element) => {}, // Called when element has been deselected
      onReset: (Element) => {}, // Called when overlay is about to be cleared
      onNext: (Element) => {}, // Called when moving to next step on any step
      onPrevious: (Element) => {}, // Called when moving to next step on any step
    });

    if (userId) {
      boarding.defineSteps(TourOptions);
    } else {
      boarding.defineSteps(WMTourOptions);
    }

    boarding.start();
  };
  const dispatch = useAppDispatch();
  // Use useEffect if you want to start the product tour when the component mounts
  const is_tour_guide = useAppSelector((state) => state.dashBoard.is_tour_guide);
  useEffect(() => {
    // if(is_tour_guide){
    //   startProductTour();
    //   dispatch(postTourGuide({page_title : "workstation"}))
    // }
  }, [is_tour_guide]);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    setRightHoldingDrawer(false);
  });

  // const TransactionDetail = useAppSelector(
  //   (state: any) => state.transaction.transactionData.transaction_details
  // );

  // const transactionReference = useAppSelector(
  //   (state:any) => state.transaction.transactionData.reference
  // )

  const transaction = useAppSelector((state: any) => state.transaction);

  // console.log("----", TransactionDetail);

  const isDark = false;
  const [rightholdingDrawer, setRightHoldingDrawer] = useState(false);

  const theme_menus = themesConfig.getBrandMenu().menu;
  const theme_name = themesConfig.getThemeName();
  const themeOption = themesConfig.getThemeoption();
  var fileExtension = themeOption.theme_logo.split(".").pop();
  // console.log("themeOption fileExtension", fileExtension);
  // console.log("themeOption", themeOption.theme_logo);
  // console.log("themeOption--", themeOption.theme_logo);
  var logo_path = "";
  if (fileExtension === "svg") {
    logo_path = require("../../assets/images/" + theme_name + "-images/" + themeOption.theme_logo);
    // logo_path =
    //   require("../../assets/images/hblbankuk-images/logo.svg").default;
  } else {
    logo_path = require("../../assets/images/" + theme_name + "-images/" + themeOption.theme_logo);
  }
  // set theme favicon

  useEffect(() => {
    // const accountRef = useRef<HTMLInputElement>();
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    let favicon_path = "";
    if (theme_name === "emfi") {
      favicon_path = themeOption.theme_favicon;
    } else {
      favicon_path = require("../../assets/images/" + theme_name + "-images/" + themeOption.theme_favicon);
    }

    link.href = favicon_path;
  }, []);

  useEffect(() => {
    document.title = `${theme_name.toUpperCase()} | Execution`;
  }, []);

  const menuClick = (item: any) => {
    // console.log(item);
    window.location.href = item.en_link;
  };
  const userId = useAppSelector((state) => state.dashBoard.is_bulk_upload);
  return (
    <>
      <div className={!isDark ? `nav menu-bg header-main` : `bg-secondaryBgDark nav header-main`}>
        <div className="logo header-left">
          {/* theme wise logo all should be svg or png else check here if svg then have to use .default at end */}
          <img src={logo_path} alt="" />
          <Breadcrumb />
        </div>
        <div className="navItems flex header-center justify-center items-center">
          {/* <ul className="flex">
            {theme_menus.map((item: any) => (
              <a
                href={`${item.en_link}`}
                className="navItem font-medium"
                key={item.key}
                target={"_blank"}
              >
                {item.title}
              </a>
            ))}
          </ul> */}
          <GlobalSearch />
        </div>
        <div className="dropdown flex justify-end items-center header-right new-header-right">
          <Notification setRightHoldingDrawer={setRightHoldingDrawer} />
          {userId ? <UploadButton /> : null}
          {/* <CurrencyDropdown /> */}
          <PortfolioDropdown />
          <SettingsBox />
        </div>
      </div>

      <SideDrawer refProp={ref} setSideDrawer={setRightHoldingDrawer} sideDrawer={rightholdingDrawer} varient="Right">
        {transaction.transactionDetailStatus === "loading" ? (
          <Loading isMaximized="" />
        ) : (
          <TransactionDetails
            details={transaction?.transactionData}
            setClose={setRightHoldingDrawer}
            // referenceNo={transaction?.transactionData.reference}
          />
        )}
      </SideDrawer>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  holdings: state.holdings,
  transaction: state.transaction,
  performance: state.perfomance,
  cashFlows: state.cashFlows,
  statements: state.statements,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
