import { textBoxTypes } from "../../types/components/types";

function Radio({
  label,
  value,

  onClick = null,
  readonly = false,
  type = "radio",
  name = "",
  className = "",
  filterValue,
  setFilter,
  id,
}: any) {
  return (
    <li key={`li-${id}`}>
      <label key={`chk-${id}`} className={` left-2 ${value !== null && "filled"}`} htmlFor={`chk-${id}`}>
        {label}
      </label>
      <input
        key={`in-${id}`}
        className={className}
        value={value}
        type={type}
        name={name}
        id={`chk-${id}`}
        onClick={(e: any) => onClick(e)}
        readOnly={readonly}
      />
    </li>
  );
}

export default Radio;
