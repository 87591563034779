import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { OrderListing } from "../../../types/components/types";
import { RootState } from "../../app/store";

export const transactionExecute: any = createAsyncThunk("ordersList/transaction-execute", async (data: any) => {
  const response: any = await postData("transaction-execute", data);
  // console.log("transaction-execute response-----", response);
  return response.data;
});
export const transactionFill: any = createAsyncThunk("ordersList/transaction-fill", async (data: any) => {
  const response: any = await postData("transaction-fill", data);
  return response.data;
});

export const transactionKill: any = createAsyncThunk("ordersList/transaction-kill", async (data: any) => {
  const response: any = await postData("trad-kill", data);
  // console.log("trad-kill response-----", response);
  return response.data;
});
export const sendEmailAllFund: any = createAsyncThunk("ordersList/send-email", async (data: any) => {
  const response: any = await postData("send-email", data);
  return response.data;
});
export const fetchOrders: any = createAsyncThunk("ordersList/orders", async (data: any) => {
  const response: any = await postData("orders", data.payload);
  return {
    data: response.data,
    loader: data.loader,
    isTransaction: data.isTransaction,
    showLoader: data.showLoader,
  };
});

export const posttransactionChartData: any = createAsyncThunk("ordersList/transactionChartData", async (data: any) => {
  const response: any = await postData("transactions-chart", data);
  // console.log(response);
  return response.data;
});
export const posttransactionDetailsData: any = createAsyncThunk("ordersList/transaction-details", async (data: any) => {
  const response: any = await postData("transaction-details", data);
  // console.log(response);
  return response.data;
});

const initialState: OrderListing = {
  Loader: false,
  transactionStatus: "loading",
  transactionChartStatus: "loading",
  transactionDetailStatus: "loading",
  transactions: [],
  total_records: 0,
  current_page: 0,
  next_page_index: 1,
  startPerPage: 0,
  last_page: 0,
  is_filter: 0,
  is_scanner: 0,
  filterPanel: {
    settlement_date_oprator: "equal",
    settlement_date_val: "",
    trade_date_oprator: "equal",
    trade_date_val: "",
    security_name_oprator: "equal",
    security_name_val: null,
    identifier_oprator: "equal",
    identifier_val: null,
    amount_oprator: "equal",
    amount_val: null,
    price_oprator: "equal",
    price_val: null,
    portfolio_oprator: "equal",
    portfolio_val: null,
    counterparty_oprator: "equal",
    counterparty_val: null,
    status_oprator: "equal",
    status_val: null,
  },
  max_value: 0,
  timeline: "one_year",
  moving_avg: "90",
  periodicity: "monthly",
  cash_status: 1,
  equities_status: 1,
  bonds_status: 1,
  funds_status: 1,
  transactionData: [],
  isUpdateTransactionData: false,
  transactionExecuteResponse: {},
  transactionFillResponse: {},
  transactionKillResponse: {},
  sendEmailAllFundResponse: {},
  transaction_execute_status: -1,
  reference: null,
  trade_date: null,
  security_name: null,
  status: null,
  settlement_date_oprator: "equal",
  settlement_date_val: "",
  trade_date_oprator: "equal",
  trade_date_val: "",
  security_name_oprator: "equal",
  security_name_val: null,
  identifier_oprator: "equal",
  identifier_val: null,
  amount_oprator: "equal",
  amount_val: null,
  market_value: null,
  price_oprator: "equal",
  price_val: null,
  portfolio_oprator: "equal",
  portfolio_val: null,
  counterparty_oprator: "equal",
  counterparty_val: null,
  status_oprator: "equal",
  status_val: null,
  isin_val: null,
  order_type: "",
  accured_val: null,
  buy_direction_val: null,
  sortType: null,
  sortDir: null,
  scanner_on: false,
  orders: [],
};

const OrderListingSlice = createSlice({
  name: "orderListing",
  initialState,
  reducers: {
    clearOrderFilter: (state: OrderListing) => {
      return {
        ...state,
        reference: null,
        trade_date: null,
        status: null,
        settlement_date_oprator: "equal",
        settlement_date_val: "",
        trade_date_oprator: "equal",
        trade_date_val: "",
        security_name_oprator: "equal",
        security_name_val: null,
        identifier_oprator: "equal",
        identifier_val: null,
        amount_oprator: "equal",
        amount_val: null,
        market_value: null,
        price_oprator: "equal",
        price_val: null,
        portfolio_oprator: "equal",
        portfolio_val: null,
        counterparty_oprator: "equal",
        counterparty_val: null,
        status_oprator: "equal",
        status_val: null,
        isin_val: null,
        accured_val: null,
        buy_direction_val: null,
        sortType: null,
        sortDir: null,
        order_type: "",
      };
    },
    filterAccured: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        accured_val: payload.accured_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterPrice: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        price_val: payload.price_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterISIN: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        isin_val: payload.isin_val,
        scanner_on: payload.scanner_on,
      };
    },

    filterOrderType: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        order_type: payload.order_type,
        scanner_on: payload.scanner_on,
      };
    },
    filterBuyDirection: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        buy_direction_val: payload.buy_direction_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterIdentifier: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        identifier_val: payload.identifier_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterCounterParty: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        counterparty_val: payload.counter_party_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterStatus: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        status_val: payload.status_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterPortfolio: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        portfolio_val: payload.portfolio_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterAmount: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        amount_val: payload.amount_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterMarketValue: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        market_value: payload.market_value,
        scanner_on: payload.scanner_on,
      };
    },
    filterTradeDate: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        trade_date_val: payload.trade_date_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterReference: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        reference: payload.reference,
        scanner_on: payload.scanner_on,
      };
    },
    filterSettlementDate: (state: any, { payload }: any) => {
      return {
        ...state,
        settlement_date_val: payload.settlement_date_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterSecurityName: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        security_name_val: payload.security_name_val,
        scanner_on: payload.scanner_on,
      };
    },
    sortColumn: (state: OrderListing, { payload }: any) => {
      // console.log(payload.sortDir);
      if (state.sortType === payload.sortType) {
        switch (payload.sortDir) {
          case "nosort":
            return {
              ...state,
              sortDir: "asc",
            };
          case "asc":
            return {
              ...state,
              sortDir: "desc",
            };
            break;
          case "desc":
            return {
              ...state,
              sortDir: null,
            };
            break;
          default:
            return {
              ...state,
              sortDir: "asc",
            };
            break;
        }
      } else {
        return {
          ...state,
          sortDir: "asc",
          sortType: payload.sortType,
        };
      }
    },
    updatetransactionKillResponse: (state: OrderListing) => {
      return {
        ...state,
        transactionKillResponse: {},
      };
    },
    updatesendEmailAllFundResponse: (state: OrderListing) => {
      return {
        ...state,
        sendEmailAllFundResponse: {},
      };
    },

    updatetransactionExecuteResponse: (state: OrderListing) => {
      return {
        ...state,
        transactionExecuteResponse: {},
      };
    },
    updatetransactionFillResponse: (state: OrderListing) => {
      return {
        ...state,
        transactionFillResponse: {},
      };
    },

    clearTransactionAndOrder: (state: OrderListing) => {
      return {
        ...state,
        transactions: [],
      };
    },
    clearOrders: (state: OrderListing) => {
      return {
        ...state,
        orders: [],
      };
    },
    removeOrderScannerFilter: (state: OrderListing) => {
      return {
        ...state,
        is_scanner: 0,
        is_filter: 0,
      };
    },
    update_scanner: (state: OrderListing, { payload }: any) => {
      return {
        ...state,
        scanner_on: payload.scanner_on,
      };
    },
    updatePayload: (state: OrderListing, { payload }: any) => {
      // console.log(payload, "iii");
      return {
        ...state,
        settlement_date_oprator: payload.settlement_date_oprator,
        settlement_date_val: payload.settlement_date_val,
        trade_date_oprator: payload.trade_date_oprator,
        trade_date_val: payload.trade_date_val,
        security_name_oprator: payload.security_name_oprator,
        identifier_oprator: payload.identifier_oprator,
        security_name_val: payload.security_name_val,
        identifier_val: payload.identifier_val,
        counterparty_oprator: payload.counterparty_oprator,
        counterparty_val: payload.counterparty_val,
        amount_oprator: payload.amount_oprator,
        amount_val: payload.amount_val,
        market_value: payload.market_value,
        price_oprator: payload.price_oprator,
        price_val: payload.price_val,
        portfolio_oprator: payload.portfolio_oprator,
        portfolio_val: payload.portfolio_val,
        status_oprator: payload.status_oprator,
        status_val: payload.status_val,
        scanner_on: payload.scanner_on,
      };
    },

    update_transaction_data: (state: OrderListing, action: PayloadAction<any>) => {
      // console.log("notification :: update_transaction_data, ", action.payload);
      let index = state.orders.map((e) => Number(e.id)).indexOf(action.payload.id);
      // state.orders.map((e) => e.id).indexOf(action.payload.id);
      // console.log("notification :: in reducer", index);
      // let updatedOrders = state.orders.map(u => u.id !== action.payload.id ? u : editedUser);
      // if (index === -1) {
      //   return {
      //     ...state,
      //     orders: [action.payload, ...state.orders],
      //   };
      // } else {
      //   let newOrders = state.orders;
      //   let updatedOrderList = newOrders[index];
      //   console.log("notification :: in reducer update", updatedOrderList);
      //   console.log("notification :: in else", updatedOrderList);
      //   return {
      //     ...state,
      //     // orders: updatedOrderList,
      //   };
      // items[index] = 1010;
      // console.log("notification", [...state.orders.slice(0, index), action.payload.id, ...state.orders.slice(index + 1)]);
      return {
        ...state,
        orders:
          state.orders.map((order) => Number(order.id)).indexOf(action.payload.id) !== -1
            ? [...state.orders.slice(0, index), action.payload, ...state.orders.slice(index + 1)]
            : [action.payload, ...state.orders],
      };
    },
    is_update_transaction_data: (state: OrderListing, action: PayloadAction<any>) => {
      return {
        ...state,
        isUpdateTransactionData: action.payload,
      };
    },
    removeTransactionData: (state: OrderListing) => {
      return {
        ...state,
        transactionData: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(transactionExecute.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(transactionExecute.fulfilled, (state, { payload }) => {
        return {
          ...state,
          transactionExecuteResponse: payload,
        };
      })
      .addCase(transactionExecute.rejected, (state) => {
        return {
          ...state,
        };
      })
      .addCase(transactionFill.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(transactionFill.fulfilled, (state, { payload }) => {
        return {
          ...state,
          transactionFillResponse: payload,
        };
      })
      .addCase(transactionFill.rejected, (state) => {
        return {
          ...state,
        };
      })

      .addCase(transactionKill.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(transactionKill.fulfilled, (state, { payload }) => {
        return {
          ...state,
          transactionKillResponse: payload,
        };
      })
      .addCase(transactionKill.rejected, (state) => {
        return {
          ...state,
        };
      })
      .addCase(sendEmailAllFund.pending, (state) => {})
      .addCase(sendEmailAllFund.fulfilled, (state, { payload }) => {
        return {
          ...state,
          sendEmailAllFundResponse: payload,
        };
      })
      .addCase(sendEmailAllFund.rejected, (state) => {})

      .addCase(fetchOrders.pending, (state, payload) => {
        // console.log(payload, "payload");
        return {
          ...state,
          transactionStatus: "loading",
          Loader: payload.meta.arg.showLoader ? true : false,
        };
      })
      .addCase(fetchOrders.fulfilled, (state, { payload }) => {
        // console.log(payload, "newtransaction");
        return {
          ...state,
          orders: !payload.isTransaction ? (payload.loader ? [...state.orders, ...payload.data.data] : [...payload.data.data]) : state.orders,
          transactions: payload.isTransaction
            ? payload.loader
              ? [...state.transactions, ...payload.data.data]
              : [...payload.data.data]
            : state.transactions,
          total_records: payload.data.total_records,
          current_page: payload.data.current_page,
          next_page_index: payload.data.data.length === 0 ? 0 : 1, //payload.data.next_page_index,
          startPerPage: payload.data.startPerPage,
          last_page: payload.data.last_page,
          is_filter: payload.data.is_filter,
          is_scanner: payload.data.is_scanner,
          transactionStatus: "success",
          Loader: false,
        };
      })
      .addCase(fetchOrders.rejected, (state) => {
        return {
          ...state,
          transactionStatus: "error",
        };
      })
      .addCase(posttransactionDetailsData.pending, (state) => {
        return {
          ...state,
          transactionDetailStatus: "loading",
        };
      })
      .addCase(posttransactionDetailsData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          transactionData: payload.data,
          transactionDetailStatus: "success",
        };
      })
      .addCase(posttransactionDetailsData.rejected, (state) => {
        return {
          ...state,
          transactionDetailStatus: "error",
        };
      });
  },
});

export const {
  filterAccured,
  filterPrice,
  filterAmount,
  filterMarketValue,
  filterSecurityName,
  filterCounterParty,
  filterIdentifier,
  filterStatus,
  filterPortfolio,
  filterReference,
  filterSettlementDate,
  filterTradeDate,
  filterISIN,
  filterOrderType,
  filterBuyDirection,
  clearOrderFilter,
  update_transaction_data,
  is_update_transaction_data,
  clearTransactionAndOrder,
  clearOrders,
  removeOrderScannerFilter,
  removeTransactionData,
  update_scanner,
  updatetransactionKillResponse,
  updatesendEmailAllFundResponse,
  updatetransactionExecuteResponse,
  updatetransactionFillResponse,
  updatePayload,
  sortColumn,
} = OrderListingSlice.actions;

export const transactionExecuteResponseKey = (state: RootState) => state.orders.transactionExecuteResponse;
export const transactionFillResponseKey = (state: RootState) => state.orders.transactionFillResponse;
export const transactionKillResponseKey = (state: RootState) => state.orders.transactionKillResponse;
export const sendEmailAllFundResponseKey = (state: RootState) => state.orders.sendEmailAllFundResponse;

export const isUpdateTransactionData = (state: RootState) => state.orders.isUpdateTransactionData;

export default OrderListingSlice.reducer;
