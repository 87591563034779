import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { useAppSelector } from "../../Redux/app/hooks";
import { buySellAmount, formatNumber } from "../../helpers/helper";
function FXSOrderConfirmationDetail({
  confirm,
  primaryCurrecnyValue,
  secondaryCurrecnyValue,
  ask_value,
  bid_value,
  userType,
  selectedPortfolios,
  allocate_portfolio,
  custodianName,
  clientMarkupData,
}: any) {
  const form = useAppSelector((state) => state.orderSection.form_data);
  const totalEstMarkup = selectedPortfolios.reduce((sum: any, item: any) => sum + item.est_markup, 0);
  return (
    <>
      <div className="order-confirmation-table">
        <table>
          <thead>
            <tr key={"FXBO-001"}>
              {userType === "internal" && <th>Entity</th>}
              {userType !== "internal" && <th>Entity</th>}
              <th>Description</th>
              <th>Transaction</th>
              <th>Type</th>
              <th>Duration</th>
              <th>Price</th>
              {userType === "internal" && <th>Est. Markup</th>}
              <th>Est. Total</th>
            </tr>
          </thead>
          <tbody>
            <tr key={"FXBO-002"} className={`${form.direction.toLowerCase() === "buy" ? "buy" : "sell"}-row`}>
              {userType === "internal" && <td>{custodianName}</td>}
              {userType !== "internal" && <td>EMFI Capital</td>}
              <td>
                <span>{primaryCurrecnyValue}</span>
                <div className="tooltiptext">
                  <p>{primaryCurrecnyValue}</p>
                </div>
              </td>
              <td>{form.direction.toUpperCase()}</td>
              <td>{form.order_type.toUpperCase()}</td>
              <td>
                <span>{confirm.time.replace(/_/g, " ")}</span>
              </td>
              <td>
                {bid_value === 0 && form.order_type.toLowerCase() === "market" && form.direction.toLowerCase() === "sell" ? (
                  <b>N/A</b>
                ) : (
                  <>{form.direction.toLowerCase() === "sell" && <b>{Number(confirm.price).toFixed(5)}</b>}</>
                )}
              </td>
              {userType === "internal" && (
                <td>
                  <b>0</b>
                </td>
              )}
              <td>
                {bid_value === 0 && form.order_type.toLowerCase() === "market" ? (
                  <b>{buySellAmount(formatNumber(confirm.quantity), form.direction.toLowerCase())}</b>
                ) : (
                  <>{<b>{buySellAmount(formatNumber(confirm.quantity, 2), form.direction.toLowerCase())}</b>}</>
                )}
              </td>
            </tr>
            {/* second TR only Forex */}
            <tr key={"FXBO-003"} className={`${form.direction.toLowerCase() === "buy" ? "sell" : "buy"}-row divider-allocation`}>
              {userType === "internal" && <td>{custodianName}</td>}
              {userType !== "internal" && <td>EMFI Capital</td>}
              <td>{secondaryCurrecnyValue}</td>
              <td>{form.direction.toLowerCase() === "buy" ? "SELL" : "BUY"}</td>
              <td>{form.order_type.toUpperCase()}</td>
              <td>{confirm.time.replace(/_/g, " ")}</td>
              <td>
                {/* <b>1.09580</b> */}
                {bid_value === 0 && form.order_type.toLowerCase() === "market" ? <b>N/A</b> : <>{<b>{Number(confirm.price).toFixed(5)}</b>}</>}
              </td>
              {userType === "internal" && (
                <td>
                  <b>0</b>
                </td>
              )}
              <td>
                {form.order_type.toLowerCase() === "market" ? (
                  <>
                    {bid_value === 0 && form.order_type.toLowerCase() === "market" ? (
                      <b>N/A</b>
                    ) : (
                      <b>
                        {buySellAmount(
                          new Intl.NumberFormat().format(confirm.quantity.replace(",", "") * confirm.price),
                          form.direction.toLowerCase() === "buy" ? "sell" : "buy"
                        )}
                      </b>
                    )}
                  </>
                ) : (
                  <>
                    {
                      <b>
                        {buySellAmount(
                          new Intl.NumberFormat().format(confirm.quantity.replace(",", "") * confirm.price),
                          form.direction.toLowerCase() === "buy" ? "sell" : "buy"
                        )}
                      </b>
                    }
                  </>
                )}
              </td>
            </tr>
            {/* For client information */}
            {userType === "internal" &&
              selectedPortfolios.map(
                (allocates_input: any, index: any) =>
                  allocates_input.client_id !== null &&
                  Number(allocates_input.client_id) !== 0 && (
                    <tr key={`EMFI-${allocates_input.client_id}`} className={`${form.direction.toLowerCase() === "buy" ? "sell" : "buy"}-row `}>
                      <td className={"td-text-indent"}>
                        - {allocate_portfolio.filter((item: any) => item.id === allocates_input.client_id).map((item: any) => item.name)}
                      </td>
                      <td>{secondaryCurrecnyValue}</td>
                      <td>{form.direction.toLowerCase() === "buy" ? "SELL" : "BUY"}</td>
                      <td>{form.order_type.toUpperCase()}</td>
                      <td>{confirm.time.replace(/_/g, " ")}</td>
                      <td>
                        {ask_value === 0 && form.order_type.toLowerCase() === "market" ? (
                          <b>N/A</b>
                        ) : (
                          // <>{<b>{Number(confirm.price).toFixed(5)}</b>}</>
                          <>{<b>{Number(allocates_input.markup_price).toFixed(5)}</b>}</>
                        )}
                      </td>
                      {userType === "internal" && (
                        <td>
                          <b>{allocates_input.est_markup}</b>
                        </td>
                      )}
                      <td>
                        {form.order_type.toLowerCase() === "market" ? (
                          <>
                            {ask_value === 0 && form.order_type.toLowerCase() === "market" && form.isInverse === false ? (
                              <b>N/A</b>
                            ) : (
                              <b>
                                {buySellAmount(
                                  new Intl.NumberFormat().format(allocates_input.qty.replace(",", "") * allocates_input.markup_price),
                                  form.direction.toLowerCase() === "buy" ? "sell" : "buy"
                                )}
                              </b>
                            )}
                          </>
                        ) : (
                          <>
                            {
                              <b>
                                {buySellAmount(
                                  new Intl.NumberFormat().format(allocates_input.qty.replace(",", "") * allocates_input.markup_price),
                                  form.direction.toLowerCase() === "buy" ? "sell" : "buy"
                                )}
                              </b>
                            }
                          </>
                        )}
                      </td>
                    </tr>
                  )
              )}
          </tbody>
        </table>
        <>
          {bid_value === 0 && form.order_type.toLowerCase() === "market" && form.direction.toLowerCase() === "sell" ? (
            <></>
          ) : (
            <>
              {form.direction.toLowerCase() === "sell" && userType === "internal" && (
                <p>
                  At an exchange rate {confirm.price !== 0 ? Number(confirm.price).toFixed(5) : ""} of {primaryCurrecnyValue} to{" "}
                  {secondaryCurrecnyValue} estimated receivable {secondaryCurrecnyValue}{" "}
                  {new Intl.NumberFormat().format(confirm.quantity * confirm.price)} against {primaryCurrecnyValue} {formatNumber(confirm.quantity)}{" "}
                  (Total Est. Markup : {secondaryCurrecnyValue} {totalEstMarkup})
                </p>
              )}
              {form.direction.toLowerCase() === "sell" && userType !== "internal" && (
                <p>
                  At an exchange rate {confirm.price !== 0 ? Number(confirm.price).toFixed(5) : ""} of {primaryCurrecnyValue} to{" "}
                  {secondaryCurrecnyValue} estimated receivable {secondaryCurrecnyValue}{" "}
                  {new Intl.NumberFormat().format(confirm.quantity * confirm.price)} against {primaryCurrecnyValue} {formatNumber(confirm.quantity)}{" "}
                </p>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
}

export default FXSOrderConfirmationDetail;
