import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import modalCloseIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";

import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useIsMount } from "../../CustomHooks/useIsMount ";
import { fetchSecurityDetails, update_search_items } from "../../Redux/features/ChartData/ChartDataSlice";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import SearchBox from "../SearchBox/SearchBox";

import Svg from "../Svg/Svg";
import { toast } from "react-toastify";
import { updateIsShowmanageColumn } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import { OrdersColumns } from "../../utils/column";
import { toggleShowHideColumn } from "../../Redux/features/Settings/ShowHideColumnSlice";
import { removeHiddenFields, addHiddenFields } from "../../Redux/features/Dashboard/DashBoardSlice";

// import { OrdersColumns } from "../../../utils/column";
type Props = {
  setShow: any;
};

export default function ManageOrderColumn({ setShow }: Props) {
  const dispatch = useAppDispatch();
  const Columns: any = useMemo(() => OrdersColumns, []);
  const [noResultFlag, setNoResultFlag] = useState(false);
  const [title, setTitle] = useState([
    { code: "code", reference: "Reference" },
    { code: "trade_create", reference: "Trade Date" },
    { code: "settlement_date", reference: "Settlement" },
    { code: "security_name", reference: "Security" },
    { code: "order_type", reference: "Order Type" },
    { code: "isin_code", reference: "ISIN" },
    { code: "direction", reference: "Direction" },
    { code: "original_quantity", reference: "Amount" },
    { code: "price", reference: "Price" },
    { code: "price", reference: "Price" },
    { code: "accrued", reference: "Accured" },
    { code: "market_value", reference: "Market Value" },
    { code: "portfolio", reference: "Portfolio" },
    { code: "legal_name_the_entity", reference: "Counterparty" },
    { code: "status", reference: "Status" },
    // Add more items as needed
  ]);
  const table_hidden_fields = useAppSelector((state) => state.dashBoard.hiddenFields);
  const showHideFields = useAppSelector((state) => state.ShowHideColumn.showHideColumns);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setNoResultFlag(false));

  useEffect(() => {
    // console.log("handleAddRemoveFields Columns::---", Columns);
    // console.log("handleAddRemoveFields showHideFields::---", showHideFields);
    Columns.forEach((item: any) => {
      // console.log("handleAddRemoveFields Columns item::---", item.sort_id);
      // console.log(`${item} in ${item.id} is true handleAddRemoveFields`);
      Object.keys(showHideFields).forEach((key) => {
        // console.log("handleAddRemoveFields showHideFields item key::---", showHideFields[item.sort_id as keyof typeof showHideFields]);
        if ((key as keyof typeof showHideFields) === item.sort_id) {
          if (showHideFields[key as keyof typeof showHideFields] === false) {
            // console.log(`${key as keyof typeof showHideFields} in ${item.sort_id} is true handleAddRemoveFields`);
            console.log("handleAddRemoveFields added---------------- key::---", item.id);
            dispatch(addHiddenFields(item.id));
          } else {
            dispatch(removeHiddenFields(item.id));
          }
        }

        // if(){}
        // console.log(`${key} in ${item.id} is true handleAddRemoveFields`);
        // if (item[key] === true) {
        //   console.log(`${key} in ${item.id} is true`);
        //   // Do something when the condition is met
        // } else if (item[key] === false) {
        //   console.log(`${key} in ${item.id} is false handleAddRemoveFields`);
        //   // Do something when the condition is not met
        // }
      });
    });
    // const codeExists = Columns.find((item: any) => item.sort_id === fid);
    // if (codeExists) {
    //   console.log("handleAddRemoveFields Code exists!", codeExists.id);
    //   // Do something if 'code' exists
    // } else {
    //   console.log("Code does not exist!");
    //   // Do something if 'code' does not exist
    // }
  }, [showHideFields]);
  const handleAddRemoveFields = (fid: any) => {
    console.log("handleAddRemoveFields::---", fid);
    dispatch(toggleShowHideColumn({ columnName: fid }));
  };
  return (
    <div className="order-col-panel ">
      <div className="order-col-panel-header flex justify-between items-center">
        <p className="text-lg font-medium">Transections Fields</p>
        <div className="flex gap-4">
          <img className="cursor-pointer" onClick={() => dispatch(updateIsShowmanageColumn(false))} src={modalCloseIcon} alt="" />
        </div>
      </div>
      <div>
        <div className="absolute order-col-list">
          <ul>
            {Object.keys(showHideFields).map((item: any, index: any) => (
              <li key={index}>
                <label key={index} className="p-1 py-1.5 w-full hover:cursor-pointer text-porfolioTextColor flex justify-start" htmlFor={item}>
                  <input
                    id={item}
                    className="mr-2 mt-[2px 5px 0 0] border-checkBoxColor rounded-sm form-checkbox checked:bg-checkBoxColor checked:focus:bg-checkBoxColor ring-offset-0 focus:ring-offset-0 checked:hover:bg-checkBoxColor focus:ring-0  styckbox"
                    type="checkbox"
                    checked={showHideFields[item as keyof typeof showHideFields]}
                    onChange={(e) => handleAddRemoveFields(item as keyof typeof showHideFields)}
                    // onChange={handleAddRemoveFields}
                    defaultValue={item}
                  />
                  {/* {item} */}
                  {title.find((t) => t.code === item)?.reference}
                  {/* {title.[item as keyof typeof showHideFields]}  */}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
