import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import RadioGroup from "../../../components/RadioGroup/RadioGroup";
import {
  getCandleTimeApiParam,
  getCandleTimeApiParamValue,
  getChartTypeParamValue,
  getTimeSelectedApiParams,
  getTimeSelectedApiParamsValue,
} from "../../../helpers/helper";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { RootState } from "../../../Redux/app/store";
import {
  fetchChartData,
  update_area_timeline,
  update_line_timeline,
  update_candle_timeline,
  update_chartdata_status,
  update_chart_type,
  update_switch_chart_type,
  update_new_area_timeline,
  live_update_candle_chart_data,
  update_candle_chart_data,
  update_is_live_update,
  update_area_chart_option,
  update_is_switch_chart,
  update_chart_data,
} from "../../../Redux/features/ChartData/ChartDataSlice";
import AreaChart from "./Charts/AreaChart";
import NewAreaChart from "./Charts/NewAreaChart";
import LineChart from "./Charts/LineChart";
import CandleStickChart from "./Charts/CandleStickChart";
import useCustomHeight from "../../../CustomHooks/useCustomHeight";
import { NewDateRangeOptions } from "../../../utils/constants";
import moment from "moment";
import socket from "../../../socket";
function TradingChartComp(props: any) {
  const { tradingChart, isMaximized, width, height } = props;
  // console.log("height", height);
  // console.log("width", width);
  const dashBoard = useAppSelector((state: RootState) => state.dashBoard);
  const from_date = useAppSelector((state) => state.chartdata.area_chart_option_from_date);
  const to_date = useAppSelector((state) => state.chartdata.area_chart_option_to_date);
  const get_area_char_timeline = useAppSelector((state) => state.chartdata.area_char_timeline);
  // console.log("Hello area_char_timeline", get_area_char_timeline);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const bondChartDataChannel = "bond_chart_bidask_data_channel_" + localStorage.getItem("login-id");
    socket.on(bondChartDataChannel, (data: any) => {
      // console.log("stream-channel :: bond_chart_bidask_data_channel_", data);
      const current_market_id = localStorage.getItem("current_market_id");
      if (Number(current_market_id) === 5) {
        dispatch(update_chart_data(data));
      }
    });

    const equityChartDataChannel = "chart_data_" + localStorage.getItem("login-id") + "_from_web";
    // const equityChartDataChannel = "equity_chart_bidask_data_channel_" + localStorage.getItem("login-id");
    socket.on(equityChartDataChannel, (data: any) => {
      let conid = data.conid;

      // console.log("socket:: call new connect move----------------- in candle", localStorage.getItem("current_security_conid"));
      if (Number(localStorage.getItem("current_security_conid")) === Number(conid)) {
        let conidData = eval(data.values);

        if (conidData.length > 1) {
          console.log("socket:: call new connect move----------------- in candle", conidData);
          dispatch(update_is_live_update(false));
          // dispatch(live_update_candle_chart_data([]));
          // dispatch(update_candle_chart_data([]));
          dispatch(update_candle_chart_data(conidData));
          // setTimeout(() => {
          // }, 500);
        } else {
          dispatch(update_is_live_update(true));
          dispatch(update_candle_chart_data(conidData));
          // setTimeout(() => {
          //   dispatch(update_is_live_update(true));
          //   // dispatch(live_update_candle_chart_data(conidData));
          //   dispatch(update_candle_chart_data(conidData));
          // }, 3000);
        }
      }
    });
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, []);
  const lineTimeline = useAppSelector((state) => state.chartdata.lineTimeline);
  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);
  useEffect(() => {
    console.log("default_security_set IN Chart------", default_security_set);
    // let currentMarketId = localStorage.getItem("current_market_id");
    // if (lineTimeline && Number(currentMarketId) === 5) {
    //   var lineTimelineData = { isin_code: default_security_set.isin_code, line_time_line: lineTimeline, user_id: localStorage.getItem("login-id") };
    //   socket.emit("lineTimeline", lineTimelineData);
    // }
    let socketpayload = {
      market_id: default_security_set.market_id,
      conid: default_security_set.conid,
      security_id: default_security_set.security_id,
      isin_code: default_security_set.isin_code,
      user_id: localStorage.getItem("login-id"),
      socket_id: socket.id,
      from: "web",
    };
    socket.emit("handle_chart_data_subscribe", socketpayload);
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [default_security_set]);
  useEffect(() => {
    // console.log("lineTimeline------", lineTimeline);
    let currentMarketId = localStorage.getItem("current_market_id");
    if (lineTimeline && Number(currentMarketId) === 5) {
      var lineTimelineData = { isin_code: default_security_set.isin_code, line_time_line: lineTimeline, user_id: localStorage.getItem("login-id") };
      socket.emit("lineTimeline", lineTimelineData);
    }
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [lineTimeline]);
  useEffect(() => {
    if (from_date === "" && to_date === "") {
      dispatch(
        update_area_chart_option({
          to_date: moment().subtract(1, "day").format("YYYY-MM-DD"),
          from_date: moment().subtract(1, "months").subtract(1, "day").format("YYYY-MM-DD"),
          name: "1M",
        })
      );
    }
  }, []);
  const chartPayload = useMemo(() => {
    return {
      security_id: dashBoard?.default_security_set.security_id,
      // timeline: tradingChart.timeline, //for area char
      from_date: from_date,
      to_date: to_date,
      price_type: tradingChart.price_type,
      candleTimeline: tradingChart.candleTimeline, //for candle chart
      switch_chart_type: tradingChart?.switch_chart_type, //for switch chart type for equity
    };
  }, [
    dashBoard?.default_security_set.security_id,
    tradingChart.candleTimeline,
    tradingChart.price_type,
    tradingChart.timeline,
    tradingChart.area_timeline,
    from_date,
    to_date,
    // tradingChart.switch_chart_type
  ]);
  // console.log("chartPayload", chartPayload);
  const chart_type = tradingChart.chart_type;
  const candle_chart_data = tradingChart.candle_chart_data;

  //component reload issue from chartSection
  useEffect(() => {
    if (from_date !== "") {
      const current_market_id = localStorage.getItem("current_market_id");
      dispatch(fetchChartData(chartPayload));
    }
    // dispatch(update_chartdata_status("success"));
  }, [chartPayload]);
  const isSwitchBy = tradingChart?.is_switch_chart;
  // const [isSwitchBy, setIsSwitchBy] = useState<any>(1);
  const [globalTImeLine, setGlobalTImeLine] = useState<any>("");
  // const areaChartDdOpts = ["Month", "Quarter", "Year", "YTD"];
  const [areaChartDdOpts, setAreaChartDdOpts] = useState<any>([]);
  const lineChartDdOpts = ["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y"];
  const candleChartDdOpts = ["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y"];
  const switchChartTypeOpts = ["Area", "Candlestick"];

  const selectedSwitchChartOpt = tradingChart?.switch_chart_type;
  const selectedAreaChartOpt = getTimeSelectedApiParamsValue(tradingChart?.timeline);
  const selectedLineChartOpt = tradingChart?.lineTimeline;

  const selectedCandleChartOpt = tradingChart.candleTimeline;
  const selectedSwitchAreaChartOpt = tradingChart.area_timeline;
  const areaChartOption = ["5D", "1M", "6M", "YTD", "1Y", "5Y", "MAX"];
  useEffect(() => {
    if (isSwitchBy !== 1) {
      setAreaChartDdOpts(["1D", "5D", "1M", "6M", "YTD", "1Y", "5Y"]);
    } else {
      // setAreaChartDdOpts(["Month", "Quarter", "Year", "YTD"]);
      // setAreaChartDdOpts(["5D", "1M", "6M", "YTD", "1Y", "5Y", 'MAX']);
    }
  }, [isSwitchBy]);
  // Area period change event 1D to 5D etc
  const onAreaChartDDOptChange = (e: any) => {
    if (isSwitchBy === 1) {
      //forex
      let timelineApiParam = getTimeSelectedApiParams(e.target.value);
      dispatch(update_area_timeline(timelineApiParam));
    } else {
      //Equity
      setGlobalTImeLine(e.target.value);
      // dispatch(live_update_candle_chart_data([]));
      // dispatch(update_candle_chart_data([]));

      dispatch(update_new_area_timeline(e.target.value));
      dispatch(update_candle_timeline(e.target.value));
    }
  };
  // switch on chart type change event
  // const onChartTypeChange = (type: any) => {
  //   dispatch(update_is_live_update(false));
  //   // setIsSwitchBy(isSwitchBy + 1);
  //   dispatch(update_is_switch_chart(isSwitchBy + 1));

  //   dispatch(update_chart_type(type.toLowerCase()));
  //   dispatch(update_switch_chart_type(type));
  // };
  // console.log("switch_chart_type", selectedSwitchChartOpt);
  // Candle period change event 1D to 5D etc
  const onCandleChartDdChange = (e: any) => {
    dispatch(update_is_live_update(false));
    dispatch(live_update_candle_chart_data([]));
    dispatch(update_candle_chart_data([]));
    setGlobalTImeLine(e.target.value);
    dispatch(update_candle_timeline(e.target.value));
    dispatch(update_new_area_timeline(e.target.value));
  };

  const onLineChartDDOptChange = (e: any) => {
    dispatch(update_line_timeline(e.target.value));
  };

  // console.log("update_area_chart_option", update_area_chart_option);
  const getHeightChart = useRef<any>(null);
  const getHeaderHeightChart = useRef<any>(null);

  const [bodyHeight, setBodyHeight] = useState(0);
  const [headerHeight, setHeadHeight] = useState(0);

  useEffect(() => {
    if (getHeightChart.current !== null) {
      setBodyHeight(getHeightChart.current.offsetHeight);
    }
  });

  useEffect(() => {
    if (getHeaderHeightChart.current !== null) {
      setHeadHeight(getHeaderHeightChart.current.offsetHeight);
    }
  });
  // console.log("CHART:: chart type:", chart_type);
  // console.log("CHART:: isSwitchBy:", isSwitchBy);
  // console.log("CHART:: isMaximized:", isMaximized);
  return (
    <>
      {tradingChart.chartdata_status === "loading" ? (
        <Loading isMaximized={isMaximized} />
      ) : (
        // <div ref={getHeightChart} className={isMaximized === "minimize" ? ` h-[calc(38vh-20px)] p-relative` : "h-[calc(100vh-205px)] p-relative"}>
        // <div ref={getHeightChart}>
        <>
          <div ref={getHeaderHeightChart} className="flex bg-layoutBg items-end justify-end">
            {/* Area chart period forex */}
            {chart_type?.toLowerCase() === "area" && isSwitchBy === 1 && (
              <>
                <ul className="daterangepicker-tab-view mr-8 mt-8">
                  {NewDateRangeOptions.map((i, index) => (
                    <li
                      key={index}
                      className={`${from_date === i.value[0] ? "active" : ""} itemList`}
                      onClick={() => {
                        dispatch(update_area_chart_option({ to_date: i.value[1], from_date: i.value[0], name: i.name }));
                      }}
                    >
                      {i.label}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {/* Equity Area chart period switch*/}
            {chart_type?.toLowerCase() === "area" && isSwitchBy !== 1 && (
              <div className="switch-time-group">
                {/* <div className="switch-chart-type">
                  <span
                    className={`${selectedSwitchChartOpt === "Area" ? "icon-area" : ""}`}
                    onClick={() => {
                      onChartTypeChange("Area");
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                      <path
                        fill="currentColor"
                        d="M21.88 7.35c.8-.76 2.12-.2 2.12.9v11.5c0 .7-.56 1.25-1.25 1.25H5.25C4.56 21 4 20.44 4 19.76v-5.48c0-.38.18-.74.48-.98l5.24-4.08c.46-.36 1.11-.35 1.56.02l4.68 3.8 5.92-5.69Zm.62 1.48-5.98 5.74a.75.75 0 0 1-1 .04l-5.03-4.1-4.99 3.9v5.1h17V8.83Z"
                      ></path>
                    </svg>
                  </span>
                  <span
                    className={`${selectedSwitchChartOpt === "Candlestick" ? "icon-candlestick" : ""}`}
                    onClick={() => {
                      onChartTypeChange("Candlestick");
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                      <path
                        fill="currentColor"
                        d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"
                      ></path>
                    </svg>
                  </span>
                </div> */}
                <RadioGroup defaultValue={selectedSwitchAreaChartOpt} onChange={onAreaChartDDOptChange} options={areaChartDdOpts} />
              </div>
            )}
            {/* candlestick period */}
            {chart_type?.toLowerCase() === "candlestick" && (
              <div className="switch-time-group">
                {/* <div className="switch-chart-type">
                  <span
                    className={`${selectedSwitchChartOpt === "Area" ? "icon-area" : ""}`}
                    onClick={() => {
                      onChartTypeChange("Area");
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                      <path
                        fill="currentColor"
                        d="M21.88 7.35c.8-.76 2.12-.2 2.12.9v11.5c0 .7-.56 1.25-1.25 1.25H5.25C4.56 21 4 20.44 4 19.76v-5.48c0-.38.18-.74.48-.98l5.24-4.08c.46-.36 1.11-.35 1.56.02l4.68 3.8 5.92-5.69Zm.62 1.48-5.98 5.74a.75.75 0 0 1-1 .04l-5.03-4.1-4.99 3.9v5.1h17V8.83Z"
                      ></path>
                    </svg>
                  </span>
                  <span
                    className={`${selectedSwitchChartOpt === "Candlestick" ? "icon-candlestick" : ""}`}
                    onClick={() => {
                      onChartTypeChange("Candlestick");
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                      <path
                        fill="currentColor"
                        d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"
                      ></path>
                    </svg>
                  </span>
                </div> */}
                <RadioGroup defaultValue={selectedCandleChartOpt} onChange={onCandleChartDdChange} options={candleChartDdOpts} />
              </div>
            )}
            {/* Line chart period */}
            {chart_type === "line" && (
              <>
                <RadioGroup defaultValue={selectedLineChartOpt} onChange={onLineChartDDOptChange} options={lineChartDdOpts} />{" "}
              </>
            )}
          </div>

          {chart_type === "area" && isSwitchBy === 1 && (
            // height={setCustomHedight}
            <AreaChart
              // containerWidth={getHeightChart?.current?.offsetWidth ? getHeightChart?.current.offsetWidth : 0}
              // containerHeight={bodyHeight - headerHeight - 20}
              containerHeight={height}
              containerWidth={width}
              security_name={tradingChart.security_name}
              chart_data={tradingChart?.chart_data}
              isMaximized={isMaximized}
              isSwitchBy={isSwitchBy}
              globalTimeline={globalTImeLine}
              deimalPoint={
                Number(default_security_set.market_id) === 6
                  ? 5
                  : Number(default_security_set.market_id) === 1
                  ? 2
                  : Number(default_security_set.market_id) === 5
                  ? 3
                  : 2
              }
            />
          )}

          {chart_type === "area" && isSwitchBy !== 1 && (
            // height={setCustomHedight}
            <NewAreaChart
              // containerWidth={getHeightChart?.current?.offsetWidth ? getHeightChart?.current.offsetWidth : 0}
              // containerHeight={bodyHeight - headerHeight - 20}
              containerHeight={height}
              containerWidth={width}
              security_name={tradingChart.security_name}
              chart_data={tradingChart?.chart_data}
              isMaximized={isMaximized}
              isSwitchBy={isSwitchBy}
              globalTimeline={globalTImeLine}
            />
          )}

          {chart_type === "line" && (
            // height={setCustomHedight}
            <LineChart
              // containerWidth={getHeightChart?.current?.offsetWidth ? getHeightChart?.current.offsetWidth : 0}
              // containerHeight={bodyHeight - headerHeight - 20}
              containerHeight={height}
              containerWidth={width}
              security_name={tradingChart.security_name}
              chart_data={tradingChart?.chart_data}
              isMaximized={isMaximized}
            />
          )}

          {chart_type === "candlestick" && (
            <CandleStickChart
              // containerWidth={getHeightChart?.current?.offsetWidth ? getHeightChart?.current?.offsetWidth : 0}
              // containerHeight={bodyHeight - headerHeight - 20}
              containerHeight={height}
              containerWidth={width}
              security_name={tradingChart.security_name}
              chart_data={tradingChart?.chart_data}
              isMaximized={isMaximized}
              globalTimeline={globalTImeLine}
            />
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  update_area_timeline_Dispatch: (data: any) => dispatch(update_area_timeline(data)),
  update_candle_timeline_dispatch: (data: any) => dispatch(update_candle_timeline(data)),
});

// export default connect(null, mapDispatchToProps)(React.memo(TradingChartComp));

export const MemoizedTradingChartComp = connect(null, mapDispatchToProps)(TradingChartComp);
