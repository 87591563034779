import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const DatePickerComponent = ({ field, className, label }: any) => {
  return (
    <div className="input-container border border-borderColor rounded-[10px] h-[2.75vw] ">
      <DatePicker
        wrapperClassName="w-full p-1 focus:ring-0 animate-none border-none "
        dateFormat="dd/MM/yyyy"
        selected={
          field.value === null ? field.value : moment(field.value).toDate()
        }
        onChange={(e: any) => {
          field?.onChange(e);
        }}
      />
      <label
        className={`${"left-2"} ${field.value && "filled"}`}
        htmlFor={"userName"}
      >
        {label}
      </label>
    </div>
  );
};
