import { useAppDispatch } from "./Redux/app/hooks";
import { io } from "socket.io-client";
import themesConfig from "./utils/themesConfig";

//geting the theme name and as per the theme name render socketURL conditionally
const theme_name = themesConfig.getThemeName();

// console.log(theme_name);
//if theme_name is "fintech" then it follows URL: "https://soc.developmentlabs.co/3000"
// let socketURL: any = theme_name === "fintech" ? "https://soc.developmentlabs.co/3000" : process.env.REACT_APP_SOCKET_URL;
let socketURL: any = theme_name === "fintech" ? process.env.REACT_APP_SOCKET_URL_2 : process.env.REACT_APP_SOCKET_URL;
// console.log("Socket url", socketURL);
if (String(socketURL) === '') {
  // console.log("Socket url is null");
}
const socket = io(socketURL, {
  transports: ["websocket"],
});
export function useSocket() {

  return {
    socket,
  };
}

export default socket;
