import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { performanceStateTypes } from "../../../types/components/types";

export const postPerformanceData: any = createAsyncThunk(
  "perfomance/perfomance",
  async (data: any) => {
    const response: any = await postData("pnl", data);
    return response.data;
  }
);

export const postPerformanceRealizedData: any = createAsyncThunk(
  "perfomance/perfomanceRealizedDetails",
  async (data: any) => {
    const response: any = await postData("pnl/detail", data);
    return response.data;
  }
);

export const postPerformanceUnRealizedData: any = createAsyncThunk(
  "perfomance/perfomanceUnRealizedDetails",
  async (data: any) => {
    const response: any = await postData("pnl/detail", data);
    return response.data;
  }
);

export const postPerformanceChartData: any = createAsyncThunk(
  "perfomance/perfomanceChart",
  async (data: any) => {
    const response: any = await postData("performance-chart", data);
    return response.data;
  }
);

const initialState: performanceStateTypes = {
  performanceStatus: "loading",
  performanceChartStatus: "loading",
  performanceDetailStatus: "loading",
  realized_data: {
    name: "",
    data: [],
  },
  unrealized_data: {
    name: "",
    data: [],
  },
  bonds_data: {
    name: "",
    data: [],
  },
  equities_data: {
    name: "",
    data: [],
  },
  funds_data: {
    name: "",
    data: [],
  },
  all_trades_data: {
    name: "",
    data: [],
  },
  trading_data: {
    name: "",
    data: [],
  },
  interest_data: {
    name: "",
    data: [],
  },
  max_value: 0,
  min_value: 0,
  all_performance_data: [],
  moving_avg: "90",
  periodicity: "monthly",
  chart_type: 1,
  chart_type_nm: "",
  last_page: 1,
  pnl: [],
  next_page_index: 0,
  startPerPage: 0,
  total_records: 0,
  start_date: "2021-09-21",
  end_date: "2022-09-21",
  RealizedItem: [],
  UnRealizedItem: [],
};

const PerformanceSlice = createSlice({
  name: "performance",
  initialState,
  reducers: {
    removePerformanceDetails: (state) => {
      return {
        ...state,
        RealizedItem: [],
        UnRealizedItem: [],
      };
    },
    update_performance_periodicity: (
      state: performanceStateTypes,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        periodicity: action.payload,
      };
    },
    update_chart_type: (
      state: performanceStateTypes,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        chart_type: action.payload.No,
        chart_type_nm: action.payload.name,
      };
    },
    update_performance_Chart_status: (
      state: performanceStateTypes,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        performanceChartStatus: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPerformanceChartData.pending, (state) => {
        return {
          ...state,
          performanceChartStatus: "loading",
        };
      })
      .addCase(postPerformanceChartData.fulfilled, (state, { payload }) => {
        if (state.chart_type === 1) {
          return {
            ...state,
            realized_data: {
              name: payload.data.realized_data?.name,
              data: payload.data.realized_data?.data,
            },
            unrealized_data: {
              name: payload.data.unrealized_data?.name,
              data: payload.data.unrealized_data?.data,
            },
            max_value: payload.data.max_value,
            min_value: payload.data.min_value,
            all_performance_data: payload.data.all_performance_data,
            bonds_data: {
              name: "",
              data: [],
            },
            equities_data: {
              name: "",
              data: [],
            },
            funds_data: {
              name: "",
              data: [],
            },
            all_trades_data: {
              name: "",
              data: [],
            },
            trading_data: {
              name: "",
              data: [],
            },
            interest_data: {
              name: "",
              data: [],
            },
            performanceChartStatus: "success",
          };
        } else if (state.chart_type === 2) {
          return {
            ...state,
            bonds_data: {
              name: payload.data.bonds_data?.name,
              data: payload.data.bonds_data?.data,
            },
            equities_data: {
              name: payload.data.equities_data?.name,
              data: payload.data.equities_data?.data,
            },
            funds_data: {
              name: payload.data.funds_data?.name,
              data: payload.data.funds_data?.data,
            },
            all_trades_data: {
              name: payload.data.all_trades_data?.name,
              data: payload.data.all_trades_data?.data,
            },
            max_value: payload.data.max_value,
            min_value: payload.data.min_value,
            all_performance_data: payload.data.all_performance_data,
            realized_data: {
              name: "",
              data: [],
            },
            unrealized_data: {
              name: "",
              data: [],
            },
            trading_data: {
              name: "",
              data: [],
            },
            interest_data: {
              name: "",
              data: [],
            },
            performanceChartStatus: "success",
          };
        } else if (state.chart_type === 3) {
          return {
            ...state,
            trading_data: {
              name: payload.data.trading_data?.name,
              data: payload.data.trading_data?.data,
            },
            interest_data: {
              name: payload.data.interest_data?.name,
              data: payload.data.interest_data?.data,
            },
            max_value: payload.data.max_value,
            min_value: payload.data.min_value,
            all_performance_data: payload.data.all_performance_data,
            realized_data: {
              name: "",
              data: [],
            },
            unrealized_data: {
              name: "",
              data: [],
            },
            bonds_data: {
              name: "",
              data: [],
            },
            equities_data: {
              name: "",
              data: [],
            },
            funds_data: {
              name: "",
              data: [],
            },
            all_trades_data: {
              name: "",
              data: [],
            },
            performanceChartStatus: "success",
          };
        }
      })
      .addCase(postPerformanceChartData.rejected, (state) => {
        return {
          ...state,
          performanceChartStatus: "error",
        };
      })
      .addCase(postPerformanceData.pending, (state) => {
        return {
          ...state,
          performanceStatus: "loading",
        };
      })
      .addCase(postPerformanceData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pnl: payload.data.data,
          last_page: payload.data.last_page,
          next_page_index: payload.data.next_page_index,
          startPerPage: payload.data.startPerpage,
          total_records: payload.data.total_records,
          performanceStatus: "success",
        };
      })
      .addCase(postPerformanceData.rejected, (state) => {
        return {
          ...state,
          performanceStatus: "error",
        };
      })

      .addCase(postPerformanceRealizedData.pending, (state) => {
        return {
          ...state,
          performanceDetailStatus: "loading",
          RealizedItem: [],
        };
      })
      .addCase(postPerformanceRealizedData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          RealizedItem: payload.data.data,
          performanceDetailStatus: "success",
        };
      })
      .addCase(postPerformanceRealizedData.rejected, (state) => {
        return {
          ...state,
          performanceDetailStatus: "error",
        };
      })
      .addCase(postPerformanceUnRealizedData.pending, (state) => {
        return {
          ...state,
          performanceDetailStatus: "loading",
          UnRealizedItem: [],
        };
      })
      .addCase(
        postPerformanceUnRealizedData.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            UnRealizedItem: payload.data.data,
            performanceDetailStatus: "success",
          };
        }
      )
      .addCase(postPerformanceUnRealizedData.rejected, (state) => {
        return {
          ...state,
          performanceDetailStatus: "error",
        };
      });
  },
});

export const {
  update_performance_periodicity,
  update_chart_type,
  removePerformanceDetails,
  update_performance_Chart_status,
} = PerformanceSlice.actions;

export default PerformanceSlice.reducer;
