import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { cashFlowChartStateTypes } from "../../../types/components/types";

export const postCashFlowData: any = createAsyncThunk(
  "cashFlows/cash-flows",
  async (data: any) => {
    const response: any = await postData("cash-flows", data);
    // console.log(response);
    return response.data;
  }
);
// first popup
export const postCashFlowDataDetails: any = createAsyncThunk(
  "cashFlows/cash-flows-Details",
  async (data: any) => {
    const response: any = await postData("cash-flows", data);
    // console.log(response);
    return response.data;
  }
);

// second popup fetchItemSecondaryDetails
export const postCashFlowDataSecondaryDetails: any = createAsyncThunk(
  "cashFlows/cash-flows-secondary-Details",
  async (data: any) => {
    const response: any = await postData("cash-flows", data);
    // console.log(response);
    return response.data;
  }
);

export const postCashFlowChartData: any = createAsyncThunk(
  "cashFlows/cash-flows-chart",
  async (data: any) => {
    const response: any = await postData("cash-flows-chart", data);
    // console.log(response);
    return response.data;
  }
);

const initialState: cashFlowChartStateTypes = {
  cashFlowStatus: "loading",
  cashFlowChartStatus: "loading",
  cashFlowDetStatus: "loading",
  cashFlowSecDetStatus: "loading",
  total_records: 0,
  current_page: 0,
  next_page_index: 1,
  startPerPage: 0,
  last_page: 0,
  cashFlowData: [],
  coupon_data: {
    name: "",
    data: [],
  },
  redemption_data: {
    name: "",
    data: [],
  },
  trailer_data: {
    name: "",
    data: [],
  },
  all_cashflow_data: {
    name: "",
    data: [],
  },
  max_value: 0,
  min_value: 0,
  timeline: "one_year",
  periodicity: "monthly",
  payment_date: null,
  cashFlowsDetails: [],
  cashFlowSecDetails: [],
};

const CashFlowSlice = createSlice({
  name: "cashFlows",
  initialState,
  reducers: {
    cleanCashFlowData: (state: any) => {
      return {
        ...state,
        cashFlowData: [],
        current_page: 0,
        next_page_index: 1,
        startPerPage: 0,
        last_page: 0,
      };
    },
    update_cashFlow_timeline: (
      state: cashFlowChartStateTypes,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        timeline: action.payload,
      };
    },
    update_cashFlow_periodicity: (
      state: cashFlowChartStateTypes,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        periodicity: action.payload,
      };
    },
    removeCashFlowDetails: (state: cashFlowChartStateTypes) => {
      return {
        ...state,
        cashFlowsDetails: [],
      };
    },
    removeCashFlowSecDetails: (state: cashFlowChartStateTypes) => {
      return {
        ...state,
        cashFlowSecDetails: [],
      };
    },
    updateCashFlowChartStatus: (
      state: cashFlowChartStateTypes,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        payment_date: null,
        cashFlowChartStatus: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCashFlowData.pending, (state) => {
        return {
          ...state,
          cashFlowStatus: "loading",
        };
      })
      .addCase(postCashFlowData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          total_records: payload.data.total_records,
          current_page: payload.data.current_page,
          next_page_index: payload.data.next_page_index,
          startPerPage: payload.data.startPerPage,
          last_page: payload.data.last_page,
          cashFlowData: [...state.cashFlowData, ...payload.data.data],
          cashFlowStatus: "success",
        };
      })
      .addCase(postCashFlowData.rejected, (state) => {
        return {
          ...state,
          cashFlowStatus: "error",
        };
      })
      .addCase(postCashFlowDataDetails.pending, (state) => {
        return {
          ...state,
          cashFlowDetStatus: "loading",
        };
      })
      .addCase(postCashFlowDataDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cashFlowsDetails: payload.data,
          cashFlowDetStatus: "success",
        };
      })
      .addCase(postCashFlowDataDetails.rejected, (state) => {
        return {
          ...state,
          cashFlowDetStatus: "error",
        };
      })
      .addCase(postCashFlowDataSecondaryDetails.pending, (state) => {
        return {
          ...state,
          cashFlowSecDetStatus: "loading",
        };
      })
      .addCase(
        postCashFlowDataSecondaryDetails.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            cashFlowSecDetails: payload.data,
            cashFlowSecDetStatus: "success",
          };
        }
      )
      .addCase(postCashFlowDataSecondaryDetails.rejected, (state) => {
        return {
          ...state,
          cashFlowSecDetStatus: "error",
        };
      })
      .addCase(postCashFlowChartData.pending, (state) => {
        return {
          ...state,
          cashFlowChartStatus: "loading",
        };
      })
      .addCase(postCashFlowChartData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          coupon_data: {
            name: payload.data.coupon_data.name,
            data: payload.data.coupon_data.data,
          },
          redemption_data: {
            name: payload.data.redemption_data.name,
            data: payload.data.redemption_data.data,
          },
          trailer_data: {
            name: payload.data.trailer_data.name,
            data: payload.data.trailer_data.data,
          },
          all_cashflow_data: {
            name: payload.data.all_cashflow_data.name,
            data: payload.data.all_cashflow_data.data,
          },
          max_value: payload.data.max_value,
          min_value: payload.data.min_value,
          cashFlowChartStatus: "success",
        };
      })
      .addCase(postCashFlowChartData.rejected, (state) => {
        // console.log("rejection");
        return {
          ...state,
          cashFlowChartStatus: "error",
        };
      });
  },
});

export const {
  update_cashFlow_periodicity,
  update_cashFlow_timeline,
  updateCashFlowChartStatus,
  cleanCashFlowData,
  removeCashFlowDetails,
  removeCashFlowSecDetails,
} = CashFlowSlice.actions;

export default CashFlowSlice.reducer;
