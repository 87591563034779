import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { mainDashBoardtypes } from "../../../types/components/mainDashboardTypes";
import { RootState } from "../../app/store";
import { fetchData, postData } from "../../../services/apiFunctions";

export const updateScreenLock: any = createAsyncThunk("updateScreenLock", async (payload) => {
  const response: any = await postData("updateScreenLock", {
    requestData: payload,
  });
  return response.data;
});
export const updateUserLayoutPosition: any = createAsyncThunk("update_user_layout_position", async (payload) => {
  const response: any = await postData("update_user_layout_position", {
    requestData: payload,
  });
  return response.data;
});
export const getGirdLayout: any = createAsyncThunk("getGirdLayout", async (payload) => {
  const response: any = await fetchData("getGirdLayout");
  return response.data;
});

export const getUserPanelsData: any = createAsyncThunk("getUserPanelsData", async (payload) => {
  const response: any = await fetchData("getUserPanelsData");
  return response.data;
});
export const getUserGridData: any = createAsyncThunk("getUserGridData", async (payload) => {
  const response: any = await fetchData("getUserGridData");
  return response.data;
});
export const addUserGirdLayout: any = createAsyncThunk("addUserGirdLayout", async (type) => {
  const response: any = await fetchData("addUserGirdLayout/" + type);
  return response.data;
});

export const removeUserGridData: any = createAsyncThunk("removeUserGridData", async (grid_id) => {
  const response: any = await fetchData("removeUserGridData/" + grid_id);
  return response.data;
});
export const storeLayoutToUserLayouts: any = createAsyncThunk("storeLayoutToUserLayouts", async (payload) => {
  const response: any = await postData("storeLayoutToUserLayouts", {
    requestData: payload,
  });
  return response.data;
});
export const editWatchlistTitle: any = createAsyncThunk("editWatchlistTitle", async (payload) => {
  const response: any = await postData("editWatchlistTitle", {
    requestData: payload,
  });
  return response.data;
});
export const addSecurityToWatchlist: any = createAsyncThunk("addSecurityToWatchlist", async (payload) => {
  const response: any = await postData("addSecurityToWatchlist", {
    requestData: payload,
  });
  return response.data;
});
export const getUserWatchlist: any = createAsyncThunk("getUserWatchlist", async (grid_id) => {
  const response: any = await fetchData("getUserWatchlist/" + grid_id);
  return response.data;
});
export const removeItemFromUserWatchlist: any = createAsyncThunk("removeItemFromUserWatchlist", async (id) => {
  const response: any = await fetchData("removeItemFromUserWatchlist/" + id);
  return response.data;
});
export const sendNewSecurityRequest: any = createAsyncThunk("sendNewSecurityRequest", async (payload) => {
  const response: any = await postData("sendNewSecurityRequest", {
    requestData: payload,
  });
  return response.data;
});
const initialState: mainDashBoardtypes = {
  loggedType: "internal",
  user_layouts: {},
  is_user_layouts: false,
  userGrids: {},
  lastInsertId: 0,
  lastInsertGridType: "",
  isUserGrids: false,
  is_screen_lock: true,
  girdLayout: [],

  breakpoints: "lg",
  FilterPanel: false,
  SideDrawer: false,
  selectedID: 0,
  isShowChangeTitle: false,
  isShowAddtoWatchList: false,
  isAlreadyAddedToWatchList: "",
  watchlistTitle: "",
  watchlistTitleID: 0,
  userWatchListItem: {},
  user_grid_data_id: 0,
  bidAskStreamData: {},
  isSecuritySubscription: false,
  sendNewSecurityRequestStatus: false,
  price_data: {
    [0]: {
      bp: 0.0,
      ap: 0.0,
      bs: 0,
      as: 0,
      bt: "",
      at: "",
    },
  },
  bid_price_data: {
    [0]: {
      bp: 0.0,
      bs: 0,
      bt: "",
      vid: 0,
    },
  },
  ask_price_data: {
    [0]: {
      ap: 0.0,
      as: 0,
      at: "",
      vid: 0,
    },
  },
  isShowSearchModal: false,
  isShowmanageColumn: false,
  selectedWatchListID: 0,
  monitorDataFlag: "pending",
  gridErrorMessage: "",
};

const MainDashBoard = createSlice({
  name: "mainDashBoard",
  initialState,
  reducers: {
    updatesendNewSecurityRequestStatus: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        sendNewSecurityRequestStatus: false,
      };
    },
    updateAskPriceData: (state, action: PayloadAction<any>) => {
      // console.log("RFS_best_stream_channel:: Stream data---------------action--- A-------------[ok]", action);
      let isin = action.payload.isin;
      let payload = action.payload.payload;
      const updatedData = {
        ...state.ask_price_data,
        [isin]: payload,
      };

      return {
        ...state,
        ask_price_data: updatedData,
      };
    },
    updateBidPriceData: (state, action: PayloadAction<any>) => {
      let isin = action.payload.isin;
      let payload = action.payload.payload;
      const updatedData = {
        ...state.bid_price_data,
        [isin]: payload,
      };
      return {
        ...state,
        bid_price_data: updatedData,
      };
    },
    updatePriceData: (state, action: PayloadAction<any>) => {
      let isin = action.payload.isin;
      let payload = action.payload.payload;
      const updatedData = {
        ...state.price_data,
        [isin]: payload,
      };
      return {
        ...state,
        price_data: updatedData,
      };
    },
    updateSelectedWatchListID: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        selectedWatchListID: action.payload,
      };
    },
    updateIsShowSearchModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowSearchModal: action.payload,
      };
    },
    updateIsShowmanageColumn: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowmanageColumn: action.payload,
      };
    },

    updateBidAskStreamData: (state, action: PayloadAction<Number>) => {
      return {
        ...state,
        bidAskStreamData: action.payload,
      };
    },
    updateWatchlistTitleID: (state, action: PayloadAction<Number>) => {
      return {
        ...state,
        watchlistTitleID: action.payload,
      };
    },
    updateWatchlistTitle: (state, action: PayloadAction<String>) => {
      return {
        ...state,
        watchlistTitle: action.payload,
      };
    },
    updatesetFilterPanel: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        FilterPanel: action.payload,
      };
    },
    updateIsShowAddtoWatchList: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowAddtoWatchList: action.payload,
      };
    },
    updateIsAlreadyAddedToWatchList: (state, action: PayloadAction<String>) => {
      return {
        ...state,
        isAlreadyAddedToWatchList: action.payload,
      };
    },

    updateIsShowChangeTitle: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowChangeTitle: action.payload,
      };
    },
    updatesetSideDrawer: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        SideDrawer: action.payload,
      };
    },
    updatesetSelectedID: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        selectedID: action.payload,
      };
    },
    updateISUserLayouts: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        is_user_layouts: action.payload,
      };
    },
    update_breakpoints: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        breakpoints: action.payload,
      };
    },
    updateUserGridTitle: (state, action: PayloadAction<any>) => {
      const updatedData = [...state.userGrids];
      for (const key in updatedData) {
        if (updatedData[key].id === action.payload.id) {
          updatedData[key].title = action.payload.title;
        }
      }
      return { ...state, userGrids: updatedData };
    },
    update_main_user_grid: (state, action: PayloadAction<any>) => {
      // console.log("onclickadd:: in slice update_main_user_grid: ", action.payload);
      const updatedData = [...state.userGrids, action.payload];
      return {
        ...state,
        userGrids: updatedData,
      };
    },
    update_grid_error_message: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        gridErrorMessage: "",
      };
    },
    remove_main_user_grid: (state, action: PayloadAction<string>) => {
      // console.log("onclickadd:: in slice remove_main_user_grid: ", action.payload);
      const updatedData = state.userGrids.filter((item: any) => item.id !== action.payload);
      return {
        ...state,
        userGrids: updatedData,
      };
    },
    addLayoutToUserLayouts: (state, action: PayloadAction<any>) => {
      const { layout }: any = action.payload;
      const updatedLayouts = {
        lg: [...state.user_layouts.lg, layout],
        md: [...state.user_layouts?.md, layout],
        sm: [...state.user_layouts?.sm, layout],
        xs: [...state.user_layouts?.xs, layout],
      };

      return { ...state, user_layouts: updatedLayouts };
    },
    updateLayoutInUserLayouts: (state, action: PayloadAction<any>) => {
      const { breakpoints, updateData }: any = action.payload;
      const updatedLayouts = {
        ...state.user_layouts,
        [breakpoints]: updateData,
      };
      // console.log("updateLayoutInUserLayouts:: updatedLayouts", updatedLayouts);
      return { ...state, user_layouts: updatedLayouts, is_user_layouts: true };
    },
    removeLayoutFromUserLayouts: (state, action: PayloadAction<any>) => {
      const { layout }: any = action.payload;
      const updatedLayouts = { ...state.user_layouts };
      // Remove "i key value match" from each layout
      for (const key in updatedLayouts) {
        updatedLayouts[key] = updatedLayouts[key].filter((item: any) => item.i !== layout);
      }
      return { ...state, user_layouts: updatedLayouts };
    },
  },
  extraReducers: (builder) => {
    //START getGirdLayout
    builder
      .addCase(getGirdLayout.rejected, () => {})
      .addCase(getGirdLayout.pending, () => {})
      .addCase(getGirdLayout.fulfilled, (state, { payload }) => {
        // console.log("getGirdLayout request Successfully!", payload);
        return {
          ...state,
          girdLayout: payload.data,
        };
      })
      //END getGirdLayout
      // START getUserPanelsData

      .addCase(getUserPanelsData.rejected, () => {})
      .addCase(getUserPanelsData.pending, () => {})
      .addCase(getUserPanelsData.fulfilled, (state, { payload }) => {
        // console.log("getUserPanelsData request Successfully!", payload);
        return {
          ...state,
          user_layouts: payload.data,
          // isUserGrids: true,
        };
      })
      // END getUserPanelsData
      // START getUserGridData

      .addCase(getUserGridData.rejected, () => {})
      .addCase(getUserGridData.pending, () => {})
      .addCase(getUserGridData.fulfilled, (state, { payload }) => {
        // console.log("getUserGridData request Successfully!", payload);
        return {
          ...state,
          userGrids: payload.data,
          user_layouts: payload.uPData,
          // girdLayout: payload.data.glData,
          is_screen_lock: payload.is_lock_unlock,
          loggedType: payload.loggedType,
          isUserGrids: true,
        };
      })
      // END getUserGridData
      //START addUserGirdLayout

      .addCase(addUserGirdLayout.rejected, () => {})
      .addCase(addUserGirdLayout.pending, () => {})
      .addCase(addUserGirdLayout.fulfilled, (state, { payload }) => {
        // console.log("addUserGirdLayout request Successfully!", payload);
        if (payload.status === 1) {
          return {
            ...state,
            userGrids: payload.data.userGridData,
            lastInsertId: payload.data.lastInsertId,
            lastInsertGridType: payload.data.lastInsertType,
            isUserGrids: true,
            girdLayout: payload.data.gridLayout,
          };
        } else {
          return {
            ...state,
            gridErrorMessage: payload.message,
          };
        }
      })
      // END addUserGirdLayout

      // START removeUserGridData

      .addCase(removeUserGridData.rejected, () => {})
      .addCase(removeUserGridData.pending, () => {})
      .addCase(removeUserGridData.fulfilled, (state, { payload }) => {
        // console.log("removeUserGridData request Successfully!", payload);
        return {
          ...state,
          girdLayout: payload.data,
        };
      })
      //END removeUserGridData

      //START updateScreenLock

      .addCase(updateScreenLock.rejected, () => {})
      .addCase(updateScreenLock.pending, () => {})
      .addCase(updateScreenLock.fulfilled, (state, { payload }) => {
        // console.log("storeLayoutToUserLayouts request Successfully!", payload);
        return {
          ...state,
          is_screen_lock: payload.data,
        };
      })
      //END updateScreenLock
      //START storeLayoutToUserLayouts

      .addCase(storeLayoutToUserLayouts.rejected, () => {})
      .addCase(storeLayoutToUserLayouts.pending, () => {})
      .addCase(storeLayoutToUserLayouts.fulfilled, (state, { payload }) => {
        // console.log("storeLayoutToUserLayouts request Successfully!", payload);
        return {
          ...state,
          is_user_layouts: false,
        };
      })
      //END storeLayoutToUserLayouts
      //START editWatchlistTitle

      .addCase(editWatchlistTitle.rejected, () => {})
      .addCase(editWatchlistTitle.pending, () => {})
      .addCase(editWatchlistTitle.fulfilled, (state, { payload }) => {
        // console.log("editWatchlistTitle request Successfully!", payload);
        return {
          ...state,
          // is_user_layouts: false,
          userGrids: payload.data.userGridData,
          isShowChangeTitle: false,
          watchlistTitle: "",
          watchlistTitleID: 0,
        };
      })
      //END editWatchlistTitle

      //START getUserWatchlist

      .addCase(getUserWatchlist.rejected, (state) => {
        state.monitorDataFlag = "rejected";
      })
      .addCase(getUserWatchlist.pending, (state) => {
        state.monitorDataFlag = "pending";
      })
      .addCase(getUserWatchlist.fulfilled, (state, { payload }) => {
        // console.log("getUserWatchlist request Successfully!", payload.data);
        // return {
        //   ...state.userWatchListItem,
        //   [payload.user_grid_data_id]: payload.user_grid_data_id,
        // };
        const updatedUserWatchlist = {
          ...state.userWatchListItem,
          [payload.user_grid_data_id]: payload.data,
        };
        // console.log("updateLayoutInUserLayouts:: updatedLayouts", updatedLayouts);
        return { ...state, userWatchListItem: updatedUserWatchlist, isSecuritySubscription: true, monitorDataFlag: "fullfilled" };
      })
      //END getUserWatchlist
      //START addSecurityToWatchlist

      .addCase(addSecurityToWatchlist.rejected, () => {})
      .addCase(addSecurityToWatchlist.pending, () => {})
      .addCase(addSecurityToWatchlist.fulfilled, (state, { payload }) => {
        // console.log("addSecurityToWatchlist request Successfully!", payload);
        const updatedUserWatchlist = {
          ...state.userWatchListItem,
          [payload.user_grid_data_id]: payload.data,
        };
        return {
          ...state,
          isShowAddtoWatchList: false,
          isAlreadyAddedToWatchList: payload.status === 0 ? payload.message : "NO",
          userWatchListItem: updatedUserWatchlist,
        };
      })
      //END addSecurityToWatchlist
      //START removeItemFromUserWatchlist

      .addCase(removeItemFromUserWatchlist.rejected, () => {})
      .addCase(removeItemFromUserWatchlist.pending, () => {})
      .addCase(removeItemFromUserWatchlist.fulfilled, (state, { payload }) => {
        // console.log("removeItemFromUserWatchlist request Successfully!", payload.data);
        // return {
        //   ...state.userWatchListItem,
        //   [payload.user_grid_data_id]: payload.user_grid_data_id,
        // };
        const updatedUserWatchlist = {
          ...state.userWatchListItem,
          [payload.user_grid_data_id]: payload.data,
        };
        // console.log("updateLayoutInUserLayouts:: updatedLayouts", updatedLayouts);
        return { ...state, userWatchListItem: updatedUserWatchlist };
      })
      //END removeItemFromUserWatchlist
      //START sendNewSecurityRequest
      .addCase(sendNewSecurityRequest.rejected, () => {})
      .addCase(sendNewSecurityRequest.pending, () => {})
      .addCase(sendNewSecurityRequest.fulfilled, (state, { payload }) => {
        return { ...state, sendNewSecurityRequestStatus: true };
      });
    //END sendNewSecurityRequest
  },
});

export const {
  updatesendNewSecurityRequestStatus,
  updateAskPriceData,
  updateBidPriceData,
  updatePriceData,
  // updateuserWatchListItem,
  updateSelectedWatchListID,
  updateIsShowSearchModal,
  updateIsShowmanageColumn,
  updateBidAskStreamData,
  updateWatchlistTitleID,
  updateWatchlistTitle,
  updatesetFilterPanel,
  updateIsShowAddtoWatchList,
  updateIsAlreadyAddedToWatchList,
  updateIsShowChangeTitle,
  updatesetSideDrawer,
  updatesetSelectedID,
  updateISUserLayouts,
  update_breakpoints,
  updateUserGridTitle,
  addLayoutToUserLayouts,
  updateLayoutInUserLayouts,
  removeLayoutFromUserLayouts,
  update_main_user_grid,
  remove_main_user_grid,
  update_grid_error_message,
} = MainDashBoard.actions;
// export const profile = (state: RootState) => state.dashBoard.profile;
export const user_layouts: any = (state: RootState) => state.mainDashBoard.user_layouts;
export default MainDashBoard.reducer;
