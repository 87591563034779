import React, { useRef, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import notificationIcon from "../../assets/notification.svg";
import Loading from "../../components/Loading/Loading";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import "../../index.scss";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { fetchNotifications, fetchUpdatedNotifications } from "../../Redux/features/NotificationSlice/NotificationSlice";
import { posttransactionDetailsData } from "../../Redux/features/Transaction/TransactionListingSlice";

import { toast } from "react-toastify";
import { update_transaction_data } from "../../Redux/features/Orders/OrderListingSlice";

import socket from "../../socket";
import { update_kill_counter } from "../../Redux/features/Dashboard/DashBoardSlice";
import { updatesetSelectedID } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
function Notification(props: any) {
  const { notification, setRightHoldingDrawer } = props;
  // const socket: any = useAppSelector((state) => state.dashBoard.socket_connect_obj);
  const [Show, setShow] = useState(false);
  const [isbadge, setIsbadge] = useState(false);
  const selectedPortfolios = useAppSelector((state) => state.dashBoard.portfoliosIds); // console.log(notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (Show) {
        setIsbadge(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [Show]);

  useEffect(() => {
    if (isbadge) {
      dispatch(fetchUpdatedNotifications({ page: 1 }));
      setIsbadge(false);
    }
  }, [isbadge]);
  useEffect(() => {
    // console.log("notification :: befrore socket ");
    socket.on("new-notification-channel:App\\Events\\NotificationEvent", (data: any) => {
      console.log("notification :: before for ", data);
      for (var i in data) {
        let messages = data[i];
        for (var j in messages) {
          if (j === "-1" && messages[j].message === "transactions") {
            //set code for update transaction list
          } else if (selectedPortfolios.includes(j)) {
            let show_toaster = messages[j].show_toaster;

            let trade_record = messages[j].trade_record;

            var user_id = localStorage.getItem("login-id");
            var notification_user_id = messages[j].user_id;
            dispatch(update_transaction_data(trade_record));
            let trades_count = messages[j].trades_count;
            dispatch(update_kill_counter(trades_count));

            if (show_toaster) {
              let title = messages[j].title;
              // set code for update notification list
              let description = messages[j].description;
              dispatch(fetchNotifications({ page: 1 }));
              toast.success(
                <>
                  <p className="font-bold">{title}</p>
                  <p className="text-xs">{description}</p>
                </>,
                {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
            setIsbadge(true);
          }
        }
      }
      // this.isIexStreamStatus = !data;
    });

    return () => {
      // socket.off();
      // this now gets called when the component unmounts
      // socket.off("security_subscription");
    };
    // eslint-disable-next-line
  }, [socket, selectedPortfolios]);
  // const trade_notification_data: any = useAppSelector((state) => state.dashBoard.trade_notification_data);
  // useEffect(() => {
  //   console.log("notification :: befrore socket ");
  //   if (trade_notification_data) {
  //     console.log("notification :: before for ", trade_notification_data);
  //     for (var i in trade_notification_data) {
  //       let messages = trade_notification_data[i];
  //       for (var j in messages) {
  //         if (j === "-1" && messages[j].message === "transactions") {
  //           //set code for update transaction list
  //         } else if (selectedPortfolios.includes(j)) {
  //           let show_toaster = messages[j].show_toaster;
  //           let trade_record = messages[j].trade_record;

  //           var user_id = localStorage.getItem("login-id");
  //           var notification_user_id = messages[j].user_id;
  //           dispatch(update_transaction_data(trade_record));

  //           if (show_toaster) {
  //             let title = messages[j].title;
  //             // set code for update notification list
  //             let description = messages[j].description;
  //             dispatch(fetchNotifications({ page: 1 }));
  //             toast.success(
  //               <>
  //                 <p className="font-bold">{title}</p>
  //                 <p className="text-xs">{description}</p>
  //               </>,
  //               {
  //                 position: "top-right",
  //                 autoClose: 1000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "colored",
  //               }
  //             );
  //           }
  //           setIsbadge(true);
  //         }
  //       }
  //     }
  //   }

  //   return () => {
  //     // socket.off();
  //     // this now gets called when the component unmounts
  //     // socket.off("security_subscription");
  //   };
  //   // eslint-disable-next-line
  // }, [trade_notification_data, selectedPortfolios]);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShow(false));

  const LoadMoreNotifications = () => {
    // console.log("LoadMoreNotifications");
    if (notification.next_page_index !== 0) dispatch(fetchNotifications({ page: notification.next_page_index }));
  };

  return (
    <div ref={ref} className={`${Show === true ? "openNoti" : ""} relative ml-10 current-design-btn`}>
      <button id="theard-element" onClick={() => setShow((prev) => !prev)} className="new-btn-2 notification-group" type="submit">
        <span className="new-btn-2-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.204" height="24.001" viewBox="0 0 19.204 24.001">
            <path
              d="M13291.281,1524.8h-4.881a.8.8,0,0,1-.711-1.159,14.532,14.532,0,0,0,1.512-6.4V1514.4a8,8,0,0,1,7.2-7.961V1504.8a.8.8,0,1,1,1.6,0v1.639a8,8,0,0,1,7.2,7.961v2.822a14.573,14.573,0,0,0,1.513,6.4.8.8,0,0,1-.711,1.175h-11.062a2.389,2.389,0,0,0,.564.895,2.458,2.458,0,0,0,3.392,0,.805.805,0,0,1,1.139,1.139,4.006,4.006,0,0,1-6.752-2.033Zm-2.48-10.4v2.822a16.063,16.063,0,0,1-1.162,5.977h15.12a16.139,16.139,0,0,1-1.159-5.977V1514.4a6.4,6.4,0,1,0-12.8,0Z"
              transform="translate(-13285.602 -1504)"
              className="innerColor"
            />
          </svg>
          {isbadge && <span className="badge-counter"></span>}
        </span>
        <div className="tooltiptext tool-right">
          <p>Notifications</p>
        </div>
      </button>
      {Show && (
        <div className="absolute border z-50 right-[10px] h-[35vh] min-w-[20vw] bg-layoutBg rounded text-tabItemText overflow-hidden notification-main">
          <div id="scrollableDiv" style={{ height: "100%", overflowY: "scroll" }}>
            <InfiniteScroll
              dataLength={notification?.data.length}
              next={LoadMoreNotifications}
              hasMore={notification?.data.length === notification.total_records ? false : true}
              loader={<></>}
              scrollableTarget="scrollableDiv"
              style={{ overflow: "hidden" }}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>No More Records</b>
                </p>
              }
            >
              {notification.notification_status === "loading" ? (
                <Loading isMaximized="minimize" />
              ) : notification?.data.length ? (
                notification?.data.map((item: any, index: any) => (
                  <div
                    className={`${
                      index % 2 === 0
                        ? "text-left text-[12px] bg-tableStripeBg cursor-pointer px-4 p-3"
                        : "text-left text-[12px] cursor-pointer px-4 p-3"
                    } hoverTableCell`}
                    onClick={() => {
                      if (item.id > 0 && item.module == "trades") {
                        console.log("killTransactions select item ", item);
                        dispatch(updatesetSelectedID(item.id));
                        localStorage.setItem("current_selected_tid", item.link);
                        dispatch(
                          posttransactionDetailsData({
                            record_type: item.record_type,
                            id: item.link,
                          })
                        );
                        setRightHoldingDrawer(true);
                        setShow((prev) => !prev);
                      }
                    }}
                    key={index}
                  >
                    <div>
                      {item.title} ({item.description})
                    </div>
                    <span className="text-tabItemText">{item.date}</span>
                  </div>
                ))
              ) : null}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  notification: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
