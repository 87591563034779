import { useEffect, useRef } from "react";
import { textBoxTypes } from "../../types/components/types";

function TextBox({
  label,
  value,
  onChange = null,
  onKeyUp = null,
  readonly = false,
  type = "text",
  name = "",
  className = "",
  filterValue,
  setFilter,
  id,
  maxLength = 200,
  isClickEvent = false,
}: any) {
  const refs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  useEffect(() => {
    // if (onKeyUp === null && refs.current[value]) {
    if (isClickEvent) {
      refs.current[value]?.focus();
    }
  }, [onKeyUp, value]);
  return (
    // <div className="flex h-full flex-col">
    //   <label htmlFor="1" className="text-xs">
    //     {label}
    //   </label>
    //   {onKeyUp === null && (
    //     <input
    //       value={value}
    //       type="text"
    //       name=""
    //       id=""
    //       onChange={(e: any) => onChange(e)}
    //       readOnly={readonly}
    //     />
    //   )}
    //   {onKeyUp !== null && (
    //     <input
    //       value={value}
    //       type="text"
    //       name=""
    //       id=""
    //       onChange={(e: any) => onChange(e)}
    //       onKeyUp={(e: any) => onKeyUp(e)}
    //       readOnly={readonly}
    //     />
    //   )}
    // </div>
    <div className="input-container">
      {onKeyUp !== null && (
        <input
          className={"w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor " + className}
          type={type}
          value={value}
          name={name}
          id=""
          onChange={(e: any) => onChange(e)}
          onKeyUp={(e: any) => onKeyUp(e)}
          readOnly={readonly}
          maxLength={maxLength}
        />
      )}
      {onKeyUp === null && (
        <input
          ref={(el) => (refs.current[value] = el)}
          className={"w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor " + className}
          value={value}
          type={type}
          name={name}
          id=""
          onChange={(e: any) => onChange(e)}
          readOnly={readonly}
          maxLength={maxLength}
        />
      )}
      <label className={` left-2 ${value !== null && "filled"}`} htmlFor={"userName"}>
        {label}
      </label>
    </div>
  );
}

export default TextBox;
