import GridItem from "../../components/Grid/GridItem";
const GridItemContainer = ({ title, gridType, data, children, item,zIndex,handleZindex, ...props }: any) => {
  return (
    <>
      <div className="h-full">
        <GridItem title={title} gridType={gridType} data={data} id={item.id} {...props} handleZindex={handleZindex}>
          {children}
        </GridItem>
      </div>
    </>
  );
};

export default GridItemContainer;
