import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { notificationStateTypes } from "../../../types/components/types";

export const fetchNotifications: any = createAsyncThunk(
  "dashBoard/fetchNotifications",
  async (data) => {
    const response: any = await postData("notifications", data);
    return response.data;
  }
);
export const fetchUpdatedNotifications: any = createAsyncThunk(
  "dashBoard/fetchUpdatedNotifications",
  async (data) => {
    const response: any = await postData("notifications", data);
    return response.data;
  }
);

const initialState: notificationStateTypes = {
  notification_status: "loading",
  current_page: 0,
  data: [],
  next_page_index: 1,
  total_records: 0,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        // console.log("pending request");
        return {
          ...state,
          notification_status: "loading",
        };
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }: any) => {
        // console.log("fetchNotifications", payload);
        return {
          ...state,
          current_page: payload.current_page,
          data: [...state.data, ...payload.data],
          next_page_index: payload.next_page_index,
          total_records: payload.total_records,
          notification_status: "success",
        };
      })
      .addCase(fetchNotifications.rejected, (state) => {
        // console.log("rejection");
        return {
          ...state,
          notification_status: "error",
        };
      })
      .addCase(fetchUpdatedNotifications.pending, (state) => {
        // console.log("pending request");
        return {
          ...state,
          notification_status: "loading",
        };
      })
      .addCase(
        fetchUpdatedNotifications.fulfilled,
        (state, { payload }: any) => {
          // console.log("fetchNotifications", payload);
          return {
            ...state,
            current_page: payload.current_page,
            data: [...payload.data],
            next_page_index: payload.next_page_index,
            total_records: payload.total_records,
            notification_status: "success",
          };
        }
      )
      .addCase(fetchUpdatedNotifications.rejected, (state) => {
        // console.log("rejection");
        return {
          ...state,
          notification_status: "error",
        };
      });
  },
});

export const {} = NotificationSlice.actions;

export default NotificationSlice.reducer;
