import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
function LineChart({ containerWidth, containerHeight, security_name, chart_data, isMaximized, socket }: any) {
  const dispatch = useAppDispatch();
  const chartRef = useRef<any>(null);
  const chartData = useAppSelector((state) => state.chartdata.chart_data);
  const lineTimeline = useAppSelector((state) => state.chartdata.lineTimeline);
  const [bidData, setBidData] = useState<any>([]);
  const [askData, setAskData] = useState<any>([]);
  const [midData, setMidData] = useState<any>([]);
  const [XAxixDateFormat, setXAxixDateFormat] = useState("HH:mm");

  useEffect(() => {
    console.log("lineTimeline", lineTimeline);
    if (lineTimeline === "1D") {
      // setXAxixDateFormat("HH:mm");
      setLineChartOptions({
        ...lineChartOptions,
        xAxis: {
          type: "datetime",
          labels: {
            formatter: function () {
              var point: any = this;
              //console.log("xAxis-point", point.value);
              return moment(point.value).format("HH:mm");
              // return moment(point.value).format(XAxixDateFormat);
            },
          },
        },
      });
    } else {
      // setXAxixDateFormat("DD MMM HH:mm");
      setLineChartOptions({
        ...lineChartOptions,
        xAxis: {
          type: "datetime",
          labels: {
            formatter: function () {
              var point: any = this;
              //console.log("xAxis-point", point.value);
              return moment(point.value).format("DD MMM HH:mm");
              // return moment(point.value).format(XAxixDateFormat);
            },
          },
        },
      });
    }
  }, [lineTimeline]);
  useEffect(() => {
    setBidData([]);
    setAskData([]);
    setMidData([]);
    console.log("CHART:: -------New----chartData", bidData);
    if (chartData.length > 0) {
      let dataLen = chartData.length;
      for (var i = 0; i < dataLen; i++) {
        let d = chartData[i];

        if (d[0] && d[1] > 0) setBidData((bidData: any) => [...bidData, [new Date(d[0]).valueOf(), Number(parseFloat(d[1]).toFixed(3))]]);
        if (d[0] && d[2] > 0) setAskData((askData: any) => [...askData, [new Date(d[0]).valueOf(), Number(parseFloat(d[2]).toFixed(3))]]);
        if (d[0] && d[2] > 0)
          setMidData((midData: any) => [
            ...midData,
            [new Date(d[0]).valueOf(), (Number(parseFloat(d[1]).toFixed(3)) + Number(parseFloat(d[2]).toFixed(3))) / 2],
          ]);
      }
      // console.log("bond_chart_bidask_data -------New----bidItems", bidData);
      // console.log("bond_chart_bidask_data -------New----askItems", askData);
    }
  }, [chartData]);
  useEffect(() => {
    if (bidData.length > 0 && askData.length > 0) {
      setLineChartOptions({
        ...lineChartOptions,
        title: {
          text: "",
        },
        series: [
          {
            // type: "areaspline",
            name: "Bid",
            lineColor: "#3cbd31",
            color: "#3cbd31",
            data: bidData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#3cbd31",
              fillColor: "#3cbd31",
              symbol: "circle",
            },
          },
          {
            // type: "areaspline",
            name: "Ask",
            lineColor: "#e55235",
            color: "#e55235",
            data: askData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#e55235",
              fillColor: "#e55235",
              symbol: "circle",
            },
          },
          {
            // type: "areaspline",
            name: "Mid",
            lineColor: "#ffff00",
            color: "#ffff00",
            data: midData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#ffff00",
              fillColor: "#ffff00",
              symbol: "circle",
            },
          },
        ],
      });
    } else {
      // console.log("bond_chart_bidask_data ----ELSE NULL---New----bidItems", bidData);
      // console.log("bond_chart_bidask_data ----ELSE NULL---New----askItems", askData);
      setLineChartOptions({
        ...lineChartOptions,
        title: {
          // text: "Nothing To Display Here",
          text: "",
        },
        series: [
          {
            // type: "areaspline",
            name: "Bid",
            lineColor: "#3cbd31",
            color: "#3cbd31",
            data: bidData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#3cbd31",
              fillColor: "#3cbd31",
              symbol: "circle",
            },
          },
          {
            // type: "areaspline",
            name: "Ask",
            lineColor: "#e55235",
            color: "#e55235",
            data: askData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#e55235",
              fillColor: "#e55235",
              symbol: "circle",
            },
          },
          {
            // type: "areaspline",
            name: "Mid",
            lineColor: "#ffff00",
            color: "#ffff00",
            data: midData,
            dataGrouping: {
              enabled: false,
            },
            marker: {
              enabled: false,
              lineColor: "#ffff00",
              fillColor: "#ffff00",
              symbol: "circle",
            },
          },
        ],
      });
    }
  }, [bidData, askData]);

  useEffect(() => {
    const chart = chartRef.current.chart;
    // chart.setSize(containerWidth, containerHeight);
    if (Number(containerHeight) && Number(containerWidth)) {
      setTimeout(() => {
        const reflow = () => chart.reflow();
        window.addEventListener("resize", reflow);
        chart.setSize(containerWidth - 20, containerHeight - 36);
        return () => window.removeEventListener("resize", reflow);
      }, 2000);
    }
  }, [containerHeight, containerWidth]);

  const [lineChartOptions, setLineChartOptions] = useState({
    chart: {
      events: {
        load: function () {
          var chart: any = this;
          // console.log(chart.series.length, chart, "Hello Chart");
          var legend: any = document.getElementById("customLegend");
          // console.log(legend, "Hello Chart");

          chart.series.forEach(function (series: any, i: any) {
            // console.log(i, "hello chart");
            var label: any = document?.createElement("label");
            label.classList.add("checking");
            label.innerHTML = series?.name + ' <input class="legendcheckbox" type="checkbox" checked>';
            label.style.color = series?.color;

            label.querySelector("input").addEventListener("change", function (e: any) {
              var series = chart?.series[i];
              if (e?.target?.checked) {
                label.classList.add("checking");
                series?.show();
              } else {
                series?.hide();
                label.classList.remove("checking");
              }
            });

            legend?.appendChild(label);
            // console.log(label);
          });
        },
      },
      type: "line",

      zoomType: "x",
      responsive: true,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          var point: any = this;
          //console.log("xAxis-point", point.value);
          // return moment(point.value).format("HH:mm");
          return moment(point.value).format(XAxixDateFormat);
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: "{value:.3f}",
      },
    },
    legend: {
      enabled: false,
    },
    lang: {
      noData: '<div class="no-datagroup"><img src="./no-data-found.svg" alt=""><p>Nothing To Display Here</p></div>',
    },
    noData: {
      useHTML: true,
      style: {
        fontWeight: "500",
        fontSize: "15px",
        color: "#e55235",
        stroke: "transparent", // basic
        strokeWidth: 0, // hyphenated
        borderBottom: "none",
      },
      position: {
        align: "center",
        verticalAlign: "middle",
      },
    },
    plotOptions: {
      series: {
        connectNulls: true,
        marker: {
          enabled: false,
        },
      },
      spline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "var(--areaGradient_Color)"],
            [1, "var(--areaGradientOne)"],
          ],
        },
        marker: {
          radius: 0,
        },
        lineWidth: 2,
        lineColor: "var(--areaLine_Color)",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        var point: any = this;

        return (
          "<div style='text-align: center'>" +
          moment(point.x).format("DD MMM yyyy HH:mm:ss") +
          "</div><div style='margin-top: 10px' >" +
          point.series.name +
          ": <b>" +
          point.y.toFixed(3) +
          "</b></div>"
        );
      },
    },
    series: [
      {
        // type: "areaspline",
        name: "Bid",
        lineColor: "#3cbd31",
        color: "#3cbd31",
        data: bidData,
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: false,
          lineColor: "#3cbd31",
          fillColor: "#3cbd31",
          symbol: "circle",
        },
      },
      {
        // type: "areaspline",
        name: "Ask",
        lineColor: "#e55235",
        color: "#e55235",
        data: askData,
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: false,
          lineColor: "#e55235",
          fillColor: "#e55235",
          symbol: "circle",
        },
      },
      {
        // type: "areaspline",
        name: "Mid",
        lineColor: "#ffff00",
        color: "#ffff00",
        data: midData,
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: false,
          lineColor: "#ffff00",
          fillColor: "#ffff00",
          symbol: "circle",
        },
      },
    ],
  });
  // console.log("lineChartOptions", lineChartOptions);

  return (
    <>
      <HighchartsReact
        ref={chartRef}
        containerProps={{
          style: {
            backgroundColor: "white",
            height: Number(containerHeight) ? containerHeight - 36 : 190,
            width: Number(containerWidth) && Number(containerWidth) >= 800 ? containerWidth - 20 : 945,
          },
        }}
        className="S"
        highcharts={Highcharts}
        options={lineChartOptions}
      />
      <div id="customLegend"></div>
    </>
  );
}

export default LineChart;
