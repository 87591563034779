import axios from "axios";
import themesConfig from "../utils/themesConfig";

// here axios intercepter will take place
// const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0MjgxIiwianRpIjoiZTMyMDRjZjU3MTdmOTYxMDI4OTc1ZDM3NzY4NGEzMjc2NDEyNmZlNTYwNWRmZWRjMDFjYzljNWZhNjVjYmMwNTA2NTg1NWJlZTRlMmE5YWIiLCJpYXQiOjE2NjE5Mzc2MTAsIm5iZiI6MTY2MTkzNzYxMCwiZXhwIjoxNjkzNDczNjEwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.lMBDiZclyw_udnR20ePClXhimeSyJ_lYHx7E6efJXTn5gl74_e6Pr6Xg17GQLpi2vvO3NT99cckyha4ScnbdLYtxXIw0B_7dRVRX9EwEqJVKw-3FqhF-gmw-RmxTKKz9cXoYwXct1vL5xK6xmESdClDqJR49ZWI2OWuF2R26ixMp8O9z7wKxCLb6W5VLjQlkBnnMBJEFXnQNl1wj6bye-sOx7JiNElbo7H59OL3FdzqNkOZ0cg2txHQzu53b0QfWl9E0hQMbo0wy-7EEQSREd-xcSZn_W_d3JK-aLhDYEztj8FatfDm5KuRCyOlpIRBeJC5aQFuyPXR2gnrDhgloTlDlFeJqtfSE3ebX-5eV4jH4iB2CyM9j3NoisNhjZkuz8TvbqvVUTU3hsS-Z35zh5wRPNmIuZaF1a77b9w5LdmIvCw_qpsLxJTAVG11CH95WgRWdmhiZfVt3xEKgF0ycpeybtY6MF8P9PnAPK50Vs0F7apD7KD2hqx3j3SAieElvdAoQIieZtLwLAHvlQNrmLd4OXCNVO4hWnJz7XD8gWsBRqP813LxirR7BzIQKCD92vh-RgB7mJhNXJwettmgmx86vzez_G0gN24wu7QKRrHQ45ccCSAkGV8OmwXctTffE6H1r-tfOotY50-tuSvb0zFhhxDSY-9rjGDvLnfoenyc'
const token: string | null = localStorage.getItem("user-token");
const baseURL: any = themesConfig.get_react_app_api_endpoint();
// `https://admin-uat.emfi.uk/api/workstation/`,
// baseURL: `http://uatlogin.emfi.local/api/workstation/`,

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

axiosClient.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    let res = error.response;

    console.error("Looks like there was a problem. Status Code: " + res.status);
    if (res.status === 0) {
      // window.location.href = "google.com";
      localStorage.removeItem("user-token");
      window.location.replace(themesConfig.get_react_app_login_url());
    }
    if (res.status === 403) {
      localStorage.removeItem("user-token");
      window.location.replace(themesConfig.get_react_app_login_url());
    }
    if (res.status === 401) {
      // alert("TOKEN ERROR")
      localStorage.removeItem("user-token");
      window.location.replace(themesConfig.get_react_app_login_url());
    }

    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
