import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import moment from "moment";
import { useAppSelector } from "../../../../Redux/app/hooks";
function AreaChart(props: any) {
  const { chart_data, security_name, isMaximized, isSwitchBy, deimalPoint } = props;
  const [first, setfirst] = useState(props.containerHeight);
  const chartRef = useRef<any>(null);
  const candleChartData = useAppSelector((state) => state.chartdata.candle_chart_data);
  const live_candleChartData = useAppSelector((state) => state.chartdata.live_candle_chart_data);
  const [isliveUpdate, setIsliveUpdate] = useState<any>(false);
  useEffect(() => {
    console.log("areachart graph onload : ", deimalPoint);
  }, []);
  useEffect(() => {
    const chart = chartRef.current.chart;
    console.log("areachart graph height : ", props.containerHeight);
    console.log("areachart graph width : ", props.containerWidth);
    // console.log("chart obj out ", chart);
    if (Number(props.containerHeight) && Number(props.containerWidth)) {
      if (chart) {
        setTimeout(() => {
          const reflow = () => chart.reflow();
          window.addEventListener("resize", reflow);
          // console.log("chart obj in ", Object.keys(chart).length);
          if (Object.keys(chart).length !== 0) {
            // chart.setSize(props.containerWidth - 20, props.containerHeight - 36);
            chart.setSize(props.containerWidth > 470 ? props.containerWidth - 20 : props.containerWidth - 40, props.containerHeight - 20);
          }
          return () => window.removeEventListener("resize", reflow);
        }, 800);
      }
    }
  }, [props.containerHeight, props.containerWidth]);

  const [areaChartOptions, setAreaChartOptions] = useState({
    chart: {
      type: "areaspline",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      // min: 0,
      // max: 0,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: `{value:.${deimalPoint}f}`,
      },
    },
    legend: {
      enabled: false,
    },
    lang: {
      noData: '<div class="no-datagroup"><img src="./no-data-found.svg" alt=""><p>Nothing To Display Here</p></div>',
    },
    noData: {
      useHTML: true,
      style: {
        fontWeight: "500",
        fontSize: "15px",
        color: "#333333",
        stroke: "transparent", // basic
        strokeWidth: 0, // hyphenated
        borderBottom: "none",
      },
      position: {
        align: "center",
        verticalAlign: "middle",
      },
    },
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "var(--areaGradient_Color)"],
            [1, "var(--areaGradientOne)"],
          ],
        },
        marker: {
          radius: 0,
        },
        lineWidth: 2,
        lineColor: "var(--areaLine_Color)",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        var point: any = this;
        // console.log("tooltip-point", point);
        return (
          "<div style='text-align: center'>" +
          moment(point.x).format("DD MMM yyyy") +
          "</div><div style='margin-top: 10px' >" +
          point.series.name +
          ": <b>" +
          point.y.toFixed(deimalPoint) +
          // new Intl.NumberFormat("en-US").format(point.y) +
          "</b></div>"
        );
      },
    },
    boost: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (chart_data?.length > 0) {
      if (isSwitchBy !== 1) {
        if (candleChartData.length > 0) {
          let dataLen = candleChartData.length;
          let items: any = [];
          for (var j = 0; j < dataLen; j++) {
            let d = candleChartData[j];
            let one = [];
            one.push(new Date(d[0]).valueOf());
            one.push(Number(parseFloat(d[4]).toFixed(deimalPoint)));
            items.push(one);
          }
          // let max = candleChartData[candleChartData.length - 1][0];
          // let min = candleChartData[candleChartData.length > 40 ? candleChartData.length - 40 : 0][0];
          setAreaChartOptions({
            ...areaChartOptions,
            title: {
              text: "",
            },
            series: [
              {
                name: security_name,
                data: items,
              },
            ],
          });
        } else {
          setAreaChartOptions({
            ...areaChartOptions,
            title: {
              // text: "Nothing To Display Here",
              text: "",
            },

            series: [
              {
                // type: "areaspline",
                name: "",
                data: [],
              },
            ],
          });
        }
      } else {
        let items: any = [];
        let sortedData = chart_data.slice().sort((a: any, b: any) => {
          return new Date(a.created).valueOf() - new Date(b.created).valueOf();
        });
        for (var i in sortedData) {
          let one = [];
          one.push(new Date(sortedData[i].created).valueOf());
          one.push(Number(parseFloat(sortedData[i].price).toFixed(deimalPoint)));
          items.push(one);
        }
        setAreaChartOptions({
          ...areaChartOptions,
          title: {
            text: "",
          },
          series: [
            {
              name: security_name,
              data: items,
            },
          ],
        });
      }
    } else {
      setAreaChartOptions({
        ...areaChartOptions,
        title: {
          // text: "Nothing To Display Here",
          text: "",
        },
        series: [
          {
            // type: "areaspline",
            name: "",
            data: [],
          },
        ],
      });
    }
    //eslint-disable-next-line
  }, [chart_data, candleChartData]);

  return (
    <HighchartsReact
      ref={chartRef}
      containerProps={{
        style: {
          backgroundColor: "white",
          // height: Number(props.containerHeight) ? props.containerHeight - 36 : 190,
          height: `${Number(props.containerHeight) && Number(props.containerHeight) - 20}px`,
          // width: "99%",
          // width: Number(props.containerWidth) && Number(props.containerWidth) >= 800 ? props.containerWidth - 20 : 945,
          width: `${Number(props.containerWidth) && Number(props.containerWidth) - 20}px`,
          marginInline: "auto",
        },
      }}
      className="S"
      highcharts={Highcharts}
      options={areaChartOptions}
    />
  );
}

export default AreaChart;
