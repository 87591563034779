function LoaderForTable({ customHieghtLoader }: any) {
  return (
    <div
      className="loading"
      style={{ height: customHieghtLoader, width: "100%", bottom: "unset", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}
    >
      <div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default LoaderForTable;
