import React from 'react'
import ReactDatePicker from 'react-datepicker'

type Props = {startDate:any, selectDateHandler:(d:any) => void, today:any, label:String}

const CustomDatePicker = ({startDate, selectDateHandler,today, label }: Props) => {
  return (
    <div className="input-container">                                                
        <ReactDatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={selectDateHandler}  maxDate={today} todayButton={"Today"}/>
        <label
        className={`left-2 ${
            startDate && "filled"
        }`}
        htmlFor={"userName"}
        >
        {!startDate ? "dd/mm/yyyy" : <>{label}<span className="required">*</span></>}
        </label>
    </div>
  )
}

export default CustomDatePicker