// import UIController from "../../Redux/features/UIChange/UIController";
import ChartSection from "../../container/Charts/ChartSection";
import RecordListing from "../../container/RecordListing/RecordListing";
import OrderSection from "../../container/TradeWindow/OrderForm/OrderSection";
import TradeWindow from "../../container/TradeWindow/TradeWindow";
import UserWishlist from "../Wishlist/UserWishlist";
import socket from "../../socket";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { postHoldingTypeChartData, update_holding_chart_type_option, update_prices } from "../../Redux/features/ChartData/ChartDataSlice";
import { updateBuyBtnDisabled, updateSellBtnDisabled } from "../../Redux/features/OrderSection/OrderSectionSlice";
import { useEffect, useRef, useState } from "react";
import { updateBidAskStreamData } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import Loading from "../Loading/Loading";
import HistoricalChart from "../../container/Charts/HoldingsChart/HistoricalChart/HistoricalChart";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

const Gridcontent = ({ gridType, height, width, gridID }: any) => {
  const dispatch = useAppDispatch();
  // console.log("graph width 1", gridType + "==" + width);
  const [streamData, setStreamData] = useState({});
  const HoldingTypeChart = useAppSelector((state) => state.chartdata.holding_type_chart);
  const get_holding_typechartdata_status: any = useAppSelector((state) => state.chartdata.holding_typechartdata_status);
  // const bidAskStreamData = useAppSelector((state) => state.mainDashBoard.bidAskStreamData);
  const getHeightChart = useRef<any>(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [headerHeight, setHeadHeight] = useState(0);

  const updateHoldingChartTypeOption = useAppSelector((state) => state.chartdata.holding_chart_type_option);
  const from_date = useAppSelector((state) => state.chartdata.holding_chart_from_date);
  const to_date = useAppSelector((state) => state.chartdata.holding_chart_to_date);
  const filter_portfolio_ids = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const security_id = useAppSelector((state) => state.dashBoard.default_security_set.security_id);
  const search_currency = useAppSelector((state) => state.dashBoard.default_security_set.iex_symbol);
  const marketId = useAppSelector((state) => state.dashBoard.default_security_set.market_id);
  const selected_currency = useAppSelector((state) => state.dashBoard.profile.selected_currency);
  // const handleUpdateChart = (e: any) => {
  //   // console.log(e.value, e.value === "securities" && (Number(marketId) !== 6 || Number(marketId) !== 2), "e.value");
  //   if (e.value !== "all") {
  //     dispatch(
  //       postHoldingTypeChartData({
  //         from_date: from_date,
  //         to_date: to_date,
  //         type: e.value,
  //         security_id:
  //           e.value === "cash" && (Number(marketId) === 6 || Number(marketId) === 2)
  //             ? String(security_id)
  //             : e.value === "securities" && Number(marketId) !== 6 && Number(marketId) !== 2
  //             ? String(security_id)
  //             : undefined,
  //         filter_portfolio_ids: filter_portfolio_ids,
  //         search_currency: Number(marketId) === 6 || Number(marketId) === 2 ? search_currency.slice(0, 3) : undefined,
  //         currency_filter: selected_currency,
  //       })
  //     );
  //   }
  //   dispatch(update_holding_chart_type_option(e));
  // };
  const [chartholdingTypeOption, _setChartholdingTypeOption] = useState([
    { value: "all", label: "All" },
    { value: "cash", label: "Cash" },
    { value: "securities", label: "Securities" },
  ]);
  // console.log("height--- gridType", gridType + "==" + height / 2);
  useEffect(() => {
    // console.log("body", "early");
    if (getHeightChart.current !== null) {
      // console.log("body");
      setBodyHeight(getHeightChart.current.offsetHeight);
    }
  });

  switch (gridType) {
    case "chartGrid":
      return (
        <>
          {/* chart */}
          <ChartSection isMaximized={"minimize"} isMaximizeDispatch={() => {}} height={height} width={width} />
        </>
      );
    case "wishList":
      return (
        <>
          <UserWishlist gridID={gridID} containerheight={"calc(100% - 1px)"} />
        </>
      );
    case "orderList":
      return (
        <>
          <RecordListing isMaximized={false} isMaximizeDispatch={false} height={"calc(100% - 1px)"} width={width - 15} />
          {/* <OrdersListing FilterPanel={orderFilterShow} setFilterPanel={setOrderFilterShow} isMaximize={false} dashBoard={dashBoard} /> */}
        </>
      );
    case "orderWindow":
      return (
        // <div ref={getHeightChart}>
        <>
          {/* Trade window */}
          <OrderSection height={"calc(100% - 1px)"} width={width} />
          {/* <div className="px-4 pb-4 flex justify-between">
            <div className="cursor-default font-bold text-sm text-primaryText"></div>
            <div className="flex items-center his-chart-nav">
              <ul className="daterangepicker-tab-view mr-8">
                {chartholdingTypeOption.map((i) => (
                  <li
                    className={`${updateHoldingChartTypeOption.value === i.value ? "active" : ""} itemList`}
                    onClick={
                      updateHoldingChartTypeOption.value === i.value
                        ? () => {}
                        : () => {
                            handleUpdateChart(i);
                          }
                    }
                  >
                    {i.value}
                  </li>
                ))}
              </ul>
              <DateRangePicker />
            </div>
          </div> */}
          {/* {get_holding_typechartdata_status === "loading" ? (
            <>
              <Loading customHieghtLoader="h-[29vh]" />
            </>
          ) : (
            <HistoricalChart
              containerWidth={getHeightChart?.current?.offsetWidth ? getHeightChart?.current.offsetWidth : 0}
              //  containerHeight={ bodyHeight - headerHeight - 20}
              // containerHeight={ chartReHeight === 'stop_limit' ? 225 : 280 }
              containerHeight={height / 2 - 15}
              chart_data={HoldingTypeChart}
            />
          )} */}
        </>
      );
    default:
      return <div className="h-full">1</div>;
  }
};

export default Gridcontent;
