import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect, useState } from "react";
import { getUserWatchlist, removeItemFromUserWatchlist, updatePriceData } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import Svg from "../Svg/Svg";
import socket from "../../socket";
import { getLastPrice, update_prices } from "../../Redux/features/ChartData/ChartDataSlice";
import { updateBuyBtnDisabled, updateSellBtnDisabled } from "../../Redux/features/OrderSection/OrderSectionSlice";
import { updateDefaultSecuritySet } from "../../Redux/features/Dashboard/DashBoardSlice";
import { formatNumber } from "../../helpers/helper";
type Props = {
  gridID: any;
  containerheight: any;
};
export default function UserWishlist({ gridID, containerheight }: Props) {
  // console.log("Selected containerheight ", containerheight);
  // console.log("Selected containerheight gridID", gridID);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserWatchlist(gridID));
  }, []);
  const userWatchListItem = useAppSelector((state) => state.mainDashBoard.userWatchListItem[gridID]);
  const statusOfuserWatchListItem = useAppSelector((state) => state.mainDashBoard.monitorDataFlag);
  // console.log(statusOfuserWatchListItem);
  // console.log(userWatchListItem);
  const removeItem = (id: any) => {
    dispatch(removeItemFromUserWatchlist(id));
  };
  const buyItem = (i: any) => {
    i = { ...i, tabName: i.security_name, text: i.title };
    console.log("Selected Buy ", i);
    dispatch(updateDefaultSecuritySet(i));
    dispatch(
      update_prices({
        bid_price: 0,
        ask_price: 0,
        rate: 0,
      })
    );
  };
  const sellItem = (i: any) => {
    i = { ...i, tabName: i.security_name, text: i.title };
    console.log("Selected Buy ", i);
    dispatch(updateDefaultSecuritySet(i));
    dispatch(
      update_prices({
        bid_price: 0,
        ask_price: 0,
        rate: 0,
      })
    );
  };
  const price_data = useAppSelector((state) => state.mainDashBoard.price_data);
  const bid_price_data = useAppSelector((state) => state.mainDashBoard.bid_price_data);
  const ask_price_data = useAppSelector((state) => state.mainDashBoard.ask_price_data);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  const clientVendors = useAppSelector((state) => state.orderSection.clientVendors);
  useEffect(() => {
    // console.log("RFS_best_stream_channel:: data------Bid", bid_price_data);
  }, [bid_price_data]);
  // console.log("userWatchListItem------Userlist price_data", price_data);
  // console.log("RFS_best_stream_channel:: data------Bid", bid_price_data);
  // console.log("RFS_best_stream_channel:: data------ASK", ask_price_data);
  // const [price_data, set_price_data] = useState<any>([]);
  useEffect(() => {
    //set grid title

    if (userWatchListItem != null) {
      userWatchListItem?.map(function (item: any) {
        let security_data = item.security;

        if (Number(security_data.market_id) === 1 || Number(security_data.market_id) === 5 || Number(security_data.market_id) === 6) {
          let reqvid = 8;
          //for client
          if (loggedType !== "internal") {
            // Client Vendor :: Find the object with the matching market type ID
            if (clientVendors) {
              const foundObject: any = clientVendors.find((item: any) => Number(item.market_type_id) === Number(security_data.market_id));
              if (foundObject !== undefined) {
                console.log("clientVendors:: watchlist", clientVendors);
                console.log("clientVendors:: watchlist foundObject", foundObject);
                console.log("clientVendors:: watchlist vendor", foundObject.vendor_id);
                reqvid = foundObject.vendor_id;
                // if (Number(security_data.market_id) === 5) {
                //   reqvid = 15;
                // }
              }
            }
          }
          let securityList = [];
          if (Number(security_data.market_id) === 5) {
            securityList = [
              {
                security_id: security_data.id,
                conid: security_data.ib_conid,
                isin_code: security_data.isin_code,
                market_id: security_data.market_id,
                exch: security_data.ib_exchange,
                currency: security_data.currency,
                reqvid: reqvid,
              },
            ];
          } else {
            securityList = [
              {
                security_id: security_data.id,
                conid: security_data.ib_conid,
                isin_code: security_data.isin_code,
                market_id: security_data.market_id,
                exch: security_data.ib_exchange,
                currency: security_data.currency,
                // bp: 0,
                // ap: 0,
              },
            ];
          }

          var securitySet = {
            security_list: securityList,
            // security_list: Number(localStorage.getItem("login-id")) === 2 ? user_securityList : client_user_securityList,
            socket_id: socket.id,
            from: "web",
            user_id: localStorage.getItem("login-id"),
          };
          socket.emit("security_subscription", securitySet);
        }
        if (Number(security_data.market_id) === 8) {
          dispatch(getLastPrice({ security_id: security_data.id, isin_code: security_data.isin_code, from: "stream" }));
        }
      });
    }
  }, [userWatchListItem]);

  // console.log("userWatchListItem", userWatchListItem);
  return (
    <>
      {statusOfuserWatchListItem === "pending" && !userWatchListItem && <span className="watchlist-nodata">Fetching Data..</span>}
      {(!userWatchListItem || userWatchListItem === undefined || userWatchListItem.length === 0) &&
        (statusOfuserWatchListItem === "rejected" || statusOfuserWatchListItem === "fullfilled") && (
          <span className="watchlist-nodata">No data available</span>
        )}
      {userWatchListItem !== undefined && userWatchListItem.length !== 0 && (
        <div className="userwatchlist-panel h-[100%]">
          <ul
            className="userwatchlist-list"
            style={{
              height: `${containerheight}`,
              overflow: "auto",
            }}
          >
            <li className="userwatchlist-item-header">
              <div className="lbl-name">Name</div>
              <div className="lbl-bid">BID</div>
              <div className="lbl-ask">ASK</div>
            </li>
            {userWatchListItem?.map(
              (item: any, index: number) =>
                item.user_grid_data_id === gridID && (
                  <li key={index} className={`${index % 2 === 0 ? "userwatchlist-item bg-tableStripeBg" : "userwatchlist-item"}`}>
                    <div className="item-name">
                      <span className="i-name" title={item.security.security_name}>
                        {item.security.security_name}
                      </span>
                      <span className="i-sub-txt">{item.security.isin_code}</span>
                    </div>
                    {/* for Fund */}
                    {Number(item.security.market_id) === 8 && (
                      <>
                        {price_data[item.security.isin_code] ? (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">{price_data[item.security.isin_code]?.bp}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.bs)}</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">{price_data[item.security.isin_code]?.ap}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.as)}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* END:: for Fund */}
                    {/* for equety */}
                    {Number(item.security.market_id) === 1 && (
                      <>
                        {price_data[item.security.isin_code] ? (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">{price_data[item.security.isin_code]?.bp}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.bs)}</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">{price_data[item.security.isin_code]?.ap}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.as)}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* END:: for equety */}
                    {/*for Forex */}
                    {Number(item.security.market_id) === 6 && (
                      <>
                        {price_data[item.security.isin_code] ? (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">{price_data[item.security.isin_code]?.bp}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.bs)}</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">{price_data[item.security.isin_code]?.ap}</span>
                              <span className="i-sub-txt">{formatNumber(price_data[item.security.isin_code]?.as)}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* END:: for Forex */}
                    {/* for Bond */}
                    {Number(item.security.market_id) === 5 && (
                      <>
                        {bid_price_data[item.security.isin_code] || ask_price_data[item.security.isin_code] ? (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">{bid_price_data[item.security.isin_code]?.bp}</span>
                              <span className="i-sub-txt">{formatNumber(bid_price_data[item.security.isin_code]?.bs)}</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">{ask_price_data[item.security.isin_code]?.ap}</span>
                              <span className="i-sub-txt">{formatNumber(ask_price_data[item.security.isin_code]?.as)}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="item-bid">
                              <span className="i-bid">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                            <div className="item-ask">
                              <span className="i-ask">0</span>
                              <span className="i-sub-txt">0</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* END:: for Bond */}

                    {/* <div className="item-ask">0.000</div> */}
                    {Number(item.security.market_id) === 8 ? (
                      <div className="item-btn">
                        <Svg
                          type="redeem"
                          title="Redeem"
                          // onClickMethod={sellItem(item.security)}
                          onClickMethod={() => sellItem(item.security)}
                          width={70}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#e55235"}
                          classString={"item-sell-btn"}
                          viewboxString={"0 0 70 31"}
                        />
                        <Svg
                          type="subscribe"
                          title="Subscribe"
                          onClickMethod={() => buyItem(item.security)}
                          // onClickMethod={buyItem(item.security)}
                          width={70}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#3cbd31"}
                          classString={"item-buy-btn"}
                          viewboxString={"0 0 70 31"}
                        />
                        <Svg
                          type="remove"
                          title="Delete"
                          onClickMethod={removeItem}
                          width={31}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#e55235"}
                          classString={"item-del-btn"}
                          viewboxString={"0 0 31 31"}
                        />
                        {/* <Svg type="v-dots" isDropDown={true} onClickMethod={setIsDataGrid} isdataGrid={dataGrid} listItem={listItem} /> */}
                      </div>
                    ) : (
                      <div className="item-btn">
                        <Svg
                          type="sell"
                          title="Sell"
                          // onClickMethod={sellItem(item.security)}
                          onClickMethod={() => sellItem(item.security)}
                          width={31}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#e55235"}
                          classString={"item-sell-btn"}
                          viewboxString={"0 0 31 31"}
                        />
                        <Svg
                          type="buy"
                          title="Buy"
                          onClickMethod={() => buyItem(item.security)}
                          // onClickMethod={buyItem(item.security)}
                          width={31}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#3cbd31"}
                          classString={"item-buy-btn"}
                          viewboxString={"0 0 31 31"}
                        />
                        <Svg
                          type="remove"
                          title="Delete"
                          onClickMethod={removeItem}
                          width={31}
                          height={31}
                          onClickParams={item.id}
                          fillColor={"#e55235"}
                          classString={"item-del-btn"}
                          viewboxString={"0 0 31 31"}
                        />
                        {/* <Svg type="v-dots" isDropDown={true} onClickMethod={setIsDataGrid} isdataGrid={dataGrid} listItem={listItem} /> */}
                      </div>
                    )}
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </>
  );
}
