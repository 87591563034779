import { useEffect } from "react";
import { connect } from "react-redux";
import Tab from "../../components/Tab/Tab";
import TabContent from "../../components/TabContent/TabContent";
import { postHoldingsChartData, update_holding_timeline, update_holding_type } from "../../Redux/features/Holdings/HoldingSlice";
import { posttransactionChartData, update_periodicity, update_timeline } from "../../Redux/features/Transaction/TransactionListingSlice";
import HoldingChartsComp from "./HoldingsChart/HoldingChartsComp";
import maximizeIcon from "../../assets/maximize.png";
import TransactionChartComp from "./TransactionChart/TransactionChartComp";
import { postPerformanceChartData, update_chart_type, update_performance_periodicity } from "../../Redux/features/Performance/PerformanceSlice";
import PerfomanceChartComp from "./PerformanceChart/PerfomanceChartComp";
import { postCashFlowChartData, update_cashFlow_periodicity, update_cashFlow_timeline } from "../../Redux/features/CashFlow/CashFlowSlice";
import CashFlowChartComp from "./CashFlowChart/CashFlowChartComp";
import { MemoizedTradingChartComp } from "./TradingChart/TradingChartComp";
import { fetchChartData, fetchGetQuote, update_area_timeline, update_candle_timeline } from "../../Redux/features/ChartData/ChartDataSlice";
import { chartTabStatusUpdate } from "../../Redux/features/UIChange/UIController";
import { useAppDispatch } from "../../Redux/app/hooks";
import minimize from "../../assets/minimize.svg";

function ChartSection({
  activeTab,
  setActiveTab,
  holdings,
  performanceData,
  perfomanceChartDataDispatch,
  dashBoard,
  holdingChartDataDispatch,
  // transaction,
  transactionChartDataDispatch,
  // cashFlows,
  cashFlowChartDataDispatch,
  isMaximized,
  isMaximizeDispatch,
  // getQuoteDataDispatch,
  fetchChartDataDispatch,
  tradingChart,
  socketIO,
  height,
  width,
}: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // console.log("active tab useEffect-----", activeTab);

    //cash flow option reset
    dispatch(update_cashFlow_timeline("one_year"));
    dispatch(update_cashFlow_periodicity("monthly"));

    //performance option reset
    dispatch(update_performance_periodicity("monthly"));
    dispatch(update_chart_type({ No: 1, name: "" }));

    //transaction option reset
    dispatch(update_timeline("one_year"));
    dispatch(update_periodicity("monthly"));

    // holding historical chart option reset
    dispatch(update_holding_timeline("one_year"));
    dispatch(update_holding_type("all"));

    //trading chart option reset
    dispatch(update_area_timeline("one_month"));
    // dispatch(update_candle_timeline("1D"));
  }, [activeTab, dispatch]);

  return (
    // <div className="h-[32vh]">
    <
      // style={{
      //   height: `${Number(height) <= 65 ? 325 : height}px`,
      //   width: `${Number(width) <= 135 ? 610 : width}px`,
      // }}
    >
      {/* <div className="Tab rounded-[20px]">
        <ul className="flex justify-between items-center bg-tabBg rounded-t-[20px]">
          <Tab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            title={[
              // tradingChart.security_name,
              dashBoard.default_security_set.tabName,
              // "Holdings",
              // "Transactions",
              // "Performance",
              // "Cash Flow",
            ]}
          />
          <div className="mx-2">
            <button
              className="mx-2"
              onClick={isMaximized === "minimize" ? () => isMaximizeDispatch("ChartSection") : () => isMaximizeDispatch("minimize")}
            >
              {isMaximized === "minimize" ? <img src={maximizeIcon} className="px-1" alt="" /> : <img src={minimize} className="px-1" alt="" />}
            </button>
          </div>
        </ul>
      </div> */}
      <div className="outlet">
        {/* <TabContent id={dashBoard.default_security_set.tabName} isMaximize={isMaximized} activeTab={activeTab}> */}
        <MemoizedTradingChartComp
          dashBoard={dashBoard}
          isMaximized={isMaximized}
          tradingChart={tradingChart}
          fetchChartDataDispatch={fetchChartDataDispatch}
          socketIO={socketIO}
          height={height}
          width={width}
        />
        {/* </TabContent> */}
        {/* <TabContent
          isMaximize={isMaximized}
          id="Holdings"
          activeTab={activeTab}
        >
          <HoldingChartsComp
            dashBoard={dashBoard}
            isMaximized={isMaximized}
            holdings={holdings}
            holdingChartDataDispatch={holdingChartDataDispatch}
          />
        </TabContent>
        <TabContent
          isMaximize={isMaximized}
          id="Transactions"
          activeTab={activeTab}
        >
          <TransactionChartComp
            // dashBoard={dashBoard}
            isMaximized={isMaximized}
            // transaction={transaction}
            transactionChartDataDispatch={transactionChartDataDispatch}
          />
        </TabContent>
        <TabContent
          isMaximize={isMaximized}
          id="Cash Flow"
          activeTab={activeTab}
        >
          <CashFlowChartComp
            // dashBoard={dashBoard}
            isMaximized={isMaximized}
            // cashFlows={cashFlows}
            cashFlowChartDataDispatch={cashFlowChartDataDispatch}
          />
        </TabContent>
        <TabContent
          isMaximize={isMaximized}
          id="Performance"
          activeTab={activeTab}
        >
          <PerfomanceChartComp
            dashBoard={dashBoard}
            isMaximized={isMaximized}
            performanceData={performanceData}
            perfomanceChartDataDispatch={perfomanceChartDataDispatch}
          />
        </TabContent> */}
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  dashBoard: state.dashBoard,
  holdings: state.holdings,
  transaction: state.transaction,
  performanceData: state.performance,
  cashFlows: state.cashFlows,
  tradingChart: state.chartdata,
  activeTab: state.uicontroller.chartTabStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  // holdingDataDispatch: (data: any) => dispatch(postHoldingsData(data)),
  holdingChartDataDispatch: (data: any) => dispatch(postHoldingsChartData(data)),
  transactionChartDataDispatch: (data: any) => dispatch(posttransactionChartData(data)),
  perfomanceChartDataDispatch: (data: any) => dispatch(postPerformanceChartData(data)),
  cashFlowChartDataDispatch: (data: any) => dispatch(postCashFlowChartData(data)),
  // getQuoteDataDispatch: (data: any) => dispatch(fetchGetQuote(data)),
  fetchChartDataDispatch: (data: any) => dispatch(fetchChartData(data)),
  setActiveTab: (data: any) => dispatch(chartTabStatusUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartSection);
