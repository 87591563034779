import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import modalCloseIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  addSecurityToWatchlist,
  updateIsAlreadyAddedToWatchList,
  updateIsShowSearchModal,
} from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import TextBox from "../TextBox/TextBox";
import Radio from "../Radio/Radio";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useIsMount } from "../../CustomHooks/useIsMount ";
import { fetchSecurityDetails, update_from_search, update_search_items } from "../../Redux/features/ChartData/ChartDataSlice";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import SearchBox from "../SearchBox/SearchBox";
import OrderSearch from "../../assets/search.svg";
import SearchLoader from "../../assets/loader.gif";
import Svg from "../Svg/Svg";
import { toast } from "react-toastify";
type Props = {
  setShow: any;
};

export default function SearchAddToWatchList({ setShow }: Props) {
  const dispatch = useAppDispatch();
  const search_items = useAppSelector((state) => state.chartdata.search_items);
  const securityDet_status = useAppSelector((state) => state.chartdata.securityDet_status);
  const search_is_loading = useAppSelector((state) => state.chartdata.search_is_loading);
  const ordertabDetails = useAppSelector((state) => state.uicontroller.tradeWindowTabStatus);
  const selectedWatchListID = useAppSelector((state) => state.mainDashBoard.selectedWatchListID);
  const isAlreadyAddedToWatchList = useAppSelector((state) => state.mainDashBoard.isAlreadyAddedToWatchList);
  const watchlistTitle = useAppSelector((state) => state.mainDashBoard.watchlistTitle);
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);
  const filteredItems = userGrids.filter((grid: any) => grid.id === selectedWatchListID);
  const sd_message = useAppSelector((state) => state.chartdata.sd_message);
  const from_search = useAppSelector((state) => state.chartdata.from_search);
  // console.log("setDataGrid :: call--ddd---ok", userGrids);
  // console.log("I----watchlistTitle", filteredItems);
  // console.log("I----watchlistTitle", watchlistTitle);
  const isMount = useIsMount();

  const [Suggetion, setSuggetion] = useState<String | null>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSuggetion(null);
    dispatch(update_search_items([]));
    dispatch(update_from_search("monitor"));
    setSuggetion(e.target.value);
  };
  const handleClickEvent = (i: any) => {
    i = { ...i, tabName: i.security_name };
    console.log("I----", i);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (Suggetion != null && Suggetion != "") {
        localStorage.removeItem("new_sec_isin");
        localStorage.setItem("send_new_sec", String(Suggetion));
        dispatch(fetchSecurityDetails({ name: Suggetion, type: ordertabDetails }));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [Suggetion]);

  const [noResultFlag, setNoResultFlag] = useState(false);

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setNoResultFlag(false));

  useEffect(() => {
    if (Object.keys(search_items).length === 0) {
      setNoResultFlag(true);
    } else {
      setNoResultFlag(false);
    }
  }, [search_items]);
  const [selectedId, setSelectedId] = useState<Number>(0);
  useEffect(() => {
    if (isAlreadyAddedToWatchList !== "") {
      dispatch(updateIsAlreadyAddedToWatchList(""));
      if (isAlreadyAddedToWatchList === "NO") {
        if (selectedId) {
          const updatedList = search_items.filter((item: any) => item.id !== selectedId);
          console.log("I----update list call here", updatedList);
          dispatch(update_search_items(updatedList));
          setSelectedId(0);
        }
      } else {
        toast.error(isAlreadyAddedToWatchList, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [isAlreadyAddedToWatchList]);
  useEffect(() => {
    if (Suggetion === "") {
      setSuggetion(null);
    }
  }, [Suggetion]);
  const handleAddWatchlistevent = (sId: any) => {
    console.log("I---- call here", sId);
    setSelectedId(sId);
    dispatch(addSecurityToWatchlist({ id: selectedWatchListID, security_id: sId }));
  };

  return (
    <div className="watchlist-panel userwatchlist-panel">
      <div className="watchlist-panel-header flex justify-between items-center">
        <p className="text-lg font-medium">{filteredItems[0].title} securities add into your list</p>
        <div className="flex gap-4">
          <img className="cursor-pointer" onClick={() => dispatch(updateIsShowSearchModal(false))} src={modalCloseIcon} alt="" />
        </div>
      </div>
      <div>
        <div className="">
          {/* {(search_is_loading === 1 || search_is_loading === 3) && <img className="order-search" src={OrderSearch} alt="" />}
          {search_is_loading === 2 && <img className="order-search-loader" src={SearchLoader} alt="" />} */}
          <SearchBox label="" placeholder="Search by ISIN or Ticker" onBlur={setSuggetion} onChangeFun={handleOnChange} />
          {Suggetion && (
            <div className={Object.keys(search_items).length > 0 ? "absolute order-search-list" : ""}>
              <ul className="userwatchlist-list">
                {Suggetion &&
                  search_items
                    // ?.filter((i: any) => i.text?.includes(Suggetion))
                    ?.map((i: any, index: any) => (
                      <li
                        className={`${index % 2 === 0 ? "userwatchlist-item bg-tableStripeBg" : "userwatchlist-item"}`}
                        key={index}
                        onClick={(e: any) => {
                          handleClickEvent(i);
                          // setSuggetion(null);
                        }}
                      >
                        {(Number(i.market_id) === 6 || Number(i.market_id) === 2) && <span className="list-type cash-type"></span>}
                        {Number(i.market_id) === 1 && <span className="list-type equity-type"></span>}
                        {Number(i.market_id) === 5 && <span className="list-type bond-type"></span>}
                        {Number(i.market_id) === 8 && <span className="list-type fund-type"></span>}
                        {/*<span className="list-type equity-type"></span>
                       <span className="list-type cash-type"></span>
                      <span className="list-type bond-type"></span>
                      <span className="list-type fund-type"></span> */}
                        <p className="overflow-hidden whitespace-nowrap text-ellipsis block w-full">{i.text}</p>
                        <span className="item-btn">
                          <Svg
                            type="plus"
                            onClickMethod={(e: any) => handleAddWatchlistevent(i.id)}
                            width={20}
                            height={20}
                            viewboxString={"0 0 30 30"}
                            classString={"addTowatch-list-add"}
                          />
                          {/* <Svg
                            type="add"
                            title="Add"
                            onClickMethod={(e: any) => handleAddWatchlistevent(i.id)}
                            width={24}
                            height={24}
                            onClickParams={i.id}
                            fillColor={"#3cbd31"}
                            classString={"item-buy-btn"}
                            viewboxString={"0 0 24 24"}
                          /> */}
                        </span>
                      </li>
                    ))}
              </ul>
            </div>
          )}
          {/* {noResultFlag && Suggetion !== null && (
            <div className={Object.keys(search_items).length === 0 ? "absolute order-search-list" : ""}>
              <ul>
                <li>No data available</li>
              </ul>
            </div>
          )} */}
          {/* {noResultFlag && search_is_loading !== 2 && <span className="no-data-string">No data available</span>} */}
          {sd_message !== "OK" && sd_message !== "pending" && sd_message !== "rejected" && from_search === "monitor" && (
            <div ref={ref} className={Object.keys(search_items).length === 0 ? "absolute order-search-list" : ""}>
              <ul>
                <li className="security-watch-not-found-li">Security does not exists. {sd_message !== "OK" ? sd_message : ""}</li>
              </ul>
            </div>
          )}
          {search_is_loading === 2 && <span className="no-data-string">Searching...</span>}
        </div>
      </div>
    </div>
  );
}
