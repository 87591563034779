import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    state : 'loading'

}

const EquitySlice = createSlice({
  name: 'Equity',
  initialState,
  reducers: {}
});

export const {} = EquitySlice.actions

export default EquitySlice.reducer