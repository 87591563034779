import { matchSorter } from "match-sorter";
import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable, useSortBy, useFilters } from "react-table";
import Loading from "../../../components/Loading/Loading";
import { useAppDispatch } from "../../../Redux/app/hooks";
import { cleanstatementData } from "../../../Redux/features/Statements/StatementsSlice";
import { statementColumn } from "../../../utils/column";

export function ReferanceCell(props: any) {
  const { row, id } = props;
  console.log(row);
  return (
    <div className="text-right">
      {row.id !== id ? (
        <div className="flex">{row.values.Portfolio}</div>
      ) : (
        <div className="flex justify-between rounded-sm">
          {row.values.Portfolio}
          <a
            className="bg-secondaryBg px-2 text-navItemsText rounded-sm"
            href={row.original.pdf}
            download
            target="__blanck"
          >
            Download
          </a>
        </div>
      )}
    </div>
  );
}

function StatementListing({
  statements,
  isMaximize,
  dashBoard,
  statementDataDispatch,
}: any) {
  const [hasMore, setHasMore] = useState(true);
  const [rowID, setrowID] = useState<String | null>("");
  const dispatch = useAppDispatch();

  function fuzzyTextFilterFn(rows: any[], id: string, filterValue: any) {
    return matchSorter(rows, filterValue, {
      keys: [(row: any) => row.values[id]],
    });
  }
  fuzzyTextFilterFn.autoRemove = (val: any) => !val;
  const filterTypes: any = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  useEffect(() => {
    statementDataDispatch({
      filter_portfolio_ids: dashBoard?.portfoliosIds,
      draw: 1,
      start: 0,
      length: 40,
      is_filter: 0,
    });
    return () => {
      dispatch(cleanstatementData());
    };
  }, [dashBoard?.portfoliosIds]);

  useEffect(() => {
    if (statements?.next_page_index === 0) {
      setHasMore(false);
    }
  }, [statements?.next_page_index]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: statementColumn,
        data: statements.statementData,
        filterTypes: filterTypes,
        initialState: {
          sortBy: [
            {
              id: "Portfolio",
              desc: false,
            },
          ],
        },
      },
      useFilters,
      useSortBy
    );

  const LoadMoreStatements = () => {
    statementDataDispatch({
      filter_portfolio_ids: dashBoard?.portfoliosIds,
      draw: statements?.next_page_index,
      start: statements.statementData.length,
      length: 40,
      is_filter: 0,
    });
  };
  return (
    <>
      <div
        className={
          isMaximize === "minimize" ? "h-[calc(38vh-20px)] tbScroll" : "h-[83vh] tbScroll"
        }
        id="abc"
        style={{ overflowY: "auto" }}
      >
        <table
          className="w-full sticky top-0 bg-layoutBg "
          {...getTableProps()}
        >
          <thead className="sticky top-0">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="px-2 py-2 text-sm font-normal"
                    {...column.getHeaderProps(
                      isMaximize === "minimize"
                        ? {
                            style: { width: column.width },
                          }
                        : {
                            style: { width: column.width },
                          }
                    )}
                  >
                    <div
                      className="flex"
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                    >
                      {column.render("Header")}
                    </div>
                    <div className="">
                      {column.canFilter
                        ? column.render("Filter", { row: rows })
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>

        <InfiniteScroll
          style={{ width: "100%", display: "table", tableLayout: "fixed" }}
          next={() => LoadMoreStatements()}
          dataLength={statements.statementData.length}
          hasMore={hasMore}
          loader={<></>}
          scrollableTarget="abc"
          {...getTableBodyProps()}
        >
          {statements.statementStatus === "loading" ? (
            <Loading isMaximized={isMaximize} />
          ) : (
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  onMouseEnter={() => setrowID(row.id)}
                  onMouseLeave={() => setrowID(null)}
                  className={`${
                    index % 2 === 0
                      ? "text-left w-full bg-tableStripeBg"
                      : "text-left w-full"
                  } hoverTableCell cursor-pointer`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      style={{ width: cell.column.width }}
                      className="tableCell"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell", { id: rowID })}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
          {/* </tbody> */}
        </InfiniteScroll>
        {statements.statementStatus === "success" && rows.length === 0 && (
          <tr className="text-center flex items-center justify-center no-data-found">
            <div className="no-data">
                <div className="no-datagroup">
                  <img src="./no-data-found.svg" alt="" />
                  <p>Nothing To Display Here</p>
                </div>
            </div>
          </tr>
        )}
      </div>
    </>
  );
}

export default StatementListing;
