import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { TransactionListing } from "../../../types/components/types";

export const fetchTransactions: any = createAsyncThunk(
  "transaction/transactions",
  async (data: any) => {
    const response: any = await postData("transactions", data.payload);
    return {
      data: response.data,
      loader: data.loader,
      isTransaction: data.isTransaction,
      showLoader: true,
    };
  }
);

export const fetchTransactionAndOrdersData: any = createAsyncThunk(
  "transaction/transactionandorder",
  async (data: any) => {
    const response: any = await postData("transactions", data);
    return response.data;
  }
);
export const posttransactionChartData: any = createAsyncThunk(
  "transaction/transactionChartData",
  async (data: any) => {
    const response: any = await postData("transactions-chart", data);
    return response.data;
  }
);
export const posttransactionDetailsData: any = createAsyncThunk(
  "transaction/transaction-details",
  async (data: any) => {
    const response: any = await postData("transaction-details", data);
    return response.data;
  }
);

const initialState: TransactionListing = {
  transactionStatus: "loading",
  transactionChartStatus: "loading",
  transactionDetailStatus: "loading",
  transactions: [],
  total_records: 0,
  current_page: 0,
  next_page_index: 1,
  startPerPage: 0,
  last_page: 0,
  is_filter: 0,
  is_scanner: 0,
  cash_data: {
    data: [],
    name: "",
  },
  bonds_data: {
    data: [],
    name: "",
  },
  equities_data: {
    data: [],
    name: "",
  },
  funds_data: {
    data: [],
    name: "",
  },
  all_trades_data: {
    data: [],
    name: "",
  },
  filterPanel: {
    settlement_date_oprator: "equal",
    settlement_date_val: "",
    trade_date_oprator: "equal",
    trade_date_val: "",
    security_name_oprator: "equal",
    security_name_val: null,
    identifier_oprator: "equal",
    identifier_val: null,
    amount_oprator: "equal",
    amount_val: null,
    price_oprator: "equal",
    price_val: null,
    portfolio_oprator: "equal",
    portfolio_val: null,
    counterparty_oprator: "equal",
    counterparty_val: null,
    status_oprator: "equal",
    status_val: null,
  },
  max_value: 0,
  timeline: "one_year",
  moving_avg: "90",
  periodicity: "monthly",
  cash_status: 1,
  equities_status: 1,
  bonds_status: 1,
  funds_status: 1,
  transactionData: [],
  isUpdateTransactionData: false,
  reference: null,
  trade_date: null,
  security_name: null,
  status: null,
  settlement_date_oprator: "equal",
  settlement_date_val: "",
  trade_date_oprator: "equal",
  trade_date_val: "",
  security_name_oprator: "equal",
  security_name_val: null,
  identifier_oprator: "equal",
  identifier_val: null,
  amount_oprator: "equal",
  amount_val: null,
  price_oprator: "equal",
  price_val: null,
  portfolio_oprator: "equal",
  portfolio_val: null,
  counterparty_oprator: "equal",
  counterparty_val: null,
  status_oprator: "equal",
  status_val: null,
  isin_val: null,
  accured_val: null,
  buy_direction_val: null,
  sortType: null,
  sortDir: null,
  scanner_on: false,
  Loader: false,
};

const TransactionListingSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    showloader: (state: TransactionListing) => {
      return {
        ...state,
        Loader: true,
      };
    },
    hideloader: (state: TransactionListing) => {
      return {
        ...state,
        Loader: false,
      };
    },
    clearTransactionFilter: (state: TransactionListing) => {
      return {
        ...state,
        reference: null,
        trade_date: null,
        status: null,
        settlement_date_oprator: "equal",
        settlement_date_val: "",
        trade_date_oprator: "equal",
        trade_date_val: "",
        security_name_oprator: "equal",
        security_name_val: null,
        identifier_oprator: "equal",
        identifier_val: null,
        amount_oprator: "equal",
        amount_val: null,
        price_oprator: "equal",
        price_val: null,
        portfolio_oprator: "equal",
        portfolio_val: null,
        counterparty_oprator: "equal",
        counterparty_val: null,
        status_oprator: "equal",
        status_val: null,
        isin_val: null,
        accured_val: null,
        buy_direction_val: null,
        sortType: null,
        sortDir: null,
      };
    },
    filterAccured: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        accured_val: payload.accured_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterPrice: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        price_val: payload.price_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterISIN: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        isin_val: payload.isin_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterBuyDirection: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        buy_direction_val: payload.buy_direction_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterIdentifier: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        identifier_val: payload.identifier_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterCounterParty: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        counterparty_val: payload.counter_party_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterStatus: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        status_val: payload.status_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterPortfolio: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        portfolio_val: payload.portfolio_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterAmount: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        amount_val: payload.amount_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterTradeDate: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        trade_date_val: payload.trade_date_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterReference: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        reference: payload.reference,
        scanner_on: payload.scanner_on,
      };
    },
    filterSettlementDate: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        settlement_date_val: payload.settlement_date_val,
        scanner_on: payload.scanner_on,
      };
    },
    filterSecurityName: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        security_name_val: payload.security_name_val,
        scanner_on: payload.scanner_on,
      };
    },
    sortColumn: (state: TransactionListing, { payload }: any) => {
      console.log(payload.sortDir);
      if (state.sortType === payload.sortType) {
        switch (payload.sortDir) {
          case "nosort":
            return {
              ...state,
              sortDir: "asc",
            };
          case "asc":
            return {
              ...state,
              sortDir: "desc",
            };
            break;
          case "desc":
            return {
              ...state,
              sortDir: null,
            };
            break;
          default:
            return {
              ...state,
              sortDir: "asc",
            };
            break;
        }
      } else {
        return {
          ...state,
          sortDir: "asc",
          sortType: payload.sortType,
        };
      }
    },
    clearTransaction: (state: TransactionListing) => {
      return {
        ...state,
        orders: [],
      };
    },
    removeTransactionScannerFilter: (state: TransactionListing) => {
      return {
        ...state,
        is_scanner: 0,
        is_filter: 0,
      };
    },
    update_scanner: (state: TransactionListing, { payload }: any) => {
      return {
        ...state,
        scanner_on: payload.scanner_on,
      };
    },
    updatePayload: (state: TransactionListing, { payload }: any) => {
      console.log(payload, "iii");
      return {
        ...state,
        settlement_date_oprator: payload.settlement_date_oprator,
        settlement_date_val: payload.settlement_date_val,
        trade_date_oprator: payload.trade_date_oprator,
        trade_date_val: payload.trade_date_val,
        security_name_oprator: payload.security_name_oprator,
        identifier_oprator: payload.identifier_oprator,
        security_name_val: payload.security_name_val,
        identifier_val: payload.identifier_val,
        counterparty_oprator: payload.counterparty_oprator,
        counterparty_val: payload.counterparty_val,
        amount_oprator: payload.amount_oprator,
        amount_val: payload.amount_val,
        price_oprator: payload.price_oprator,
        price_val: payload.price_val,
        portfolio_oprator: payload.portfolio_oprator,
        portfolio_val: payload.portfolio_val,
        status_oprator: payload.status_oprator,
        status_val: payload.status_val,
        scanner_on: payload.scanner_on,
      };
    },
    update_timeline: (
      state: TransactionListing,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        timeline: action.payload,
      };
    },
    update_periodicity: (
      state: TransactionListing,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        periodicity: action.payload,
      };
    },
    is_update_transaction_data: (
      state: TransactionListing,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        isUpdateTransactionData: action.payload,
      };
    },
    removeTransactionData: (state: TransactionListing) => {
      return {
        ...state,
        transactionData: [],
      };
    },
    update_transactionChartStatus: (
      state: TransactionListing,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        transactionChartStatus: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionAndOrdersData.pending, (state) => {
        return {
          ...state,
          transactionStatus: "loading",
        };
      })
      .addCase(
        fetchTransactionAndOrdersData.fulfilled,
        (state, { payload }) => {
          console.log(payload, "transaction");
          return {
            ...state,
            transactions: [...state.transactions, ...payload.data],
            total_records: payload.total_records,
            current_page: payload.current_page,
            next_page_index: payload.next_page_index,
            startPerPage: payload.startPerPage,
            last_page: payload.last_page,
            is_filter: payload.is_filter,
            is_scanner: payload.is_scanner,
            transactionStatus: "success",
          };
        }
      )
      .addCase(fetchTransactionAndOrdersData.rejected, (state) => {
        return {
          ...state,
          transactionStatus: "error",
        };
      })
      .addCase(fetchTransactions.pending, (state, payload) => {
        console.log(payload, "payload");
        return {
          ...state,
          transactionStatus: "loading",
          Loader: payload.meta.arg.showLoader ? true : false,
        };
      })
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        console.log(payload, "newtransaction");
        return {
          ...state,
          transactions: payload.isTransaction
            ? payload.loader
              ? [...state.transactions, ...payload.data.data]
              : [...payload.data.data]
            : state.transactions,
          total_records: payload.data.total_records,
          current_page: payload.data.current_page,
          next_page_index: payload.data.next_page_index,
          startPerPage: payload.data.startPerPage,
          last_page: payload.data.last_page,
          is_filter: payload.data.is_filter,
          is_scanner: payload.data.is_scanner,
          transactionStatus: "success",
          Loader: false,
        };
      })
      .addCase(fetchTransactions.rejected, (state) => {
        return {
          ...state,
          transactionStatus: "error",
        };
      })
      .addCase(posttransactionChartData.pending, (state) => {
        return {
          ...state,
          transactionChartStatus: "loading",
        };
      })
      .addCase(posttransactionChartData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cash_data: {
            data: payload.data.cash_data.data,
            name: payload.data.cash_data.name,
          },
          bonds_data: {
            data: payload.data.bonds_data.data,
            name: payload.data.bonds_data.name,
          },
          equities_data: {
            data: payload.data.equities_data.data,
            name: payload.data.equities_data.name,
          },
          funds_data: {
            data: payload.data.funds_data.data,
            name: payload.data.funds_data.name,
          },
          all_trades_data: {
            data: payload.data.all_trades_data.data,
            name: payload.data.all_trades_data.name,
          },
          max_value: payload.data.max_value,
          transactionChartStatus: "success",
        };
      })
      .addCase(posttransactionChartData.rejected, (state) => {
        return {
          ...state,
          transactionChartStatus: "error",
        };
      })
      .addCase(posttransactionDetailsData.pending, (state) => {
        return {
          ...state,
          transactionDetailStatus: "loading",
        };
      })
      .addCase(posttransactionDetailsData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          transactionData: payload.data,
          transactionDetailStatus: "success",
        };
      })
      .addCase(posttransactionDetailsData.rejected, (state) => {
        return {
          ...state,
          transactionDetailStatus: "error",
        };
      });
  },
});

export const {
  filterAccured,
  filterPrice,
  filterAmount,
  filterSecurityName,
  filterCounterParty,
  filterIdentifier,
  filterStatus,
  filterPortfolio,
  filterReference,
  filterSettlementDate,
  filterTradeDate,
  filterISIN,
  filterBuyDirection,
  clearTransactionFilter,
  sortColumn,
  is_update_transaction_data,
  clearTransaction,
  removeTransactionScannerFilter,
  removeTransactionData,
  update_scanner,
  update_timeline,
  updatePayload,
  update_transactionChartStatus,
  update_periodicity,
} = TransactionListingSlice.actions;

export default TransactionListingSlice.reducer;
