import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { update_chart_data } from "../../../../Redux/features/ChartData/ChartDataSlice";
import moment from "moment";
function NewAreaChart(props: any) {
  const dispatch = useAppDispatch();
  const chartRef = useRef<any>(null);
  const candleChartData = useAppSelector((state) => state.chartdata.candle_chart_data);
  const liveCandleChartData = useAppSelector((state) => state.chartdata.live_candle_chart_data);
  const isliveUpdate = useAppSelector((state) => state.chartdata.is_live_update);
  const [areaData, setAreaData] = useState<any>([]);
  const { chart_data, isMaximized, security_name, globalTimeline } = props;
  // useEffect(() => {
  //   if (areaData.length > 0) {
  //     if (liveCandleChartData.length > 0 && isliveUpdate) {
  //       let dataLen = liveCandleChartData.length;
  //       if (dataLen === 1) {
  //         const series: any = chartRef.current?.chart?.series[0];
  //         for (var i = 0; i < dataLen; i++) {
  //           let d = liveCandleChartData[i];
  //           if (areaData.length > 0) {
  //             let lastDataPoint = areaData[areaData.length - 1];
  //             let liveDate = new Date(d[0]).valueOf();
  //             console.log("CHART:: AREAPOINT lastDataPoint", lastDataPoint);
  //             console.log(`CHART:: AREAPOINT condition", ${lastDataPoint[0]} === ${liveDate}`);
  //             if (lastDataPoint[0] === liveDate) {
  //               series.points[series.points.length - 1].update([new Date(d[0]).valueOf(), Number(parseFloat(d[4]).toFixed(2))], true, true);
  //             } else {
  //               series.addPoint([new Date(d[0]).valueOf(), Number(parseFloat(d[4]).toFixed(2))], true, true);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     setTimeout(() => {
  //       if (liveCandleChartData.length > 0 && isliveUpdate) {
  //         let dataLen = liveCandleChartData.length;
  //         if (dataLen === 1) {
  //           const series: any = chartRef.current?.chart?.series[0];
  //           for (var i = 0; i < dataLen; i++) {
  //             let d = liveCandleChartData[i];
  //             if (areaData.length > 0) {
  //               let lastDataPoint = areaData[areaData.length - 1];
  //               let liveDate = new Date(d[0]).valueOf();
  //               console.log("CHART:: AREAPOINT lastDataPoint", lastDataPoint);
  //               console.log(`CHART:: AREAPOINT condition", ${lastDataPoint[0]} === ${liveDate}`);
  //               if (lastDataPoint[0] === liveDate) {
  //                 series.points[series.points.length - 1].update([new Date(d[0]).valueOf(), Number(parseFloat(d[4]).toFixed(2))], true, true);
  //               } else {
  //                 series.addPoint([new Date(d[0]).valueOf(), Number(parseFloat(d[4]).toFixed(2))], true, true);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }, 2000);
  //   }
  // }, [liveCandleChartData]);
  useEffect(() => {
    // console.log("CHART:: AREA isliveUpdate", isliveUpdate);
    // console.log("CHART:: AREA DATA", candleChartData);
    if (!isliveUpdate) {
      if (candleChartData.length > 0) {
        let dataLen = candleChartData.length;
        if (dataLen > 1) {
          for (var i = 0; i < dataLen; i++) {
            let d = candleChartData[i];
            setAreaData((areaData: any) => [...areaData, [new Date(d[0]).valueOf(), Number(parseFloat(d[4]).toFixed(2))]]);
          }
        }
      }
    }
  }, [candleChartData]);

  useEffect(() => {
    if (areaData.length > 0) {
      let max = areaData[areaData.length - 1][0];
      let min = areaData[areaData.length > 40 ? areaData.length - 40 : 0][0];
      setAreaChartOptions({
        ...areaChartOptions,
        title: {
          text: "",
        },
        xAxis: {
          min: min,
          max: max,
        },
        series: [
          {
            type: "area",
            name: security_name,
            data: areaData,
          },
        ],
      });
    } else {
      setAreaChartOptions({
        ...areaChartOptions,
        title: {
          // text: "Nothing To Display Here",
          text: "",
        },
        series: [
          {
            type: "",
            name: security_name,
            data: [],
          },
        ],
      });
    }
  }, [areaData]);

  useEffect(() => {
    const chart = chartRef.current.chart;
    if (Number(props.containerHeight) && Number(props.containerWidth)) {
      setTimeout(() => {
        const reflow = () => chart.reflow();
        window.addEventListener("resize", reflow);
        if (Object.keys(chart).length !== 0) {
          chart.setSize(props.containerWidth - 20, props.containerHeight - 36);
        }
        return () => window.removeEventListener("resize", reflow);
      }, 800);
    }
  }, [props.containerHeight, props.containerWidth]);

  // New Area Chart Options
  const [areaChartOptions, setAreaChartOptions] = useState<any>({
    chart: {
      type: "area",
      zoomType: "x",
    },
    title: {
      text: "",
    },
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          var point: any = this;
          //console.log("xAxis-point", point.value);
          return moment(point.value).format("HH:mm");
        },
      },
      // min: 0,
      // max: 0,
    },

    yAxis: {
      opposite: false,
      labels: {
        format: "{value:.2f}",
      },
      title: {
        text: "",
      },
    },
    legend: {
      enabled: true,
      align: "center",
      floating: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        var point: any = this;
        // console.log("tooltip-point", point.points[0].series.name);
        return (
          "<div style='text-align: center'>" +
          moment(point.x).format("DD MMM yyyy HH:mm:ss") +
          "</div><div style='margin-top: 10px' >" +
          point.points[0].series.name +
          ": <b>" +
          point.y.toFixed(2) +
          // new Intl.NumberFormat("en-US").format(point.y) +
          "</b></div>"
        );
      },
    },
    lang: {
      noData: '<div class="no-datagroup"><img src="./no-data-found.svg" alt=""><p>Nothing To Display Here</p></div>',
    },
    noData: {
      useHTML: true,
      style: {
        fontWeight: "500",
        fontSize: "15px",
        color: "#333333",
        stroke: "transparent", // basic
        strokeWidth: 0, // hyphenated
        borderBottom: "none",
      },
      position: {
        align: "center",
        verticalAlign: "middle",
      },
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "var(--areaGradient_Color)"],
            [1, "var(--areaGradientOne)"],
          ],
        },
        marker: {
          radius: 0,
        },
        lineWidth: 2,
        lineColor: "var(--areaLine_Color)",
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },
    navigator: {
      series: {
        type: "area",
        color: "#ccc",
        fillOpacity: 0.5,
        lineColor: "#c5c5c5",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#ccc"],
            [1, "#fff"],
          ],
        },
      },
    },
    series: [
      {
        type: "",
        name: security_name,
        data: [],
      },
    ],
  });
  return (
    <div>
      <HighchartsReact
        ref={chartRef}
        containerProps={{
          style: {
            backgroundColor: "white",
            height: Number(props.containerHeight) ? props.containerHeight - 36 : 190,
            width: Number(props.containerWidth) && Number(props.containerWidth) >= 800 ? props.containerWidth - 20 : 945,
          },
        }}
        highcharts={Highcharts}
        options={areaChartOptions}
        constructorType={"stockChart"}
      />
    </div>
  );
}

export default NewAreaChart;
