import ColumnFilter from "../components/ColumnFilter/ColumnFilter";
import { OrderAccountFilter } from "../components/OrdersFilter/OrderAccountFilter";
import { AccuredFilter } from "../components/OrdersFilter/OrderAccuredFilter";
import { AmountFilter } from "../components/OrdersFilter/AmountFilter";
import { MarketValueFilter } from "../components/OrdersFilter/MarketValueFilter";
import { BuyDirectionFilter } from "../components/OrdersFilter/OrderBuyDirectionFilter";
import { CounterPartyFilter } from "../components/OrdersFilter/OrderCounterPartyFilter";
import { ISINFilter } from "../components/OrdersFilter/OrderISINFIlter";
import { PriceFilter } from "../components/OrdersFilter/OrderPriceFilter";
import { ReferenceFilter } from "../components/OrdersFilter/OrderReferenceFilter";
import { SecurityNameFilter } from "../components/OrdersFilter/OrderSecurityNameFilter";
import { SettlementDateFilter } from "../components/OrdersFilter/OrderSettlementDateFilter";
import { StatusSelectFilter } from "../components/OrdersFilter/OrderStatusFilter";
import { TradeDateFilter } from "../components/OrdersFilter/OrderTradeDateFilter";
import { OrderTypeFilter } from "../components/OrdersFilter/OrderTypeFilter";
import { TransactionAccountFilter } from "../components/TransactionFilters/TransactionAccountFilter";
import { TransactionAccuredFilter } from "../components/TransactionFilters/TransactionAccuredFilter";
import { TransactionAmountFilter } from "../components/TransactionFilters/TransactionAmountFilter";
import { TransactionBuyDirectionFilter } from "../components/TransactionFilters/TransactionBuyDirectionFilter";
import { TransactionCounterPartyFilter } from "../components/TransactionFilters/TransactionCounterPartyFilter";
import { TransactionISINFilter } from "../components/TransactionFilters/TransactionISINFIlter";
import { TransactionPriceFilter } from "../components/TransactionFilters/TransactionPriceFilter";
import { TransactionReferenceFilter } from "../components/TransactionFilters/TransactionReferenceFilter";
import { TransactionSecurityNameFilter } from "../components/TransactionFilters/TransactionSecurityNameFilter";
import { TransactionSettlementDateFilter } from "../components/TransactionFilters/TransactionSettlementDateFilter";
import { TransactionStatusSelectFilter } from "../components/TransactionFilters/TransactionStatusFilter";
import { TransactionTradeDateFilter } from "../components/TransactionFilters/TransactionTradeDateFilter";
import { RealizedPerformanceCell, UnrealizedPerformanceCell } from "../container/RecordListing/PerfomanceListing/PerfomanceListing";
import { ReferanceCell } from "../container/RecordListing/StatementListing/StatementListing";
import {
  AmountCell,
  AmountPerformanceCell,
  CenterHeader,
  CostBasisPerformanceCell,
  CostValuePerformanceCell,
  couponCashFlowCell,
  LeftSideCell,
  LeftSideHeader,
  MarketHeader,
  MarketValueCell,
  MarketValuePerformanceCell,
  NameCell,
  paymentDateCell,
  PriceCell,
  RedemptionCashFlowCell,
  ReferencePerformanceCell,
  RightSideCell,
  RightSideHeader,
  StatusCell,
  TotalCashFlowCell,
  TradeDateCell,
  TrailerCashFlowCell,
  OrderTypeCell,
} from "./TableCells";

export const HoldingColumns = [
  {
    id: "Name",
    Header: LeftSideHeader,
    accessor: "security_name",
    Filter: ColumnFilter,
    width: "40%",
    MaxWidth: "25%",
    sortType: "basic",
    Cell: NameCell,
  },
  {
    id: "Identifier",
    Header: LeftSideHeader,
    accessor: "identifier_name",
    Filter: ColumnFilter,
    width: "auto",
    MaxWidth: "15%",
    sortType: "basic",
  },
  {
    id: "Class",
    Header: LeftSideHeader,
    accessor: "market_name",
    Filter: ColumnFilter,
    width: "20%",
    MaxWidth: "15%",
  },
  {
    id: "Price",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.last_price),
    Filter: ColumnFilter,
    Cell: PriceCell,
    width: "20%^",
    MaxWidth: "15%",
    sortType: "basic",
    isRightAlign: true,
  },
  {
    id: "Amount",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.original_amount).toFixed(3),
    Filter: ColumnFilter,
    Cell: AmountCell,
    sortType: "basic",
    width: "20%",
    isRightAlign: true,
    MaxWidth: "15%",
  },
  {
    id: "MarketValue",
    Header: RightSideHeader,
    accessor: (d: any) => Number(Number(d.original_value).toFixed()),
    Filter: ColumnFilter,
    sortType: "basic",
    Cell: MarketValueCell,
    width: "20%",
    MaxWidth: "15%%",
    isRightAlign: true,
  },
];

export const OrdersColumns = [
  {
    id: "Reference",
    sort_id: "code",
    Header: LeftSideHeader,
    accessor: "code",
    Filter: ReferenceFilter,
    isRightAlign: false,
    Cell: ReferencePerformanceCell,
    width: "15%",
    MaxWidth: "11%",
    isDisPlay: true,
  },
  {
    id: "Trade Date",
    sort_id: "trade_create",
    Header: LeftSideHeader,
    accessor: "trade_create",
    Filter: TradeDateFilter,
    isRightAlign: false,
    Cell: TradeDateCell,
    width: "11%",
    MaxWidth: "10.5%",
    isDisPlay: true,
  },
  {
    id: "Settlement",
    sort_id: "settlement_date",
    Header: LeftSideHeader,
    accessor: "settlement_date",
    Filter: SettlementDateFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    MaxWidth: "10.5%",
    isDisPlay: true,
  },
  {
    id: "Security",
    sort_id: "security_name",
    Header: LeftSideHeader,
    accessor: "security_name",
    Filter: SecurityNameFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "14%",
    MaxWidth: "10%",
    isDisPlay: true,
  },
  {
    id: "Order Type",
    sort_id: "order_type",
    Header: LeftSideHeader,
    accessor: "order_type",
    Filter: OrderTypeFilter,
    isRightAlign: false,
    Cell: OrderTypeCell,
    width: "11%",
    MaxWidth: "9%",
    isDisPlay: true,
  },
  {
    id: "ISIN",
    sort_id: "isin_code",
    Header: LeftSideHeader,
    accessor: "isin_code",
    // Filter: ColumnFilter,
    Filter: ISINFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "20%",
    MaxWidth: "12%",
    isDisPlay: false,
  },
  {
    id: "Direction",
    sort_id: "direction",
    Header: LeftSideHeader,
    accessor: "direction",
    // Filter: ColumnFilter,
    Filter: BuyDirectionFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "9%",
    MaxWidth: "8%",
    isDisPlay: true,
  },
  {
    id: "Amount",
    sort_id: "original_quantity",
    Header: RightSideHeader,
    accessor: "original_quantity",
    Filter: AmountFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    sortType: "basic",
    width: "14%",
    MaxWidth: "14%",
    isDisPlay: true,
  },
  {
    id: "Price",
    sort_id: "price",
    Header: RightSideHeader,
    accessor: (d: any) => d.price,
    Filter: PriceFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "10%",
    MaxWidth: "8%",
    isDisPlay: true,
  },
  {
    id: "Accured",
    sort_id: "accrued",
    Header: RightSideHeader,
    accessor: "accrued",
    // Filter: ColumnFilter,
    Filter: AccuredFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "10%",
    MaxWidth: "8%",
    isDisPlay: true,
  },
  {
    id: "Market Value",
    sort_id: "market_value",
    Header: MarketHeader,
    accessor: "market_value",
    // Filter: ColumnFilter,
    Filter: MarketValueFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "12%",
    MaxWidth: "11%",
    isDisPlay: true,
  },
  {
    id: "Portfolio",
    sort_id: "portfolio",
    Header: LeftSideHeader,
    accessor: "portfolio",
    Filter: OrderAccountFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "10%",
    MaxWidth: "10%",
    isDisPlay: true,
  },
  {
    id: "Counterparty",
    sort_id: "legal_name_the_entity",
    Header: LeftSideHeader,
    accessor: "legal_name_the_entity",
    Filter: CounterPartyFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "12%",
    MaxWidth: "12%",
    isDisPlay: true,
  },
  {
    id: "Status",
    sort_id: "status",
    Header: CenterHeader,
    accessor: "status",
    Filter: StatusSelectFilter,
    Cell: StatusCell,
    width: "16%",
    MaxWidth: "14%",
    isDisPlay: true,
  },
];
export const TransactionColumns = [
  {
    id: "Reference",
    sort_id: "code",
    Header: LeftSideHeader,
    accessor: "code",
    Filter: TransactionReferenceFilter,
    isRightAlign: false,
    Cell: ReferencePerformanceCell,
    width: "15%",
    MaxWidth: "11%",
  },
  {
    id: "Trade Date",
    sort_id: "trade_create",
    Header: LeftSideHeader,
    accessor: "trade_create",
    Filter: TransactionTradeDateFilter,
    isRightAlign: false,
    Cell: TradeDateCell,
    width: "15%",
    MaxWidth: "12%",
  },
  {
    id: "Settlement",
    sort_id: "settlement_date",
    Header: LeftSideHeader,
    accessor: "settlement_date",
    Filter: TransactionSettlementDateFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    MaxWidth: "12%",
  },
  {
    id: "Security",
    sort_id: "security_name",
    Header: LeftSideHeader,
    accessor: "security_name",
    Filter: TransactionSecurityNameFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "15%",
    MaxWidth: "12%",
  },
  {
    id: "ISIN",
    sort_id: "isin_code",
    Header: LeftSideHeader,
    accessor: "isin_code",
    // Filter: ColumnFilter,
    Filter: TransactionISINFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "20%",
    MaxWidth: "12%",
  },
  {
    id: "Direction",
    sort_id: "trade_direction",
    Header: LeftSideHeader,
    accessor: "trade_direction",
    // Filter: ColumnFilter,
    Filter: TransactionBuyDirectionFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "20%",
    MaxWidth: "8%",
  },
  {
    id: "Amount",
    sort_id: "original_quantity",
    Header: RightSideHeader,
    accessor: (d: any) => d.original_quantity,
    Filter: TransactionAmountFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    sortType: "basic",
    width: "10%",
    MaxWidth: "8%",
  },
  {
    id: "Price",
    sort_id: "price",
    Header: RightSideHeader,
    accessor: (d: any) => d.price,
    Filter: TransactionPriceFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "10%",
    MaxWidth: "8%",
  },
  {
    id: "Accured",
    sort_id: "accrued",
    Header: RightSideHeader,
    accessor: "accrued",
    // Filter: ColumnFilter,
    Filter: TransactionAccuredFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "10%",
    MaxWidth: "8%",
  },
  {
    id: "Market Value",
    sort_id: "market_value",
    Header: MarketHeader,
    accessor: (d: any) => Intl.NumberFormat("en-US").format(parseFloat(d.market_value)),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "12%",
    MaxWidth: "12%",
  },
  {
    id: "Portfolio",
    sort_id: "portfolio",
    Header: LeftSideHeader,
    accessor: "portfolio",
    Filter: TransactionAccountFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "10%",
    MaxWidth: "10%",
  },
  {
    id: "Counterparty",
    sort_id: "legal_name_the_entity",
    Header: LeftSideHeader,
    accessor: "legal_name_the_entity",
    Filter: TransactionCounterPartyFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    width: "12%",
    MaxWidth: "12%",
  },
  {
    id: "Status",
    sort_id: "status",
    Header: CenterHeader,
    accessor: "status",
    Filter: TransactionStatusSelectFilter,
    Cell: StatusCell,
    width: "15%",
    MaxWidth: "10%",
  },
];

export const PerformanceColumns = [
  {
    id: "Name",
    Header: LeftSideHeader,
    accessor: "security_name",
    Filter: ColumnFilter,
    isRightAlign: false,
    Cell: LeftSideCell,
    sortType: "basic",
    width: "25%",
    MaxWidth: "25%",
  },
  {
    id: "Amount",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d?.total_quantity_original?.toFixed(2)), //"total_quantity",
    Filter: ColumnFilter,
    sortType: "basic",
    isRightAlign: true,
    Cell: AmountPerformanceCell,
    width: "15%",
  },
  {
    id: "Cost Basis",
    Header: RightSideHeader,
    accessor: (d: any) => Number(parseFloat(d.avg_cost).toFixed(2)),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: CostValuePerformanceCell,
    sortType: "basic",
    width: "15%",
  },
  {
    id: "Cost Value",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.cost_value_original.toFixed()),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: CostBasisPerformanceCell,
    sortType: "basic",
    width: "15%",
  },
  {
    id: "Market Price",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.market_price),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: RightSideCell,
    width: "15%",
  },
  {
    id: "Market Value",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.market_value_original.toFixed()),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: MarketValuePerformanceCell,
    sortType: "basic",
    width: "15%",
  },
  {
    id: "Unrealized",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.un_realized_original.toFixed()),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: UnrealizedPerformanceCell,
    sortType: "basic",
    width: "15%",
  },
  {
    id: "Realized",
    Header: RightSideHeader,
    accessor: (d: any) => Number(Number(d.realized_original).toFixed()),
    Filter: ColumnFilter,
    isRightAlign: true,
    Cell: RealizedPerformanceCell,
    width: "15%",
    sortType: "basic",
  },
];

export const CashFlowColumn = [
  {
    id: "Payment Date",
    Header: LeftSideHeader,
    accessor: "paymentDate",
    Filter: ColumnFilter,
    Cell: paymentDateCell,
    isRightAlign: false,
    // width:""
  },
  {
    id: "Coupon",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.interestPayments.toFixed(2)),
    isRightAlign: true,
    Cell: couponCashFlowCell,
    Filter: ColumnFilter,
    sortType: "basic",
  },
  {
    id: "Redemption",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.originalmaturityPayment),
    isRightAlign: true,
    Filter: ColumnFilter,
    Cell: RedemptionCashFlowCell,
    sortType: "basic",
  },
  {
    id: "Trailer",
    Header: RightSideHeader,
    accessor: (d: any) => Number(Intl.NumberFormat("en-US").format(d.trailerPayments)).toFixed(),
    Filter: ColumnFilter,
    Cell: TrailerCashFlowCell,
    isRightAlign: true,
    sortType: "basic",
  },
  {
    id: "Total",
    Header: RightSideHeader,
    accessor: (d: any) => Number(d.totalPayments.toFixed(2)),
    Filter: ColumnFilter,
    Cell: TotalCashFlowCell,
    isRightAlign: true,
    sortType: "basic",
  },
];

export const statementColumn = [
  {
    id: "Month-Year",
    Header: LeftSideHeader,
    accessor: "month",
    Filter: ColumnFilter,
    width: "50%",
    sortBy: "basic",
    filter: "fuzzyText",
    MaxWidth: "50%",
  },
  {
    id: "Portfolio",
    Header: LeftSideHeader,
    accessor: "title",
    Filter: ColumnFilter,
    Cell: ReferanceCell,
    width: "50%",
    sortBy: "basic",
    filter: "fuzzyText",
  },
];
