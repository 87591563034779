import { ReactNode, useEffect } from "react";
import arrow1 from "../../assets/arrow.svg";
import { useAppSelector, useAppDispatch } from "../../Redux/app/hooks";
import { updatetransactionKillResponse } from "../../Redux/features/Orders/OrderListingSlice";

type SideDrawerTypes = {
  sideDrawer: boolean;
  setSideDrawer: Function;
  children: ReactNode;
  details?: any;
  refProp?: any;
  varient?: "Right" | "Left";
  isCashFlowWindow?: boolean;
  isholding?: boolean;
  isTrailerFeesWindow?: boolean;
};

function SideDrawer({
  sideDrawer,
  setSideDrawer,
  children,
  details,
  refProp,
  varient = "Right",
  isCashFlowWindow,
  isTrailerFeesWindow,
  isholding,
}: SideDrawerTypes) {
  const dispatch = useAppDispatch();
  const transactionKillResponse = useAppSelector(
    (state) => state.orders.transactionKillResponse
  );

  useEffect(() => {
    if (transactionKillResponse.status === 1) {
      setSideDrawer(false);
    }
    // return () => {
    //   dispatch(updatetransactionKillResponse());
    // };
  }, [transactionKillResponse]);

  return (
    <>
      {sideDrawer ? (
        <>
          <div
            className={sideDrawer ? "side-drawer-shadow z-30" : "hide"}
          ></div>
          <div className={`fixed left-0 top-0 w-full h-screen z-[999]`}>
            <div
              className={`${
                varient === "Right"
                  ? "flex items-center  flex-row z-10 h-screen"
                  : "flex items-center flex-row-reverse z-10 h-screen"
              } `}
            >
              <div
                onClick={() => {
                  setSideDrawer(false);
                }}
                className={` ${isCashFlowWindow ? "w-[45vw]" : "w-[72vw]"} ${
                  isTrailerFeesWindow && "w-[55vw]"
                } h-full`}
              ></div>
              <div
                // ref={refProp}
                className={`
              ${
                varient === "Right"
                  ? "relative h-full rounded-l-[20px] drop-shadow-2xl bg-layoutBg"
                  : "relative h-full rounded-r-[20px] drop-shadow-2xl bg-layoutBg"
              } ${
                  isCashFlowWindow
                    ? "w-[55vw]"
                    : isTrailerFeesWindow
                    ? "w-[45vw]"
                    : "w-[35vw] 2xl:w-[28vw]"
                } ${isTrailerFeesWindow && "w-[45vw]"}`}
              >
                <div
                  className={
                    varient === "Right"
                      ? "absolute left-[0] ml-[-28px] bg-secondaryBg  z-50 top-[45%] font-extrabold  rounded-l-lg "
                      : "absolute right-[0] mr-[-28px] bg-secondaryBg  z-50 top-[45%] font-extrabold  rounded-r-lg"
                  }
                >
                  {isholding ? (
                    <span
                      onClick={() => setSideDrawer(false)}
                      className="cursor-pointer rounded-xl"
                    >
                      <span className="cursor-pointer flex  ">
                        <img
                          className={`h-6 mx-2 my-4 justify-center ${
                            varient !== "Right" ? null : "rotate-180"
                          }`}
                          src={arrow1}
                          alt=""
                        />
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default SideDrawer;
