import { tabTypes } from "../../types/components/types";
import TabNavItem from "../TabItem/TabItem";

function Tab({ activeTab, setActiveTab, title, isDark = false }: tabTypes) {
  return (
    <ul className="Tabs flex h-full">
      {title.map((i) => (
        <TabNavItem
          key={i}
          title={i}
          id={i}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ))}
    </ul>
  );
}

export default Tab;
