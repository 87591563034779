import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../../../services/apiFunctions";
import { ShowHideColumn } from "../../../types/components/types";
import { RootState } from "../../app/store";

const initialState: ShowHideColumn = {
  showHideColumns: {
    code: true,
    trade_create: true,
    settlement_date: true,
    security_name: true,
    order_type: true,
    isin_code: true,
    direction: true,
    original_quantity: true,
    price: true,
    accrued: true,
    market_value: true,
    portfolio: true,
    legal_name_the_entity: true,
    status: true,
  },
};
type YourActionType = {
  type: string;
  payload: {
    columnName: keyof ShowHideColumn["showHideColumns"];
  };
};
const ShowHideColumnSlice = createSlice({
  name: "showHideColumn",
  initialState,
  reducers: {
    toggleShowHideColumn: (state: ShowHideColumn, action: YourActionType) => {
      // const { columnName } = action.payload.columnName;
      console.log("handleAddRemoveFields columnName", action.payload.columnName);
      return {
        ...state,
        showHideColumns: {
          ...state.showHideColumns,
          // [columnName]: action.payload,
          [action.payload.columnName]: !state.showHideColumns[action.payload.columnName],
        },
      };
    },
    // removeShowHideColumns: (state: ShowHideColumn, action: PayloadAction<any>) => {
    //   return {
    //     ...state,
    //     showHideColumns: state.showHideColumns.filter((item) => item !== action.payload),
    //   };
    // },
  },
  extraReducers: (builder) => {},
});

export const { toggleShowHideColumn } = ShowHideColumnSlice.actions;

export default ShowHideColumnSlice.reducer;
