import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./Redux/app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./themes.scss";
import "./index.scss";
import "./fintech-theme.scss";

// import { socketIO } from "./services/apiFunctions";
// let socketURL = process.env.REACT_APP_SOCKET_URL;
// const socket = socketIO(socketURL);

const container = document.getElementById("root")!;
const root = createRoot(container);
let app_mode = process.env.REACT_APP_MODE;
if (app_mode === "production") {
  console.log = function () {};
  console.error = () => {};
  console.debug = () => {};
}
// SocketConnect();
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
