import { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Tab from "../../components/Tab/Tab";
import TabContent from "../../components/TabContent/TabContent";
import { cleanCashFlowData, postCashFlowData } from "../../Redux/features/CashFlow/CashFlowSlice";
import { postHoldingsData } from "../../Redux/features/Holdings/HoldingSlice";
import { postPerformanceData } from "../../Redux/features/Performance/PerformanceSlice";
import { postStatementData } from "../../Redux/features/Statements/StatementsSlice";
import { clearOrderFilter, fetchOrders, removeOrderScannerFilter } from "../../Redux/features/Orders/OrderListingSlice";
import { clearTransactionFilter, removeTransactionScannerFilter } from "../../Redux/features/Transaction/TransactionListingSlice";
import CashFlowlisting from "./CashFlowListing/CashFlowlisting";
import PerfomanceListing from "./PerfomanceListing/PerfomanceListing";
import TransactionListing from "./TransactionListing/TransactionListing";
import FilterIcon from "../../assets/filter.png";
import maximizeIcon from "../../assets/maximize.png";
import { recordListingTabUpdate } from "../../Redux/features/UIChange/UIController";
import { RootState } from "../../Redux/app/store";
import Calendar from "../../assets/Calendar.svg";
import PerformanceDateRange from "../../components/PerformanceDateRange/PerformanceDateRange";
import { DateRangeOptions } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { fetchKill, fetchkillcount, updateDateRange } from "../../Redux/features/Dashboard/DashBoardSlice";
import moment from "moment";
import minimize from "../../assets/minimize.svg";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import OrdersListing from "./OrdersListing/OrdersListing";
import { confirmAlert } from "react-confirm-alert";
import { fetchTransactions } from "../../Redux/features/Transaction/TransactionListingSlice";
import OrdersListingNew from "./OrdersListing/OrderListingNew";

const RecordListing = (props: any) => {
  const {
    activeTab,
    setActiveTab,
    performanceDataDispatch,
    cashFlowDataDispatch,
    isMaximizeDispatch,
    isMaximized,
    transaction,
    cashFlows,
    performance,
    dashBoard,
    isDark = false,
    width,
    height,
  } = props;

  const orders = useAppSelector((state) => state.orders);

  const clearFilters = () => {
    if (dashBoard?.portfoliosIds.length > 0) {
      if (activeTab === "Transactions") {
        dispatch(removeTransactionScannerFilter());
        dispatch(clearTransactionFilter());
        dispatch(
          fetchTransactions({
            payload: {
              filter_portfolio_ids: dashBoard?.portfoliosIds,
              draw: 1,
              start: 0,
              length: 40,
              reference: null,
              trade_date: null,
              security_name: null,
              status: null,
              settlement_date_oprator: "equal",
              settlement_date_val: null,
              trade_date_oprator: "equal",
              trade_date_val: null,
              security_name_oprator: "equal",
              security_name_val: null,
              identifier_oprator: "equal",
              identifier_val: null,
              amount_oprator: "equal",
              amount_val: null,
              price_oprator: "equal",
              price_val: null,
              portfolio_oprator: "equal",
              portfolio_val: null,
              counterparty_oprator: "equal",
              counterparty_val: null,
              status_oprator: "equal",
              status_val: null,
              sort_type: null,
              sort_dir: null,
              is_status_ordered: 1,
            },
            loader: false,
            isTransaction: true,
            showLoader: true,
          })
        );
      } else if (activeTab === "Orders") {
        dispatch(removeOrderScannerFilter());
        dispatch(clearOrderFilter());
        // console.log("here");
        console.log("enter AT ORDER else 3");
        dispatch(
          fetchOrders({
            payload: {
              filter_portfolio_ids: dashBoard?.portfoliosIds,
              draw: 1,
              start: 0,
              length: 40,
              reference: null,
              trade_date: null,
              security_name: null,
              status: null,
              settlement_date_oprator: "equal",
              settlement_date_val: null,
              trade_date_oprator: "equal",
              trade_date_val: null,
              security_name_oprator: "equal",
              security_name_val: null,
              identifier_oprator: "equal",
              identifier_val: null,
              amount_oprator: "equal",
              amount_val: null,
              price_oprator: "equal",
              price_val: null,
              portfolio_oprator: "equal",
              portfolio_val: null,
              counterparty_oprator: "equal",
              counterparty_val: null,
              status_oprator: "equal",
              status_val: null,
              sort_type: null,
              sort_dir: null,
              is_status_ordered: 1,
            },
            loader: false,
            isTransaction: false,
            showLoader: true,
          })
        );
      }
    }
  };

  const [DateRange, setDateRange] = useState(moment().subtract(1, "years").format("YYYY-MM-DD"));
  const [ShowDateRange, setShowDateRange] = useState<boolean>(false);
  const [transactionFilterShow, setTransactionFilterShow] = useState(false);
  const [orderFilterShow, setOrderFilterShow] = useState(false);
  const [killPopUp, setKillPopUp] = useState(false);

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShowDateRange(false));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      updateDateRange({
        start_date: DateRange,
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
  }, [DateRange]);
  const kill_counter = useAppSelector((state) => state.dashBoard.kill_counter);
  useEffect(() => {
    //dispatch(fetchkillcount());
  }, []);

  const tradeKill = () => {
    dispatch(fetchKill({ record_type: "All", id: 0 }));
    setKillPopUp(false);
  };

  const dummyclick = (e: any) => {};

  return (
    <>
      {/* <ul
        className={
          !isDark ? "Tabs flex justify-between  bg-tabBg w-full rounded-t-2xl" : "Tabs flex justify-between  bg-tabBgDark w-full rounded-t-2xl"
        }
      >
        
        <Tab activeTab={activeTab} setActiveTab={setActiveTab} title={["Orders"]} />
        <div className="flex mx-2 items-center">
          {activeTab === "Performance" ? (
            <div>
              <div className="flex bg-tabBg items-center text-tabItemText border border-[#78787980] p-1 text-xs rounded-md">
                <img src={Calendar} className="px-1 h-4" alt="" />
                <button className="" onClick={() => setShowDateRange((prev) => !prev)}>
                  {dashBoard.start_date}-{dashBoard.end_date}
                </button>
              </div>
              <PerformanceDateRange refProp={ref} DateRange={DateRange} setDateRange={setDateRange} Options={DateRangeOptions} Show={ShowDateRange} />
            </div>
          ) : null}

          {activeTab === "Transactions" && (transaction.is_filter || transaction.is_scanner) ? (
            <button
              onClick={() => clearFilters()}
              className="bg-[#d3d4d5] text-[#787879] text-xs my-2 py-1 px-1 mx-3 flex items-center justify-between rounded-md"
            >
              <img src={FilterIcon} className="px-1" alt="" />
              <span className="px-1">Clear Filter</span>
            </button>
          ) : null}

          {activeTab === "Orders" && (orders.is_filter || orders.is_scanner) ? (
            <button
              onClick={() => clearFilters()}
              className="bg-[#d3d4d5] text-[#787879] text-xs my-2 py-1 px-1 mx-3 flex items-center justify-between rounded-md"
            >
              <img src={FilterIcon} className="px-1" alt="" />
              <span className="px-1">Clear Filter</span>
            </button>
          ) : null}

          {(activeTab === "Orders" || activeTab === "Transactions") && isMaximized !== "minimize" ? (
            <button
              className="small-custom-btn filter-btn "
              onClick={activeTab === "Orders" ? () => setOrderFilterShow(true) : () => setTransactionFilterShow(true)}
            >
              Filters
            </button>
          ) : null}

          {activeTab === "Orders" && kill_counter !== 0 && (
            <button
              onClick={() =>
                confirmAlert({
                  title: "Kill Orders",
                  message: "Are you sure ?",
                  buttons: [
                    {
                      label: "OK",
                      className: "custom-btn ok-btn",
                      onClick: () => tradeKill(),
                    },
                    {
                      label: "Cancel",
                      className: "custom-btn cancel-btn",
                      onClick: () => dummyclick(true),
                    },
                  ],
                })
              }
              disabled={kill_counter === 0 ? true : false}
              className="mx-2 my-2 small-custom-btn kill-btn "
            >
              X Kill All
            </button>
          )}

          <button
            className="mx-2"
            onClick={
              isMaximized === "minimize"
                ? () => {
                    dispatch(clearTransactionFilter());
                    dispatch(cleanCashFlowData());
                    isMaximizeDispatch("RecordListing");
                  }
                : () => {
                    isMaximizeDispatch("minimize");
                    dispatch(cleanCashFlowData());
                  }
            }
          >
            {isMaximized === "minimize" ? <img src={maximizeIcon} className="px-1" alt="" /> : <img src={minimize} className="px-1" alt="" />}
          </button>
        </div>
      </ul> */}

      <div className="outlet bg-layoutBg rounded-b-2xl h-[100%]">
        {/* <TabContent isMaximize={isMaximized} id="Orders" activeTab={activeTab}> */}
        <OrdersListingNew
          FilterPanel={orderFilterShow}
          setFilterPanel={setOrderFilterShow}
          isMaximize={isMaximized}
          dashBoard={dashBoard}
          height={"calc(100% - 1px)"}
          width={width}
        />
        {/* <OrdersListing
          FilterPanel={orderFilterShow}
          setFilterPanel={setOrderFilterShow}
          isMaximize={isMaximized}
          dashBoard={dashBoard}
          height={height}
          width={width}
        /> */}
        {/* </TabContent> */}
        {/* <TabContent isMaximize={isMaximized} id="Transactions" activeTab={activeTab}>
          <Suspense fallback={<></>}>
            <TransactionListing
              transaction={transaction}
              isMaximize={isMaximized}
              dashBoard={dashBoard}
              FilterPanel={transactionFilterShow}
              setFilterPanel={setTransactionFilterShow}
            />
          </Suspense>
        </TabContent>
        <TabContent isMaximize={isMaximized} id="Performance" activeTab={activeTab}>
          <PerfomanceListing
            performance={performance}
            isMaximize={isMaximized}
            dashBoard={dashBoard}
            performanceDataDispatch={performanceDataDispatch}
          />
        </TabContent>
        <TabContent isMaximize={isMaximized} id="Cash Flow" activeTab={activeTab}>
          <CashFlowlisting cashFlows={cashFlows} isMaximize={isMaximized} dashBoard={dashBoard} cashFlowDataDispatch={cashFlowDataDispatch} />
        </TabContent> */}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dashBoard: state.dashBoard,
  holdings: state.holdings,
  transaction: state.transaction,
  performance: state.performance,
  cashFlows: state.cashFlows,
  statements: state.statements,
  activeTab: state.uicontroller.recordListingTabStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  holdingDataDispatch: (data: any) => dispatch(postHoldingsData(data)),
  cashFlowDataDispatch: (data: any) => dispatch(postCashFlowData(data)),
  statementDataDispatch: (data: any) => dispatch(postStatementData(data)),
  performanceDataDispatch: (data: any) => dispatch(postPerformanceData(data)),
  setActiveTab: (data: any) => dispatch(recordListingTabUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordListing);
