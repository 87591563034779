import ReactSelect from "react-select";
import { DropDownStyles2 } from "../../helpers/helper";

function SelectFIlter({ field, label }: any) {
  return (
    <div className="order-form-select-input custome-select">
      <label className="select-lable text-2xl">{label}</label>{" "}
      <ReactSelect
       menuPlacement="auto"
       maxMenuHeight={180}
        styles={DropDownStyles2}
        {...field}
        options={[
          { value: "equal", label: "Equal" },
          { value: "not_equal", label: "Not Equal" },
        ]}
      />
    </div>
  );
}

export default SelectFIlter;
