import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import themesConfig from "../utils/themesConfig";
import Loading from "../components/Loading/Loading";

const ProtectedRoute = ({ children }: any) => {
  const [user, setUser] = useState(true);
  const token = localStorage.getItem("user-token");

  useEffect(() => {
    if (!token) {
      window.location.replace(themesConfig.get_react_app_login_url());
      setUser(false);
    }
  }, []);
  return user ? children : <Loading isMaximized={true}/>;
};

export default ProtectedRoute;
