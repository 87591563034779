import React, { useEffect } from "react";
import Loading from "../../../components/Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { RootState } from "../../../Redux/app/store";
import {
  fetchSecurityData,
  removeSecurityData,
} from "../../../Redux/features/DataandResearchSlice/DataandResearchSlice";

function SecurityData() {
  const dispatch = useAppDispatch();
  const SecurityDetails = useAppSelector(
    (state: any) => state.dataAndResearch.security_research.basic_details
  );
  const security_data_loader = useAppSelector(
    (state: any) => state.dataAndResearch.dataStatus
  );
  const SecurityID = useAppSelector(
    (state: RootState) => state.dashBoard.default_security_set.security_id
  );

  useEffect(() => {
    dispatch(fetchSecurityData(SecurityID));
    return () => {
      dispatch(removeSecurityData());
    };
  }, [dispatch, SecurityID]);
  return (
    <>
      {security_data_loader !== "loading" ? (
        <table className="w-full">
          {SecurityDetails?.map((i: any, index: any) => (
            <tr
              className={`${
                index % 2 === 0 ? "text-left" : "text-left"
              } hoverTableCell`}
            >
              <td className="tableCell">{i.label}</td>
              <td className="tableCell">{i.value}</td>
            </tr>
          ))}
        </table>
      ) : (
        <Loading isMaximized={"minimize"} />
      )}
    </>
  );
}

export default SecurityData;
