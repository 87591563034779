import themesConfig from "../../utils/themesConfig";
import { useAppSelector } from "../../Redux/app/hooks";
type Props = {};

const GlobalSearch = (props: Props) => {
  const baseURL: any = themesConfig.get_react_app_login_url();
  // console.log("userId", );
  return (
    <div className="relative header-search">
      <form id="fifth-element" className="flex header-search-box" method="GET" action={`${baseURL}all-transactions`}>
        <input
          className="order-form-search text-primary"
          placeholder="Search by Reference, ISIN, Ticker or Counterparty"
          type="text"
          name="transactionGlobalSearch"
          id=""
        />
        <input type="hidden" name="transactionGlobalSearchLevel" id="" value="0" />
        <button type="submit">
          <svg className="searchSvg" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
              d="M17.749,16.689l-4.471-4.471a7.5,7.5,0,1,0-1.059,1.059l4.471,4.471a.749.749,0,1,0,1.059-1.059ZM7.482,13.474a5.992,5.992,0,1,1,5.992-5.992A5.992,5.992,0,0,1,7.482,13.474Z"
              transform="translate(0.032 0.032)"
              fill="#071932"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default GlobalSearch;
