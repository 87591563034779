import moment from "moment";
import downArrow from "../assets/downArrow.svg";
import { useAppSelector } from "../Redux/app/hooks";

export function LeftSideHeader(props: any) {
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  return (
    <div className="flex flex-row justify-start">
      <div className="text-left table-title-name font-semibold cursor-pointer overflow-hidden block whitespace-nowrap text-ellipsis">
        {props.column.id}
      </div>

      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <img className="px-2 h-4 " src={downArrow} alt="" />
        ) : (
          <img className="px-2 h-4 rotate-180" src={downArrow} alt="" />
        )
      ) : null}
      {props.column.sort_id === sort_type ? (
        sort_dir !== null ? (
          <img className={`px-2 h-4 ${sort_dir === "asc" ? "rotate-180" : ""} `} src={downArrow} alt="" />
        ) : null
      ) : null}
    </div>
  );
}
export function CenterHeader(props: any) {
  return (
    <div className="text-center table-title-name font-semibold cursor-pointer overflow-hidden block whitespace-nowrap text-ellipsis">
      {props.column.id}
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <img className="px-2 h-4 " src={downArrow} alt="" />
        ) : (
          <img className="px-2 h-4 rotate-180" src={downArrow} alt="" />
        )
      ) : null}
    </div>
  );
}
export function RightSideHeader(props: any) {
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  return (
    <div className="flex flex-row justify-end">
      <div className="table-title-name font-semibold overflow-hidden block whitespace-nowrap text-ellipsis">{props.column.id}</div>
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <img className="px-2 pr-0 h-4 " src={downArrow} alt="" />
        ) : (
          <img className="px-2 pl-0 h-4 rotate-180" src={downArrow} alt="" />
        )
      ) : null}
      {props.column.sort_id === sort_type ? (
        sort_dir !== null ? (
          <img className={`px-2  h-4 ${sort_dir === "asc" ? "rotate-180 pl-0" : "pr-0"} `} src={downArrow} alt="" />
        ) : null
      ) : null}
    </div>
  );
}
export function MarketHeader(props: any) {
  const sort_type = useAppSelector((state) => state.orders.sortType);
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  return (
    <div className="flex flex-row justify-end">
      {/* {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <img className="px-2 h-4 " src={downArrow} alt="" />
        ) : (
          <img className="px-2 h-4 rotate-180" src={downArrow} alt="" />
        )
      ) : null} */}
      <div className="table-title-name font-semibold overflow-hidden block whitespace-nowrap text-ellipsis">{props.column.id}</div>
      {/* {props.column.sort_id === sort_type ? (
        sort_dir !== null ? (
          <img className={`px-2 h-4 ${sort_dir === "asc" ? "rotate-180 pl-0" : "pr-0"} `} src={downArrow} alt="" />
        ) : null
      ) : null} */}
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <img className="px-2 pr-0 h-4 " src={downArrow} alt="" />
        ) : (
          <img className="px-2 pl-0 h-4 rotate-180" src={downArrow} alt="" />
        )
      ) : null}
      {props.column.sort_id === sort_type ? (
        sort_dir !== null ? (
          <img className={`px-2  h-4 ${sort_dir === "asc" ? "rotate-180 pl-0" : "pr-0"} `} src={downArrow} alt="" />
        ) : null
      ) : null}
    </div>
  );
}

export function AmountCell({ row, id }: any) {
  return (
    <div className="text-right">
      {row.id !== id ? (
        row.original.base_amount ? (
          row.original.base_amount
        ) : (
          row.original.amount
        )
      ) : (
        <button className="bg-[#3cbd31] hover:bg-[#0B8800] px-2 text-navItemsText rounded-sm">Buy</button>
      )}
    </div>
  );
}
export function NameCell({ row }: any) {
  return (
    <div className="flex  items-center">
      <span
        className={`list-bullet-style w-[6px] h-[6px] mx-2 inline-block rounded-[50%]  
          ${row.original.market_name === "Equity" ? "bg-[#50C076]" : "null"} 
          ${row.original.market_name === "Currency" ? "bg-[#9CAA99]" : "null"} 
          ${row.original.market_name === "Bond" ? "bg-[#FCE526]" : "null"} 
          ${row.original.market_name === "Fund" ? "bg-[#6765A6]" : "null"} `}
      ></span>
      <div className="w-[11rem]">
        <p className="block whitespace-nowrap overflow-hidden text-ellipsis max-w-[9rem]">{row.original.security_name.toString()}</p>
      </div>
    </div>
  );
}
export function PriceCell(props: any) {
  const { row, id } = props;
  return (
    <div className="text-right overflow-hidden block whitespace-nowrap text-ellipsis">
      {row.id !== id ? row.original.last_price : <button className="bg-[#e55235] hover:bg-[#A52107] px-2 text-navItemsText rounded-sm">Sell</button>}
    </div>
  );
}
export function MarketValueCell(props: any) {
  const { row } = props;
  return <div className="text-right overflow-hidden block whitespace-nowrap text-ellipsis">{row.original.value}</div>;
}
export function TradeDateCell(props: any) {
  const { row } = props;
  return (
    <div className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">{moment(row.original.trade_date).format("MM/DD/YYYY")}</div>
  );
}
// Performance CELL
export function AmountPerformanceCell(props: any) {
  return (
    <div className="text-right overflow-hidden block whitespace-nowrap text-ellipsis">
      {Math.sign(props.cell.value) === -1
        ? `(${Intl.NumberFormat("en-US")
            .format(Number(Number(props.cell.value).toFixed(2)))
            .replace("-", "")})`
        : `${Intl.NumberFormat("en-US").format(Number(Number(props.cell.value).toFixed(2)))}`}
    </div>
  );
}
export function CostValuePerformanceCell(props: any) {
  return (
    <div className="text-right">
      <div className="text-right">
        {Math.sign(props.cell.value) === -1
          ? `(${Intl.NumberFormat("en-US")
              .format(Number(Number(props.cell.value).toFixed(2)))
              .replace("-", "")})`
          : `${Intl.NumberFormat("en-US").format(Number(Number(props.cell.value).toFixed(2)))}`}
      </div>
    </div>
  );
}
export function MarketValuePerformanceCell(props: any) {
  return <div className="text-right">{props.row.original.market_value}</div>;
}

export function CostBasisPerformanceCell(props: any) {
  return <div className="text-right">{props.row.original.cost_value}</div>;
}

// CashFlow CELL
export function RedemptionCashFlowCell(props: any) {
  return <div className="text-right">{props.row.original.maturityPayment}</div>;
}
export function TrailerCashFlowCell(props: any) {
  return <div className="text-right">{Number(Intl.NumberFormat("en-US").format(props.row.original.trailerPayments)).toFixed()}</div>;
}
export function TotalCashFlowCell(props: any) {
  return (
    <div className="text-right">
      {Math.sign(props.row.original.totalPayments.toFixed(2)) === -1
        ? `(${Intl.NumberFormat("en-US").format(Math.abs(props.row.original.totalPayments.toFixed(2)))})`
        : `${Intl.NumberFormat("en-US").format(props.row.original.totalPayments.toFixed(2))}`}
    </div>
  );
}

export function couponCashFlowCell(props: any) {
  return (
    <div className="text-right">
      {Math.sign(props.row.original.interestPayments.toFixed(2)) === -1
        ? `(${Intl.NumberFormat("en-US").format(Math.abs(props.row.original.interestPayments.toFixed(2)))})`
        : `${Intl.NumberFormat("en-US").format(props.row.original.interestPayments.toFixed(2))}`}
    </div>
  );
}
export function OrderTypeCell(props: any) {
  // console.log("{props.row.original.order_type", props.row.original.order_type.replace("_", " ").toUpperCase());
  return (
    <div className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">
      {props.row.original.order_type?.replace("_", " ").toUpperCase()}
    </div>
  );
}
// Global CELL
export function LeftSideCell(props: any) {
  return <div className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">{props.cell.value}</div>;
}
export function paymentDateCell(props: any) {
  return <div className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">{props.row.original.paymentDates}</div>;
}
export function ReferencePerformanceCell(props: any) {
  const { row } = props;
  return (
    <p className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">{props.cell.value.toString()}</p>
    // <div className="flex items-center">
    //   <span
    //     className={`list-bullet-style w-[6px] h-[6px] mx-2 inline-block rounded-[50%]
    //       ${row.original.market_id === 1 ? "bg-[#50C076]" : "null"}
    //       ${row.original.market_id === 6 || row.original.market_id === 2 ? "bg-[#9CAA99]" : "null"}
    //       ${row.original.market_id === 5 ? "bg-[#FCE526]" : "null"}
    //       ${row.original.market_id === 8 ? "bg-[#6765A6]" : "null"} `}
    //   ></span>
    //   <div className="">
    //     <p className="text-left overflow-hidden block whitespace-nowrap text-ellipsis">{props.cell.value.toString()}</p>
    //   </div>
    // </div>
  );
}
export function RightSideCell(props: any) {
  return <div className="text-right overflow-hidden block whitespace-nowrap text-ellipsis">{props.cell.value}</div>;
}
export function StatusCell(props: any) {
  return (
    <div className="text-center c-tooltip-wrap">
      <div className={`${props.cell.value} c-tooltip-selected`}>
        <div className="c-tooltip-label">{props.cell.value}</div>
        <ul className="c-tooltip">
          {props.row.original.web_status_tooltips?.map((i: any) => (
            <li key={`sc-${i}`} className={`${i.lastIndexOf(props.cell.value) > -1 ? "active" : ""}`}>
              {i}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function StatusCellForV8table(props: any) {
  // console.log(props?.cell?.row?.original?.web_status_tooltips);
  // {
  //   props?.cell?.row?.original?.web_status_tooltips?.map((i: any) => {
  //     const statusWords = i.toLowerCase().split(" ");
  //     // Check if any of the words match props.getValue()
  //     const isMatch = statusWords.includes(props.cell.row.original.status.toLowerCase());
  //     console.log(isMatch ? "yes" : "no", props.cell.row.original.status, i);
  //   });
  // }
  return (
    <div className="text-center c-tooltip-wrap">
      <div className={`${props.cell.row.original.status} c-tooltip-selected`}>
        <div className="c-tooltip-label">{props?.cell?.row?.original?.status}</div>
        <ul className="c-tooltip">
          {props?.cell?.row?.original?.web_status_tooltips?.map((tooltipText: string, index: number) => (
            <li key={`status-tooltip-${index}`} className={`${tooltipText.replace("Order ", "") === props.cell.row.original.status ? "active" : ""}`}>
              {tooltipText}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

// Transaction Filters
