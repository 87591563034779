import React, { ReactNode, useEffect, useState } from "react";
import Tab from "../Tab/Tab";
import TabContent from "../TabContent/TabContent";
import modalCloseIcon from "../../assets/close-icon.svg";
import Button from "../Button/Button";
import Select, { components, ValueContainerProps } from "react-select";
import {
  DropDownStyles,
  DropDownStyles2,
  DropDownStylesMulti,
  DropDownStylesMulti2,
  getCountryPreferencesSelected,
  getMailingPreferencesSelected,
  getSelectedCountriesFromStrings,
  getSelectedRegionsFromStrings,
} from "../../helpers/helper";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  update_country_ids,
  update_is_report_country,
  update_regions_ids,
  update_report_send_time,
} from "../../Redux/features/Dashboard/DashBoardSlice";
import { postChangePwd, postSettings, update_SettingMSg } from "../../Redux/features/Settings/SettingSlice";
import AlertBox from "../AlertBox/AlertBox";
import TextBox from "../TextBox/TextBox";
import { useForm } from "react-hook-form";

type PopModalType = {
  view: boolean;
  setView: Function;
};

function SettingsPopup({ view, setView }: PopModalType) {
  const [activeTab, setActiveTab] = useState("Preferences");
  const dispatch = useAppDispatch();
  const [newpassword, setnewpassword] = useState();
  const [oldpassword, setoldpassword] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiMsg, setApiMsg] = useState("");
  // mail pref vars start
  const mail_preferences_data: any = [
    { value: 1, label: "Realtime" },
    { value: 2, label: "Daily" },
    { value: 3, label: "Weekly" },
    { value: 0, label: "No Reports" },
  ];

  const report_send_time = useAppSelector((state: any) => state.dashBoard.profile.report_send_time);

  const selectedMailPref = getMailingPreferencesSelected(report_send_time);

  const onMailPrefChange = (e: any) => {
    dispatch(update_report_send_time(e.value));
  };
  // mail pref vars over

  // country pref vars start
  const country_preferences_data: any = [
    { value: 1, label: "All" },
    { value: 2, label: "Selected" },
  ];

  const is_report_country = useAppSelector((state: any) => state.dashBoard.profile.is_report_country);

  const selectedCountryPref: any = getCountryPreferencesSelected(is_report_country);

  const onCountryPrefChange = (e: any) => {
    // console.log("onCountryPrefChange", e);
    dispatch(update_is_report_country(e.value));
  };
  // country pref vars over

  const ValueContainerRegion = ({ children, ...props }: ValueContainerProps<any>) => {
    let [values, input] = children as any;
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          0 Regions
          {/* {input} */}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        {`${nbValues} Regions`}
        {input}
      </components.ValueContainer>
    );
  };

  const ValueContainerCountries = ({ children, ...props }: ValueContainerProps<any>) => {
    let [values, input] = children as any;
    // console.log("==", children);
    // if (Array.isArray(values)) {
    //   const val = (i: number) => values[i].props.children;
    //   const { length } = values;

    //   if (length === 1) {
    //     values = `${val(0)}`;
    //   } else if (length > 1) {
    //     values = `${length} Countries`;
    //   }
    // } else {
    //   values = "0 Countries";
    // }
    // // console.log(values);
    // return (
    //   <components.ValueContainer {...props}>
    //     {values}
    //     {input}
    //   </components.ValueContainer>
    // );
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          0 Countries
          {/* {input} */}
          {/* {children} */}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        {`${nbValues} Countries`}
        {input}
      </components.ValueContainer>
    );
  };

  const settings = useAppSelector((state) => state.settings);
  //   console.log(settings);

  // useEffect(() => {
  //   // console.log("updateSettingMSg===", settings.updateSettingMSg);
  //   if (settings.updateSettingMSg !== "") {
  //     // alert(settings.updateSettingMSg);
  //     setApiMsg(settings.updateSettingMSg);
  //     setAlertOpen(true);
  //     if (settings.post_Status === "success") setView(false);
  //   }
  //   // return () => {
  //   //   console.log("updateSettingMSg=== cleanup");
  //   //   dispatch(update_SettingMSg());
  //   //   reset();
  //   // };
  // }, [settings.updateSettingMSg]);

  // regions var start
  const regions_ids = useAppSelector((state: any) => state.dashBoard.profile.regions_ids);

  const regions = useAppSelector((state) => state.settings.regions_list);

  const selectedRegion = getSelectedRegionsFromStrings(regions_ids, regions);

  const onRegionsChange = (e: any) => {
    // console.log("onRegionsChange", e);

    let select_region_ids = e.map((i: any) => Number(i.value));

    dispatch(update_regions_ids(select_region_ids.toString()));

    //updating countries from selected regions
    let newArray = [];
    for (var j in countries) {
      if (select_region_ids.includes(countries[j].region_id)) {
        newArray.push(countries[j].id);
      }
    }

    dispatch(update_country_ids(newArray.toString()));
  };
  // regions var end

  //countries var start
  const country_ids = useAppSelector((state: any) => state.dashBoard.profile.country_ids);

  const countries = useAppSelector((state) => state.settings.counties_with_region);

  const selectedCountry = getSelectedCountriesFromStrings(country_ids, countries);

  // console.log("selectedCountry", selectedCountry);

  const onCountryChange = (e: any) => {
    // setSelectedCountry(e);
    // console.log("onCountryChange", e);
    // selectedCountry = e;
    dispatch(update_country_ids(e.map((i: any) => i.value).toString()));
  };
  //countries var end

  const updateSettings = () => {
    // if (activeTab === "Preferences") {
    let payload = {
      is_report_country: selectedCountryPref.value,
      report_send_time: selectedMailPref.value,
      country_ids: selectedCountry.map((item) => item.value),
      region_ids: selectedRegion.map((item) => item.value),
    };

    // console.log(payload);

    dispatch(postSettings(payload));
    // } else if (activeTab === "Profile") {
    // dispatch(postChangePwd(profileData));
    // }
  };

  const userName = useAppSelector((state: any) => state.dashBoard.profile.name);

  const userEmail = useAppSelector((state: any) => state.dashBoard.profile.email);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  // console.log(watch("name"), "name");
  const onSubmit = (data: any) => {
    // console.log(data);
    dispatch(postChangePwd(data));
  };

  const required = "This field is required";

  const errorMessage = (error: any) => {
    return <div className="text-red-600">{error}</div>;
  };

  return (
    <>
      {view ? (
        <div className="fixed z-20 top-0 left-0 bottom-0 right-0 h-screen  ">
          <div className="flex items-center justify-center bg-[rgba(0,0,0,0.68)] z-10 h-screen  ">
            <div className="w-[80vw] h-[80vh] sm:w-[585px] sm:h-auto  bg-layoutBg custome-modal ">
              <div className="modal-header">
                <h2>Settings</h2>
                <span className="cursor-pointer" onClick={() => setView(false)}>
                  <img src={modalCloseIcon} alt="" />
                </span>
              </div>
              <div className="modal-body">
                <ul className="flex bg-tabBg rounded-t-[20px]">
                  <Tab activeTab={activeTab} setActiveTab={setActiveTab} title={["Preferences", "Profile"]} />
                </ul>

                <div className="outlet bg-layoutBg h-[100%] text-black">
                  <TabContent isMaximize="minimize" id="Preferences" activeTab={activeTab} className="overflow-visible">
                    <div className="form-main flex-wrap mt-4">
                      <div className="form-group custome-select">
                        <label className="select-lable">Mailing Preferences</label>
                        <Select
                          placeholder=""
                          defaultValue={selectedMailPref}
                          onChange={(e: any) => onMailPrefChange(e)}
                          options={mail_preferences_data.filter((item: any) => item.value !== selectedMailPref.value)}
                          styles={DropDownStyles2}
                        />
                      </div>
                      <div className="form-group custome-select">
                        <label className="select-lable">Country Selection</label>
                        <Select
                          placeholder=""
                          defaultValue={selectedCountryPref}
                          onChange={(e: any) => onCountryPrefChange(e)}
                          options={country_preferences_data.filter((item: any) => item.value !== selectedCountryPref.value)}
                          styles={DropDownStyles2}
                        />
                      </div>
                      {selectedCountryPref.value === 2 && (
                        <>
                          <div className="form-group custome-select w-48">
                            <label className="select-lable">Regions</label>
                            <Select
                              value={selectedRegion}
                              // defaultValue={selectedRegion}
                              components={{
                                ValueContainer: ValueContainerRegion,
                              }}
                              options={regions.map((option: any) => ({
                                value: option.id,
                                label: option.region,
                              }))}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={(e: any) => onRegionsChange(e)}
                              styles={DropDownStylesMulti2}
                              blurInputOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={false}
                              // menuPlacement="top"
                            />
                          </div>
                          <div className="form-group custome-select">
                            <label className="select-lable">Countries</label>
                            <Select
                              value={selectedCountry}
                              // defaultValue={selectedCountry}
                              components={{
                                ValueContainer: ValueContainerCountries,
                              }}
                              options={countries.map((option: any) => ({
                                value: option.id,
                                label: option.country_name,
                                region_id: option.region_id,
                              }))}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={(e: any) => onCountryChange(e)}
                              styles={DropDownStylesMulti2}
                              blurInputOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={false}
                              menuPlacement="top"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </TabContent>
                  <TabContent isMaximize="minimize" id="Profile" activeTab={activeTab}>
                    <div className="">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-main flex-wrap mt-4 w-48">
                          <div className="form-group custom-input">
                            <div className="input-container">
                              <input
                                defaultValue={userName}
                                className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                                type="text"
                                {...register("name", { required: true })}
                              />
                              <label className={` left-2 ${watch("name") !== "" ? "filled" : ""}`} htmlFor={"userName"}>
                                Name
                              </label>
                            </div>
                            {errors.name && errors.name.type === "required" && errorMessage(required)}
                          </div>
                          <div className="form-group custom-input">
                            <div className="input-container">
                              <input
                                defaultValue={userEmail}
                                className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                                type="text"
                                {...register("email", { required: true })}
                              />
                              <label className={` left-2 ${watch("email") !== "" && "filled"}`} htmlFor={"email"}>
                                Email
                              </label>
                            </div>
                            {errors.email && errors.email.type === "required" && errorMessage(required)}
                          </div>
                          <div className="form-group custom-input">
                            <div className="input-container">
                              <input
                                defaultValue={oldpassword}
                                className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                                type="password"
                                {...register("old_password", {
                                  required: true,
                                })}
                              />
                              <label className={` left-2 ${watch("old_password") !== "" && "filled"}`} htmlFor={"old_password"}>
                                Old Password
                              </label>
                            </div>
                            {errors.old_password && errors.old_password.type === "required" && errorMessage(required)}
                          </div>
                          <div className="form-group custom-input">
                            <div className="input-container">
                              <input
                                defaultValue={newpassword}
                                className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                                type="password"
                                {...register("new_password", {
                                  required: true,
                                })}
                              />
                              <label className={` left-2 ${watch("new_password") !== "" && "filled"}`} htmlFor={"new_password"}>
                                New Password
                              </label>
                            </div>
                            {errors.new_password && errors.new_password.type === "required" && errorMessage(required)}
                          </div>
                          <div className="form-group custom-input">
                            <div className="input-container">
                              <input
                                className="w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor form-control "
                                type="password"
                                {...register("new_password_confirmation", {
                                  required: true,
                                })}
                              />
                              <label
                                className={` left-2 ${watch("new_password_confirmation") !== "" && "filled"}`}
                                htmlFor={"new_password_confirmation"}
                              >
                                Confirm Password
                              </label>
                            </div>
                            {errors.new_password_confirmation && errors.new_password_confirmation.type === "required" && errorMessage(required)}
                          </div>

                          <div className="w-full">
                            {/* <input
                            className="btn btn-primary"
                            type="submit"
                            placeholder="Update"
                          /> */}
                            <button className={"mx-0 h-[100%] text-white green-btn custom-btn "} type="submit">
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                      {/* 
                      
                      
                      <TextBox
                        className="mt-2"
                        label={"Confirm Password"}
                        type="password"
                        value={profileData.new_password_confirmation}
                        name="new_password_confirmation"
                        onChange={(e: any) => handleInputChange(e)}
                      /> */}
                    </div>
                  </TabContent>
                </div>
              </div>
              {/* Footer */}
              {activeTab === "Preferences" && (
                <div className="text-end">
                  <Button
                    className={"mx-0 bg-black h-[100%] text-white"}
                    type="button"
                    varient={"green"}
                    CTA="Update"
                    handleClick={(e: any) => {
                      updateSettings();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {alertOpen && (
        <AlertBox
          AlertOpen={alertOpen}
          apiMessage={apiMsg}
          setAlertOpen={setAlertOpen}
          status={settings.post_Status}
          handleEvent={() => {
            dispatch(update_SettingMSg());
            reset();
          }}
        />
      )}
    </>
  );
}

export default SettingsPopup;
