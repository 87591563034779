export const formatNumber = (number: any, digit: any = 2) => {
  // console.log("formatNumber 1 =", number)
  if (number === undefined || number == null) {
    return "";
  }

  number = "" + number;
  number = Number(String(number).replace(/,/g, ""));
  number = parseFloat(number);
  let options: any = {};
  if (digit !== undefined) {
    options.maximumFractionDigits = digit;
  }
  // console.log("formatNumber 2 =", number)
  if (number === undefined || number === 0 || number == null || Number.isNaN(number)) {
    return 0;
  } else {
    number = new Intl.NumberFormat("en-US", options).format(number);
    // console.log("formatNumber 2 =", number.length)
    if (number.length <= 11) {
      return number;
    } else {
      return false;
    }
  }
};
export const buySellAmount = (amount: any, direction: String) => {
  if (direction.toLowerCase() === 'sell') {
    return '(' + amount + ')';
  }
  return amount;
};
export const validQuantity = (qty: any) => {
  console.log("Fund qty before 1", qty);
  qty = qty.replace(/,/g, "");
  console.log("Fund qty before 2", qty);
  const [integerPart, decimalPart] = qty.split(".");
  console.log("Fund qty before decimalPart", decimalPart);
  let isdecimal = false;
  if (decimalPart !== undefined && decimalPart !== "") {
    qty = integerPart;
    isdecimal = true;
  }
  qty = new Intl.NumberFormat("en-US").format(qty);
  console.log("Fund qty before 3", qty);
  // Regular expression to allow US format numbers with commas as thousand separators
  const regex = /^(\d{1,3}(,\d{3})*|\d+)(\.\d{0,5})?$/;

  // Check if the input value matches the regex pattern
  if (regex.test(qty)) {
    console.log("Fund qty before 4", qty);
    console.log("Fund qty before isdecimal", isdecimal);
    if (isdecimal) {
      // check in decimal have any other characters

      qty = `${qty}.${String(decimalPart).replace(/[^0-9.]/g, '')}`;
      console.log("Fund qty before 5", qty);
    }
    if (isdecimal === false && decimalPart === "") {
      qty = qty + ".";
    }
    if (decimalPart !== undefined && decimalPart !== "" && decimalPart.length > 5) {
      return false;
    }
  } else {
    return false;
  }
  return qty;
};
export const price_format = (value: any) => {
  const re = /^\d*\.?\d*$/;
  let res = re.test(value);
  if (value.length <= 15 && res === true) {
    res = true;
  } else {
    res = false;
  }
  return res;
};
export const calculate_percentage = (partialValue: any, totalValue: any) => {
  return (100 * Number(String(partialValue.replace(/,/g, "")))) / Number(String(totalValue.replace(/,/g, "")));
};
export const removeCommaFromString = (value: any) => {
  return Number(String(value.replace(/[ ,]+/g, "")));
};
export const getTimeSelectedApiParams = (time: string) => {
  if (time === "Month") return "one_month";
  else if (time === "Quarter") return "three_months";
  else if (time === "3 Month") return "three_months";
  else if (time === "6 Month") return "six_months";
  else if (time === "Year") return "one_year";
  else if (time === "1 Year") return "one_year";
  else if (time === "5 Years") return "five_year";
  else if (time === "10 Year") return "ten_year";
  else if (time === "30 Year") return "thirty_year";
  // else if (time === "5 Years") return "all";
  // else if (time === "MTD") return "mtd";
  else if (time === "YTD") return "ytd";
  else if (time === "Year End") return "year_to_end";
  else return "one_year";
};

export const dateFromat = (date: any) => {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  // console.log(`${mo} ${ye}`);
  return `${da}/${mo}/${ye}`;
};
export const getChartTypeParamValue = (type: string) => {
  if (type === "new_area") return { value: "new_area", label: "Area" };
  if (type === "candlestick") return { value: "candlestick", label: "Candlestick" };
};
export const getTimeSelectedApiParamsValue = (params: string) => {
  if (params === "one_month") return { value: "1", label: "Month" };
  else if (params === "three_months") return { value: "2", label: "Quarter" };
  else if (params === "three_months") return { value: "3", label: "3 Month" };
  else if (params === "six_months") return { value: "4", label: "6 Month" };
  else if (params === "one_year") return { value: "5", label: "Year" };
  // else if (params === "1 Year") return "one_year";
  else if (params === "five_year") return { value: "6", label: "5 Years" };
  else if (params === "ten_year") return { value: "7", label: "10 Year" };
  else if (params === "thirty_year") return { value: "8", label: "30 Year" };
  // else if (params === "5 Years") return "all";
  // else if (params === "MTD") return "mtd";
  else if (params === "ytd") return { value: "9", label: "YTD" };
  else if (params === "year_to_end") return { value: "10", label: "Year End" };
  else return { value: "1", label: "Month" };
};

export const getMarketTypeApiParams = (market_type: string) => {
  if (market_type === "Currencies") return "2";
  else if (market_type === "Equities") return "1";
  else if (market_type === "Funds") return "8";
  else if (market_type === "Bonds") return "5";
  else return "all";
};

export const getMarketTypeApiParamsValue = (market_type: string) => {
  if (market_type === "2") return { value: "2", label: "Currencies" };
  else if (market_type === "1") return { value: "1", label: "Equities" };
  else if (market_type === "8") return { value: "8", label: "Funds" };
  else if (market_type === "5") return { value: "5", label: "Bonds" };
  else return { value: "0", label: "All" };
};

export const getSelectedchartOption = (chartOption_type: string) => {
  if (chartOption_type === "1") return { value: "1", label: "Securities" };
  else if (chartOption_type === "2") return { value: "2", label: "Cash" };
  else return { value: "0", label: "All" };
};

export const getPeriodSelectedApiParam = (period: string) => {
  if (period === "Daily") return "daily";
  else if (period === "Weekly") return "weekly";
  else if (period === "Monthly") return "monthly";
  else return "daily";
};

export const getPeriodSelectedApiParamValue = (period: string) => {
  if (period === "daily") return { value: "1", label: "Daily" };
  else if (period === "weekly") return { value: "2", label: "Weekly" };
  else if (period === "monthly") return { value: "3", label: "Monthly" };
  else return { value: "1", label: "Daily" };
};

export const getCandleTimeApiParam = (time: string) => {
  if (time === "Month") return "1m";
  else if (time === "Quarter") return "3m";
  else if (time === "Year") return "1y";
  else if (time === "5 Years") return "5y";
  else if (time === "YTD") return "ytd";
  else return "1m";
};

export const getCandleTimeApiParamValue = (time: string) => {
  if (time === "1m") return "Month";
  else if (time === "3m") return "Quarter";
  else if (time === "1y") return "Year";
  else if (time === "5y") return "5 Years";
  else if (time === "ytd") return "YTD";
  else return "Month";
};

export const currencyApiParams = (param: string) => {
  if (param === "USD") return "USD";
  else if (param === "EUR") return "EUR";
  else if (param === "GBP") return "GBP";
  else if (param === "BASE") return "BASE";
  else return "USD";
};

export const currencyApiParamsValue = (param: string) => {
  if (param === "USD") return "USD";
  else if (param === "EUR") return "EUR";
  else if (param === "GBP") return "GBP";
  else if (param === "BASE") return "BASE";
  else return "USD";
};

export const performanceChartTypeApiParamVal = (param: string) => {
  if (param === "")
    return {
      value: "1",
      label: "Realized & Unrealized",
    };
  // "Realized & Unrealized";
  else if (param === "by_assets")
    return {
      value: "2",
      label: "Breakdown By Assets",
    };
  //"Breakdown By Assets";
  else if (param === "by_pnl") return { value: "3", label: "Trading & Interest" }; //"Trading & Interest";
  else return { value: "1", label: "Realized & Unrealized" };
};

export const removeNumberFormat = (number: string) => {
  if (number === undefined || number === null) {
    return "";
  }
  number = "" + number;
  number = number.replace(/[(]/g, "");
  number = number.replace(/[)]/g, "");
  number = number.replace(/,/g, "");
  number = parseFloat(number).toString();
  return number;
};

export const formatPrice = (price: any, market_id: any, digit: any) => {
  price = parseFloat(price);

  if (price === undefined || price === 0 || price === null || Number.isNaN(price)) {
    price = 0;
  }
  if (digit !== undefined && digit !== null && digit > 0) {
    digit = parseInt(digit);
    return price.toFixed(digit);
  }
  if (market_id === "cash" || market_id === 6) {
    price = price.toFixed(5);
  } else if (market_id === 5) {
    price = price.toFixed(3);
  } else {
    price = price.toFixed(2);
  }
  return price;
};

export const DropDownStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "16px",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    // right: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "12px",
    lineHieght: "1",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "13px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    lineHieght: "inherit",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};

export const ChartDropDownStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "16px",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    width: "6rem",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "12px",
    lineHieght: "1",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "13px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    lineHieght: "inherit",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};
export const ChartDropDownStylesNew = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(176, 204, 243, 0.32)",
      color: "var(--primaryText)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "rgba(176, 204, 243, 0.32)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "400",
    lineHeight: "16px",
    color: state.isSelected ? "var(--primaryText)" : "#8d8d8d",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    right: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    width: "auto",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "34px",
    maxHeight: "auto",
    margin: "0",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "14px",
    lineHieght: "1",
    fontWeight: "400",
    color: "#8d8d8d",
    paddingRight: "8px",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "17px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    position: "relative",
    top: "3px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    lineHieght: "inherit",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};
export const DropDownStyles3 = {
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      margin: "auto",
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "16px",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    fontSize: "12px",
    lineHieght: "1",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "13px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    lineHieght: "inherit",
    margin: "auto",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};
export const NewDropDownStyles3 = {
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      margin: "auto",
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "16px",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 8px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "auto",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "auto",
    fontSize: "12px",
    lineHieght: "1",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "13px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    lineHieght: "inherit",
    margin: "auto",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};
export const NewDropDownStyles4 = {
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      margin: "auto",
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "4px 10px",
    fontSize: "11px",
    // fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "16px",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "5px 8px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      borderColor: "var(--borderColor2)",
    },
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "auto",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "auto",
    fontSize: "12px",
    lineHieght: "1",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    fontWeight: "400",
    width: "13px",
    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    lineHieght: "inherit",
    margin: "auto",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};
export const DropDownStyles2 = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "1.1",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    zIndex: "99",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
    maxHeight: "130px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "1vw 0.6vw 0.4vw",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      // backgroundColor: "var(--secondaryBg)",
      // color: "white",
      cursor: state.isDisabled ? "not-allowed" : "",
      borderColor: "var(--borderColor2)",
    },
    minHeight: "2.75vw",
    maxHeight: "2.75vw",
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,

    cursor: state.isDisabled ? "not-allowed" : "",
    fontSize: "0.73vw",
    lineHieght: "1.2",
    fontWeight: "600",
    margin: "0",
    color: "var(--color-black)",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
    color: "var(--color-black)",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    width: "16px",

    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  Container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "inherit",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    flexWrap: "nowrap",
    "&:hover": {
      cursor: state.isDisabled ? "not-allowed" : "",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
    padding: "0",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
    color: "var(--color-black)",
  }),
};
export const DropDownStylesBroker = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "1.1",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",

    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    zIndex: "99",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
    maxHeight: "130px",
    overflowY: "auto",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "1vw 0.6vw 0.4vw",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      // backgroundColor: "var(--secondaryBg)",
      // color: "white",
      cursor: state.isDisabled ? "not-allowed" : "",
      borderColor: "var(--borderColor2)",
    },
    minHeight: "2.75vw",
    maxHeight: "2.75vw",
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,

    cursor: state.isDisabled ? "not-allowed" : "",
    fontSize: "0.73vw",
    lineHieght: "1.2",
    fontWeight: "600",
    margin: "0",
    color: "var(--color-black)",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
    color: "var(--color-black)",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    width: "16px",

    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  Container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "inherit",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    flexWrap: "nowrap",
    "&:hover": {
      cursor: state.isDisabled ? "not-allowed" : "",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
    padding: "0",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
    color: "var(--color-black)",
  }),
};
export const ProfileDropDownStyle = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    outline: "none",
    backgroundColor: state.isSelected ? "var(--light-gray)" : "var(--light-gray)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "1.1",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    zIndex: "99",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--light-gray)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: "none",
    backgroundColor: "#f7f7f7",
    // borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "1vw 0.6vw 0.4vw",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      // backgroundColor: "var(--secondaryBg)",
      // color: "white",
      cursor: state.isDisabled ? "not-allowed" : "",
      borderColor: "var(--borderColor2)",
    },
    minHeight: "2.75vw",
    maxHeight: "2.75vw",
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,

    cursor: state.isDisabled ? "not-allowed" : "",
    fontSize: "0.73vw",
    lineHieght: "1.2",
    fontWeight: "600",
    margin: "0",
    color: "var(--formLable)",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    color: "var(--primaryText)",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "14px",
    lineHieght: "1.2 !important",
    fontWeight: "400",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "var(--primaryText)",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    width: "16px",

    "&:hover": {
      // color: "white",
      color: "var(--primaryText)",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  Container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "inherit",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      cursor: state.isDisabled ? "not-allowed" : "",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};

export const chartDropDownStyle = {
  ...DropDownStyles,
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    right: 0,
  }),
};

export const MultiSelectStyle = {
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "1.1",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    zIndex: "99",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "1vw 0.6vw 0.4vw",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      // backgroundColor: "var(--secondaryBg)",
      // color: "white",
      cursor: state.isDisabled ? "not-allowed" : "",
      borderColor: "var(--borderColor2)",
    },
    minHeight: "2.75vw",
    // maxHeight: "2.75vw",
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,

    cursor: state.isDisabled ? "not-allowed" : "",
    fontSize: "0.73vw",
    lineHieght: "1.2",
    fontWeight: "600",
    margin: "0",
    color: "var(--color-black)",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    width: "16px",

    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  Container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "inherit",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      cursor: state.isDisabled ? "not-allowed" : "",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};

export const DropDownStylesMulti = {
  ...DropDownStyles,
  option: (provided: any, state: any) => ({
    ...provided,
    // "&:hover": {
    //   // backgroundColor: state.isFocused ? "var(--secondaryBg)" : null,
    //   backgroundColor: state.isFocused ? "#f2f2f2" : null,
    //   color: "black",
    //   // color: state.isFocused ? "white" : null,
    // },
    minHeight: "20px",
    maxHeight: "30px",
    padding: 5,
  }),
};
export const DropDownStylesMulti2 = {
  ...DropDownStyles,
  option: (provided: any, state: any) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--selectHover)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected ? "var(--selectHover)" : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "6px 12px",
    fontSize: "0.73vw",
    fontWeight: "300",
    lineHeight: "1.1",
    color: "var(--primaryText)",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
  }),
  menu: (base: any) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    margin: 0,
    zIndex: "99",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    boxShadow: "0px 3px 6px rgba(var(--color-black-rgb), 0.25) !important",
    border: "none",
    color: "var(--primaryText)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "var(--borderColor2)",
    boxShadow: "none",
    padding: "1vw 0.6vw 0.4vw",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      // backgroundColor: "var(--secondaryBg)",
      // color: "white",
      cursor: state.isDisabled ? "not-allowed" : "",
      borderColor: "var(--borderColor2)",
    },
    minHeight: "2.75vw",
    maxHeight: "2.75vw",
  }),

  placeholder: (provided: any, state: any) => ({
    ...provided,

    cursor: state.isDisabled ? "not-allowed" : "",
    fontSize: "0.73vw",
    lineHieght: "1.2",
    fontWeight: "600",
    margin: "0",
    color: "var(--formLable)",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    // color: "inherit",
    minHeight: "auto",
    maxHeight: "auto",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    fontSize: "0.73vw",
    lineHieght: "1.2 !important",
    fontWeight: "600",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "inherit",
    padding: "0",
    minHeight: "auto",
    maxHeight: "auto",
    width: "16px",

    "&:hover": {
      // color: "white",
      color: "inherit",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    height: "16px",
    padding: "0",
  }),
  Container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "auto",
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "inherit",
    cursor: state.isDisabled ? "not-allowed" : "",
    width: "100%",
    "&:hover": {
      cursor: state.isDisabled ? "not-allowed" : "",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
};

export const getMailingPreferencesSelected = (type: number) => {
  // console.log("type", type);
  if (type === 1) return { value: 1, label: "Realtime" };
  else if (type === 2) return { value: 2, label: "Daily" };
  else if (type === 3) return { value: 3, label: "Weekly" };
  else return { value: 0, label: "No Reports" };
};

export const getCountryPreferencesSelected = (type: number) => {
  if (type === 1) return { value: 1, label: "All" };
  else if (type === 2) return { value: 2, label: "Selected" };
  else return "";
};

export const getSelectedRegionsFromStrings = (str: string, allRegion: Array<any>) => {
  if (!str) return [];
  let select_region_ids = str.split(",").map(Number);
  let newRegArray = [];
  for (var j in allRegion) {
    if (select_region_ids.includes(allRegion[j].id)) {
      let myregionObj = { value: allRegion[j].id, label: allRegion[j].region };
      newRegArray.push(myregionObj);
    }
  }
  return newRegArray;
};

export const getSelectedCountriesFromStrings = (str: string, allCountries: Array<any>) => {
  if (!str) return [];
  let select_Country_ids = str.split(",").map(Number);
  let newRegArray = [];
  for (var j in allCountries) {
    if (select_Country_ids.includes(allCountries[j].id)) {
      let myregionObj = {
        value: allCountries[j].id,
        label: allCountries[j].country_name,
        // region_id: allCountries[j].region_id,
      };
      newRegArray.push(myregionObj);
    }
  }
  return newRegArray;
};

export const user_validate_dates = (tradeDate: any, settlementDate: any) => {
  const tradeTime = new Date(tradeDate).getTime();
  const settlementTime = new Date(settlementDate).getTime();
  return settlementTime > tradeTime;
}