import React, { useEffect, useRef } from "react";
import { useState } from "react";
import UploadButton from "../UploadButton/UploadButton";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import { currencyApiParams, currencyApiParamsValue } from "../../helpers/helper";
import {
  fetchProfileCurrency,
  postUserMenu,
  profile_selected_currency,
  fx_currency_data,
  update_current_currency,
} from "../../Redux/features/Dashboard/DashBoardSlice";
import { Link } from "react-router-dom";
import ArrowIcon from "../Icons/ArrowIcon";
import Svg from "../Svg/Svg";
import {
  addLayoutToUserLayouts,
  addUserGirdLayout,
  updateScreenLock,
  update_grid_error_message,
  update_main_user_grid,
} from "../../Redux/features/MainDashboard/MainDashBoardSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

type Props = {};

const DashboardTab = (props: Props) => {
  const [data, setData] = useState(false);
  const [dataGrid, setDataGrid] = useState(false);
  const [dataCurrency, setCurrency] = useState(false);
  const menuData = useAppSelector((state) => state.dashBoard.menuData);
  // const fxCurrencyArray: any = useAppSelector(fx_currency_data);
  const fxCurrencyArray: any = useAppSelector((state) => state.dashBoard.fx_currency_data);
  const selected_currency = useAppSelector((state) => state.dashBoard?.fx_currency_data?.selected_currency);
  const currencyValue = Object.keys(fxCurrencyArray?.currencyList);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  // console.log("currencyValue", currencyValue);
  const items = currencyValue;
  const groupSize = 4;
  const groups: any = [];
  for (let i = 0; i < items.length; i += groupSize) {
    const group = items.slice(i, i + groupSize);
    groups.push(group);
  }
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const onCurrencyChange = (item: string) => {
    setSelectedItem(item);
    let currencyApiparam = selectedItem;

    // dispatch(fetchProfileCurrency(currencyApiparam));
    dispatch(
      fetchProfileCurrency({
        currency: item,
      })
    );
  };

  const dispatch = useAppDispatch();
  const [isOpened, setIsOpened] = useState<any>(0);
  function toggle(item: any) {
    // setIsOpened((menuId: any) => (item.id === menuId ? 0 : item.id));
    if (item.name !== "Execution") {
      if (item.url !== "") {
        window.location.href = item.url;
      }
    }
  }
  // const userId = useAppSelector((state) => state.dashBoard.is_bulk_upload);
  // console.log("userId",userId);
  // let id = 1;

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => {
    setData(false);
    setDataGrid(false);
    setIsOpened(0);
  });
  // console.log("parentArray",parentArray);
  // console.log("menuData", menuData);
  // ${parentArray[0]?.id === item.id ? "active" : ""}`
  const girdLayout = useAppSelector((state) => state.mainDashBoard.girdLayout);
  const handleOpenItem = () => {};
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);
  const gridErrorMessage = useAppSelector((state) => state.mainDashBoard.gridErrorMessage);
  useEffect(() => {
    if (gridErrorMessage !== "") {
      console.log("INNNN :: ");
      toast.error(gridErrorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(update_grid_error_message({ gridErrorMessage: "" }));
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [gridErrorMessage]);
  const ValidationOptionsArray: Array<{ value: string; label: string }> = [
    { value: "chartGrid", label: "Chart grid already existing in layout" },
    { value: "orderWindow", label: "Order Execution Chart grid already existing in layout" },
    { value: "orderList", label: "Order list grid already existing in layout" },
  ];
  // const [disabledItems, setDisabledItems] = useState([]);
  const [isClickDisabled, setClickDisabled] = useState(false);
  const handleAddNewGrid = (item: any, index: number) => {
    //code for adding new grid and validation
    console.log("userGrids validation type :: ", item);
    console.log("userGrids validation :: ", userGrids);
    if (isClickDisabled) {
      toast.error("Please wait previous event is working!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    setClickDisabled(true);
    // setDisabledItems((prevDisabledItems) => {
    //   const updatedDisabledItems: any = [...prevDisabledItems];
    //   updatedDisabledItems[index] = !updatedDisabledItems[index];
    //   return updatedDisabledItems;
    // });
    var hasOrderGridValue = userGrids.some((gridItem: any) => gridItem.grid_component_slug === item);
    console.log("userGrids validation hasOrderGridValue :: ", hasOrderGridValue);
    if (item === "wishList") {
      hasOrderGridValue = false;
    }
    if (hasOrderGridValue) {
      // fire existing grid message
      const chartGridOption: any = ValidationOptionsArray.find((option) => option.value === item);
      let validationMessage = chartGridOption.label;
      toast.error(validationMessage, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      //add new grid item
      dispatch(addUserGirdLayout(item));
    }
    setTimeout(() => {
      setClickDisabled(false);
    }, 2000);
  };
  useEffect(() => {
    dispatch(postUserMenu());
  }, []);
  const is_screen_lock = useAppSelector((state) => state.mainDashBoard.is_screen_lock);
  const [isSwitchOn, setIsSwitchOn] = useState(is_screen_lock);
  // useEffect(() => {
  //   setIsSwitchOn(is_screen_lock);
  // }, [is_screen_lock]);
  const handleSwitchToggle = () => {
    console.log("isSwitchOn", isSwitchOn);
    dispatch(updateScreenLock(!isSwitchOn));
    setIsSwitchOn(!isSwitchOn);
  };
  return (
    <div className="tab-group">
      <div className="tab-main">
        <ul ref={ref} className="main-menu-group">
          {menuData.map((item: any) => {
            return (
              <li key={item.id} className={`${item.name === "Execution" ? "active" : ""} ${item.name === "Clients" ? "active" : ""} `}>
                <a
                  onClick={() => {
                    toggle(item);
                  }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {item.name}
                </a>
                {item.children && item.children?.length !== undefined && item.children?.length !== 0 ? (
                  <ul className="dropdown-menu pull-left submenu">
                    {item.children &&
                      item.children?.map((children: any) => {
                        return (
                          <li key={children.id}>
                            <a className="nav-link" href={`${children.name !== "Execution" ? children.url : "#"} `}>
                              {children.name}
                              {children?.children?.length !== undefined ? <ArrowIcon /> : null}
                            </a>
                            {children?.children?.length !== undefined ? (
                              <ul className="dropdown-menu pull-left sub-sub-manu">
                                {children?.children?.map((subChild: any) => {
                                  return (
                                    <li key={subChild.id}>
                                      <a className="nav-link" href={`${subChild.name !== "Execution" ? subChild.url : "#"} `}>
                                        {subChild.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      })}
                  </ul>
                ) : null}
              </li>
            );
          })}
        </ul>
        <div className="current-design-btn flex" ref={ref}>
          <div className={`${data === true ? "open" : ""} p-relative ml-10 upload-btn mt-0 btn-match`}>
            <button
              id="first-element"
              className="dropdown-toggle new-btn-2"
              type="button"
              data-toggle="dropdown"
              onClick={() => {
                setData((show) => !show);
                setDataGrid(false);
              }}
            >
              <span className="new-btn-2-span">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path
                    className="innerColor"
                    d="M7.187,17.638l-.6-.247a2.922,2.922,0,0,1-3.66-.32,2.861,2.861,0,0,1-.32-3.66l-.247-.6a2.858,2.858,0,0,1,0-5.629l.247-.6a2.859,2.859,0,0,1,.32-3.66,2.862,2.862,0,0,1,3.659-.32l.6-.247a2.857,2.857,0,0,1,5.628,0l.6.247a2.923,2.923,0,0,1,3.66.32,2.86,2.86,0,0,1,.319,3.659l.248.6a2.858,2.858,0,0,1,0,5.629l-.247.6a2.858,2.858,0,0,1-3.981,3.98l-.6.248a2.857,2.857,0,0,1-5.628,0Zm-.448-1.732,1.392.577a.715.715,0,0,1,.441.66,1.428,1.428,0,1,0,2.857,0,.715.715,0,0,1,.441-.66l1.392-.577a.714.714,0,0,1,.778.155,1.429,1.429,0,1,0,2.021-2.02.714.714,0,0,1-.155-.778l.577-1.391a.715.715,0,0,1,.661-.441,1.429,1.429,0,0,0,0-2.858.717.717,0,0,1-.661-.44l-.577-1.393a.714.714,0,0,1,.155-.779,1.432,1.432,0,0,0,0-2.021,1.461,1.461,0,0,0-2.02,0,.714.714,0,0,1-.778.155l-1.393-.577a.716.716,0,0,1-.441-.66,1.428,1.428,0,1,0-2.857,0,.716.716,0,0,1-.441.66l-1.392.577a.716.716,0,0,1-.779-.155A1.429,1.429,0,1,0,3.939,5.96a.714.714,0,0,1,.155.779L3.517,8.132a.716.716,0,0,1-.66.441,1.429,1.429,0,1,0,0,2.858.715.715,0,0,1,.66.441l.577,1.391a.714.714,0,0,1-.155.778,1.43,1.43,0,0,0,0,2.02,1.461,1.461,0,0,0,2.02,0,.715.715,0,0,1,.779-.155ZM6.236,10a3.764,3.764,0,1,1,6.924,2.048.753.753,0,0,1-1.264-.819,2.259,2.259,0,1,0-1.9,1.03.753.753,0,1,1,0,1.506A3.768,3.768,0,0,1,6.236,10ZM20,10h0Z"
                  />
                </svg>
              </span>
              <div className="tooltiptext tool-right">
                <p>Settings</p>
              </div>
            </button>
            {data && (
              <ul className="dropdown-menu pull-right sm-wh-100 setting-dropdown-one dropdown-custom-width">
                <li key={"sw-1"}>
                  <div className="switch-container">
                    <p className="opt-txt">{isSwitchOn ? "Unlock Screen" : "Lock Screen"}</p>
                    {/* <p className="opt-txt">Screen Lock/Unlock</p> */}
                    <label className="switch-label">
                      Switch
                      <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchToggle} className="switch-input" />
                      <span className="switch-slider"></span>
                    </label>
                    {/* <p className="opt-txt">{isSwitchOn ? "" : "Unlock"}</p> */}
                  </div>
                </li>
                {/* {loggedType === "internal" && (
                  <li key={"sw-2"}>
                    {groups.map((group: any, groupIndex: any) => (
                      <ul className="multi-option-tab">
                        {group.map((item: any, itemIndex: any) => (
                          <li key={itemIndex + "-" + groupIndex} className={`${selected_currency === item ? "active" : ""}`}>
                            <a key={itemIndex + "-a-" + groupIndex} onClick={() => onCurrencyChange(item)} title={item} className="currency_filter">
                              {fxCurrencyArray.currencyList[item]}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </li>
                )} */}
                {/* {dataGrid && ( */}
                {/* <ul className="dropdown-menu pull-right sm-wh-100 setting-dropdown-menu "> */}
                {girdLayout.map((item: any, index: number) => (
                  <>
                    {loggedType === "internal" ? (
                      <li key={index} className="grid-layout-panel-listitem">
                        <Link to="" title={""} onClick={(e: any) => handleAddNewGrid(item, index)}>
                          {item === "chartGrid" && <>Chart</>}
                          {item === "orderWindow" && <>Execution</>}
                          {item === "orderList" && <>Transactions</>}
                          {item === "wishList" && <>Monitor</>}
                          <svg id="addNew" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.78 23.81">
                            <path
                              fill="#071932"
                              className="innerColor"
                              d="M23.78,14.41H14.38v9.4h-4.93V14.41H0v-4.96H9.45V0h4.93V9.45h9.4v4.96Z"
                            ></path>
                          </svg>
                        </Link>
                      </li>
                    ) : (
                      <>
                        {item === "wishList" && (
                          <li key={index} className="grid-layout-panel-listitem">
                            <Link to="" title={""} onClick={(e: any) => handleAddNewGrid(item, index)}>
                              {item === "wishList" && <>Monitor</>}
                              <svg id="addNew" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.78 23.81">
                                <path
                                  fill="#071932"
                                  className="innerColor"
                                  d="M23.78,14.41H14.38v9.4h-4.93V14.41H0v-4.96H9.45V0h4.93V9.45h9.4v4.96Z"
                                ></path>
                              </svg>
                            </Link>
                          </li>
                        )}
                      </>
                    )}
                  </>
                ))}
                {/* </ul> */}
                {/* )} */}
              </ul>
            )}
          </div>

          {/* <div className={`${dataGrid === true ? "open" : ""} grid-controll-btn p-relative ml-10 upload-btn mt-0 btn-match`}>
            <button
              id="controll-element"
              className="dropdown-toggle new-btn-2"
              type="button"
              data-toggle="dropdown"
              onClick={() => {
                setDataGrid((show) => !show);
                setData(false);
              }}
            >
              <span className="new-btn-2-span">
                <Svg type="v-dots" />
              </span>
              <div className="tooltiptext tool-right">
                <p>Grid</p>
              </div>
            </button>
            {dataGrid && (
              <ul className="dropdown-menu pull-right sm-wh-100 setting-dropdown-menu ">
                {girdLayout.map((item: any, index: number) => (
                  <li key={index} className="grid-layout-panel-listitem">
                    <Link to="" title={""} onClick={(e: any) => handleAddNewGrid(item, index)}>
                      {item === "chartGrid" && <>Chart</>}
                      {item === "orderWindow" && <>Execution</>}
                      {item === "orderList" && <>Transactions</>}
                      {item === "wishList" && <>Monitor</>}
                      <svg id="addNew" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.78 23.81">
                        <path fill="#071932" className="innerColor" d="M23.78,14.41H14.38v9.4h-4.93V14.41H0v-4.96H9.45V0h4.93V9.45h9.4v4.96Z"></path>
                      </svg>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div> */}
        </div>
      </div>

      {/* { userId ? 
        <UploadButton />
        : null
      }       */}
    </div>
  );
};

export default DashboardTab;
