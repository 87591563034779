import Button from "../Button/Button";
import closeIcon from "../../assets/close-icon.svg";

function AlertBox({
  apiMessage,
  setAlertOpen,
  AlertOpen,
  status,
  handleEvent,
}: any) {
  return (
    <>
      {AlertOpen && (
        <div className="fixed z-20 top-0 left-0 bottom-0 right-0 h-screen  ">
          <div className="flex items-center justify-center bg-[rgba(0,0,0,0.68)] z-10 h-screen  ">
            <div className="w-[80vw] h-[80vh] sm:w-[585px] sm:h-auto  bg-layoutBg custome-modal ">
              <div className="modal-header">
                <h2>
                  {status &&
                    status
                      .toLowerCase()
                      .replace(/\b\w/g, (s: any) => s.toUpperCase())}
                </h2>
                <img
                  src={closeIcon}
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                  className="px-4  h-3 cursor-pointer"
                  alt=""
                />
              </div>
              <div className="modal-body mb-[20px]">
                <h2 className="">
                  {apiMessage}
                  {}
                </h2>
              </div>
              <div className="flex justify-end">
                <Button
                  className={"bg-black text-white w-[100px]"}
                  type="button"
                  varient={status === "error" ? "red" : "green"}
                  CTA="OK"
                  handleClick={(e: any) => {
                    setAlertOpen(false);
                    handleEvent();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AlertBox;
