import React, { useEffect, useState } from "react";
import Tab from "../../components/Tab/Tab";
import TabContent from "../../components/TabContent/TabContent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { RootState } from "../../Redux/app/store";
import { fetchSecurityData } from "../../Redux/features/DataandResearchSlice/DataandResearchSlice";
import SecurityData from "./SecurityData/SecurityData";

function InformationWindow({ refProp }: any) {
  const [activeTab, setActiveTab] = useState("Data");
  const showInformationWindow = useAppSelector((state: RootState) => state.uicontroller.informationwindow);

  const isMaximized = "minimize";
  return (
    <div className=" w-full h-full  Tabs  ">
      <ul className="flex bg-tabBg rounded-t-[20px]">
        {/* <Tab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          title={["Data"]}
        /> */}
      </ul>

      <div className="outlet bg-layoutBg min-h-[27vh] max-h-[27vh] overflow-auto">
        {/* <TabContent isMaximize={isMaximized} id="Data" activeTab={activeTab}> */}
        <SecurityData />
        {/* </TabContent> */}
        <TabContent isMaximize={isMaximized} id="Research" activeTab={activeTab}>
          <p>Tab 2 works!</p>
        </TabContent>
        <TabContent isMaximize={isMaximized} id="Bond" activeTab={activeTab}>
          <p>Tab 3 works!</p>
        </TabContent>
        <TabContent isMaximize={isMaximized} id="Equity" activeTab={activeTab}>
          <p>Tab 3 works!</p>
        </TabContent>
        <TabContent isMaximize={isMaximized} id="Funds" activeTab={activeTab}>
          <p>Tab 3 works!</p>
        </TabContent>
      </div>
    </div>
  );
}

export default InformationWindow;
