function RadioGroup({ defaultValue, onChange, options }: any) {
  return (
    <div className="rContainer h-[10%]">
      <div className="radio_container">
        {options.map((item: string, index: number) => (
          <div key={index}>
            <input
              className="radioInput"
              id={item}
              name="radio-item-1"
              type="radio"
              value={item}
              onChange={onChange}
              checked={defaultValue === item}
            />
            <label className="label" htmlFor={item}>
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioGroup;
