// import axios from "axios";
import axiosClient from "./api";
import io from "socket.io-client";
import axios from "axios";

export const fetchData = async (url: string) => {
  try {
    const res = await axiosClient.get(url);
    // console.log(res);
    return res;
  } catch (error: any) {
    // console.log(error.res);
    return error.res;
  }
};

export const postData = async (url: string, data: any) => {
  try {
    const res = await axiosClient.post(url, data);
    return res;
  } catch (error: any) {
    // console.log(error.res);
    return error.res;
  }
};

export const postDataLive = async (url: string, data: any) => {
  try {
    const res = await axios.post(url, data);
    return res;
  } catch (error: any) {
    // console.log(error.res);
    return error.res;
  }
};
// const { Server } = require('socket.io');
// const { createClient } = require('redis');
// const { createAdapter } = require('@socket.io/redis-adapter');
// const redisAdapter = require("socket.io-redis");

export const socketIO = (socketURL: any) => {
  // let socketURL = process.env.REACT_APP_SOCKET_URL;
  // const socket = io(socketURL, {
  //   transports: ["websocket"]
  // });
  // socket.on("connect", () => {
  //   localStorage.setItem("current_socket_id", socket.id);
  //   console.log("socket:: call connect first here", socket.id);
  // });

  // socket.on("connect_error", (err) => {
  //   console.log("Error on socket.io client", err);
  //   setTimeout(() => {
  //     socket.connect();
  //   }, 1000);
  // });
  // socket.on("disconnect", () => {
  //   console.log("socket:: call disconnect aaa");

  // });

  // socket.on("pong", () => {
  //   console.log("socket:: call pong");
  // });

  // return socket;
};
