// import { queryHelpers } from "@testing-library/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useIsMount } from "../../CustomHooks/useIsMount ";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  fetchSecurityDetails,
  getLastPrice,
  live_update_candle_chart_data,
  update_candle_chart_data,
  update_from_search,
  update_prices,
  update_search_items,
} from "../../Redux/features/ChartData/ChartDataSlice";
import { updateDefaultSecuritySet } from "../../Redux/features/Dashboard/DashBoardSlice";
import { chartTabStatusUpdate } from "../../Redux/features/UIChange/UIController";
import SearchBox from "../SearchBox/SearchBox";
import OrderSearch from "../../assets/search.svg";
import SearchLoader from "../../assets/loader.gif";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import TextBox from "../../components/TextBox/TextBox";
import Select from "react-select";
import { DropDownStyles2 } from "../../helpers/helper";
import Button from "../../components/Button/Button";
function AutoCompleteSearchBox({ is_load_change }: any) {
  const dispatch = useAppDispatch();
  const search_items = useAppSelector((state) => state.chartdata.search_items);
  const securityDet_status = useAppSelector((state) => state.chartdata.securityDet_status);
  const sd_message = useAppSelector((state) => state.chartdata.sd_message);
  const from_search = useAppSelector((state) => state.chartdata.from_search);
  const search_is_loading = useAppSelector((state) => state.chartdata.search_is_loading);
  const ordertabDetails = useAppSelector((state) => state.uicontroller.tradeWindowTabStatus);
  const dashBoard_selector = useAppSelector((state) => state.dashBoard.default_security_set);
  const securityMessage = useAppSelector((state) => state.chartdata.securityMessage);
  const send_new_sec_req = useAppSelector((state) => state.chartdata.send_new_sec_req);
  const isMount = useIsMount();

  const [Suggetion, setSuggetion] = useState<String | null>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(update_from_search("execution"));
    setSuggetion(e.target.value);
  };
  const handleClickEvent = (i: any) => {
    dispatch(live_update_candle_chart_data([]));
    dispatch(update_candle_chart_data([]));
    i = { ...i, tabName: i.security_name };
    dispatch(updateDefaultSecuritySet(i));
    dispatch(
      update_prices({
        bid_price: 0,
        ask_price: 0,
        rate: 0,
      })
    );
    if (Number(i.market_id) === 8) {
      setTimeout(() => {
        dispatch(getLastPrice({ security_id: i.id, isin_code: i.isin_code }));
      }, 2000);
    }
  };
  const options = [
    { value: "Security", label: "Security" },
    { value: "Forex", label: "Forex" },
    { value: "Equity", label: "Equity" },
    { value: "Bond", label: "Bond" },
  ];

  useEffect(() => {
    if (!isMount) {
      dispatch(chartTabStatusUpdate(dashBoard_selector.tabName));
    }
  }, [dashBoard_selector.security_id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(update_search_items([]));
      if (Suggetion != null && Suggetion != "") {
        localStorage.removeItem("new_sec_isin");
        localStorage.setItem("send_new_sec", String(Suggetion));
        dispatch(fetchSecurityDetails({ name: Suggetion, type: ordertabDetails }));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [Suggetion]);

  const [noResultFlag, setNoResultFlag] = useState(false);

  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setNoResultFlag(false));

  useEffect(() => {
    if (Object.keys(search_items).length === 0 && securityDet_status === "success") {
      setNoResultFlag(true);
    } else {
      setNoResultFlag(false);
    }
  }, [search_items, securityDet_status]);

  useEffect(() => {
    if (Suggetion === "") {
      setSuggetion(null);
    }
  }, [Suggetion]);
  useEffect(() => {
    if (is_load_change === "end") {
      setSuggetion(null);
    }
  }, [is_load_change]);

  return (
    <>
      <div className="relative">
        {(search_is_loading === 1 || search_is_loading === 3) && <img className="order-search" src={OrderSearch} alt="" />}
        {search_is_loading === 2 && <img className="order-search-loader" src={SearchLoader} alt="" />}
        {is_load_change === "end" && <SearchBox label="" placeholder="Search by ISIN or Ticker" onBlur={setSuggetion} onChangeFun={handleOnChange} />}
        {is_load_change === "start" && (
          <SearchBox label="" placeholder="Search by ISIN or Ticker" onBlur={setSuggetion} onChangeFun={handleOnChange} />
        )}
        {Suggetion && (
          <div className={Object.keys(search_items).length > 0 ? "absolute order-search-list" : ""}>
            <ul>
              {Suggetion &&
                search_items
                  // ?.filter((i: any) => i.text?.includes(Suggetion))
                  ?.map((i: any, index: any) => (
                    <li
                      key={index}
                      onClick={(e: any) => {
                        handleClickEvent(i);
                        setSuggetion(null);
                      }}
                    >
                      {(Number(i.market_id) === 6 || Number(i.market_id) === 2) && <span className="list-type cash-type"></span>}
                      {Number(i.market_id) === 1 && <span className="list-type equity-type"></span>}
                      {Number(i.market_id) === 5 && <span className="list-type bond-type"></span>}
                      {Number(i.market_id) === 8 && <span className="list-type fund-type"></span>}
                      {/*<span className="list-type equity-type"></span>
                       <span className="list-type cash-type"></span>
                      <span className="list-type bond-type"></span>
                      <span className="list-type fund-type"></span> */}
                      <p className="overflow-hidden whitespace-nowrap text-ellipsis block w-full">{i.text}</p>
                    </li>
                  ))}
            </ul>
          </div>
        )}
        {sd_message !== "OK" && sd_message !== "pending" && sd_message !== "rejected" && from_search !== "monitor" && (
          <div ref={ref} className={Object.keys(search_items).length === 0 ? "absolute order-search-list" : ""}>
            <ul>
              <li>Security does not exists. {sd_message !== "OK" ? sd_message : ""}</li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default AutoCompleteSearchBox;
