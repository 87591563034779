import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchData, postData } from "../../../services/apiFunctions";

export const fetchSecurities: any = createAsyncThunk("get-securities", async (security_id) => {
  const response: any = await fetchData("get-securities/" + security_id);
  return response.data;
});
export const cancelRFQ: any = createAsyncThunk("cancel-rfq", async (payload) => {
  const response: any = await postData("cancel-rfq", payload);
  return response.data;
});
export const getOrderTypes: any = createAsyncThunk("get-order-types", async () => {
  const response: any = await postData("get-order-types", {
    market_id: 1,
    vendor: null,
  });
  return response.data;
});
export const getClientMarkup: any = createAsyncThunk("get-mark-up", async (payload) => {
  const response: any = await postData("get-mark-up", payload);
  return response.data;
});
export const getVendorList: any = createAsyncThunk("vandor-list", async () => {
  const response: any = await postData("vandor-list", null);
  return response.data;
});

export const getOrderTimeTypes: any = createAsyncThunk("get-order-time-types", async (orderType) => {
  const response: any = await postData("get-order-time-types", {
    order_type: orderType,
    vendor: null,
  });
  return response.data;
});
export const placeOrder: any = createAsyncThunk("place-order", async (payload) => {
  const response: any = await postData("place-order", payload);
  return response.data;
});
export const placeFxspotOrder: any = createAsyncThunk("place-fxspot-order", async (payload) => {
  const response: any = await postData("place-fxspot-order", payload);
  return response.data;
});
export const getFXQuoteRequest: any = createAsyncThunk("get-fx-quote", async (payload) => {
  const response: any = await postData("get-fx-quote", payload);
  return response.data;
});

export const requestRfq: any = createAsyncThunk("request-rfq", async (payload) => {
  const response: any = await postData("request-rfq", payload);
  return response.data;
});

export const getFees: any = createAsyncThunk("get-fees", async (payload) => {
  const response: any = await postData("get-fees", payload);
  return response.data;
});

const initialState = {
  market_id: 6,
  fund_legal_documents: null,
  ibbond_details: null,
  company_details: null,
  logo: null,
  cash_flow_details: null,
  insider_roster: null,
  insider_summary: null,
  isDisplayImg: true,
  loading: false,
  name: "EURUSD",
  security_id: 11,
  con_id: "12087792",
  timeline: "one_month",
  candleTimeline: "1m",
  bid: 0,
  ask: 0,
  rate: 1,
  price_type: 1,
  chart_type: "area",
  last_price: 0,
  fund_order_types_value: [],
  limits_val: [],
  is_show_vendor_dropdown: true,
  vendorsoptions_val: [],
  bond_vendor_option: [],
  clientVendors: [],
  show_bond_vendor_dropdown: true,
  markupOpt_val: [],
  // vendorsoptions_val: [
  //   {
  //     value: 2,
  //     label: "Interactive",
  //   },
  //   {
  //     value: 1,
  //     label: "Instinet",
  //   },
  // ],
  days_value: [],
  responsePlaceOrder: {},
  responseplaceFxspotOrder: {},
  responsegetFXQuoteRequest: {},
  responserequestRfq: {},
  responseCancelRFQ: {},
  responseGetFees: {},
  place_order_status: "",
  rfq_place_order_status: "",
  get_quote_status: "",
  form_data: {
    isConfirm: false,
    isInverse: false,
    ltp_price_data: {},
    fx_quote_data: {},
    order_type: "market",
    fund_order_type: "share",
    fund_amount: 0,
    day: "day",
    quantity: "0",
    price: 0.0,
    stop_price: 0.0,
    portfolio_id: 0,
    bid: 0,
    ask: 0,
    rate: 0,
    last_price: 0,
    direction: "",
    security_id: Number(localStorage.getItem("current_security_id")),
    is_calculate: 1,
    allocate_portfolios: [
      {
        client_id: null,
        qty: "0",
        percentage: "",
        fees: 0,
        set_fees: 0,
        markup_fees: 0,
        original_fees: 0,
        est_markup: 0,
        markup_price: 0,
        inverse_convert_amt_display: 0,
        inverse_currency_amt_display: 0,
      },
    ],
    new_commission: 0,
    filter_client_id: 0,
    filter_client_qty: 0,
    market_value_text: 0,
    vendor: 2,
    gtd_expire_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    display_quantity: 0,
    allocates_input_fees: 0,
    allocates_input_set_fees: 0,
  },
  rfq_timer: "00:00",
  rfq_form_data: {
    trade_way: null,
    timeout_action: 1,
    portfolio_id: 0,
    step: 1,
    rfq_form_type: "bond",
    security_id: Number(localStorage.getItem("current_security_id")),
    is_calculate: 1,
    currencyOne: null,
    currencyTwo: null,
    currency: null,
    settlementType: "SPOT",
    settlement_type: "SPOT",
    settlement_date: "",
    bid_price: 0,
    ask_price: 0,
    bid_size: 0,
    ask_size: 0,
    bid_side: null,
    ask_side: null,
    rate: 0,
    direction: "",
    side: "",
    quote_side: null,
    //timer: "00:00",
    quantity: "0",
    limit_price: 0,
    bid_entry_id: null,
    ask_entry_id: null,
    symbol: null,
    lock_quote: {},
    rfs_lock_quote: {
      bidQuoteId: 0,
      askQuoteId: 0,
      securityId: "",
      dbSecurityId: 0,
      bidVendorId: 0,
      askVendorId: 0,
      bidPrice: 0.0,
      bidSize: 0,
      askPrice: 0.0,
      askSize: 0,
      bidTime: "",
      askTime: "",
      askMsz: 0,
      bidMsz: 0,
      bidReqvid: 0,
      askReqvid: 0,
    },
    type: "limit",
    day: "day",
    is_zero_price: false,
    rfq_request_id: "",
    order_executed_id: "",
    allocates_input_fees: 0,
    allocates_input_set_fees: 0,
    allocate_portfolios: [
      {
        client_id: null,
        qty: "0",
        percentage: "",
        fees: 0,
        set_fees: 0,
        markup_fees: 0,
        amount: 0,
        markup_type: "abs",
        markup_value: 0,
        original_fees: 0,
        est_markup: 0,
        markup_price: 0,
        inverse_convert_amt_display: 0,
        inverse_currency_amt_display: 0,
      },
    ],
    filter_client_id: 0,
    filter_client_qty: 0,
    vendor: 8,
  },
  form_allocates_inputs: [
    {
      client_id: null,
      qty: "0",
      percentage: "",
      fees: 0,
      set_fees: 0,
      markup_fees: 0,
      amount: 0,
      markup_type: "abs",
      markup_value: 0,
      est_markup: 0,
      inverse_convert_amt_display: 0,
      inverse_currency_amt_display: 0,
      markup_price: 0,
    },
  ],
  buy_btn_disabled: true,
  sell_btn_disabled: true,
  rfs_stream_channel_data: {},
  // fx_rfq_quote_data: {rid:0,dbsecid:0,qid:'',rate:0,inverserate:0,tm:""},
  fx_rfq_quote_data: {
    // key: "",
    // value: {
    //   message: { rid: 0, dbsecid: 0, qid: "", rate: 0, inverserate: 0, tm: "" },
    // },
    statuscode: 0,
    tm: "",
    px: 0,
    reqvid: 0,
    vid: 0,
    qrespid: "",
    qid: "",
    typ: "",
    rid: 0,
    dbsecid: 0,
    sec: "",
  },
  tradeweb_quotes_stream_channel_data: {
    px: 0,
    qid: "",
    rid: "",
    sec: "",
    sz: 0,
    tm: "",
    typ: "",
    vid: 0,
  },
  clientMarkupData: {},
};

const OrderSection = createSlice({
  name: "orderSection",
  initialState,
  reducers: {
    update_fx_quote_data: (state: any, action: any) => {
      return {
        ...state,
        fx_rfq_quote_data: {
          statuscode: 0,
          tm: "",
          px: 0,
          reqvid: 0,
          vid: 0,
          qrespid: "",
          qid: "",
          typ: "",
          rid: 0,
          dbsecid: 0,
          sec: "",
        },
      };
    },
    updateBuyBtnDisabled: (state: any, action: any) => {
      return {
        ...state,
        buy_btn_disabled: action.payload,
      };
    },
    updateSellBtnDisabled: (state: any, action: any) => {
      return {
        ...state,
        sell_btn_disabled: action.payload,
      };
    },
    update_map_allocates_inputs: (state: any, action: any) => {
      // console.log("RFQ:: handleRfqQty reducer");
      // console.log(action.payload.allocates_inputs, "FUND:: allocates_inputs");
      // const input: any = action.payload.allocates_inputs.map((item: any, index: any) => {
      //   let updated_client_id = item.client_id;
      //   let updated_qty = item.qty;
      //   let updated_set_fees = item.set_fees;
      //   let updated_fees = item.fees;
      //   let updated_percentage = item.percentage;
      //   let updated_markup_type = item.markup_type;
      //   let updated_markup_value = item.markup_value;
      //   let updated_amount = item.amount;
      //   let updated_markup_fees = item.markup_fees;
      //   // manage RFQ allocates_inputs and update
      //   let form_type = action.payload.form_type;
      //   if (form_type === false) {
      //     let client_id = action.payload?.client_id;
      //     updated_set_fees = action.payload?.set_fees;
      //     updated_fees = action.payload?.fees;
      //     updated_amount = action.payload?.amount;
      //     updated_percentage = action.payload?.percentage;
      //     updated_markup_type = action.payload?.markup_type;
      //     updated_markup_value = action.payload?.markup_value;
      //     let clientIndex = action.payload?.updated_index;
      //     if (action.payload?.client_id) {
      //       if (clientIndex === index) {
      //         updated_client_id = client_id;
      //       }
      //     }
      //     if (item.client_id === action.payload?.client_id) {
      //       updated_markup_type = action.payload?.markup_type !== undefined ? action.payload?.markup_type : updated_markup_type;
      //       updated_markup_value = action.payload?.markup_value !== undefined ? action.payload?.markup_value : updated_markup_value;
      //     }
      //     if (client_id === 0) {
      //       //updated_client_id = 0;
      //       // alert(clientIndex + "===" + index);
      //       if (clientIndex === index) {
      //         updated_client_id = 0;
      //       }
      //     }
      //     if (index === 0 && state.form_allocates_inputs.length <= 1) {
      //       if (typeof state.rfq_form_data.quantity === "string") {
      //         let i_qty: String = String(state.rfq_form_data.quantity);
      //         let quantity_set = Number(i_qty.replace(/,/g, ""));
      //         updated_qty = String(quantity_set);
      //       } else {
      //         updated_qty = String(state.rfq_form_data.quantity);
      //       }
      //     } else {
      //       // console.log("RFQ:: clientIndex value", clientIndex);
      //       // console.log("RFQ:: index value", index);
      //       var u_qty = action.payload?.qty;
      //       if (action.payload?.qty === undefined) {
      //         u_qty = item.qty;
      //       }
      //       if (clientIndex === index) {
      //         updated_qty = String(u_qty);
      //       }
      //     }
      //     //set commision
      //     var u_f_markup_fees = action.payload?.markup_fees;
      //     if (action.payload?.markup_fees === undefined) {
      //       u_f_markup_fees = item.markup_fees;
      //     }
      //     if (clientIndex === index) {
      //       updated_markup_fees = String(u_f_markup_fees);
      //     }
      //     if (client_id !== 0) {
      //       var partialValue = typeof updated_qty === "string" ? Number(updated_qty.replace(/,/g, "")) : Number(updated_qty);
      //       var totalValue =
      //         typeof state.rfq_form_data.quantity === "string"
      //           ? Number(state.rfq_form_data.quantity.replace(/,/g, ""))
      //           : Number(state.rfq_form_data.quantity);
      //       var percentage = (100 * partialValue) / totalValue;
      //       // console.log("percentage", percentage);
      //       updated_percentage = percentage.toFixed(0);
      //     }
      //   } else {
      //     // equity and other form
      //     let client_id = action.payload?.client_id;
      //     updated_set_fees = action.payload?.set_fees;
      //     updated_fees = action.payload?.fees;
      //     //updated_amount = action.payload?.amount !== undefined ? action.payload?.amount : updated_amount;
      //     updated_percentage = action.payload?.percentage;
      //     if (item.client_id === action.payload?.client_id) {
      //       updated_markup_type = action.payload?.markup_type !== undefined ? action.payload?.markup_type : updated_markup_type;
      //       updated_markup_value = action.payload?.markup_value !== undefined ? action.payload?.markup_value : updated_markup_value;
      //     }
      //     let clientIndex = action.payload?.updated_index;
      //     if (action.payload?.client_id) {
      //       if (clientIndex === index) {
      //         updated_client_id = client_id;
      //       }
      //     }
      //     // console.log("handleMarkupChange in:: updated_client_id", updated_client_id);
      //     // console.log("handleMarkupChange in::", updated_markup_type);
      //     // console.log("handleMarkupChange in::", updated_markup_type);
      //     // console.log("handleMarkupChange in ::", updated_markup_value);
      //     // console.log(item, "allocates_inputs");
      //     //console.log("RFQ:: new qty value", action.payload?.qty);
      //     if (index === 0 && state.form_allocates_inputs.length <= 1) {
      //       if (typeof state.form_data.quantity === "string") {
      //         let i_qty: String = String(state.form_data.quantity);
      //         let quantity_set = Number(i_qty.replace(/,/g, ""));
      //         updated_qty = String(quantity_set);
      //       } else {
      //         updated_qty = String(state.form_data.quantity);
      //       }
      //       if (typeof state.form_data.fund_amount === "string") {
      //         let i_amt: String = String(state.form_data.fund_amount);
      //         let amt_set = Number(i_amt.replace(/,/g, ""));
      //         updated_amount = String(amt_set);
      //       } else {
      //         updated_amount = String(state.form_data.fund_amount);
      //       }
      //     } else {
      //       console.log("Fund:: clientIndex value", clientIndex);
      //       console.log("Fund:: index value", index);
      //       var u_f_qty = action.payload?.qty;
      //       if (action.payload?.qty === undefined) {
      //         u_f_qty = item.qty;
      //       }
      //       if (clientIndex === index) {
      //         updated_qty = String(u_f_qty);
      //       }
      //       var u_f_amt = action.payload?.amount;
      //       if (action.payload?.amount === undefined) {
      //         u_f_amt = item.amount;
      //       }
      //       if (clientIndex === index) {
      //         updated_amount = String(u_f_amt);
      //       }
      //     }
      //     //set commision
      //     var u_f_markup_fees = action.payload?.markup_fees;
      //     if (action.payload?.markup_fees === undefined) {
      //       u_f_markup_fees = item.markup_fees;
      //     }
      //     if (clientIndex === index) {
      //       updated_markup_fees = String(u_f_markup_fees);
      //     }
      //     if (Number(state.form_data.vendor) === 3) {
      //       if (state.form_data.fund_order_type.toLowerCase() === "amount") {
      //         var partialValue = typeof updated_amount === "string" ? Number(updated_amount.replace(/,/g, "")) : Number(updated_amount);
      //         var totalValue =
      //           typeof state.form_data.fund_amount === "string"
      //             ? Number(state.form_data.fund_amount.replace(/,/g, ""))
      //             : Number(state.form_data.fund_amount);
      //         var percentage = (100 * partialValue) / totalValue;
      //         // console.log("percentage", percentage);
      //         updated_percentage = percentage.toFixed(0);
      //       } else {
      //         var partialValue = typeof updated_qty === "string" ? Number(updated_qty.replace(/,/g, "")) : Number(updated_qty);
      //         var totalValue =
      //           typeof state.form_data.quantity === "string" ? Number(state.form_data.quantity.replace(/,/g, "")) : Number(state.form_data.quantity);
      //         var percentage = (100 * partialValue) / totalValue;
      //         // console.log("percentage", percentage);
      //         updated_percentage = percentage.toFixed(0);
      //       }
      //     } else {
      //       var partialValue = typeof updated_qty === "string" ? Number(updated_qty.replace(/,/g, "")) : Number(updated_qty);
      //       var totalValue =
      //         typeof state.form_data.quantity === "string" ? Number(state.form_data.quantity.replace(/,/g, "")) : Number(state.form_data.quantity);
      //       var percentage = (100 * partialValue) / totalValue;
      //       // console.log("percentage", percentage);
      //       updated_percentage = percentage.toFixed(0);
      //     }
      //   }
      //   if (updated_percentage === undefined) {
      //     updated_percentage = 0;
      //   }
      //   console.log("Fund:: updated amount value", updated_amount);
      //   console.log("Fund:: reducer item ", item);
      //   return (item = {
      //     client_id: updated_client_id,
      //     qty: updated_qty,
      //     percentage: updated_percentage,
      //     fees: updated_fees,
      //     set_fees: updated_set_fees,
      //     markup_fees: updated_markup_fees,
      //     amount: updated_amount,
      //     markup_type: updated_markup_type,
      //     markup_value: updated_markup_value,
      //   });
      // });
      // return {
      //   ...state,
      //   form_allocates_inputs: input,
      // };
    },
    remove_form_allocates_inputs: (state: any, action: any) => {
      if (Number(action.payload.mktid) === 5) {
        return {
          ...state,
          form_allocates_inputs: [
            ...state.form_allocates_inputs.slice(0, action.payload.index),
            ...state.form_allocates_inputs.slice(action.payload.index + 1),
          ],
          rfq_form_data: {
            ...state.rfq_form_data,
            allocate_portfolios: [
              ...state.rfq_form_data.allocate_portfolios.slice(0, action.payload.index),
              ...state.rfq_form_data.allocate_portfolios.slice(action.payload.index + 1),
            ],
          },
        };
      } else {
        return {
          ...state,
          form_allocates_inputs: [
            ...state.form_allocates_inputs.slice(0, action.payload.index),
            ...state.form_allocates_inputs.slice(action.payload.index + 1),
          ],
          form_data: {
            ...state.form_data,
            allocate_portfolios: [
              ...state.form_data.allocate_portfolios.slice(0, action.payload.index),
              ...state.form_data.allocate_portfolios.slice(action.payload.index + 1),
            ],
          },
        };
      }
    },
    clear_form_allocates_inputs: (state: any) => {
      return {
        ...state,
        form_allocates_inputs: [
          {
            client_id: null,
            qty: "0",
            percentage: "",
            fees: 0,
            set_fees: 0,
            markup_fees: 0,
            amount: 0,
            markup_type: "abs",
            markup_value: 0,
            est_markup: 0,
            inverse_convert_amt_display: 0,
            inverse_currency_amt_display: 0,
            markup_price: 0,
          },
        ],
        form_data: {
          ...state.form_data,
          allocate_portfolios: [
            {
              client_id: null,
              qty: "0",
              percentage: "",
              fees: 0,
              set_fees: 0,
              markup_fees: 0,
              amount: 0,
              markup_type: "abs",
              markup_value: 0,
              est_markup: 0,
              inverse_convert_amt_display: 0,
              inverse_currency_amt_display: 0,
              markup_price: 0,
            },
          ],
        },
        rfq_form_data: {
          ...state.rfq_form_data,
          allocate_portfolios: [
            {
              client_id: null,
              qty: "0",
              percentage: "",
              fees: 0,
              set_fees: 0,
              markup_fees: 0,
              amount: 0,
              markup_type: "abs",
              markup_value: 0,
              est_markup: 0,
              inverse_convert_amt_display: 0,
              inverse_currency_amt_display: 0,
              markup_price: 0,
            },
          ],
        },
      };
    },
    update_client_markup_data: (state: any, action: any) => {
      return {
        ...state,
        clientMarkupData: action.payload,
      };
    },
    update_form_allocates_inputs: (state: any, action: any) => {
      if (Number(action.payload.mktid) === 5) {
        if ("client_id" in action.payload) {
          console.log("updateIDDDDD", action.payload);
          return {
            ...state,
            form_allocates_inputs: state.form_allocates_inputs.map((input: any, index: any) => {
              if (input.client_id === action.payload.client_id) {
                return {
                  ...input,
                  [action.payload.field]: action.payload.value,
                };
              }
              return input;
            }),
            rfq_form_data: {
              ...state.rfq_form_data,
              allocate_portfolios: state.form_allocates_inputs.map((input: any, index: any) => {
                if (input.client_id === action.payload.client_id) {
                  return {
                    ...input,
                    [action.payload.field]: action.payload.value,
                  };
                }
                return input;
              }),
            },
          };
        } else {
          console.log("updateIDDDDD else", action.payload);
          return {
            ...state,
            form_allocates_inputs: state.form_allocates_inputs.map((input: any, index: any) => {
              if (index === action.payload.index) {
                return {
                  ...input,
                  [action.payload.field]: action.payload.value,
                };
              }
              return input;
            }),
            rfq_form_data: {
              ...state.rfq_form_data,
              allocate_portfolios: state.form_allocates_inputs.map((input: any, index: any) => {
                if (index === action.payload.index) {
                  return {
                    ...input,
                    [action.payload.field]: action.payload.value,
                  };
                }
                return input;
              }),
            },
          };
        }
      } else {
        // not for bond
        if ("client_id" in action.payload) {
          console.log("Update Forex with client id", action.payload);
          return {
            ...state,
            form_allocates_inputs: state.form_allocates_inputs.map((input: any, index: any) => {
              if (input.client_id === action.payload.client_id) {
                if (action.payload.field === "markup_value" || action.payload.field === "markup_type") {
                  if (Number(input.markup_value) === 0 || (Number(input.markup_value) !== action.payload.value && action.payload.value !== 0)) {
                    if (action.payload.field === "markup_type" && String(action.payload.value) !== String(input.markup_type)) {
                      return {
                        ...input,
                        [action.payload.field]: action.payload.value,
                      };
                    } else {
                      return {
                        ...input,
                        [action.payload.field]: action.payload.value,
                      };
                    }
                  }
                } else {
                  return {
                    ...input,
                    [action.payload.field]: action.payload.value,
                  };
                }
              }
              if (input.client_id === 0) {
                return {
                  ...input,
                  ["markup_type"]: "abs",
                  ["markup_value"]: 0,
                };
              }
              return input;
            }),
            form_data: {
              ...state.form_data,
              allocate_portfolios: state.form_allocates_inputs.map((input: any, index: any) => {
                if (input.client_id === action.payload.client_id) {
                  if (action.payload.field === "markup_value" || action.payload.field === "markup_type") {
                    if (Number(input.markup_value) === 0 || (Number(input.markup_value) !== action.payload.value && action.payload.value !== 0)) {
                      if (action.payload.field === "markup_type" && String(action.payload.value) !== String(input.markup_type)) {
                        return {
                          ...input,
                          [action.payload.field]: action.payload.value,
                        };
                      } else {
                        return {
                          ...input,
                          [action.payload.field]: action.payload.value,
                        };
                      }
                    }
                  } else {
                    return {
                      ...input,
                      [action.payload.field]: action.payload.value,
                    };
                  }
                }
                if (input.client_id === 0) {
                  return {
                    ...input,
                    ["markup_type"]: "abs",
                    ["markup_value"]: 0,
                  };
                }
                return input;
              }),
            },
          };
        } else {
          console.log("Update Forex without client ID", action.payload);
          return {
            ...state,
            form_allocates_inputs: state.form_allocates_inputs.map((input: any, index: any) => {
              if (index === action.payload.index) {
                if (action.payload.field === "markup_value" || action.payload.field === "markup_type") {
                  if (Number(input.markup_value) === 0 || (Number(input.markup_value) !== action.payload.value && action.payload.value !== 0)) {
                    if (action.payload.field === "markup_type" && String(action.payload.value) !== String(input.markup_type)) {
                      return {
                        ...input,
                        [action.payload.field]: action.payload.value,
                      };
                    } else {
                      return {
                        ...input,
                        [action.payload.field]: action.payload.value,
                      };
                    }
                  }
                } else {
                  return {
                    ...input,
                    [action.payload.field]: action.payload.value,
                  };
                }
              }
              if (input.client_id === 0) {
                return {
                  ...input,
                  ["markup_type"]: "abs",
                  ["markup_value"]: 0,
                };
              }
              return input;
            }),
            form_data: {
              ...state.form_data,
              allocate_portfolios: state.form_allocates_inputs.map((input: any, index: any) => {
                if (index === action.payload.index) {
                  if (action.payload.field === "markup_value" || action.payload.field === "markup_type") {
                    if (Number(input.markup_value) === 0 || (Number(input.markup_value) !== action.payload.value && action.payload.value !== 0)) {
                      if (action.payload.field === "markup_type" && String(action.payload.value) !== String(input.markup_type)) {
                        return {
                          ...input,
                          [action.payload.field]: action.payload.value,
                        };
                      } else {
                        return {
                          ...input,
                          [action.payload.field]: action.payload.value,
                        };
                      }
                    }
                  } else {
                    return {
                      ...input,
                      [action.payload.field]: action.payload.value,
                    };
                  }
                }
                if (input.client_id === 0) {
                  return {
                    ...input,
                    ["markup_type"]: "abs",
                    ["markup_value"]: 0,
                  };
                }
                return input;
              }),
            },
          };
        }
      }
    },
    add_form_allocates_inputs: (state: any, action: any) => {
      if (Number(action.payload.mktid) === 5) {
        return {
          ...state,
          form_allocates_inputs: [...state.form_allocates_inputs, ...action.payload.data],
          rfq_form_data: {
            ...state.rfq_form_data,
            allocate_portfolios: [...state.rfq_form_data.allocate_portfolios, ...action.payload.data],
          },
        };
      } else {
        return {
          ...state,
          form_allocates_inputs: [...state.form_allocates_inputs, ...action.payload.data],
          form_data: {
            ...state.form_data,
            allocate_portfolios: [...state.form_data.allocate_portfolios, ...action.payload.data],
          },
        };
      }
    },
    update_rfq_timer: (state: any, action: any) => {
      return {
        ...state,
        rfq_timer: action.payload,
      };
    },

    update_rfq_form_data: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        rfq_form_data: {
          ...state.rfq_form_data,
          ...action.payload,
        },
      };
    },
    update_form_data: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        form_data: {
          ...state.form_data,
          ...action.payload,
        },
      };
    },
    update_fx_rfq_quote: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        fx_rfq_quote_data: action.payload,
      };
    },
    update_rfs_stream_channel_data: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        rfs_stream_channel_data: action.payload,
      };
    },
    clear_tradeweb_quotes_stream_channel_data: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        tradeweb_quotes_stream_channel_data: {
          px: 0,
          qid: "",
          rid: "",
          sec: "",
          sz: 0,
          tm: "",
          typ: "",
          vid: 0,
        },
      };
    },
    update_tradeweb_quotes_stream_channel_data: (state: any, action: any) => {
      // console.log(action.payload, "RFQ:: action payload");
      return {
        ...state,
        tradeweb_quotes_stream_channel_data: action.payload,
      };
    },
    updateresponsePlaceOrder: (state: any) => {
      return {
        ...state,
        responsePlaceOrder: {},
      };
    },
    updateName: (state: any, action: any) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    updateMarketId: (state: any, action: any) => {
      return {
        ...state,
        market_id: action.payload,
      };
    },
    updateAsk: (state: any, action: any) => {
      return {
        ...state,
        ask: action.payload,
      };
    },
    updateBid: (state: any, action: any) => {
      return {
        ...state,
        bid: action.payload,
      };
    },
    updateRate: (state: any, action: any) => {
      return {
        ...state,
        rate: action.payload,
      };
    },
    updatelastPrice: (state: any, action: any) => {
      return {
        ...state,
        last_price: action.payload,
      };
    },
  },
  extraReducers: {
    //START Cancel RFQ
    [cancelRFQ.rejected]: () => {},
    [cancelRFQ.pending]: () => {},

    [cancelRFQ.fulfilled]: (state, { payload }) => {
      console.log("RFQ request cancelled Successfully!", payload);
      return {
        ...state,
        responseCancelRFQ: payload,
      };
    },
    //END Cancel RFQ
    [fetchSecurities.pending]: () => {
      // console.log("fetchSecurities Pending");
    },
    [fetchSecurities.fulfilled]: (state, { payload }) => {
      // console.log("fetchProfilePortfolio Fetched Successfully!",payload);
      return {
        ...state,
        market_id: payload.data.market_id,
      };
    },

    //START getVendorList
    [getVendorList.rejected]: () => {},
    [getVendorList.pending]: () => {},
    [getVendorList.fulfilled]: (state, { payload }) => {
      //for client set vendors
      let clientobjectArray = [];
      if (payload.data?.clientVendors) {
        console.log("clientVendors:: api", payload.data.clientVendors);
        clientobjectArray = payload.data.clientVendors;
      }
      //equity vendor
      const objectArray = payload.data.vendors;
      let newArray = [];
      if (objectArray.length > 0) {
        newArray = objectArray.map((item: any) => ({
          value: item.id,
          label: item.title,
        }));
      }
      //bond vendor
      let objectArray2 = payload.data.bondVendors;
      let newArray2 = [];
      if (objectArray2.length > 0) {
        newArray2 = objectArray2.map((item: any) => ({
          value: item.id,
          label: item.title,
        }));
      }
      //forex vendor
      let objectArray3 = payload.data.forexVendors;
      let newArray3 = [];
      if (objectArray3?.length > 0) {
        newArray3 = objectArray3.map((item: any) => ({
          value: item.id,
          label: item.title,
        }));
      }
      return {
        ...state,
        vendorsoptions_val: newArray,
        bond_vendor_option: newArray2,
        forex_vendor_option: newArray3,
        show_bond_vendor_dropdown: payload.data.show_bond_vendor_dropdown,
        show_forex_vendor_dropdown: payload.data.show_forex_vendor_dropdown,
        is_show_vendor_dropdown: payload.data.show_dropdown,
        clientVendors: clientobjectArray,
      };
      // if (payload.data.bondVendors) {
      //   const objectArray2 = payload.data.bondVendors;
      //   const newArray2: any = objectArray2.map((item: any) => ({
      //     value: item.id,
      //     label: item.title,
      //   }));
      //   return {
      //     ...state,
      //     vendorsoptions_val: newArray,
      //     bond_vendor_option: newArray2,
      //     show_bond_vendor_dropdown: payload.data.show_bond_vendor_dropdown,
      //     is_show_vendor_dropdown: payload.data.show_dropdown,
      //     clientVendors: clientobjectArray,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     vendorsoptions_val: newArray,
      //     is_show_vendor_dropdown: payload.data.show_dropdown,
      //     clientVendors: clientobjectArray,
      //   };
      // }
    },
    [getVendorList.rejected]: () => {},
    //END getVendorList
    //START getOrderTypes
    [getOrderTypes.rejected]: () => {},
    [getOrderTypes.pending]: () => {},
    [getOrderTypes.fulfilled]: (state, { payload }) => {
      // console.log("getOrderTypes Fetched Successfully!",payload.data);
      const objectArray = Object.entries(payload.data);
      // const result: any = [];
      // objectArray.forEach(([key, value]) => {
      //   result["value"] = value;
      //   result["label"] = value;
      // });
      const newArray: any = objectArray.map((item: any) => ({
        value: item[0],
        label: item[1],
      }));
      const newArray2: any = Object.entries(payload.fund_order_types).map((item: any) => ({
        value: item[0],
        label: item[1],
      }));
      const newArray3: any =
        payload.markup_type === undefined
          ? {}
          : Object.entries(payload.markup_type).map((item: any) => ({
              value: item[0],
              label: item[1],
            }));
      // console.log("limit option ", newArray);
      return {
        ...state,
        fund_order_types_value: newArray2,
        limits_val: newArray,
        markupOpt_val: newArray3,
      };
    },
    [getOrderTypes.rejected]: () => {},
    //END getOrderTypes
    //START getClientMarkup
    [getClientMarkup.rejected]: () => {},
    [getClientMarkup.pending]: () => {},
    [getClientMarkup.fulfilled]: (state, { payload }) => {
      console.log("getClientMarkup Fetched Successfully!", payload.data);
      return {
        ...state,
        clientMarkupData: payload.data,
      };
    },
    [getClientMarkup.rejected]: () => {},
    //END getClientMarkup
    //START getOrderTimeTypes
    [getOrderTimeTypes.rejected]: () => {},
    [getOrderTimeTypes.pending]: () => {},
    [getOrderTimeTypes.fulfilled]: (state, { payload }) => {
      // console.log("getOrderTimeTypes Fetched Successfully!",payload.data);
      const objectArray = Object.entries(payload.data);
      // const result: any = [];
      // objectArray.forEach(([key, value]) => {
      //   result[key] = value;
      // });
      const newArray: any = objectArray.map((item: any) => ({
        value: item[0],
        label: item[1],
      }));
      return {
        ...state,
        days_value: newArray,
      };
    },
    [getOrderTimeTypes.rejected]: () => {},
    //END getOrderTypes

    //START placeOrder
    [placeOrder.rejected]: (state, { payload }) => {
      return {
        ...state,
        place_order_status: "rejected",
      };
    },

    [placeOrder.pending]: (state, { payload }) => {
      return {
        ...state,
        place_order_status: "pending",
      };
    },
    [placeOrder.fulfilled]: (state, { payload }) => {
      console.log("placeOrder Fetched Successfully!", payload);
      return {
        ...state,
        responsePlaceOrder: payload,
        place_order_status: "success",
      };
    },
    //END placeOrder
    //START getFXQuoteRequest
    [getFXQuoteRequest.rejected]: (state, { payload }) => {
      return {
        ...state,
        get_quote_status: "rejected",
      };
    },
    [getFXQuoteRequest.pending]: (state, { payload }) => {
      return {
        ...state,
        get_quote_status: "pending",
      };
    },
    [getFXQuoteRequest.fulfilled]: (state, { payload }) => {
      console.log("getFXQuoteRequest Fetched Successfully!", payload);
      return {
        ...state,
        responsegetFXQuoteRequest: payload.data,
        get_quote_status: "success",
      };
    },
    // END getFXQuoteRequest
    //START placeFxspotOrder
    [placeFxspotOrder.rejected]: (state, { payload }) => {
      return {
        ...state,
        rfq_place_order_status: "rejected",
      };
    },
    [placeFxspotOrder.pending]: (state, { payload }) => {
      return {
        ...state,
        rfq_place_order_status: "pending",
      };
    },
    [placeFxspotOrder.fulfilled]: (state, { payload }) => {
      console.log("placeFxspotOrder Fetched Successfully!", payload);
      return {
        ...state,
        responseplaceFxspotOrder: payload,
        rfq_place_order_status: "success",
      };
    },
    //END placeFxspotOrder
    //START requestRfq
    [requestRfq.rejected]: () => {},
    [requestRfq.pending]: () => {},
    [requestRfq.fulfilled]: (state, { payload }) => {
      console.log("requestRfq Fetched Successfully!", payload);
      return {
        ...state,
        responserequestRfq: payload,
      };
    },
    [requestRfq.rejected]: () => {},
    //END requestRfq
    //START getFees
    [getFees.rejected]: () => {},
    [getFees.pending]: () => {},
    [getFees.fulfilled]: (state, { payload }) => {
      console.log("getFees Fetched Successfully!", payload);
      return {
        ...state,
        responseGetFees: payload,
      };
    },
    [getFees.rejected]: () => {},
    //END getFees
  },
});

export const {
  update_fx_quote_data,
  updateBuyBtnDisabled,
  updateSellBtnDisabled,
  updateresponsePlaceOrder,
  update_rfq_form_data,
  update_rfq_timer,
  update_form_data,
  update_rfs_stream_channel_data,
  update_fx_rfq_quote,
  update_tradeweb_quotes_stream_channel_data,
  clear_tradeweb_quotes_stream_channel_data,
  add_form_allocates_inputs,
  update_form_allocates_inputs,
  remove_form_allocates_inputs,
  update_map_allocates_inputs,
  clear_form_allocates_inputs,
  update_client_markup_data,
} = OrderSection.actions;

export const rfq_timer_key = (state: any) => state.orderSection.rfq_timer;
export const marketId = (state: any) => state.orderSection.market_id;
export const name = (state: any) => state.orderSection.name;
export const securityId = (state: any) => state.orderSection.security_id;
export const bid = (state: any) => state.orderSection.bid;
export const ask = (state: any) => state.orderSection.ask;
export const rate = (state: any) => state.orderSection.rate;
export const last_price_key = (state: any) => state.orderSection.last_price;
export const is_show_vendor_dropdown_key = (state: any) => state.orderSection.is_show_vendor_dropdown;
export const show_bond_vendor_dropdown_key = (state: any) => state.orderSection.show_bond_vendor_dropdown;
export const show_forex_vendor_dropdown_key = (state: any) => state.orderSection.show_forex_vendor_dropdown;
export const limits_key = (state: any) => state.orderSection.limits_val;
export const vendorsoptions_key = (state: any) => state.orderSection.vendorsoptions_val;
export const bond_vendor_option_key = (state: any) => state.orderSection.bond_vendor_option;
export const forex_vendor_option_key = (state: any) => state.orderSection.forex_vendor_option;
// export const vendor_option_key = (state: any) => state.orderSection.vendorsoptions_val;
export const markupOpt_key = (state: any) => state.orderSection.markupOpt_val;
export const fund_order_types_key = (state: any) => state.orderSection.fund_order_types_value;
export const days_key = (state: any) => state.orderSection.days_value;
export const responsePlaceOrder_key = (state: any) => state.orderSection.responsePlaceOrder;
export const responseplaceFxspotOrder_key = (state: any) => state.orderSection.responseplaceFxspotOrder;
export const responsegetFXQuoteRequest_key = (state: any) => state.orderSection.responsegetFXQuoteRequest;
export const responserequestRfq_key = (state: any) => state.orderSection.responserequestRfq;
export const responseCancelRFQ_key = (state: any) => state.orderSection.responseCancelRFQ;
export const responseGetFees_key = (state: any) => state.orderSection.responseGetFees;
export const clientMarkupData_key = (state: any) => state.orderSection.clientMarkupData;

export default OrderSection.reducer;
