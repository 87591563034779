import React, { useState, useEffect, useRef, createRef } from "react";
import { toast } from "react-toastify";
import closeIcon from "../../assets/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  is_update_transaction_data,
  transactionExecute,
  transactionKill,
  sendEmailAllFund,
  transactionExecuteResponseKey,
  transactionFillResponseKey,
  transactionKillResponseKey,
  sendEmailAllFundResponseKey,
  update_transaction_data,
  updatetransactionKillResponse,
  updatetransactionExecuteResponse,
  removeTransactionData,
  updatesendEmailAllFundResponse,
  transactionFill,
  updatetransactionFillResponse,
} from "../../Redux/features/Orders/OrderListingSlice";
import Button from "../Button/Button";
import OrderConfirmationModal from "../OrderConfirmationModal/OrderConfirmationModal";
import TextBox from "../TextBox/TextBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { price_format, user_validate_dates } from "../../helpers/helper";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { addDays, format, getDay } from "date-fns";
function TransactionDetails({ details, selectedorderid, setClose }: any) {
  // console.log("Date today,", Date.now());
  const dispatch = useAppDispatch();
  const selectedID = useAppSelector((state) => state.mainDashBoard.selectedID);
  const [bookingDialog, set_bookingDialog] = useState(false);
  const [booking_details, set_booking_details] = useState<any>([]);
  const [book_price, set_book_price] = useState(0);
  // const [book_execution_fees, set_book_execution_fees] = useState(0);
  const [book_qty, set_book_qty] = useState(0);
  const [book_ref, set_book_ref] = useState("");
  const [book_sett_date, set_book_sett_date] = useState<any>("");
  const [book_trade_date, set_book_trade_date] = useState<any>("");
  const [book_execution_fees, set_book_execution_fees] = useState(0);
  const [book_commission, set_book_commission] = useState(0);
  const [book_ib_execution_fees, set_book_ib_execution_fees] = useState(0);
  const [btn_load_book, set_btn_load_book] = useState(false);
  const [btn_play_book, set_btn_play_book] = useState(true);
  const [btn_email_disable, set_btn_email_disable] = useState(false);
  const [btn_fill_disable, set_btn_fill_disable] = useState(false);
  const [book_btn_txt, set_book_btn_txt] = useState("Fill");
  const [send_email_lbl, set_send_email_lbl] = useState("Send Email");
  const [edb_default_content, set_edb_default_content] = useState("");
  const [selected_order_id, set_selected_order_id] = useState(selectedorderid);
  const [is_submitOrderFrom, set_is_submitOrderFrom] = useState(false);
  const [is_first_time, set_is_first_time] = useState(1);
  const [formName, setFormName] = useState("finle-buy-btn");
  const [isexecuteTrade, set_isexecuteTrade] = useState(false);
  const [isStateless, set_isStateless] = useState(false);
  const [btn_load_execute, set_btn_load_execute] = useState(false);
  const [btn_play_execute, set_btn_play_execute] = useState(true);
  const [btn_kill_load_execute, set_btn_kill_load_execute] = useState(false);
  const [btn_kill_execute, set_btn_kill_execute] = useState(true);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  let inputButtonElement: any = useRef(null);

  const transaction_execute_response: any = useAppSelector(transactionExecuteResponseKey);
  const transaction_fill_response: any = useAppSelector(transactionFillResponseKey);
  const transaction_kill_response: any = useAppSelector(transactionKillResponseKey);
  const sendemail_response: any = useAppSelector(sendEmailAllFundResponseKey);
  const [fund_order_type, set_fund_order_type] = useState("");
  const [fund_amount_qty, set_fund_amount_qty] = useState(0);
  const [fund_order_type_title, set_fund_order_type_title] = useState("");
  const [amount_type_title, set_amount_type_title] = useState("");
  useEffect(() => {
    if (details) {
      if (String(details.fund_order_type) !== "") {
        set_fund_order_type(fund_order_type);
      }
      if (String(details.fund_order_type) === "amount") {
        set_fund_order_type_title("Amount: ");
        // set_fund_order_type_title("Request for market value: ");
        set_fund_amount_qty(details.fund_amount);
        set_amount_type_title("Market Value");
      } else {
        set_fund_order_type_title("Amount: ");
        // set_fund_order_type_title("Request for shares: ");
        set_fund_amount_qty(details.quantity);
        set_amount_type_title("Shares");
      }
    }
    //get settlement date from current date
    const currentDate = new Date();
    let resultDate = addDays(currentDate, 2);
    let dayOfWeek = getDay(resultDate);

    // If the result date is Saturday (6) or Sunday (0), move to Monday
    if (dayOfWeek === 6) {
      resultDate = addDays(resultDate, 2); // Move to Monday
    } else if (dayOfWeek === 0) {
      resultDate = addDays(resultDate, 1); // Move to Monday
    }
    set_book_sett_date(moment(resultDate).format("yyyy-MM-DD"));
    //get trade date from current date

    set_book_trade_date(moment(details.trade_date).format("yyyy-MM-DD"));
    return () => {};
    // eslint-disable-next-line
  }, [details]);
  // useEffect(() => {
  //   if (fund_order_type === "amount") {
  //     set_fund_order_type_title("Amount: USD ");
  //     set_fund_amount_qty(details.fund_amount);
  //   } else {
  //     set_fund_order_type_title("No of shares: ");
  //     set_fund_amount_qty(details.quantity);
  //   }
  //   return () => {};
  //   // eslint-disable-next-line
  // }, [fund_order_type]);
  const showBookModal = (item: any) => {
    set_bookingDialog(true);
    set_booking_details(item);
  };
  const executeTrade = (e: any) => {
    set_booking_details(e);
    confirmAlert({
      title: "Order Release",
      message: "Are you sure you want to release this order?",
      buttons: [
        {
          label: "OK",
          className: "custom-btn ok-btn",
          onClick: () => submitOrderFrom(e, 2),
        },
        {
          label: "Cancel",
          className: "custom-btn cancel-btn",
          onClick: () => dummyclick(e),
        },
      ],
    });
  };
  const killAlert = (item_details: any) => {
    set_isStateless(true);
    set_booking_details(item_details);
    confirmAlert({
      title: "Order Kill",
      message: "Are you sure you want to kill this order?",
      buttons: [
        {
          label: "OK",
          className: "custom-btn ok-btn",
          onClick: () => killTransactions(item_details),
        },
        {
          label: "Cancel",
          className: "custom-btn cancel-btn",
          onClick: () => dummyclick(item_details),
        },
      ],
    });
  };
  const [selected_t_id, set_selected_t_id] = useState(0);
  useEffect(() => {
    console.log("killTransactions --- item id", selectedID);
    if (selectedID) {
      set_selected_t_id(selected_t_id);
    }
    return () => {};
    // eslint-disable-next-line
  }, [selectedID]);
  const killTransactions = (item: any) => {
    // set_is_submitOrderFrom(true);
    // console.log("transactionKill --- item", item);
    set_btn_kill_load_execute(true);
    set_btn_kill_execute(false);
    set_btn_load_book(true);
    set_btn_play_book(false);

    let selectedTid: any = 0;
    if (selectedorderid === undefined) {
      selectedTid = localStorage.getItem("current_selected_tid");
    } else {
      selectedTid = selectedorderid;
    }
    let payload = {
      id: selectedTid,
      record_type: item.record_type,
    };
    console.log("killTransactions", payload);
    dispatch(transactionKill(payload));
    localStorage.removeItem("current_selected_tid");
  };
  // Kill response
  useEffect(() => {
    return () => {
      // dispatch(updatetransactionKillResponse());
      // dispatch(updatetransactionExecuteResponse());
      dispatch(removeTransactionData());
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    (async () => {
      if (Object.keys(transaction_kill_response).length > 0) {
        console.log("transactionKill", transaction_kill_response);
        if (transaction_kill_response.status === 1) {
          var user_id = localStorage.getItem("login-id");
          if (loggedType === "internal") {
            toast.success(
              <>
                <p className="font-weight">{transaction_kill_response?.message_title}</p>
                <p className="text-xs">{transaction_kill_response?.message_description}</p>
              </>,
              {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              }
            );
          } else {
            toast.success("Order Killed", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
          }
        } else {
          toast.error(transaction_kill_response?.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
        set_isStateless(false);
        set_btn_kill_load_execute(false);
        set_btn_kill_execute(true);
        dispatch(updatetransactionKillResponse());
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [transaction_kill_response]);
  useEffect(() => {
    (async () => {
      if (is_submitOrderFrom) {
        hiddenclickFire(true);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [is_submitOrderFrom]);
  const hiddenclickFire = (e: any) => {
    set_btn_load_book(true);
    set_btn_fill_disable(true);
    inputButtonElement.click();
  };
  const submitFillOrderFrom = (e: any, type: any) => {
    // alert("Trade id " + selectedorderid);

    set_btn_fill_disable(true);
    set_book_btn_txt("Filling...");
    let payload = {};
    let selectedTid: any = 0;
    if (selectedorderid === undefined) {
      selectedTid = localStorage.getItem("current_selected_tid");
    } else {
      selectedTid = selectedorderid;
    }
    payload = {
      id: selectedTid,
      book_price: book_price,
      book_qty: book_qty,
      book_ref: book_ref,
      book_sett_date: book_sett_date,
      book_execution_fees: book_execution_fees,
      book_trade_date: book_trade_date,
    };
    dispatch(transactionFill(payload));
  };
  const submitOrderFrom = (e: any, type: any) => {
    // set_is_submitOrderFrom(true);
    let payload = {};
    let selectedTid: any = 0;
    if (selectedorderid === undefined) {
      selectedTid = localStorage.getItem("current_selected_tid");
    } else {
      selectedTid = selectedorderid;
    }
    if (type === 1) {
      set_isexecuteTrade(false);
      set_book_btn_txt("Booking...");
      set_btn_load_book(true);
      set_btn_play_book(false);
      payload = {
        id: selectedTid,
        type: booking_details.record_type,
        is_book: 1,
        book_price: book_price,
        book_commission: book_commission,
        book_execution_fees: book_execution_fees,
        book_ib_execution_fees: book_ib_execution_fees,
      };
    } else {
      set_isexecuteTrade(true);
      set_btn_load_execute(true);
      set_btn_play_execute(false);
      set_isStateless(true);
      set_btn_load_book(true);
      set_btn_play_book(false);
      // console.log("booking_details", booking_details);
      payload = { id: selectedTid, type: e.record_type };
    }
    // console.log("booking_details", e);
    // console.log("booking_details", payload);
    dispatch(transactionExecute(payload));
  };

  const submitTradeFill = (e: any) => {
    e.preventDefault();
    // if (Number(book_qty) === 0) {
    //   toast.error("Please enter valid quantity", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: false,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   return false;
    // }
    if (Number(book_price) === 0) {
      toast.error("Please enter valid price", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    confirmAlert({
      title: "Order Fill",
      message: "Are you sure you want to fill this order?",
      buttons: [
        {
          label: "OK",
          className: "custom-btn ok-btn",
          onClick: () => submitFillOrderFrom(e, 1),
        },
        {
          label: "Cancel",
          className: "custom-btn cancel-btn",
          onClick: () => dummyclick(e),
        },
      ],
    });
  };
  const submitBooking = (e: any) => {
    e.preventDefault();
    confirmAlert({
      title: "Execute",
      message: "Are you sure ?",
      buttons: [
        {
          label: "OK",
          className: "custom-btn ok-btn",
          onClick: () => submitOrderFrom(e, 1),
        },
        {
          label: "Cancel",
          className: "custom-btn cancel-btn",
          onClick: () => dummyclick(e),
        },
      ],
    });
  };
  useEffect(() => {
    if (Object.keys(transaction_fill_response).length > 0) {
      if (transaction_fill_response.status === 1) {
        set_btn_fill_disable(false);

        setClose(false);
        // toast.success("Order Filled", {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else {
        set_btn_fill_disable(false);
        toast.error(transaction_fill_response.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
      dispatch(updatetransactionFillResponse());
      set_book_btn_txt("Fill");
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [transaction_fill_response]);

  // when got responce then fire dependancey
  useEffect(() => {
    (async () => {
      if (Object.keys(transaction_execute_response).length > 0) {
        set_book_btn_txt("Booking");
        set_btn_load_book(false);
        set_btn_play_book(true);
        set_btn_load_execute(false);
        set_btn_play_execute(true);
        setClose(false);
        if (transaction_execute_response.status === 1) {
          if (loggedType === "internal") {
            if (isexecuteTrade === true) {
              if (transaction_execute_response.api_error === 1) {
                toast.error(transaction_execute_response.message, {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: "colored",
                });
              } else {
                toast.success("Order execution request sent successfully", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: "colored",
                });
                // update transection records
                if (typeof transaction_execute_response.trade_record !== "undefined") {
                  var transaction_record = transaction_execute_response.trade_record;
                  dispatch(update_transaction_data(transaction_record));
                } else {
                  dispatch(is_update_transaction_data(true));
                }
              }
            } else {
              set_bookingDialog(false);
              set_book_price(0);
              set_book_commission(0);
              set_book_execution_fees(0);
              set_book_ib_execution_fees(0);
              toast.success("Order Placed", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
              // update transection records
              dispatch(is_update_transaction_data(true));
            }
          } else {
            toast.success("Order execution request sent successfully", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
            // update transection records
            if (typeof transaction_execute_response.trade_record !== "undefined") {
              var transaction_record = transaction_execute_response.trade_record;
              dispatch(update_transaction_data(transaction_record));
            } else {
              dispatch(is_update_transaction_data(true));
            }
          }
        } else {
          if (loggedType === "internal") {
            if (transaction_execute_response.message) {
              toast.error(transaction_execute_response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.error(transaction_execute_response.msg, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
            }
          } else {
            toast.success("Order execution request sent successfully", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
            // update transection records
            if (typeof transaction_execute_response.trade_record !== "undefined") {
              var transaction_record = transaction_execute_response.trade_record;
              dispatch(update_transaction_data(transaction_record));
            } else {
              dispatch(is_update_transaction_data(true));
            }
          }
        }

        dispatch(updatetransactionExecuteResponse());
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [transaction_execute_response]);

  const handleBookExeFees = (e: any) => {
    if (price_format(e.target.value)) {
      // dispatch(update_form_data({ price: e.target.value }));
      set_book_execution_fees(e.target.value);
    }
  };
  const handleBookPrice = (e: any) => {
    if (price_format(e.target.value)) {
      // dispatch(update_form_data({ price: e.target.value }));
      set_book_price(e.target.value);
    }
  };
  const handleBookQty = (e: any) => {
    set_book_qty(e.target.value);
  };

  const handleBookOrderRef = (e: any) => {
    if (e.target.value.length < 30) {
      set_book_ref(e.target.value);
    }
  };

  const handleBookTradeDate = (e: any) => {
    // Validate against the regex
    if (e === null) {
      set_book_trade_date("");
      toast.error("Please enter trade date", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return true;
    }
    console.log("handleBookTradeDate", moment(e).format("yyyy-MM-DD"));
    if (moment(e).format("yyyy-MM-DD") === null || moment(e).format("yyyy-MM-DD") === "") {
      toast.error("Please enter trade date", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
    set_book_trade_date(moment(e).format("yyyy-MM-DD"));
    if (book_sett_date !== null && moment(e).format("yyyy-MM-DD") !== "") {
      if (!user_validate_dates(moment(e).format("yyyy-MM-DD"), book_sett_date)) {
        toast.error("Settlement date must be biger than the trade date.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const handleBookSettDate = (e: any) => {
    // Validate against the regex
    if (e === null) {
      set_book_sett_date("");
      toast.error("Please enter settlement date", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return true;
    }
    console.log("handleBookSettDate", moment(e).format("yyyy-MM-DD"));
    if (moment(e).format("yyyy-MM-DD") === null || moment(e).format("yyyy-MM-DD") === "") {
      toast.error("Please enter settlement date", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
    set_book_sett_date(moment(e).format("yyyy-MM-DD"));
    if (moment(e).format("yyyy-MM-DD") !== null && book_trade_date !== "") {
      if (!user_validate_dates(book_trade_date, moment(e).format("yyyy-MM-DD"))) {
        toast.error("Settlement date must be biger than the trade date.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    // const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    // if (!datePattern.test(e.target.value)) {
    //   // set_book_sett_date("");
    // }
  };

  const handleBookExecutionFees = (e: any) => {
    set_book_execution_fees(e.target.value);
  };
  const handleBookCommission = (e: any) => {
    set_book_commission(e.target.value);
  };
  const handleBookIBExecutionFees = (e: any) => {
    set_book_ib_execution_fees(e.target.value);
  };

  const closeDialog = () => {
    set_bookingDialog(false);
  };
  const [is_open_fill, set_is_open_fill] = useState(false);
  const [is_open_send_email, set_is_open_send_email] = useState(false);
  const [is_open_allfund_email, set_is_open_allfund_email] = useState(false);
  const [is_open_edb_email, set_is_open_edb_email] = useState(false);
  const [editor_content, set_editor_content] = useState("");
  const [to_email, set_to_email] = useState("trading@allfunds.com");
  const [cc_email, set_cc_email] = useState("execution@emfi.uk;sales@emfi.uk;ops@emfi.uk");
  const [is_cc_change_event, set_is_cc_change_event] = useState(false);
  const [email_subject, set_email_subject] = useState("EMFI - Order query for account username API1413601");
  const opensendEmail = () => {
    set_is_open_send_email(true);
    set_is_open_allfund_email(false);
    set_is_open_fill(false);
    set_editor_content("");
    set_email_subject("EMFI - Order query for account username API1413601");
    set_to_email("trading@allfunds.com");
    set_is_open_allfund_email(true);
    set_is_open_edb_email(false);
    let tDetails = details.transaction_details;
    // const securityDescObj = tDetails.find((item: any) => item.key === "Security Description");
    // const isinObj = tDetails.find((item: any) => item.key === "ISIN");
    // const directionObj = tDetails.find((item: any) => item.key === "Direction");
    // const quantityObj = tDetails.find((item: any) => item.key === "Quantity");
    // let txt =
    //   "<p>Dear Team,<br/><br/></p><p>We encountered an error for the following fund. Would you please review and enable the following:</p>" +
    //   "<br/><p>Fund ISIN: " +
    //   isinObj.value +
    //   "</p><p>Fund Name: " +
    //   securityDescObj.value +
    //   "</p>" +
    //   "<br/><p>Let us know if you need further details. Thank you.</p><br/><p>Kind regards</p>";
    // set_editor_content(txt);
    set_to_email("trading@eudepobank.eu");
    set_cc_email("execution@emfi.uk;sales@emfi.uk;ops@emfi.uk");
    set_email_subject("EMFI - New Order for Account 889830");
    console.log("tdetails", tDetails);
    const securityDescObj = tDetails.find((item: any) => item.key === "Security Description");
    const isinObj = tDetails.find((item: any) => item.key === "ISIN");
    const directionObj = tDetails.find((item: any) => item.key === "Direction");
    const quantityObj = tDetails.find((item: any) => item.key === "Quantity");
    let txt =
      "<p>Dear Team,<br/><br/></p><p>Would you please execute the following order:</p>" +
      "<br/><p>Account: EMFI Capital Limited (Root Account 889830)</p><p>Direction: " +
      (directionObj.value.toLowerCase() === "subscribe" ? "Subscribe (Buy)" : "Redemption (Sell)") +
      "</p><p>Amount Type: " +
      amount_type_title +
      "</p>" +
      "<p>" +
      fund_order_type_title +
      " " +
      //new Intl.NumberFormat().format(3000) +
      new Intl.NumberFormat().format(fund_amount_qty) +
      "</p><p>Fund ISIN: " +
      isinObj.value +
      "</p><p>Fund Name: " +
      securityDescObj.value +
      "</p>" +
      "<br/><p>Would you please confirm once the order is filled or if any issues. Thank you.</p><br/><p>Kind regards</p>";
    set_editor_content(txt);
    set_edb_default_content(txt);
  };
  const cancelClick = () => {
    set_is_open_send_email(false);
    set_is_open_allfund_email(false);
    set_is_open_edb_email(false);
    set_is_open_fill(false);
  };
  const handleClickFill = (tDetails: any) => {
    set_is_open_send_email(false);
    set_is_open_allfund_email(false);
    set_is_open_edb_email(false);
    set_is_open_fill(true);
  };
  useEffect(() => {
    if (editor_content) {
      console.log("editor_content", editor_content);
    }
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [editor_content]);
  const handleToEmailSubject = (e: any) => {
    set_email_subject(e.target.value);
  };
  const handleToEmail = (e: any) => {
    set_to_email(e.target.value);
  };
  const handleCcEmail = (e: any) => {
    set_cc_email(e.target.value);
    set_is_cc_change_event(true);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const emails = cc_email.split(";").map((e: any) => e.trim());
      const allValid = emails.every((e: any) => validateEmail(e));
      if (!allValid) {
        toast.error("One or more emails are invalid", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
      set_is_cc_change_event(false);
    };
  }, [is_cc_change_event]);
  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleClickemailType = (type: String, tDetails: any) => {
    if (type === "edb") {
      set_is_open_allfund_email(false);
      set_is_open_edb_email(true);
      set_to_email("trading@eudepobank.eu");
      set_cc_email("execution@emfi.uk;sales@emfi.uk;ops@emfi.uk");
      set_email_subject("EMFI - New Order for Account 889830");
      console.log("tdetails", tDetails);
      const securityDescObj = tDetails.find((item: any) => item.key === "Security Description");
      const isinObj = tDetails.find((item: any) => item.key === "ISIN");
      const directionObj = tDetails.find((item: any) => item.key === "Direction");
      const quantityObj = tDetails.find((item: any) => item.key === "Quantity");
      let txt =
        "<p>Dear Team,<br/><br/></p><p>For our Account in the name of <b>EMFI Capital Limited (Root Account No. 889830)</b>:</p>" +
        "<br/><p>Direction: " +
        (directionObj.value.toLowerCase() === "subscribe" ? "Subscribe/Buy" : "Redemption/Sell") +
        "</p><p>Amount Type: Market Value</p>" +
        "<p>" +
        fund_order_type_title +
        " " +
        //new Intl.NumberFormat().format(3000) +
        new Intl.NumberFormat().format(fund_amount_qty) +
        "</p><p>Fund ISIN: " +
        isinObj.value +
        "</p><p>Fund Name: " +
        securityDescObj.value +
        "</p>" +
        "<br/><p>Would you please confirm once the order is filled or if any issues. Thank you.</p><br/><p>Kind regards</p>";
      set_editor_content(txt);
      set_edb_default_content(txt);
    }
    if (type === "allfund") {
      set_editor_content("");
      set_email_subject("EMFI - Order query for account username API1413601");
      set_to_email("trading@allfunds.com");
      set_is_open_allfund_email(true);
      set_is_open_edb_email(false);
      const securityDescObj = tDetails.find((item: any) => item.key === "Security Description");
      const isinObj = tDetails.find((item: any) => item.key === "ISIN");
      const directionObj = tDetails.find((item: any) => item.key === "Direction");
      const quantityObj = tDetails.find((item: any) => item.key === "Quantity");
      let txt =
        "<p>Dear Team,<br/><br/></p><p>We encountered an error for the following fund. Would you please review and enable the following:</p>" +
        "<br/><p>Fund ISIN: " +
        isinObj.value +
        "</p><p>Fund Name: " +
        securityDescObj.value +
        "</p>" +
        "<br/><p>Let us know if you need further details. Thank you.</p><br/><p>Kind regards</p>";
      set_editor_content(txt);
      set_edb_default_content(txt);
    }
  };
  useEffect(() => {
    if (Object.keys(sendemail_response).length > 0) {
      set_send_email_lbl("Send Email");
      set_btn_email_disable(false);
      console.log("sendemail_response", sendemail_response);
      if (sendemail_response.status === 1) {
        setClose(false);
        toast.success(
          <>
            <p className="font-weight">{sendemail_response?.message}</p>
          </>,
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
      } else {
        toast.error(sendemail_response?.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
      dispatch(updatesendEmailAllFundResponse());
    }

    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line
  }, [sendemail_response]);
  const sendEmailAF = () => {
    set_send_email_lbl("Sending...");
    set_btn_email_disable(true);
    let selectedTid: any = 0;
    if (selectedorderid === undefined) {
      selectedTid = localStorage.getItem("current_selected_tid");
    } else {
      selectedTid = selectedorderid;
    }
    let payload = {
      id: selectedTid,
      to_email: to_email,
      cc_email: cc_email,
      message: editor_content,
      subject: email_subject,
    };
    dispatch(sendEmailAllFund(payload));
  };
  const dummyclick = (e: any) => {};

  return (
    <div className="drower-box rounded-l-2xl w-full overflow-auto">
      {Object.keys(details).length > 0 && (
        <div className="flex justify-between items-center ">
          <h1 className="text-secondaryBg font-bold item-title py-2 text-lg">{details.reference}</h1>

          <div className="transection-button-groups">
            {/* {typeof details.is_execute_btn !== "undefined" &&
              details.is_execute_btn === 1 && (
                <Button
                  type="button"
                  varient={"green"}
                  CTA={"Order Book"}
                  className="drawer-custom-btn"
                  handleClick={() => showBookModal(details)}
                />
              )}*/}
            {details.is_kill === 1 && loggedType === "internal" && (
              <Button
                type="button"
                varient={"red"}
                CTA={"Kill"}
                className="drawer-custom-btn !mr-0"
                handleClick={() => killAlert(details)}
                isCrossIcon={btn_kill_execute}
                isLoaderIcon={btn_kill_load_execute}
              />
            )}
            {details.is_kill === 1 && loggedType !== "internal" && Number(details.is_email_order_sent) === 0 && (
              <Button
                type="button"
                varient={"red"}
                CTA={"Kill"}
                className="drawer-custom-btn !mr-0"
                handleClick={() => killAlert(details)}
                isCrossIcon={btn_kill_execute}
                isLoaderIcon={btn_kill_load_execute}
              />
            )}
            {typeof details.isExecution !== "undefined" &&
              Number(details.is_validate_order) === 0 &&
              (String(details.status).toLocaleLowerCase() === "pending" || String(details.status).toLocaleLowerCase() === "placed") &&
              details.isExecution === 1 && (
                <Button
                  type="button"
                  varient={"green"}
                  CTA={"Release"}
                  className="drawer-custom-btn !ml-4 order-execute-btn"
                  handleClick={() => executeTrade(details)}
                  // isCrossIcon={false}
                  isRightIcon={true}
                  isLoaderIcon={btn_load_execute}
                />
              )}

            {typeof details.isExecution !== "undefined" &&
              (String(details.status).toLocaleLowerCase() === "pending" || String(details.status).toLocaleLowerCase() === "placed") &&
              details.isExecution === 2 && (
                <Button
                  type="button"
                  varient={"green"}
                  CTA={"Release"}
                  className="drawer-custom-btn !ml-4 order-execute-btn"
                  handleClick={() => dummyclick(details)}
                  isRightIcon={true}
                  isLoaderIcon={btn_load_execute}
                  disabled={true}
                  title={"You cannot approve your booked order. please contact another authorizer or EMFI sales coverage."}
                />
              )}
            {Number(details.is_validate_order) === 1 &&
              Number(details.is_email_order_sent) === 0 &&
              String(details.status).toLocaleLowerCase() === "working" && (
                <Button
                  type="button"
                  varient={"green"}
                  CTA={"Email"}
                  className="drawer-custom-btn !ml-4 order-execute-btn ml-0-6rem"
                  handleClick={() => opensendEmail()}
                  isRightIcon={false}
                  isEmailIcon={true}
                  isLoaderIcon={btn_load_execute}
                  // disabled={true}
                />
              )}
            {Number(details.is_validate_order) === 1 && String(details.status).toLocaleLowerCase() === "working" && (
              <Button
                type="button"
                varient={"green"}
                CTA={"Fill"}
                className={`drawer-custom-btn !ml-4 order-execute-btn  ${Number(details.is_email_order_sent) !== 0 ? "ml-0-6rem" : "ml-0"}`}
                handleClick={() => handleClickFill(details)}
                isRightIcon={true}
                isLoaderIcon={btn_load_execute}
                // disabled={true}
              />
            )}
          </div>
          <img
            src={closeIcon}
            onClick={() => {
              setClose(false);
            }}
            className="px-4  h-3 cursor-pointer"
            alt=""
          />
        </div>
      )}
      <div className="items-list">
        {!is_open_send_email &&
          !is_open_fill &&
          details.transaction_details
            ?.filter((i: any) => i.value !== "")
            .map((i: any, index: any) => (
              <div
                key={index}
                className={`${
                  index % 2 === 0
                    ? "flex text-left text-[13px] bg-tableStripeBg h-9 cursor-pointer item-name"
                    : "text-left flex text-[13px] h-9 cursor-pointer item-name"
                } hoverTableCell`}
              >
                <div className="w-[50%] item-name-value">{i.key}</div>
                <div className="w-[50%] item-name-value">
                  <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap">{i.value}</span>
                </div>
              </div>
            ))}
        {is_open_send_email && (
          <>
            <div className="email-wrap form-main">
              {/* <div className="tab-group">
                <div className="tab-main">
                  <ul className="main-menu-group">
                    <li className={`${is_open_allfund_email ? "active" : ""}`}>
                      <a
                        className="nav-link"
                        onClick={() => {
                          handleClickemailType("allfund", details.transaction_details);
                        }}
                        href="#Allfunds"
                      >
                        Allfunds
                      </a>
                    </li>
                    <li className={`${is_open_edb_email ? "active" : ""}`}>
                      <a
                        className="nav-link"
                        onClick={() => {
                          handleClickemailType("edb", details.transaction_details);
                        }}
                        href="#EDB"
                      >
                        EDB
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {is_open_allfund_email && (
                <div className="tab-content allfund">
                  <div className="form-group custom-input">
                    <TextBox label={"To"} value={to_email} onChange={(e: any) => handleToEmail(e)} />
                  </div>
                  <div className="form-group custom-input">
                    <TextBox label={"Cc"} value={cc_email} onChange={(e: any) => handleCcEmail(e)} maxLength={100} />
                  </div>
                  <div className="form-group custom-input">
                    <TextBox label={"Subject"} value={email_subject} onChange={(e: any) => handleToEmailSubject(e)} />
                  </div>
                  <div className="form-group custom-input editor-container ">
                    <ReactQuill
                      theme="snow"
                      value={editor_content}
                      onChange={set_editor_content}
                      style={{
                        minHeight: "200px",
                      }}
                    />
                  </div>
                  <div className="send-email-button-groups">
                    <Button
                      className={"drawer-custom-btn order-execute-btn"}
                      type="button"
                      varient={"darkblue"}
                      CTA={"Cancel"}
                      disabled={btn_load_book}
                      handleClick={(e: any) => cancelClick()}
                    />
                    <Button
                      className={"drawer-custom-btn !ml-4 order-execute-btn"}
                      type="button"
                      varient={"green"}
                      CTA={send_email_lbl}
                      disabled={btn_email_disable}
                      handleClick={(e: any) => sendEmailAF()}
                    />
                  </div>
                </div>
              )}
              {is_open_edb_email && (
                <div className="tab-content edb">
                  <div className="tab-content allfund">
                    <div className="form-group custom-input">
                      <TextBox label={"To"} value={to_email} onChange={(e: any) => handleToEmail(e)} />
                    </div>
                    <div className="form-group custom-input">
                      <TextBox label={"Cc"} value={cc_email} onChange={(e: any) => handleCcEmail(e)} maxLength={100} />
                    </div>
                    <div className="form-group custom-input">
                      <TextBox label={"Subject"} value={email_subject} onChange={(e: any) => handleToEmailSubject(e)} />
                    </div>
                    <div className="form-group custom-input editor-container ">
                      <ReactQuill
                        theme="snow"
                        value={editor_content}
                        onChange={set_editor_content}
                        style={{
                          minHeight: "200px",
                        }}
                      />
                    </div>
                    <div className="send-email-button-groups">
                      <Button
                        className={"drawer-custom-btn order-execute-btn"}
                        type="button"
                        varient={"darkblue"}
                        CTA={"Cancel"}
                        disabled={btn_load_book}
                        handleClick={(e: any) => cancelClick()}
                      />
                      <Button
                        className={"drawer-custom-btn !ml-4 order-execute-btn"}
                        type="button"
                        varient={"green"}
                        CTA={send_email_lbl}
                        disabled={btn_email_disable}
                        handleClick={(e: any) => sendEmailAF()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {is_open_fill && (
          <form onSubmit={submitTradeFill}>
            <div className="email-wrap form-main">
              {/* <div className="form-group custom-input">
                <TextBox label={"Quantity"} value={book_qty} onChange={(e: any) => handleBookQty(e)} />
              </div> */}
              <div className="form-group custom-input">
                <TextBox label={"Price"} value={book_price} onChange={(e: any) => handleBookPrice(e)} />
              </div>
              {/* <div className="form-group custom-input">
                <TextBox label={"Reference"} value={book_ref} onChange={(e: any) => handleBookOrderRef(e)} />
              </div> */}
              <div className="form-group custom-input input-container">
                <ReactDatePicker
                  portalId="trade-date"
                  popperClassName="z-999"
                  strictParsing={true}
                  // isClearable={true}
                  id={"trade_date"}
                  wrapperClassName="datepick "
                  dateFormat="MM/dd/yyyy"
                  onChange={(e: any) => handleBookTradeDate(e)}
                  selected={book_trade_date === "" || book_trade_date === null ? null : moment(book_trade_date).toDate()}
                  minDate={new Date()}
                />
                <label className={`left-2 filled`} htmlFor={"sett_date"}>
                  Trade Date
                </label>
              </div>
              <div className="form-group custom-input input-container">
                <ReactDatePicker
                  portalId="trade-sett-date"
                  popperClassName="z-999"
                  strictParsing={true}
                  // isClearable={true}
                  id={"sett_date"}
                  wrapperClassName="datepick "
                  dateFormat="MM/dd/yyyy"
                  onChange={(e: any) => handleBookSettDate(e)}
                  selected={book_sett_date === "" || book_sett_date === null ? null : moment(book_sett_date).toDate()}
                  minDate={new Date()}
                />
                <label className={`left-2 filled`} htmlFor={"sett_date"}>
                  Settlement Date
                </label>
              </div>

              <div className="form-group custom-input">
                <TextBox label={"Execution Fees"} value={book_execution_fees} onChange={(e: any) => handleBookExeFees(e)} />
              </div>
              <div className="send-email-button-groups">
                <Button
                  className={"drawer-custom-btn order-execute-btn"}
                  type="button"
                  varient={"darkblue"}
                  CTA={"Cancel"}
                  disabled={btn_load_book}
                  handleClick={(e: any) => cancelClick()}
                />
                {/* <Button
                      className={"drawer-custom-btn !ml-4 order-execute-btn"}
                      type="button"
                      varient={"green"}
                      CTA={send_email_lbl}
                      disabled={btn_email_disable}
                      handleClick={(e: any) => sendEmailAF()}
                    /> */}
                <Button
                  className={"drawer-custom-btn !ml-4 order-execute-btn"}
                  type="submit"
                  varient={"green"}
                  CTA={book_btn_txt}
                  disabled={btn_fill_disable}
                  handleClick={(e: any) => dummyclick(e)}
                />
              </div>
            </div>
            <Button
              inputRef={(input: any) => (inputButtonElement = input)}
              className="hidden "
              id={formName}
              type="submit"
              varient={"green"}
              CTA={"Fill"}
              handleClick={() => hiddenclickFire("Sell")}
            />
          </form>
        )}
      </div>
      {bookingDialog && (
        <OrderConfirmationModal show={bookingDialog} confirmClose={closeDialog} header={details.reference}>
          <form onSubmit={submitBooking}>
            <div className="order-wrap form-main">
              <div className="form-group custom-input">
                <TextBox label={"Execution Price"} value={book_price} onChange={(e: any) => handleBookPrice(e)} />
              </div>
              <div className="form-group custom-input">
                <TextBox label={"Execution Fees"} value={book_execution_fees} onChange={(e: any) => handleBookExecutionFees(e)} />
              </div>
              <div className="form-group custom-input">
                <TextBox label={"Commission"} value={book_commission} onChange={(e: any) => handleBookCommission(e)} />
              </div>
              <div className="form-group custom-input">
                <TextBox label={"EMFI Execution Fees"} value={book_ib_execution_fees} onChange={(e: any) => handleBookIBExecutionFees(e)} />
              </div>
              <Button
                className={"w-full mx-0"}
                type="submit"
                varient={"green"}
                CTA={book_btn_txt}
                disabled={btn_load_book}
                handleClick={(e: any) => dummyclick(e)}
              />
            </div>
            <Button
              inputRef={(input: any) => (inputButtonElement = input)}
              className="hidden "
              id={formName}
              type="submit"
              varient={"green"}
              CTA={"Book"}
              handleClick={() => hiddenclickFire("Sell")}
            />
          </form>
        </OrderConfirmationModal>
      )}
    </div>
  );
}

export default TransactionDetails;
