import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFilters, useSortBy, useTable } from "react-table";
import downArrow from "../../../assets/downArrow.svg";
import Loading from "../../../components/Loading/Loading";
import PerformanceRealizedDetails from "../../../components/PerformanceDetails/PerformanceRealizedDetails";
import PerformanceUnRealizedDetails from "../../../components/PerformanceDetails/PerformanceUnRealizedDetails";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  postPerformanceRealizedData,
  postPerformanceUnRealizedData,
} from "../../../Redux/features/Performance/PerformanceSlice";
import { PerformanceColumns } from "../../../utils/column";

export function UnrealizedPerformanceCell(props: any) {
  const dispatch = useAppDispatch();
  const dashBoard = useAppSelector((state) => state.dashBoard);
  const unRealizedDetails = useAppSelector(
    (state) => state.performance.UnRealizedItem
  );
  const performanceDetailStatus = useAppSelector(
    (state) => state.performance.performanceDetailStatus
  );
  const [otherDetails, setOtherDetails] = useState({});
  const [sideDrawer, setSideDrawer] = useState(false);
  // console.log("props.row", props.row.original);
  return (
    <>
      <div
        className="text-right"
        onClick={() => {
          if (props.row.original.un_realized !== 0) {
            dispatch(
              postPerformanceUnRealizedData({
                detail_id: props.row.original.id,
                record_type: "unrealized",
                filter_portfolio_ids: dashBoard?.portfoliosIds,
                currency_filter: dashBoard?.profile.selected_currency,
                start_date: dashBoard.start_date,
                end_date: dashBoard.end_date,
                draw: 1,
                start: 0,
                length: 1000,
              })
            );
            setOtherDetails({
              pnl_security_name: props.row.original.security_name,
              start_filter_date: dashBoard.start_date,
              end_filter_date: dashBoard.end_date,
            });
            setSideDrawer(true);
          }

          // item.realized == 0
        }}
      >
        {props.row.original.un_realized}
      </div>
      <SideDrawer
        // refProp={ref}
        isCashFlowWindow={true}
        sideDrawer={sideDrawer}
        setSideDrawer={setSideDrawer}
        // details={TransactionDetail}
      >
        {performanceDetailStatus === "loading" ? (
          <Loading isMaximized="RecordListing" />
        ) : (
          <PerformanceUnRealizedDetails
            details={unRealizedDetails}
            setClose={setSideDrawer}
            otherDetails={otherDetails}
          />
        )}
      </SideDrawer>
    </>
  );
}
export function RealizedPerformanceCell(props: any) {
  const dispatch = useAppDispatch();
  const dashBoard = useAppSelector((state) => state.dashBoard);
  const [otherDetails, setOtherDetails] = useState({});
  const [sideDrawer, setSideDrawer] = useState(false);
  const RealizedDetails = useAppSelector(
    (state) => state.performance.RealizedItem
  );
  const performanceDetailStatus = useAppSelector(
    (state) => state.performance.performanceDetailStatus
  );
  return (
    <>
      <div
        className="text-right"
        onClick={() => {
          if (props.row.original.realized !== 0) {
            dispatch(
              postPerformanceRealizedData({
                detail_id: props.row.original.id,
                record_type: "realized",
                filter_portfolio_ids: dashBoard?.portfoliosIds,
                currency_filter: dashBoard?.profile.selected_currency,
                start_date: dashBoard.start_date,
                end_date: dashBoard.end_date,
                draw: 1,
                start: 0,
                length: 1000,
              })
            );
            setOtherDetails({
              pnl_security_name: props.row.original.security_name,
              start_filter_date: dashBoard.start_date,
              end_filter_date: dashBoard.end_date,
            });
            setSideDrawer(true);
          }
        }}
      >
        {props.row.original.realized}
      </div>

      <SideDrawer
        // refProp={ref}
        isCashFlowWindow={true}
        sideDrawer={sideDrawer}
        setSideDrawer={setSideDrawer}
        // details={TransactionDetail}
      >
        {performanceDetailStatus === "loading" ? (
          <Loading isMaximized="RecordListing" />
        ) : (
          <PerformanceRealizedDetails
            details={RealizedDetails}
            setClose={setSideDrawer}
            otherDetails={otherDetails}
          />
        )}
      </SideDrawer>
    </>
  );
}

function PerfomanceListing({
  performance,
  isMaximize,
  dashBoard,
  performanceDataDispatch,
}: any) {
  const Columns = useMemo(() => PerformanceColumns, []);
  const Data = useMemo(() => performance.pnl, [performance.pnl]);
  const [hasMore, setHasMore] = useState(true);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable(
    {
      columns: Columns,
      data: Data,
      initialState: {
        hiddenColumns: ["Cost Basis", "Cost Value"],
        sortBy: [
          {
            id: "Name",
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    if (isMaximize !== "minimize") {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(["Cost Basis", "Cost Value"]);
    }
  }, [isMaximize]);

  const performancePayload = useMemo(() => {
    return {
      filter_portfolio_ids: dashBoard?.portfoliosIds,
      currency_filter: dashBoard?.profile.selected_currency,
      start_date: dashBoard.start_date,
      end_date: dashBoard.end_date,
      draw: performance.next_page_index,
      is_filter: 0,
      length: 40,
      start: performance.pnl.length,
    };
  }, [
    dashBoard?.portfoliosIds,
    dashBoard?.profile.selected_currency,
    dashBoard.start_date,
    dashBoard.end_date,
    performance.next_page_index,
    performance.pnl.length,
  ]);

  useEffect(() => {
    performanceDataDispatch({
      filter_portfolio_ids: dashBoard?.portfoliosIds,
      currency_filter: dashBoard?.profile.selected_currency,
      start_date: dashBoard.start_date,
      end_date: dashBoard.end_date,
      draw: 1,
      is_filter: 0,
      length: 40,
      start: 0,
    });
  }, [
    dashBoard?.portfoliosIds,
    dashBoard?.profile.selected_currency,
    dashBoard.start_date,
    dashBoard.end_date,
  ]);

  useEffect(() => {
    if (performance.next_page_index === 0) {
      setHasMore(false);
    }
  }, []);

  return (
    <>
      <div
        className={
          isMaximize === "minimize"
            ? "h-[calc(38vh-20px)] tbScroll"
            : " h-[calc(100vh-(140px+3.2rem))] tbScroll"
        }
        id="performanceListing"
        style={{ overflowY: "auto" }}
      >
        <table className="w-full sticky top-0 bg-layoutBg ">
          <thead {...getTableProps()} className="sticky top-0 bg-layoutBg">
            {headerGroups.map((headerGroup) => (
              <tr
                className="text-left h-8 "
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <>
                    <th
                      {...column.getHeaderProps(
                        isMaximize === "minimize"
                          ? {
                              style: { width: column.width },
                            }
                          : {
                              style: { width: column.MaxWidth },
                            }
                      )}
                      className="px-2 py-2 text-sm font-normal"
                    >
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined })
                        )}
                      >
                        {column.render("Header")}
                      </div>
                      <div className="">
                        {column.canFilter
                          ? column.render("Filter", {
                              row: rows,
                            })
                          : null}
                      </div>
                    </th>
                  </>
                ))}
              </tr>
            ))}
          </thead>
        </table>
        <InfiniteScroll
          style={{ display: "table", width: "100%", tableLayout: "fixed" }}
          next={() => {
            performanceDataDispatch(performancePayload);
          }}
          dataLength={performance.pnl.length}
          hasMore={hasMore}
          loader={<></>}
          scrollableTarget="performanceListing"
          {...getTableBodyProps()}
        >
          {performance.performanceStatus === "loading" ? (
            <Loading isMaximized={isMaximize} />
          ) : (
            rows.map((row: any, index) => {
              prepareRow(row);
              return (
                <tr
                  className={`${
                    index % 2 === 0
                      ? "text-left w-full bg-tableStripeBg"
                      : "text-left w-full"
                  } hoverTableCell cursor-pointer`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any) => (
                    <td
                      className="tableCell px-2"
                      style={
                        isMaximize === "minimize"
                          ? { width: cell.column.width }
                          : { width: cell.column.MaxWidth }
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </InfiniteScroll>
        {performance.performanceStatus === "success" && rows.length === 0 && (
          <tr className="text-center flex items-center justify-center no-data-found">
            <div className="no-data">
                <div className="no-datagroup">
                  <img src="./no-data-found.svg" alt="" />
                  <p>Nothing To Display Here</p>
                </div>
            </div>
          </tr>
        )}
      </div>
    </>
  );
}

export default PerfomanceListing;
