import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { columns } from "../../../container/RecordListing/OrdersListing/OrderColumnNew";
import { fetchData, postData } from "../../../services/apiFunctions";

interface StateTypes {
  selectedColumns: any[];
  filteredColumns: any[];
  getColumnData: any[];
  uncheckedColums: any[];
  loading: boolean;
  orderedColumns: string[];
  baseOrderColumnData: string[];
  filteredColumsStringArray: string[];
  zIndex: boolean;
}

const initialState: StateTypes = {
  selectedColumns: columns,
  filteredColumns: columns,
  getColumnData: [],
  uncheckedColums: [],
  loading: false,
  orderedColumns: [],
  baseOrderColumnData: [],
  filteredColumsStringArray: [],
  zIndex: false,
};

export const fetchColumnData: any = createAsyncThunk("columnsOforder/getColumns", async () => {
  const response: any = await fetchData("gettableHiddenfields");
  // console.log(response?.data?.data, "column response");
  return response?.data?.data;
});

export const updateColumnData: any = createAsyncThunk("columnsOforder/updateColumn", async (data: any) => {
  const response: any = await postData("updateHiddenfields", {
    requestData: data,
  });
  // console.log(response?.data?.data, "column update response");
  // console.log(response.data.data, "column update response");
});

export const fetchOrderedColumnData: any = createAsyncThunk("columnsOforder/getOrderedColumns", async () => {
  const response: any = await fetchData("gettableColumnOrder");
  // console.log(response?.data?.data, "column response");
  return response?.data?.data;
});

export const updateOrderedColumnData: any = createAsyncThunk("columnsOforder/updateOrderedColumn", async (data: any) => {
  const response: any = await postData("updatetableColumnOrder", {
    requestData: data,
  });
  // console.log(response?.data?.data, "column update response");
  return response?.data?.data;
  // console.log(response.data.data, "column update response");
});

const OrderColumnSlice = createSlice({
  name: "columnsOforder",
  initialState,
  reducers: {
    setSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload;
    },
    setOrderColumnData: (state, action) => {
      state.orderedColumns = action.payload;
    },
    setZindex: (state) => {
      state.zIndex = !state.zIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchColumnData.fulfilled, (state, action) => {
      // console.log("fullfilled");
      state.loading = false;
      // Update state with the fetched data
      state.getColumnData = action.payload;
      // console.log(action.payload);

      // Loop over selectedColumns and filter out items with IDs present in getColumnData
      state.filteredColumns = state.selectedColumns.filter((selectedColumn) => !state.getColumnData.includes(selectedColumn.id));
      state.filteredColumsStringArray = state.selectedColumns
        .filter((selectedColumn) => !state.getColumnData.includes(selectedColumn.id))
        .map((selectedColumn) => selectedColumn.id);

      // console.log(state.filteredColumsStringArray);
    });

    builder.addCase(fetchColumnData.pending, (state) => {
      // console.log("pending");
      state.loading = true;
    });
    builder.addCase(fetchColumnData.rejected, (state) => {
      // console.log("rejected");
      state.loading = false;
    });
    builder.addCase(updateColumnData.fulfilled, (state) => {
      // console.log("fullfilled");
      state.loading = false;
    });
    builder.addCase(updateColumnData.pending, (state) => {
      // console.log("pending");
      state.loading = true;
    });
    builder.addCase(updateColumnData.rejected, (state) => {
      // console.log("rejected");
      state.loading = false;
    });
    builder.addCase(fetchOrderedColumnData.fulfilled, (state, action) => {
      // console.log("fullfilled");
      state.loading = false;
      // console.log(action.payload);

      state.orderedColumns = action.payload;
    });

    builder.addCase(fetchOrderedColumnData.pending, (state) => {
      // console.log("pending");
      state.loading = true;
    });
    builder.addCase(fetchOrderedColumnData.rejected, (state) => {
      // console.log("rejected");
      state.loading = false;
    });
    builder.addCase(updateOrderedColumnData.fulfilled, (state, action) => {
      // console.log("fullfilled");
      state.loading = false;
      // console.log(action.payload);
    });
    builder.addCase(updateOrderedColumnData.pending, (state) => {
      // console.log("pending");
      state.loading = true;
    });
    builder.addCase(updateOrderedColumnData.rejected, (state) => {
      // console.log("rejected");
      state.loading = false;
    });
  },
});

export const { setSelectedColumns, setOrderColumnData, setZindex } = OrderColumnSlice.actions;

export const transactionExecuteResponseKey = (state: RootState) => state.orders.transactionExecuteResponse;
export const transactionKillResponseKey = (state: RootState) => state.orders.transactionKillResponse;

export const isUpdateTransactionData = (state: RootState) => state.orders.isUpdateTransactionData;

export default OrderColumnSlice.reducer;
